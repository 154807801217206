export const supportedChains = [
  {
    chainId: 1329,
    name: "Sei v2",
    currency: "SEI",
    explorerUrl: "https://seitrace.com",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/23149.png",
    rpcUrl: "https://evm-rpc.sei-apis.com"
  },
  {
    chainId: 3652501241,
    name: "Bitcoin",
    currency: "BTC",
    explorerUrl: "https://mempool.space",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    rpcUrl: "" // Bitcoin doesn't use RPC in the same way as Ethereum-based chains
  },
  {
    chainId: 1,
    name: "Ethereum",
    currency: "ETH",
    explorerUrl: "https://etherscan.io",
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/info/logo.png",
    rpcUrl: "https://eth.llamarpc.com"
  },
  {
    chainId: 56,
    name: "BNB",
    currency: "BNB",
    explorerUrl: "https://bscscan.com",
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/info/logo.png",
    rpcUrl: "https://binance.llamarpc.com"
  },
  {
    chainId: 137,
    name: "Polygon",
    currency: "POL",
    explorerUrl: "https://polygonscan.com",
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/info/logo.png",
    rpcUrl: "https://polygon.llamarpc.com"
  },
  {
    chainId: 43114,
    name: "Avalanche",
    currency: "AVAX",
    explorerUrl: "https://snowtrace.io",
    icon: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/avalanchex/info/logo.png",
    rpcUrl: "https://api.avax.network/ext/bc/c/rpc"
  },
  {
    chainId: 288,
    name: "Boba Ethereum",
    currency: "ETH",
    explorerUrl: "https://eth.bobascan.com",
    icon: "https://raw.githubusercontent.com/allush/assets/main/images/blockchains/boba-ethereum/logo.png",
    rpcUrl: "https://mainnet.boba.network"
  },
  {
    chainId: 56288,
    name: "Boba BNB",
    currency: "BOBA",
    explorerUrl: "https://bnb.boba.network",
    icon: "https://raw.githubusercontent.com/allush/assets/main/images/blockchains/boba-bnb/logo.png",
    rpcUrl: "https://bobascan.com"
  },
  {
    chainId: 40,
    name: "Telos",
    currency: "TLOS",
    explorerUrl: "https://teloscan.io",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/4660.png",
    rpcUrl: "https://mainnet.telos.net/evm"
  },
  {
    chainId: 2222,
    name: "KAVA EVM",
    currency: "KAVA",
    explorerUrl: "https://explorer.kava.io",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/4846.png",
    rpcUrl: "https://evm.kava.io"
  },
  {
    chainId: 324,
    name: "ZkSync Era",
    currency: "ETH",
    explorerUrl: "https://era.zksync.network",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/24091.png",
    rpcUrl: "https://mainnet.era.zksync.io"
  },
  {
    chainId: 42161,
    name: "Arbitrum One",
    currency: "ETH",
    explorerUrl: "https://arbiscan.io",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
    rpcUrl: "https://arbitrum.llamarpc.com"
  },
  {
    chainId: 42170,
    name: "Arbitrum Nova",
    currency: "ETH",
    explorerUrl: "https://nova-explorer.arbitrum.io",
    icon: "https://l2beat.com/icons/nova.png",
    rpcUrl: "https://nova.arbitrum.io/rpc"
  },
  {
    chainId: 10,
    name: "Optimism",
    currency: "ETH",
    explorerUrl: "https://optimistic.etherscan.io",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
    rpcUrl: "https://mainnet.optimism.io"
  },
  {
    chainId: 1101,
    name: "Polygon zkEVM",
    currency: "ETH",
    explorerUrl: "https://zkevm.polygonscan.com",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    rpcUrl: "https://rpc.ankr.com/polygon_zkevm"
  },
  {
    chainId: 59144,
    name: "Linea",
    currency: "ETH",
    explorerUrl: "https://lineascan.build",
    icon: "https://l2beat.com/icons/linea.png",
    rpcUrl: "https://linea.blockpi.network/v1/rpc/public"
  },
  {
    chainId: 5000,
    name: "Mantle",
    currency: "MNT",
    explorerUrl: "https://mantlescan.xyz",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/27075.png",
    rpcUrl: "https://rpc.mantle.xyz"
  },
  {
    chainId: 8453,
    name: "Base",
    currency: "ETH",
    explorerUrl: "https://basescan.org",
    icon: "https://raw.githubusercontent.com/allush/assets/main/images/blockchains/base/logo.png",
    rpcUrl: "https://mainnet.base.org"
  },
  {
    chainId: 534352,
    name: "Scroll",
    currency: "ETH",
    explorerUrl: "https://scrollscan.com",
    icon: "https://res.cloudinary.com/dzkjyvmsn/image/upload/v1680688496/scroll_p8h6bl.png",
    rpcUrl: "https://rpc.scroll.io"
  },
  {
    chainId: 169,
    name: "Manta",
    currency: "ETH",
    explorerUrl: "https://pacific-explorer.manta.network",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/13631.png",
    rpcUrl: "https://pacific-rpc.manta.network/http"
  },
  {
    chainId: 1088,
    name: "Metis",
    currency: "METIS",
    explorerUrl: "https://andromeda-explorer.metis.io",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/9640.png",
    rpcUrl: "https://andromeda.metis.io/?owner=1088"
  },
  {
    chainId: 5165,
    name: "Bahamut",
    currency: "FTN",
    explorerUrl: "https://ftnscan.com",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/22615.png",
    rpcUrl: "https://rpc1.bahamut.io"
  },
  {
    chainId: 34443,
    name: "Mode",
    currency: "ETH",
    explorerUrl: "https://explorer.mode.network",
    icon: "https://assets-global.website-files.com/64c906a6ed3c4d809558853b/64d0b081f0331ac3c64c5e4d_flav.png",
    rpcUrl: "https://1rpc.io/mode"
  },
  {
    chainId: 30,
    name: "Rootstock",
    currency: "RBTC",
    explorerUrl: "https://explorer.rsk.co",
    icon: "https://rootstock.blockscout.com/assets/favicon/favicon-32x32.png",
    rpcUrl: "https://mycrypto.rsk.co"
  },
  {
    chainId: 81457,
    name: "Blast",
    currency: "ETH",
    explorerUrl: "https://blastscan.io",
    icon: "https://assets.coingecko.com/coins/images/35494/standard/blast2.jpeg?1708919600",
    rpcUrl: "https://rpc.blast.io"
  },
  {
    chainId: 4200,
    name: "Merlin",
    currency: "BTC",
    explorerUrl: "https://scan.merlinchain.io",
    icon: "https://assets.coingecko.com/asset_platforms/images/188/small/merlin-chain.jpeg?1708522313",
    rpcUrl: "https://merlin.blockpi.network/v1/rpc/public"
  },
  {
    chainId: 810180,
    name: "ZkLink",
    currency: "ETH",
    explorerUrl: "https://explorer.zklink.io",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/13039.png",
    rpcUrl: "https://rpc.zklink.io"
  },
  {
    chainId: 1116,
    name: "CORE",
    currency: "CORE",
    explorerUrl: "https://scan.coredao.org",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/23254.png",
    rpcUrl: "https://rpc.coredao.org"
  },
  {
    chainId: 167000,
    name: "Taiko",
    currency: "ETH",
    explorerUrl: "https://taikoscan.io",
    icon: "https://taikoscan.io/assets/taiko/images/svg/logos/chain-light.svg?v=24.5.4.0",
    rpcUrl: "https://rpc.taiko.xyz"
  },
  {
    chainId: 7000,
    name: "ZetaChain",
    currency: "ZETA",
    explorerUrl: "https://zetachain.blockscout.com",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/21259.png",
    rpcUrl: "https://zetachain-evm.blockpi.network/v1/rpc/public"
  },
  {
    chainId: 25,
    name: "Cronos",
    currency: "CRO",
    explorerUrl: "https://cronoscan.com",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png",
    rpcUrl: "https://evm.cronos.org"
  },
  {
    chainId: 252,
    name: "Fraxtal",
    currency: "frxETH",
    explorerUrl: "https://fraxscan.com",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png",
    rpcUrl: "https://rpc.frax.com"
  },
  {
    chainId: 1625,
    name: "Gravity Alpha Mainnet",
    currency: "G",
    explorerUrl: "https://explorer.gravity.xyz",
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/32120.png",
    rpcUrl: "https://rpc.gravity.xyz"
  }
];