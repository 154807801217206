import { Link } from "react-router-dom";
import { MainRouter } from "../../routers";
import Logo from '../../assets/images/seifun.png';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useMainContext } from "../../context/mainContext";
import { Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Toolbar, Typography } from "@mui/material";
import HexIcon from '../../assets/images/hex.svg';
import TelegramIcon from '../../assets/images/telegram.svg';
import TwitterIcon from '../../assets/images/twitter.svg';

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

function Sidebar(props: Props) {
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;
  const { mobileOpen, handleDrawerClose, handleDrawerTransitionEnd, handleDrawerToggle } = useMainContext();
  
  const draw = (
    <Box>
      <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent:'center', paddingTop: '2rem', paddingBottom: '0.5rem'  }}>
        <Link to="/">
          <img src={Logo} height={120} style={{ margin: "auto" }} alt="logo" />
        </Link>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginLeft: "0.5rem", marginBottom: '0.5rem', display: { sm: 'none' } }}
        >
          <MenuOpenIcon />
        </IconButton>
      </Toolbar>
      <Divider style={{ borderColor: 'transparent' }} />
      <List>
        {MainRouter.main.map((router, index) => (
          <Link key={index} to={router.path} style={{ textDecoration: 'none', color: '#A9A9A9', fontFamily: "JosefinSans" }}>
            <ListItem  >
              <ListItemButton sx={{ borderRadius: 50 }}>
                <img src={router.icon} height={35} width={35} alt={router.title} />
                <ListItemText primary={router.title} sx={{ ml: '0.5rem', fontFamily: "JosefinSans" }} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider style={{ borderColor: 'transparent' }} />
      <Divider style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} />
      <Box sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto', marginTop: '3rem' }}>
        <Typography sx={{color:'#FF0089', fontSize:16, mb:'2rem'}}>How it works</Typography>
        <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
          <Link to="/"><img src={HexIcon} width={50} height={50} alt="logo"/></Link>
          <Link to="https://t.me/+NkB8d9fJ6HEyNDM0"><img src={TelegramIcon} width={50} height={50} alt="logo"/></Link>
          <Link to="https://x.com/SeiMemeFun"><img src={TwitterIcon} width={50} height={50} alt="logo"/></Link>
        </Box>
      </Box>
    </Box>
  );


  return (
    <div className="Sidebar" style={{ background: '#182120' }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          container={container}
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#182120', backgroundImage: 'none', border: 'none' },
          }}
        >
          {draw}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, border: 'none', backgroundColor: '#182120' },
          }}
          style={{ background: 'transparent' }}
          open
        >
          {draw}
        </Drawer>
      </Box>
    </div>
  );
}

export default Sidebar;
