import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import LanguageIcon from "@mui/icons-material/Language";
import TelegramIcon from "@mui/icons-material/Telegram";
import XIcon from "@mui/icons-material/X";
import ShareButton from "../Button/ShareButton";

export default function MainCard({
  image,
  title,
  ticker,
  description,
  market_cap,
  trades,
  deployer,
  web,
  telegram,
  twitter,
  network,
  className,
  tokenAddr,
}: {
  image: string;
  title: string;
  ticker: string;
  description: string;
  market_cap: number;
  trades: number;
  deployer: string;
  web?: string;
  telegram?: string;
  twitter?: string;
  network?: string;
  className?: string;
  tokenAddr?: string;
}) {
  const MAX_DESCRIPTION_LENGTH = 150; // Adjust this as needed

  const truncatedDescription =
    description?.length > MAX_DESCRIPTION_LENGTH
      ? `${description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
      : description;

  return (
    <div
      style={{
        background:
          "linear-gradient(90deg, rgb(255, 168, 0) 14.14%, rgb(158, 31, 99) 100%)",
        height: "100%",
        padding: "2px",
        borderRadius: "4px",
      }}
    >
      <Card sx={{ mb: "1rem", height: "100%" }} className={className}>
        <CardContent>
          <Container>
            <Grid2 container spacing={2}>
              <Grid2
                xs={6}
                sm={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {image === "" ? (
                  <Box
                    sx={{
                      width: { sm: 200, xs: 120 },
                      height: { sm: 200, xs: 120 },
                      margin: "auto",
                    }}
                  >
                    <img
                      src={`https://api.multiavatar.com/${tokenAddr}.svg`}
                      width="100%"
                      height="100%"
                      alt="token avatar"
                      style={{
                        objectPosition: "top",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: { sm: 200, xs: 120 },
                      height: { sm: 200, xs: 120 },
                      margin: "auto",
                    }}
                  >
                    <img
                      src={image}
                      width="100%"
                      height="100%"
                      alt="token pic"
                      style={{
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                    />
                  </Box>
                )}
              </Grid2>
              <Grid2 xs={6} sm={6}>
                <Typography
                  sx={{
                    color: "#FFA800",
                    fontSize: { sm: 24, xs: 20 },
                    fontFamily: "JosefinSans",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    color: "#FF0089",
                    fontSize: { sm: 20, xs: 14 },
                    fontFamily: "JosefinSans",
                  }}
                >
                  {ticker}
                </Typography>
                <Typography
                  sx={{
                    color: "#00FFF0",
                    fontSize: { sm: 20, xs: 16 },
                    fontWeight: "medium",
                  }}
                >
                  Market Cap $
                  {market_cap > 1000
                    ? `${(market_cap / 1000)?.toFixed(3)}k`
                    : market_cap?.toFixed(3)}
                </Typography>
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: { sm: 20, xs: 16 },
                    fontWeight: "medium",
                  }}
                >
                  Replies: {trades}
                </Typography>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      color: "#FFFFFF",
                      fontSize: { sm: 20, xs: 16 },
                      fontWeight: "medium",
                    }}
                  >
                    Deployed by
                  </Typography>
                  <Avatar
                    src={image}
                    sx={{ width: 25, height: 25, ml: "1rem" }}
                  />
                </Box>
              </Grid2>
              <Grid2 xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>{truncatedDescription}</Box>
                </Box>
              </Grid2>
              <Grid2 xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    {web !== "" && web !== null && (
                      <a target="_blank" href={`${web}`} rel="noreferrer">
                        <IconButton>
                          <LanguageIcon />
                        </IconButton>
                      </a>
                    )}
                    {telegram !== "" && telegram !== null && (
                      <a target="_blank" href={`${telegram}`} rel="noreferrer">
                        <IconButton>
                          <TelegramIcon />
                        </IconButton>
                      </a>
                    )}
                    {twitter !== "" && twitter !== null && (
                      <a target="_blank" href={`${twitter}`} rel="noreferrer">
                        <IconButton>
                          <XIcon />
                        </IconButton>
                      </a>
                    )}
                    <ShareButton
                      tokenInfo={{
                        tokenName: title,
                        tokenSymbol: ticker,
                        market_cap,
                        network,
                        tokenAddress: tokenAddr,
                        tokenImage: image,
                      }}
                    />
                  </Box>
                  <IconButton>
                    <Avatar
                      src={`/networks/${network}.svg`}
                      sx={{ width: 30, height: 30 }}
                    />
                  </IconButton>
                </Box>
              </Grid2>
            </Grid2>
          </Container>
        </CardContent>
      </Card>
    </div>
  );
}
