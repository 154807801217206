import axios from 'axios';

export async function makeApiRequest(path) {
	try {
    const headers = { 
      'Access-Control-Allow-Origin': '*',
      'x-api-key': process.env.REACT_APP_AUTH_KEY,
      'x-user-identifier': process.env.REACT_APP_USER_ID
    };
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`, { headers });
		return response.data;
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error}`);
	}
}

export function getResolutionInMs(resolution) {
  const resolutionMap = {
    '1': 60 * 1000,        // 1 minute
    '5': 5 * 60 * 1000     // 5 minutes
  };
  return resolutionMap[resolution] || 60 * 1000;
}

export function transformBaseData(baseData, resolution) {
  const bars = [];
  const resolutionMs = getResolutionInMs(resolution);
  let currentBar = null;
  let currentBarStartTime = 0;

  baseData.forEach((item) => {
    const time = item.date * 1000; // Convert to milliseconds
    const price = parseFloat(item.tokenPrice);

    // Check if we need to start a new bar
    if (!currentBar || time >= currentBarStartTime + resolutionMs) {
      if (currentBar) {
        bars.push(currentBar);
      }
      currentBarStartTime = Math.floor(time / resolutionMs) * resolutionMs;
      currentBar = {
        time: currentBarStartTime,
        open: price,
        high: price,
        low: price,
        close: price
      };
    } else {
      // Update the current bar
      currentBar.high = Math.max(currentBar.high, price);
      currentBar.low = Math.min(currentBar.low, price);
      currentBar.close = price;
    }
  });

  // Push the last bar if it exists
  if (currentBar) {
    bars.push(currentBar);
  }

  return bars;
}
