import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  CircularProgress,
  Alert,
  Button,
  DialogActions,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';

interface SwapStatusDialogProps {
  open: boolean;
  onClose: () => void;
  isSwapping: boolean;
  currentStep: number;
  error: string | null;
  type: 'BUY' | 'SELL';
  txHash: string | null;
  onRetry?: () => void;
}

export default function SwapStatusDialog({
  open,
  onClose,
  isSwapping,
  currentStep,
  error,
  type,
  txHash,
  onRetry
}: SwapStatusDialogProps) {
  const steps = type === 'BUY' 
    ? ['Preparing swap Transaction', 'Executing Buy', 'Processing Transaction', 'Updating Token Data']
    : ['Preparing swap Transaction', 'Executing Sell', 'Processing Transaction', 'Updating Token Data'];

  return (
    <Dialog 
      open={open} 
      onClose={isSwapping ? undefined : onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {isSwapping ? `Processing ${type} Order` : 'Transaction Status'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Stepper activeStep={currentStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                    {currentStep === index && isSwapping && (
                      <CircularProgress size={16} sx={{ ml: 2 }} />
                    )}
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && (
            <Alert 
              severity="error" 
              sx={{ mt: 2 }}
              action={onRetry && (
                <Button color="inherit" size="small" onClick={onRetry}>
                  RETRY
                </Button>
              )}
            >
              {error}
            </Alert>
          )}

          {txHash && !error && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Transaction successful! View on{' '}
              <a 
                href={`https://seitrace.com/tx/${txHash}?chain=pacific-1`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'inherit', textDecoration: 'underline' }}
              >
                Explorer
              </a>
            </Alert>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button 
          variant="outlined" 
          onClick={onClose}
          disabled={isSwapping}
          sx={{ 
            border: '1px solid #FFA800',
            background: '#FFA80020',
            color: 'white'
          }}
        >
          {isSwapping ? 'Please Wait...' : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}