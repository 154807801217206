import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useSwitchNetwork,
} from "@web3modal/ethers/react";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import { socket } from "../utils/socket";
import {
  SEI_LP_CONTRACT_ADDRESS
} from "../abis/FairLaunchFactoryAddress";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DeployDialog from "../components/Dialogs/DeployDialog";
import fairLaunchFactorySeiABI from "../abis/FairLaunchFactorySeiABI";

interface PresignedPostData {
  url: string;
  fields: {
    key: string;
    bucket: string;
    "X-Amz-Algorithm": string;
    "X-Amz-Credential": string;
    "X-Amz-Date": string;
    Policy: string;
    "X-Amz-Signature": string;
  };
}

interface AWSS3 {
  presignedPostData: PresignedPostData;
  fileUrl: string;
  fileKey: string;
}

const VALID_FILE_TYPES = [
  "image/webp",
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/jpg",
] as const;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

const validateFile = (file: File): { valid: boolean; error?: string } => {
  if (
    !VALID_FILE_TYPES.includes(file.type as (typeof VALID_FILE_TYPES)[number])
  )
    return {
      valid: false,
      error:
        "Invalid file type. Please upload a .webp, .png, .jpeg/jpg, or .gif file.",
    };
  if (file.size > MAX_FILE_SIZE)
    return { valid: false, error: "File size should not exceed 5MB limit." };

  return { valid: true };
};

export default function CreateCurvePage() {
  const [network, setNetwork] = React.useState("Sei");
  const [deployModal, setDeployModal] = React.useState(false);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();
  const navigate = useNavigate();

  const [coinName, setCoinName] = React.useState("");
  const [coinTicker, setCoinTicker] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [imageLink, setImageLink] = React.useState("");
  const [file, setFile] = React.useState<File | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [telegramLink, setTelegramLink] = React.useState("");
  const [twitterLink, setTwitterLink] = React.useState("");
  const [webLink, setWebLink] = React.useState("");
  const [signer, setSigner] = useState(null as any);
  const [fairLaunchContract, setFairLaunchContract] = React.useState(
    null as any
  );
  const [nativeBalanceString, setNativeBalanceString] = React.useState("");
  const [initBuyAmount, setInitBuyAmount] = React.useState("0");
  const [isImageUploading, setIsImageUploading] = useState(false);

  const [isDeploying, setIsDeploying] = useState(false);
  const [deploymentStep, setDeploymentStep] = useState(0);
  const [deploymentError, setDeploymentError] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      if (!walletProvider) {
        return;
      }

      if (chainId && chainId !== 1329) {
        toast.warn("Please switch to Sei network!");
        return;
      }

      const ethersProvider = new BrowserProvider(
        walletProvider as Eip1193Provider
      );
      const tempSigner = await ethersProvider.getSigner();
      setSigner(tempSigner);
      const tempFairLaunchContract = new ethers.Contract(
        SEI_LP_CONTRACT_ADDRESS,
        fairLaunchFactorySeiABI.abi,
        tempSigner
      );
      setFairLaunchContract(tempFairLaunchContract);
      const nativeBalance = await tempSigner.provider.getBalance(
        tempSigner.address
      );
      const tempNativeBalanceString = ethers.formatEther(nativeBalance);
      setNativeBalanceString(tempNativeBalanceString);
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, address]);

  const handleClickOpen = async () => {
    if (!coinName || !coinTicker || !description || !file) {
      toast.error("Fill in token name, ticker, description and logo");
      return;
    }

    if (chainId && chainId !== 1329) {
      await switchNetwork(1329);
    }

    setDeployModal(true);
  };

  const tokenDeploy = async () => {
    if (!isConnected) {
      toast.error("Please connect wallet!");
      return;
    }

    if (isDeploying) {
      return; // Prevent multiple submissions
    }

    try {
      setIsDeploying(true);
      setDeploymentError(null);

      // Step 1: Initial checks
      setDeploymentStep(0);
      const initAmountFloat = parseFloat(initBuyAmount);
      if (initAmountFloat > 10000) {
        throw new Error("Initial buy amount must be less than 10000 SEI!");
      }

      // Step 2: Deploy contract
      setDeploymentStep(1);
      const txHashInit = await fairLaunchContract.createToken(
        coinName,
        coinTicker,
        { value: ethers.parseEther(initBuyAmount) }
      );
      const txHash = await txHashInit.wait();

      // Step 3: Configure token
      setDeploymentStep(2);
      const tokenCreatedEvent = txHash?.logs[0];
      const tokenAddress = tokenCreatedEvent.address;

      const tokenMarketCap = BigInt(
        await fairLaunchContract.getTokenMarketCap(tokenAddress)
      );
      const tokenPriceBigInt = BigInt(
        await fairLaunchContract.getPrice(tokenAddress)
      );
      const ethPriceBigInt = BigInt(
        await fairLaunchContract.getSEIPriceByUSD()
      );
      const tokenPriceETH = Number(tokenPriceBigInt) / Math.pow(10, 18);
      const ethPriceUSD = Number(ethPriceBigInt) / Math.pow(10, 6);
      const tokenPriceUSD = tokenPriceETH * ethPriceUSD;

      // Step 4: Backend integration
      setDeploymentStep(3);
      const body = {
        type: "createToken",
        data: {
          tokenName: coinName,
          tokenSymbol: coinTicker,
          tokenDescription: description,
          tokenAddress: tokenAddress,
          tokenImage: imageLink,
          marketcap: tokenMarketCap.toString(),
          price: tokenPriceUSD.toString(),
          virtualLP: ethPriceUSD.toString(),
          creatorAddress: signer.address,
          contractAddress: SEI_LP_CONTRACT_ADDRESS,
          network: network,
          replies: 0,
          telegramLink: telegramLink,
          twitterLink: twitterLink,
          webLink: webLink,
        },
      };

      await new Promise((resolve, reject) => {
        socket.emit(
          "message",
          JSON.stringify(body),
          (response: { success: boolean; error?: string }) => {
            if (response.success) {
              resolve(response);
            } else {
              reject(new Error(response.error));
            }
          }
        );
      });

      toast.success("Token Created Successfully!");
      navigate(`/${network}/${tokenAddress}`);
    } catch (e: any) {
      let errMsg = ((e.message as string).includes("User rejected action") && "user rejected action") || "Failed to create token. Please try again."
      setDeploymentError(
        errMsg
      );
      toast.error(errMsg);
    } finally {
      setIsDeploying(false);
      setDeploymentStep(0);
      setDeployModal(false);
    }
  };

  const handleClose = () => {
    setDeployModal(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    let chainId = 11155111;
    switch (event.target.value) {
      case "Ethereum":
        chainId = 1;
        break;
      case "Avax":
        chainId = 43114;
        break;
      case "Arbitrum":
        chainId = 42161;
        break;
      case "Base":
        chainId = 8453;
        break;
      case "Sepolia":
        chainId = 11155111;
        break;
      case "Sei":
        chainId = 1329;
        break;
      default:
        break;
    }
    switchNetwork(chainId);
    setNetwork(event.target.value);
  };

  const uploadImageToS3 = async (
    file: File,
    presignedPostData: PresignedPostData
  ): Promise<string | null> => {
    const formData = new FormData();

    // Add the presigned POST fields to the FormData object
    Object.entries(presignedPostData.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("file", file, presignedPostData.fields.key);

    try {
      const response = await axios.post(presignedPostData.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": process.env.REACT_APP_AUTH_KEY,
          "x-user-identifier": process.env.REACT_APP_USER_ID,
        },
      });

      if (response.status === 204) {
        return `https://${presignedPostData.fields.bucket}.s3.amazonaws.com/${presignedPostData.fields.key}`;
      } else {
        console.error("Unexpected response status:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      toast.error("File upload failed");
      return;
    }

    const fileValidationResult = validateFile(file);
    if (!fileValidationResult.valid) {
      toast.error(fileValidationResult.error);
      event.target.value = "";
      return;
    }

    setIsLoading(true);
    setFile(file);
    onFileUpload(file);
  };

  const onFileUpload = async (file: File) => {
    setIsImageUploading(true);
    try {
      const { presignedPostData, fileUrl, fileKey } =
        await getPresignedPostData(file);
      console.log("Here", presignedPostData, fileUrl, fileKey);
      const imageUrl = await uploadImageToS3(file, presignedPostData);
      if (imageUrl) {
        setImageLink(fileUrl);
        setIsLoading(false);
        toast.success("Image uploaded successfully!");
      } else {
        throw new Error("Failed to upload image");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      if (error instanceof Error) {
        toast.error(`Failed to upload image: ${error.message}`);
      } else {
        toast.error("An unexpected error occurred while uploading the image.");
      }
    } finally {
      setIsImageUploading(false);
    }
  };

  const getPresignedPostData = async (file: File): Promise<AWSS3> => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "x-api-key": process.env.REACT_APP_AUTH_KEY,
      "x-user-identifier": process.env.REACT_APP_USER_ID,
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/misc/presigned-url?fileType=${file.type}`,
      { headers }
    );
    return response.data;
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={6} mx="auto">
          <Box
            sx={{
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1rem",
            }}
          >
            <FormControl fullWidth sx={{ mb: "1rem" }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={network}
                onChange={handleChange}
              >
                <MenuItem value="Sei">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={{ fontSize: 18 }}>Sei</Typography>
                    <Avatar
                      sx={{ width: 25, height: 25 }}
                      alt="avatar"
                      src={"/networks/Sei.svg"}
                    />
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb="1rem"
            >
              <Typography sx={{ fontSize: { sm: 36, xs: 20 } }}>
                Create a new Curve
              </Typography>
              <Link to="/">
                <Typography
                  sx={{
                    fontSize: { sm: 36, xs: 20 },
                    color: "#FFA800",
                    textDecoration: "none",
                  }}
                >
                  Go Back
                </Typography>
              </Link>
            </Box>
            <TextField
              id="outlined-basic"
              sx={{ mb: "1rem" }}
              label="Coin Name"
              variant="outlined"
              fullWidth
              value={coinName}
              onChange={(e) => setCoinName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              sx={{ mb: "1rem" }}
              label="Coin Ticker"
              variant="outlined"
              fullWidth
              value={coinTicker}
              onChange={(e) => setCoinTicker(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              sx={{ mb: "1rem" }}
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {imageLink !== "" && (
              <Avatar
                src={`${imageLink}`}
                sx={{ width: 100, height: 100, mb: "1rem" }}
                alt="image"
              />
            )}
            {isLoading === true && (
              <Skeleton
                variant="circular"
                width={100}
                height={100}
                sx={{ mb: "1rem" }}
              />
            )}
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Image file
              <VisuallyHiddenInput type="file" onChange={onFileChange} />
            </Button>

            <Accordion sx={{ background: "transparent", boxShadow: "none" }}>
              <AccordionSummary sx={{ color: "#00A79D" }}>
                More Options
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  id="outlined-basic"
                  sx={{ mb: "1rem" }}
                  label="Telegram Link"
                  value={telegramLink}
                  onChange={(e) => setTelegramLink(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  id="outlined-basic"
                  sx={{ mb: "1rem" }}
                  label="Twitter Link"
                  value={twitterLink}
                  onChange={(e) => setTwitterLink(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  id="outlined-basic"
                  sx={{ mb: "1rem" }}
                  label="Website Link"
                  value={webLink}
                  onChange={(e) => setWebLink(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </AccordionDetails>
            </Accordion>
            {/* <Button variant="contained" sx={{ background: '#9E1F63', color: 'white' }} fullWidth onClick={handleClickOpen}>Deploy</Button> */}
            <Button
              variant="contained"
              sx={{ background: "#9E1F63", color: "white" }}
              fullWidth
              onClick={handleClickOpen}
              disabled={isImageUploading}
            >
              {isImageUploading ? "Uploading Image..." : "Deploy"}
            </Button>
            <Typography sx={{ fontSize: { sm: 18, xs: 16 }, my: "1rem" }}>
              Deploy Fee ()
            </Typography>
          </Box>
        </Grid2>
      </Grid2>
      <DeployDialog
        open={deployModal}
        onClose={handleClose}
        network={network}
        nativeBalanceString={nativeBalanceString}
        initBuyAmount={initBuyAmount}
        setInitBuyAmount={setInitBuyAmount}
        onDeploy={tokenDeploy}
        isDeploying={isDeploying}
        currentStep={deploymentStep}
        error={deploymentError}
      />
    </Box>
  );
}
