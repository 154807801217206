import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Card,
  CardContent,
  Box,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
  Avatar,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Alert
} from '@mui/material';

interface DeployDialogProps {
  // Control dialog visibility
  open: boolean;
  
  // Dialog close handler
  onClose: () => void;
  
  // Network identifier (e.g., "Sei", "Ethereum")
  network: string;
  
  // User's wallet balance as formatted string
  nativeBalanceString: string;
  
  // Initial buy amount value
  initBuyAmount: string;
  
  // Function to update initial buy amount
  setInitBuyAmount: (amount: string) => void;
  
  // Function to handle token deployment
  onDeploy: () => Promise<void>;
  
  // Flag indicating if deployment is in progress
  isDeploying: boolean;
  
  // Current step in deployment process (0-3)
  currentStep: number;
  
  // Error message if deployment fails (null if no error)
  error: string | null;
}

export default function DeployDialog({
  open,
  onClose,
  network,
  nativeBalanceString,
  initBuyAmount,
  setInitBuyAmount,
  onDeploy,
  isDeploying,
  currentStep,
  error
}: DeployDialogProps) {
  const steps = [
    'Initializing Token Creation',
    'Deploying Smart Contract',
    'Configuring Token Settings',
    'Finalizing Deployment'
  ];

  return (
    <Dialog 
      open={open} 
      onClose={isDeploying ? undefined : onClose}
      maxWidth="sm"
      fullWidth
      disableEscapeKeyDown={isDeploying}
    >
      <DialogTitle>
        {isDeploying ? "Deploying Token..." : "Create & Buy?"}
      </DialogTitle>
      <DialogContent>
        {!isDeploying ? (
          <>
            <DialogContentText sx={{ mb: 2 }}>
              Choose how many tokens you want to buy (optional). Buying a small amount of coins helps encourage sales and improves visibility of your token.
            </DialogContentText>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb='0.5rem'>
                  <Typography>Spend</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography mr='1rem'>Balance: {nativeBalanceString}</Typography>
                    <Typography sx={{ color: '#FFA800', cursor: 'pointer' }} 
                      onClick={() => setInitBuyAmount(nativeBalanceString)}>
                      MAX
                    </Typography>
                  </Box>
                </Box>
                <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', border: '1px solid' }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="0.0"
                    value={initBuyAmount}
                    onChange={(e) => setInitBuyAmount(e.target.value)}
                    type="number"
                    disabled={isDeploying}
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton sx={{ p: '10px' }}>
                    <Avatar src={`/networks/${network}.svg`} sx={{ width: 25, height: 25 }} />
                  </IconButton>
                </Paper>
              </CardContent>
            </Card>
          </>
        ) : (
          <Box sx={{ width: '100%', mt: 2 }}>
            <Stepper activeStep={currentStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {label}
                      {currentStep === index && (
                        <CircularProgress size={20} sx={{ ml: 2 }} />
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button 
          variant="outlined" 
          sx={{ 
            border: '1px solid #FF0000', 
            background: '#FF000020', 
            color: 'white' 
          }}
          onClick={onClose}
          disabled={isDeploying}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: '1px solid #FFA800',
            background: '#FFA80020',
            color: 'white'
          }}
          onClick={onDeploy}
          disabled={isDeploying}
        >
          {isDeploying ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Deploying...
            </Box>
          ) : (
            'Deploy Token'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}