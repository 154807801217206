import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./components/layout";
import DashboardPage from "./pages/dashboard";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { MainContextProvider } from "./context/mainContext";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import walletConnectLogo from "./assets/images/wallet-connect.jpg";
import coinbaseLogo from "./assets/images/coin/Coinbase.svg";
import ethereumLogo from "./assets/images/coin/eth-1.png";
import logo from "../src/assets/images/gold-logo.png";
import CreateCurvePage from "./pages/createCurvs";
import AdminDashboard from "./pages/admin_dashboard";
import MyCurvsPage from "./pages/myCurvs";
import DetailsPage from "./pages/detailsPage";
import ReferralPage from "./pages/referralPage";
import React from "react";
import { socket } from "./utils/socket";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import UnderMaintenancePage from "./components/UnderMaintenance";
import SymbiosisBridge from "./pages/bridge";
import { supportedChains } from "./utils/supportedChains";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const projectId = "woof_oracle";

const metadata = {
  name: "Fair Launch",
  description:
    "It prevents rugs by making sure that all created tokens are safe",
  url: "...", // origin must match your domain & subdomain 
  icons: [logo],
};

const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: false, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: "...", // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
});

createWeb3Modal({
  ethersConfig,
  chains: supportedChains,
  chainImages: {
    1: ethereumLogo,
  },
  connectorImages: {
    coinbaseWallet: coinbaseLogo,
    walletConnect: walletConnectLogo,
  },
  projectId,
});

function App() {
  React.useEffect(() => {
    socket.on("connect", () => {});

    return () => {
      socket.off("connect");
    };
  }, []);

  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  if (isUnderMaintenance) {
    return <UnderMaintenancePage />;
  }

  return (
    <div className="App">
      <MainContextProvider>
        <ToastContainer />
        <ThemeProvider theme={darkTheme}>
          <BrowserRouter>
            <Routes>
              <Route element={<MainLayout />}>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/create_curves" element={<CreateCurvePage />} />
                <Route path="/my_curves" element={<MyCurvsPage />} />
                <Route path="/:chain/:id" element={<DetailsPage />} />
                <Route path="/referral" element={<ReferralPage />} />
                <Route path="/bridge" element={<SymbiosisBridge />} />
                <Route
                  path="/admin_0xba20538f6742343fF908992D248B2c460aB43D78"
                  element={<AdminDashboard />}
                />
              </Route>
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
      </MainContextProvider>
    </div>
  );
}

export default App;
