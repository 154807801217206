import React from 'react';
import { IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import LinkIcon from '@mui/icons-material/Link';
import { toast } from 'react-toastify';

const ShareButton = ({ tokenInfo }: { tokenInfo: any }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getShareUrl = () => {
    // Using the main app URL directly since middleware handles the preview
    return `${window.location.origin}/${tokenInfo.network}/${tokenInfo.tokenAddress}`;
  };

  const shareText = `Check out ${tokenInfo.tokenName} ($${tokenInfo.tokenSymbol}) on SeiFun!\n\nMarket Cap: $${tokenInfo.market_cap > 1000 ? 
    `${(tokenInfo.market_cap / 1000)?.toFixed(3)}k` : 
    tokenInfo.market_cap?.toFixed(3)}`;

  const handleShare = (platform: any) => {
    const url = getShareUrl();
    let shareUrl = '';

    switch (platform) {
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(url)}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(shareText)}`;
        break;
      case 'copy':
        navigator.clipboard.writeText(url);
        toast.success('Link copied to clipboard!');
        break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, '_blank');
    }
    handleClose();
  };

  return (
    <>
      <Tooltip title="Share">
        <IconButton onClick={handleClick} sx={{ color: 'white' }}>
          <ShareIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: '#182120',
            color: 'white'
          }
        }}
      >
        <MenuItem onClick={() => handleShare('twitter')} sx={{ gap: 1 }}>
          <TwitterIcon fontSize="small" />
          Share on Twitter
        </MenuItem>
        <MenuItem onClick={() => handleShare('telegram')} sx={{ gap: 1 }}>
          <TelegramIcon fontSize="small" />
          Share on Telegram
        </MenuItem>
        <MenuItem onClick={() => handleShare('copy')} sx={{ gap: 1 }}>
          <LinkIcon fontSize="small" />
          Copy Link
        </MenuItem>
      </Menu>
    </>
  );
};

export default ShareButton;