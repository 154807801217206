import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";

import MainCard from "../components/cards";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomPaginationActionsTable from "../components/table";
import axios from "axios";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useSwitchNetwork,
} from "@web3modal/ethers/react";
import { socket } from "../utils/socket";
import { useEffect, useState } from "react";
import { BrowserProvider, Eip1193Provider, ethers } from "ethers";
import fairLaunchFactorySeiABI from "../abis/FairLaunchFactorySeiABI";
import {
  SEI_LP_CONTRACT_ADDRESS
} from "../abis/FairLaunchFactoryAddress";
import { toast } from "react-toastify";
import { useMainContext } from "../context/mainContext";
import { TVChartContainer } from "../components/TVWidget";
import { useParams } from "react-router-dom";
import SwapStatusDialog from "../components/Dialogs/SwapDialog";

const totalSupply = 10 ** 9;

const tokenABI = [
  // Minimal ABI for ERC-20 Token Balance
  "function balanceOf(address owner) view returns (uint256)",
  "function launched() view returns (bool)",
];

export default function DetailsPage() {
  const { chain, id } = useParams();
  const [detailData, setDetailData] = React.useState<any>(null);
  const [chatData, setChatData] = React.useState<any>([]);
  const [tradeData, setTradeData] = React.useState<any>([]);
  const [value, setValue] = React.useState("1");
  const [holders, setHolders] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  const [type, setType] = React.useState<'BUY'|'SELL'>("BUY");
  const [estimateAmount, setEstimateAmount] = React.useState("0");
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const [lpBalance, setLPBalance] = React.useState(0);
  const [maxAmount, setMaxAmount] = React.useState("0");
  const [launched, setLaunched] = React.useState(false);
  const { setUserScore } = useMainContext();
  const [isLoading, setIsLoading] = useState(true);

  const { address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { switchNetwork } = useSwitchNetwork();

  const [nativeBalanceString, setNativeBalanceString] = React.useState("");
  const [isSwapping, setIsSwapping] = useState(false);
  const [swapStep, setSwapStep] = useState(0);
  const [swapError, setSwapError] = useState<string | null>(null);
  const [showSwapStatus, setShowSwapStatus] = useState(false);
  const [swapTxHash, setSwapTxHash] = useState<string | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const setInput = (e: any) => {
    if (!e.target.value) setAmount(0);
    else setAmount(e.target.value);
  };

  const showOutput = async (val: any) => {
    if (!detailData) return;
    let inputAmount = val;
    if (!inputAmount) inputAmount = "0";

    const tokenProvider = new ethers.JsonRpcProvider(
      "https://evm-rpc.sei-apis.com"
    );
    const tempFairLaunchContract = new ethers.Contract(
      detailData?.contractAddress,
      fairLaunchFactorySeiABI.abi,
      tokenProvider
    );

    const tokenPriceBigInt = BigInt(
      await tempFairLaunchContract.getPrice(detailData?.tokenAddress)
    );
    const realInputAmount = (parseFloat(inputAmount) * 99) / 100; // 1% Fee of Buy & Sell

    let outputAmount = 0; // This will now be a float

    if (type === "BUY") {
      const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it
      outputAmount = Number(amountInWei) / Number(tokenPriceBigInt);
    } else if (type === "SELL") {
      const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it, handle as Number to ensure float result
      outputAmount = (amountInWei / 1e18) * (Number(tokenPriceBigInt) / 1e18);
    }

    setEstimateAmount(outputAmount.toFixed(9).toString());
  };

  const funcInput = async (e: any) => {
    setInput(e);
    await showOutput(e.target.value);
  };

  const getMaxAmount = async () => {
    if (type === "BUY") setMaxAmount(parseFloat(nativeBalanceString).toFixed(9));
    else if (type === "SELL" && detailData) {
      const tokenProvider = new ethers.JsonRpcProvider(
        "https://evm-rpc.sei-apis.com"
      );
      const tokenContract = new ethers.Contract(
        detailData.tokenAddress,
        tokenABI,
        tokenProvider
      );
      const tokenBalance = await tokenContract.balanceOf(address);
      setMaxAmount(parseFloat(ethers.formatEther(tokenBalance)).toFixed(6));
    }
  };

  useEffect(() => {
    showOutput(amount);
    if (detailData) {
      getMaxAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePost = async () => {
    if (id === "" || address === undefined || comment === "" || chain === "")
      return;
    const body = {
      tokenAddress: id,
      replyAddress: address,
      comment: comment,
      network: chain,
    };
    try {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "x-api-key": process.env.REACT_APP_AUTH_KEY,
        "x-user-identifier": process.env.REACT_APP_USER_ID,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/chats/reply`,
        body,
        { headers: headers }
      );
      setChatData(response.data);
    } catch (error) {}
    setOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      if (!walletProvider) {
        return;
      }

      if (chainId && chainId !== 1329) {
        toast.warn("Please switch to Sei network!");
        return;
      }

      const ethersProvider = new BrowserProvider(
        walletProvider as Eip1193Provider
      );
      const tempSigner = await ethersProvider.getSigner();
      const nativeBalance = await tempSigner.provider.getBalance(
        tempSigner.address
      );
      const tempNativeBalanceString = ethers.formatEther(nativeBalance);
      setNativeBalanceString(tempNativeBalanceString);
      if (!address) return;
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, address]);

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const funcSetLPBalance = async (response: any) => {
    if (!response) return;
    const tokenProvider = new ethers.JsonRpcProvider(
      "https://evm-rpc.sei-apis.com"
    );
    const tokenContract = new ethers.Contract(
      response.tokenAddress,
      tokenABI,
      tokenProvider
    );
    const LPBalance = await tokenContract.balanceOf(SEI_LP_CONTRACT_ADDRESS);
    setLPBalance(parseFloat(ethers.formatEther(LPBalance)) / totalSupply);
  };

  const funcGetLaunched = async (tempDetailData: any) => {
    if (!tempDetailData) return;
    const tokenProvider = new ethers.JsonRpcProvider(
      "https://evm-rpc.sei-apis.com"
    );
    const tokenContract = new ethers.Contract(
      tempDetailData.tokenAddress,
      tokenABI,
      tokenProvider
    );
    const isLaunched: boolean = await tokenContract.launched(); // check token launched variable
    setLaunched(isLaunched);

    if (isLaunched && !tempDetailData.launched) {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "x-api-key": process.env.REACT_APP_AUTH_KEY,
        "x-user-identifier": process.env.REACT_APP_USER_ID,
      };
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/tokens/${chain}/${id}`,
        { headers }
      );
    }
  };

  const getDetils = async () => {
    try {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "x-api-key": process.env.REACT_APP_AUTH_KEY,
        "x-user-identifier": process.env.REACT_APP_USER_ID,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tokens/${chain}/${id}`,
        { headers }
      );
      setDetailData(response.data.tokenDetils);
      setChatData(response.data.chatList);
      setHolders(response.data.holdersDetails);

      funcSetLPBalance(response.data.tokenDetils);
      funcGetLaunched(response.data.tokenDetils);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getTrades = async () => {
    const body = {
      tokenAddress: id,
      network: chain,
    };
    try {
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "x-api-key": process.env.REACT_APP_AUTH_KEY,
        "x-user-identifier": process.env.REACT_APP_USER_ID,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/trades`,
        body,
        { headers: headers }
      );
      setTradeData(response.data);
    } catch (error) {}
  };

  const dateFormat = (date: string) => {
    const dateObj = new Date(date);

    // Format the date components
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = dateObj.getDate().toString().padStart(2, "0");
    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const seconds = dateObj.getSeconds().toString().padStart(2, "0");

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const swapHandler = async () => {
    if (!detailData) return;

    setIsSwapping(true);
    setSwapError(null);
    setSwapStep(0);
    setShowSwapStatus(true);

    try {
      let inputAmount = amount.toString();
      if (!inputAmount) inputAmount = "0";

      if (chain !== "Sei") await switchNetwork(1329);

      const ethersProvider = new BrowserProvider(
        walletProvider as Eip1193Provider
      );
      const tempSigner = await ethersProvider.getSigner();
      const tempFairLaunchContract = new ethers.Contract(
        detailData?.contractAddress,
        fairLaunchFactorySeiABI.abi,
        tempSigner
      );

      const tokenPriceBigInt = BigInt(
        await tempFairLaunchContract.getPrice(detailData?.tokenAddress)
      );

      const realInputAmount = (parseFloat(inputAmount) * 99) / 100; // 1% Fee of Buy & Sell

      // let outputAmount = 0; // This will now be a float
      let txHash: any;
      let ethPriceUSD: any,
        tokenPriceUSD: any,
        ethAmount: any,
        tokenAmount: any,
        mCap: any;

      if (type === "BUY") {
        setSwapStep(0);
        const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it
        ethAmount = amount;
        tokenAmount = (Number(amountInWei) / Number(tokenPriceBigInt))
          .toFixed(9)
          .toString();

        setSwapStep(1);
        const transactionResponse = await tempFairLaunchContract.buyTokens(
          detailData?.tokenAddress,
          { value: ethers.parseEther(amount.toString()) }
        );

        setSwapStep(2);
        const waitForResponse = await transactionResponse.wait();
        txHash = waitForResponse.hash;
        setSwapTxHash(txHash);
      } else if (type === "SELL") {
        if (amount > parseFloat(maxAmount)) {
          toast.error("Insufficient balance!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }

        setSwapStep(0);
        const amountInWei = Math.round(realInputAmount * 1e18); // Convert to Wei and round it, handle as Number to ensure float result
        tokenAmount = amount;
        ethAmount = ((amountInWei / 1e18) * (Number(tokenPriceBigInt) / 1e18))
          .toFixed(9)
          .toString();

        setSwapStep(1);
        const transactionResponse = await tempFairLaunchContract.sellTokens(
          detailData?.tokenAddress,
          ethers.parseEther(amount.toString())
        );

        setSwapStep(2);
        const waitForResponse = await transactionResponse.wait();
        txHash = waitForResponse.hash;
        setSwapTxHash(txHash);
      }

      const ethPriceBigInt = BigInt(
        await tempFairLaunchContract.getSEIPriceByUSD()
      );
      const tokenPriceETH = Number(tokenPriceBigInt) / Math.pow(10, 18);
      ethPriceUSD = Number(ethPriceBigInt) / Math.pow(10, 6); // on Sei network, 1 sei price related to USD
      tokenPriceUSD = tokenPriceETH * ethPriceUSD;

      mCap = BigInt(
        await tempFairLaunchContract.getTokenMarketCap(detailData?.tokenAddress)
      );

      const tokenProvider = new ethers.JsonRpcProvider(
        "https://evm-rpc.sei-apis.com"
      );
      const tokenContract = new ethers.Contract(
        detailData?.tokenAddress,
        tokenABI,
        tokenProvider
      );
      const tokenBalanceOfUser = await tokenContract.balanceOf(
        tempSigner?.address
      );

      setSwapStep(3);
      const body = {
        type: "swap",
        data: {
          tokenName: detailData?.tokenName,
          tokenSymbol: detailData?.tokenSymbol,
          tokenAddress: detailData?.tokenAddress,
          tokenImage: detailData?.tokenImage,
          creatorAddress: detailData?.creatorAddress,
          swapperAddress: address, // wallet address
          type: type,
          ethAmount: ethAmount,
          tokenAmount: tokenAmount,
          network: chain,
          txHash: txHash,
          tokenPrice: tokenPriceUSD.toString(),
          tokenBalanceOfUser: ethers.formatEther(tokenBalanceOfUser),
          marketCap: mCap.toString(),
        },
      };

      // Upgrade trader's score and referrer's score
      //  Trade USD amount = ethPriceUSD * Number(ethAmount)
      const referralBody = {
        walletAddress: address,
        tradeAmount: ethPriceUSD * Number(ethAmount),
      };
      const headers = {
        "Access-Control-Allow-Origin": "*",
        "x-api-key": process.env.REACT_APP_AUTH_KEY,
        "x-user-identifier": process.env.REACT_APP_USER_ID,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/referral/upgrade`,
        referralBody,
        { headers: headers }
      );
      socket.emit("message", JSON.stringify(body));
      toast.success("Swap succeed!!");

      // Refresh page
      setUserScore(response.data.score.toString());
      await getDetils();
      await getTrades();
      setAmount(0);
      getMaxAmount();
    } catch (e: any) {
      let errMsg =
        ((e.message as string).includes("User rejected action") &&
          "User rejected action") ||
        "Failed to execute swap. Please try again.";
      setSwapError(errMsg);
      toast.error(errMsg);
    } finally {
      setIsSwapping(false);
      setSwapStep(0);
    }
  };

  React.useEffect(() => {
    const init = async () => {
      if (!walletProvider) {
        return;
      }
      const ethersProvider = new BrowserProvider(
        walletProvider as Eip1193Provider
      );
      const tempSigner = await ethersProvider.getSigner();
      const nativeBalance = await tempSigner.provider.getBalance(
        tempSigner.address
      );
      const tempNativeBalanceString = ethers.formatEther(nativeBalance);
      if (type === "BUY") setMaxAmount(parseFloat(tempNativeBalanceString).toFixed(9));
    };
    getDetils();
    getTrades();
    init();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletProvider]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} sm={8} mx="auto">
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: 24, fontWeight: "bold" }}>
                {detailData?.price.toFixed(9)}
              </Typography>
              <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                <a
                  href={`https://seitrace.com/address/${detailData?.tokenAddress}?chain=pacific-1`}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <span style={{ color: "#dcc814" }}>
                    {detailData?.tokenAddress?.slice(0, 5)}...
                    {detailData?.tokenAddress?.slice(-4)}
                  </span>
                </a>
              </Typography>
              <Typography
                sx={{ fontSize: 18, fontWeight: "bold", color: "green" }}
              >
                {launched === true ? "Launched Token" : ""}
              </Typography>

              <div>
                {detailData && <TVChartContainer tokenInfo={detailData} />}
              </div>
            </CardContent>
          </Card>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTab} aria-label="lab API tabs example">
                  <Tab label="Chat" value="1" />
                  <Tab label="Trade" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid2 container spacing={2}>
                  <Grid2 xs={12}>
                    {chatData.map((item: any, index: number) => (
                      <Card sx={{ mb: "0.5rem" }} key={index}>
                        <CardContent>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              src={`https://api.multiavatar.com/${index}.png`}
                              sx={{ width: 20, height: 20, mr: "0.5rem" }}
                              alt=""
                            />
                            <a
                              href={`https://seitrace.com/address/${item.replyAddress}?chain=pacific-1`}
                              target="_blank"
                              rel="noreferrer"
                              style={{ textDecoration: "none" }}
                            >
                              <Typography sx={{ color: "#dcc814" }}>
                                {item.replyAddress.slice(0, 5)}...
                                {item.replyAddress.slice(-4)}
                              </Typography>
                            </a>
                            <Typography sx={{ ml: "0.5rem", color: "#94a3b8" }}>
                              {dateFormat(item.date)}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography>{item.comment}</Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Grid2>
                  <Grid2 xs={12} mx="auto" textAlign="center">
                    <Button variant="outlined" onClick={handleClickOpen}>
                      Post Reply
                    </Button>
                  </Grid2>
                </Grid2>
              </TabPanel>
              <TabPanel value="2">
                <CustomPaginationActionsTable data={tradeData} />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid2>
        <Grid2 xs={12} sm={4} mx="auto">
          <Card sx={{ border: "1px solid #FFA800", mb: "1rem" }}>
            <CardContent>
              <Grid2 container spacing={2}>
                <Grid2 xs={6}>
                  <Button
                    variant={type === "BUY" ? "contained" : "outlined"}
                    color="warning"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                    onClick={() => setType("BUY")}
                    fullWidth
                  >
                    BUY
                  </Button>
                </Grid2>
                <Grid2 xs={6}>
                  <Button
                    variant={type === "SELL" ? "contained" : "outlined"}
                    color="success"
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                    onClick={() => setType("SELL")}
                    fullWidth
                  >
                    Sell
                  </Button>
                </Grid2>
                <Grid2 xs={12} textAlign="end">
                  <Typography>Max: {maxAmount}</Typography>
                </Grid2>
                <Grid2 xs={12} mb="1rem">
                  <FormControl fullWidth variant="outlined">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={amount}
                      onChange={(e) => funcInput(e)}
                      type="number"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility">
                            {type === "BUY" &&
                            chain?.toLowerCase() === "sei" ? (
                              <Avatar
                                src={`/networks/${detailData?.network}.svg`}
                                sx={{ width: 25, height: 25 }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  width: { sm: 25, xs: 25 },
                                  height: { sm: 25, xs: 25 },
                                  margin: "auto",
                                }}
                              >
                                <img
                                  src={
                                    detailData?.tokenImage === ""
                                      ? `https://api.multiavatar.com/${detailData?.tokenAddress}.svg`
                                      : detailData?.tokenImage
                                  }
                                  width="100%"
                                  alt="token pic"
                                />
                              </Box>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid2>
                <Grid2 xs={12} textAlign="start">
                  <Typography>{estimateAmount}</Typography>
                </Grid2>
                <Grid2 xs={12}>
                  <Button
                    variant="contained"
                    disabled={launched || isSwapping}
                    onClick={swapHandler}
                    sx={{
                      background: "#FFA800",
                      fontSize: 24,
                      fontWeight: "bold",
                    }}
                    fullWidth
                  >
                    {isSwapping ? (
                      <React.Fragment>
                        <CircularProgress
                          size={24}
                          color="inherit"
                          sx={{ marginRight: 1 }}
                        />
                        Swapping...
                      </React.Fragment>
                    ) : (
                      "Place Trade"
                    )}
                  </Button>
                </Grid2>
                {launched && (
                  <Grid2 xs={12} sx={{ mt: 1, textAlign: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#4CAF50",
                        cursor: "pointer",
                        "&:hover": { textDecoration: "underline" },
                      }}
                      onClick={() =>
                        window.open("https://dragonswap.app/swap", "_blank")
                      }
                    >
                      Token launched! Swap on Dragonswap
                    </Typography>
                  </Grid2>
                )}
                {/* <Grid2 xs={12}><Button variant="contained" onClick={swapHandler} sx={{ background: '#FFA800', fontSize: 24, fontWeight: 'bold' }} fullWidth>Place Trade</Button></Grid2> */}
              </Grid2>
            </CardContent>
          </Card>
          <MainCard
            network={detailData?.network}
            image={detailData?.tokenImage}
            title={detailData?.tokenName}
            ticker={detailData?.tokenSymbol}
            description={detailData?.tokenDescription}
            market_cap={detailData?.marketcap}
            trades={detailData?.replies}
            deployer={detailData?.creatorAddress}
            web={detailData?.webLink}
            telegram={detailData?.telegramLink}
            twitter={detailData?.twitterLink}
            tokenAddr={detailData?.tokenAddress}
          />
          <Card sx={{ border: "1px solid #FFA800", mb: "1rem" }}>
            <CardContent>
              <List>
                <ListItem
                  disablePadding
                  dense={true}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <>
                    <Typography>
                      1.{" "}
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`https://seitrace.com/address/${SEI_LP_CONTRACT_ADDRESS}?chain=pacific-1`}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {SEI_LP_CONTRACT_ADDRESS.slice(0, 5)}...
                        {SEI_LP_CONTRACT_ADDRESS.slice(-4)} (Bonding Curve)
                      </a>
                    </Typography>
                    <Typography>{(lpBalance * 100).toFixed(2)}%</Typography>
                  </>
                </ListItem>
                {holders
                  .sort((a: any, b: any) => b?.tokenAmount - a?.tokenAmount)
                  .map((item: any, index: number) => (
                    <ListItem
                      key={index + "dhdgdajadh"}
                      disablePadding
                      dense={true}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>
                        {index + 2}.{" "}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://seitrace.com/address/${item.holderAddress}?chain=pacific-1`}
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {item.holderAddress.slice(0, 5)}...
                          {item.holderAddress.slice(-4)}
                          {item.holderAddress === item.creatorAddress
                            ? " (Creator)"
                            : ""}
                        </a>
                      </Typography>
                      <Typography>
                        {((item.tokenAmount / totalSupply) * 100).toFixed(2)}%
                      </Typography>
                    </ListItem>
                  ))}
              </List>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={`md`}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add a comment"}</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rows={4}
            sx={{ width: { xs: "100%", sm: 500 } }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #FF0000",
              background: "#FF000020",
              color: "white",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #FFA800",
              background: "#FFA80020",
              color: "white",
            }}
            onClick={handlePost}
          >
            Post
          </Button>
        </DialogActions>
      </Dialog>

      <SwapStatusDialog
            open={showSwapStatus}
            onClose={() => {
                if (!isSwapping) {
                    setShowSwapStatus(false);
                    setSwapTxHash(null);
                }
            }}
            isSwapping={isSwapping}
            currentStep={swapStep}
            error={swapError}
            type={type}
            txHash={swapTxHash ?? null}
            onRetry={swapHandler}
        />
    </Box>
  );
}
