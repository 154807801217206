import { Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, Slide, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import MainCard from "../components/cards";
import { TransitionProps } from "@mui/material/transitions";
import axios from "axios";
import React from "react";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DashboardPage() {
    let checkedStatus = localStorage.getItem('checked');
    const [open, setOpen] = React.useState(checkedStatus !== null && JSON.parse(checkedStatus) === true ? false : true);
    const [orderType, setOrderType] = React.useState('creationTime');
    const [orderFlag, setOrderFlag] = React.useState('DESC');
    const [searchWord, setSearchWord] = React.useState('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [network, setNetwork] = React.useState('All');
    const [pageNumber, setPageNumber] = React.useState(1);
    const [totalPage, setTotalPage] = React.useState(10);
    const [recommendToken, setRecommendToken] = React.useState<any>(null);
    const [animation, setAnimation] = React.useState(true); // or false, based on your preference
    const [shake, setShake] = React.useState(false);
    const [allTokens, setAllTokens] = React.useState<any[]>([]);
    const [newTokens, setNewTokens] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);


    const getInitialTokens = async () => {
        setLoading(true);
        const params = new URLSearchParams({
            orderType,
            orderFlag,
            searchWord,
            network,
            page: '1'
        });

        const headers = { 
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tokens?${params}`, { headers });
            setAllTokens(response.data.tokenList);
            setTotalPage(response.data.totalPages);
            setRecommendToken(response.data.recommendToken);
        } catch (error) {
            console.error("Error fetching initial tokens:", error);
        } finally {
            setLoading(false);
        }
    };

    const getLatestTokens = async () => {
        const params = new URLSearchParams({
            orderType,
            orderFlag,
            searchWord,
            network,
            page: '1'
        });

        const headers = { 
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tokens?${params}`, { headers });
            const latestTokens = response.data.tokenList;

            //For testing of shaking
            const randomIndex = Math.floor(Math.random() * (latestTokens.length - 1)) + 1;
            if(latestTokens.length>0){
                let x = latestTokens[0];
                latestTokens[0] = latestTokens[randomIndex]
                latestTokens[randomIndex] = x
            }
        
            setNewTokens(latestTokens.filter((token: { tokenAddress: any; }) => 
                !allTokens.some(existingToken => existingToken.tokenAddress === token.tokenAddress)
            ));

            setAllTokens(prevTokens => {
                const updatedTokens = [...latestTokens];
                prevTokens.forEach(token => {
                    if (!updatedTokens.some(newToken => newToken.tokenAddress === token.tokenAddress)) {
                        updatedTokens.push(token);
                    }
                });
                return updatedTokens;
            });

            setTotalPage(response.data.totalPages);
            setRecommendToken(response.data.recommendToken);
        } catch (error) {
            console.error("Error fetching latest tokens:", error);
        }
    };

    const loadMore = async () => {
        if (loading || pageNumber >= totalPage) return;

        setLoading(true);
        const nextPage = pageNumber + 1;
        const params = new URLSearchParams({
            orderType,
            orderFlag,
            searchWord,
            network,
            page: nextPage.toString()
        });

        const headers = { 
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/tokens?${params}`, { headers });
            setAllTokens(prevTokens => [...prevTokens, ...response.data.tokenList]);
            setPageNumber(nextPage);
        } catch (error) {
            console.error("Error loading more tokens:", error);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        getInitialTokens();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType, orderFlag, searchWord, network]);

    React.useEffect(() => {
        const interval = setInterval(getLatestTokens, 2000);
        return () => clearInterval(interval);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderType, orderFlag, searchWord, network]);

    React.useEffect(() => {
        if (newTokens.length > 0) {
            setShake(true);
            setTimeout(() => setShake(false), 1000);
        }
    }, [newTokens]);

    const handleClose = () => {
        localStorage.setItem('checked', JSON.stringify(true));
        setOpen(false);
    };

    return (
        <div className="DashboardPage">
            {recommendToken && (
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={6} mx='auto'>
                        <Link to={`/${recommendToken?.network}/${recommendToken.tokenAddress}`} style={{ textDecoration: 'none' }}>
                            <MainCard network={recommendToken.network} image={recommendToken.tokenImage} title={recommendToken.tokenName} ticker={recommendToken.tokenSymbol} description={recommendToken.description} market_cap={recommendToken.marketcap} trades={recommendToken.replies} deployer={recommendToken.creatorAddress} web={recommendToken.webLink} telegram={recommendToken.telegramLink} twitter={recommendToken.twitterLink} tokenAddr={recommendToken.tokenAddress} />
                        </Link>
                    </Grid2>
                </Grid2>
            )}
            <Grid2 container spacing={2} mb="1rem">
                <Grid2 xs={6} sm={3} mb="1rem">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Latest Curvs</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orderType}
                            label="Latest Curvs"
                            onChange={(e) => setOrderType(e.target.value)}
                        >
                            <MenuItem value="marketcap">Marketcap</MenuItem>
                            <MenuItem value="creationTime">CreationTime</MenuItem>
                            <MenuItem value="updateTime">UpdateTime</MenuItem>
                            <MenuItem value="replies">Replies</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>

                <Grid2 xs={6} sm={3} mb="1rem">
                    <FormControl fullWidth>
                        <InputLabel id="animation-select-label">Animation</InputLabel>
                        <Select
                            labelId="animation-select-label"
                            id="animation-select"
                            value={animation ? 'On' : 'Off'} // Display 'On' or 'Off' based on boolean
                            label="Animation"
                            onChange={(e) => setAnimation(e.target.value === 'On')} // Set true for 'On', false for 'Off'
                        >
                            <MenuItem value='On'>On</MenuItem>
                            <MenuItem value='Off'>Off</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>

                {/* <Grid2 xs={6} sm={3} mb="1rem">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Network</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={network}
                                label="Curve Lvl"
                                onChange={(e) => setNetwork(e.target.value)}
                            >
                                <MenuItem value='All'>All Networks</MenuItem>
                                <MenuItem value='Sei'>Sei</MenuItem>
                                <MenuItem value='Ethereum'>Ethereum</MenuItem>
                                <MenuItem value='Base'>Base</MenuItem>
                                <MenuItem value='Avax'>Avax</MenuItem>
                                <MenuItem value='Arbitrum'>Arbitrum</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid2> */}
                <Grid2 xs={6} sm={3} mb="1rem">
                    <FormControl fullWidth>
                        {/* <InputLabel id="demo-simple-select-label">Search</InputLabel> */}
                        <TextField label="Search Keyword" value={searchWord} onChange={(e) => setSearchWord(e.target.value)} />
                    </FormControl>
                </Grid2>
                <Grid2 xs={6} sm={3} mb="1rem">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Curve Lvl</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={orderFlag}
                            label="Network"
                            onChange={(e) => setOrderFlag(e.target.value)}
                        >
                            <MenuItem value='ASC'>ASC</MenuItem>
                            <MenuItem value='DESC'>DESC</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
            </Grid2>

            {allTokens.length > 0 ? (
                <Grid2 container spacing={2}>
                    {allTokens.map((item, index) => (
                        <Grid2 xs={12} sm={4} key={`${item.tokenAddress}-${index}`}>
                            <Link to={`/${item?.network}/${item.tokenAddress}`} style={{ textDecoration: 'none' }}>
                                <MainCard
                                    network={item.network}
                                    image={item.tokenImage}
                                    title={item.tokenName}
                                    ticker={item.tokenSymbol}
                                    description={item.description}
                                    market_cap={item.marketcap}
                                    trades={item.replies}
                                    deployer={item.creatorAddress}
                                    web={item.webLink}
                                    telegram={item.telegramLink}
                                    twitter={item.twitterLink}
                                    tokenAddr={item.tokenAddress}
                                    className={(newTokens.some(newToken => newToken.tokenAddress === item.tokenAddress) && animation && shake && index === 0) ? 'shake highlight' : ''}
                                />
                            </Link>
                        </Grid2>
                    ))}
                </Grid2>
            ) : (
                <Grid2 container spacing={2}>
                    <Grid2 xs={12} sm={6} mx='auto' mb="1rem">
                        <Card>
                            <CardContent>
                                <Typography textAlign='center'>Empty Curve Data</Typography>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
            )}

            <Grid2 sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={loadMore} disabled={pageNumber >= totalPage}>
                    Load More
                </Button>
            </Grid2>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"How It Works"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" mb='1rem'>
                        This platform prevents rugs by making sure that all created tokens are safe. Each token on this platform is a fair-launch with no presale and full transparency on allocation.
                    </DialogContentText>
                    <Typography>step 1: Choose the token you like</Typography>
                    <Typography>step 2: Buy the token on the selected chain</Typography>
                    <Typography>step 3: Sell at any time to lock in your profits or losses</Typography>
                    <Typography>step 4: When enough people buy, it reaches a market cap of $34.5k</Typography>
                    <Typography>step 5: $6k of liquidity is then deposited on DragonSwap and burned</Typography>
                </DialogContent>
                <DialogActions>
                    <Container>
                        <Button onClick={handleClose} variant="outlined" sx={{ border: '1px solid #FFA800', background: '#FFA80020', color: 'white', mb: '1rem' }} fullWidth >Let's go!</Button>
                    </Container>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DashboardPage;
