import React, { useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Logo from '../../assets/images/seifun.png';
import { useMainContext } from "../../context/mainContext";
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { AppBar, Avatar, Box, Button, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import axios from "axios";
import { socket } from "../../utils/socket";
import { Link, useNavigate } from "react-router-dom";

function ElevationScroll({ children, window }: { children: React.ReactElement, window?: () => Window }) {
    // Use useScrollTrigger to respond to user scroll
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0, // Adjust elevation based on scroll. You can adjust this value.
        style: {
            backgroundColor: '#113737', // Change background color on scroll
            transition: trigger ? 'background-color 0.3s, box-shadow 0.3s' : 'none', // Smooth transition for the background change
        },
    });
}

function Header({ drawerWidth, elevation }: { drawerWidth: number, elevation?: number }) {
    const { open } = useWeb3Modal();
    const { address, isConnected } = useWeb3ModalAccount();
    const { handleDrawerToggle, userScore, setUserScore } = useMainContext();
    const [latestTrades, setLatestTrades] = React.useState<any[]>([]);
    const [shake, setShake] = React.useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const interval = setInterval(() => {
            setShake(true);
            setTimeout(() => setShake(false), 1000);
        }, 10000);
    
        return () => clearInterval(interval);
      }, [latestTrades]);

    const getLatestTrade = async () => {
        const headers = { 
            'Access-Control-Allow-Origin': '*',
            'x-api-key': process.env.REACT_APP_AUTH_KEY,
            'x-user-identifier': process.env.REACT_APP_USER_ID
        };

        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}`, { headers });
            setLatestTrades(response.data);
        } catch (error) {

        }
    }    

    React.useEffect(() => {
        getLatestTrade();
    }, []);

    React.useEffect(() => {
        socket.on("message", (data:any) => {
           getLatestTrade();
        })

        return () => {
            socket.off("message");
        }
    }, []);

  React.useEffect(() => {
    const init = async () => {
      if (!address) return;
      const headers = {
        'Access-Control-Allow-Origin': '*',
        'x-api-key': process.env.REACT_APP_AUTH_KEY,
        'x-user-identifier': process.env.REACT_APP_USER_ID
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/referral/${address}`, { headers: headers });
      setUserScore(response.data.score.toString());
    }
    init();
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);
  
    const handleClick = (network: string, tokenAddress: string) => (e: any) => {
      e.preventDefault();
      navigate(`/${network}/${tokenAddress}`, { replace: true });
    };

    return (
        <div className="Header">
            <ElevationScroll>
                <AppBar
                    position="fixed"
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        ml: { sm: `${drawerWidth}px` },
                        background: '#113737',
                        boxShadow: 'none'
                    }}
                >
                    <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                        <Box display={"flex"}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                sx={{ display: { sm: 'none' } }}
                            >
                                <img src={Logo} height={50} alt="logo" />
                            </IconButton>
                        </Box>
                        <Box sx={{ width: '90%', overflow: 'hidden', display: { sm: 'inline-flex', xs: 'none' } }}>
                            {latestTrades?.map((item: any, index: number) => 
                                <a className={(shake && index === 0) ? 'shake highlight' : ''} href={`/${item.network}/${item.tokenAddress}`} style={{ marginRight: '1rem', background: '#ffffff29', padding: '0.25rem', borderRadius: '2rem', textDecoration:'none', color:'white' }} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ width: 25, height: 25, mr: '0.5rem' }} alt="Natacha" src={`https://api.multiavatar.com/${index}.png`} />
                                        <Typography sx={{textWrap:'nowrap'}}>{`${item.type} ${item.ethAmount} ${item.network === "Base" ? "BaseETH" : item.network === "Sei" ? "SEI" : item.network === "Sepolia" ? "SepoliaETH" : item.network === "Avax" ?  "Avax" : item.network === "Arbitrum" ? "Arb" : "ETH"} of ${item.tokenName}`}</Typography>
                                        <Avatar sx={{ width: 25, height: 25, ml: '0.5rem', textWrap:'nowrap' }} alt="Natacha" src={item.tokenImage} />
                                    </Box>
                                </a>
                            )}
                        </Box>
                        <Box marginLeft='auto' display={"flex"}>
                            {isConnected && <Button variant="contained" style={{ backgroundImage: "linear-gradient(90deg, #FFA800 14.14%, #9E1F63 100%)", color: 'white', borderBottomRightRadius: 20, borderTopRightRadius: 5, borderTopLeftRadius: 20, paddingLeft: '2rem', paddingRight: '2rem' }} onClick={() => open()}>{`${address?.slice(0, 5)}....${address?.slice(-4)}`}</Button>}
                            {!isConnected && <Button variant="contained" style={{ backgroundImage: "linear-gradient(90deg, #FFA800 14.14%, #9E1F63 100%)", color: 'white', borderBottomRightRadius: 20, borderTopRightRadius: 5, borderTopLeftRadius: 20, paddingLeft: '2rem', paddingRight: '2rem' }} onClick={() => open()}>Connect</Button>}
                        </Box>
                        <Box marginLeft='auto' display={"flex"}>
                            <Typography sx={{textWrap:'nowrap'}}>Score: {parseFloat(userScore).toFixed(4)}</Typography>
                        </Box>
                    </Toolbar>
                    <Toolbar sx={{ display: { xs: latestTrades.length > 0 ? 'flex' : 'none', sm: 'none' }, flexDirection: 'row', justifyContent: "space-between", borderRadius:150 }}>
                        <Box sx={{ width: '100%', overflow: 'hidden', display: { sm: 'none', xs: 'inline-flex' } }}>
                        {latestTrades.map((item: any, index: number) => (
                                <a href={`/${item.network}/${item.tokenAddress}`} style={{ marginRight: '1rem', background: '#ffffff29', padding: '0.25rem', borderRadius: '2rem', textDecoration:'none', color:'white' }} key={index}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ width: 25, height: 25, mr: '0.5rem' }} alt="Natacha" src={`https://api.multiavatar.com/${index}.png`} />
                                        <Typography sx={{textWrap:'nowrap'}}>{`${item.type} ${item.ethAmount} ${item.network} of ${item.tokenName}`}</Typography>
                                        <Avatar sx={{ width: 25, height: 25, ml: '0.5rem',  }} alt="Natacha" src={item.tokenImage} />
                                    </Box>
                                </a>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </div>
    );
}

export default Header;
