interface Token {
  chainId?: number;
  name: string;
  symbol: string;
  address: string;
  decimals: number;
  logoURI?: string | null;
}

interface TokensByChainId {
  [chainId: string]: Token[];
}

const tokens: TokensByChainId = {
  "1": [
    {
      "chainId": 1,
      "name": "Chainlink",
      "symbol": "LINK",
      "address": "0x514910771af9ca656af840dff83e8264ecf986ca",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 1,
      "name": "UMA",
      "symbol": "UMA",
      "address": "0x04fa0d235c4abf4bcf4787af4cf447de572ef828",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5617.png"
    },
    {
      "chainId": 1,
      "name": "Aave",
      "symbol": "LEND",
      "address": "0x80fb784b7ed66730e8b1dbd9820afd29931aab03",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2239.png"
    },
    {
      "chainId": 1,
      "name": "yearn.finance",
      "symbol": "YFI",
      "address": "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png"
    },
    {
      "chainId": 1,
      "name": "Maker",
      "symbol": "MKR",
      "address": "0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png"
    },
    {
      "chainId": 1,
      "name": "Wrapped Bitcoin",
      "symbol": "WBTC",
      "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 1,
      "name": "Synthetix",
      "symbol": "SNX",
      "address": "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png"
    },
    {
      "chainId": 1,
      "name": "Compound",
      "symbol": "COMP",
      "address": "0xc00e94cb662c3520282e6f5717214004a7f26888",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png"
    },
    {
      "chainId": 1,
      "name": "Dai",
      "symbol": "DAI",
      "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 1,
      "name": "0x",
      "symbol": "ZRX",
      "address": "0xe41d2489571d322189246dafa5ebde1f4699f498",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1896.png"
    },
    {
      "chainId": 1,
      "name": "Ren",
      "symbol": "REN",
      "address": "0x408e41876cccdc0f92210600ef50372656052a38",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2539.png"
    },
    {
      "chainId": 1,
      "name": "Loopring",
      "symbol": "LRC",
      "address": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1934.png"
    },
    {
      "chainId": 1,
      "name": "Kyber Network",
      "symbol": "KNC",
      "address": "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1982.png"
    },
    {
      "chainId": 1,
      "name": "SushiSwap",
      "symbol": "SUSHI",
      "address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
      "chainId": 1,
      "name": "Band Protocol",
      "symbol": "BAND",
      "address": "0xba11d00c5f74255f56a5e366f4f77f5a186d7f55",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4679.png"
    },
    {
      "chainId": 1,
      "name": "Augur",
      "symbol": "REP",
      "address": "0x1985365e9f78359a9b6ad760e32412f4a445e862",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1104.png"
    },
    {
      "chainId": 1,
      "name": "Ampleforth",
      "symbol": "AMPL",
      "address": "0xd46ba6d942050d489dbd938a2c909a5d5039a161",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4056.png"
    },
    {
      "chainId": 1,
      "name": "Balancer",
      "symbol": "BAL",
      "address": "0xba100000625a3754423978a60c9317c58a424e3d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5728.png"
    },
    {
      "chainId": 1,
      "name": "DFI.Money",
      "symbol": "YFII",
      "address": "0xa1d0e215a23d7030842fc67ce582a6afa3ccab83",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5957.png"
    },
    {
      "chainId": 1,
      "name": "Swipe",
      "symbol": "SXP",
      "address": "0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4279.png"
    },
    {
      "chainId": 1,
      "name": "Reserve Rights",
      "symbol": "RSR",
      "address": "0x320623b8e4ff03373931769a31fc52a4e78b5d70",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3964.png"
    },
    {
      "chainId": 1,
      "name": "Numeraire",
      "symbol": "NMR",
      "address": "0x1776e1f26f98b1a5df9cd347953a26dd3cb46671",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1732.png"
    },
    {
      "chainId": 1,
      "name": "Curve DAO Token",
      "symbol": "CRV",
      "address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png"
    },
    {
      "chainId": 1,
      "name": "Bancor",
      "symbol": "BNT",
      "address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1727.png"
    },
    {
      "chainId": 1,
      "name": "xDai",
      "symbol": "STAKE",
      "address": "0x0ae055097c6d159879521c384f1d2123d1f195e6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5601.png"
    },
    {
      "chainId": 1,
      "name": "Gnosis",
      "symbol": "GNO",
      "address": "0x6810e776880c02933d47db1b9fc05908e5386b96",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png"
    },
    {
      "chainId": 1,
      "name": "Melon",
      "symbol": "MLN",
      "address": "0xec67005c4e498ec7f55e092bd1d35cbc47c91892",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1552.png"
    },
    {
      "chainId": 1,
      "name": "Tellor",
      "symbol": "TRB",
      "address": "0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4944.png"
    },
    {
      "chainId": 1,
      "name": "Akropolis",
      "symbol": "AKRO",
      "address": "0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4134.png"
    },
    {
      "chainId": 1,
      "name": "IDEX",
      "symbol": "IDEX",
      "address": "0xb705268213d593b8fd88d3fdeff93aff5cbdcfae",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3928.png"
    },
    {
      "chainId": 1,
      "name": "dForce",
      "symbol": "DF",
      "address": "0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4758.png"
    },
    {
      "chainId": 1,
      "name": "Cream Finance",
      "symbol": "CREAM",
      "address": "0x2ba592f78db6436527729929aaf6c908497cb200",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6193.png"
    },
    {
      "chainId": 1,
      "name": "Nectar",
      "symbol": "NEC",
      "address": "0xcc80c051057b774cd75067dc48f8987c4eb97a5e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2538.png"
    },
    {
      "chainId": 1,
      "name": "PowerPool",
      "symbol": "CVP",
      "address": "0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6669.png"
    },
    {
      "chainId": 1,
      "name": "Request",
      "symbol": "REQ",
      "address": "0x8f8221afbb33998d8584a2b05749ba73c37a938a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2071.png"
    },
    {
      "chainId": 1,
      "name": "DIA",
      "symbol": "DIA",
      "address": "0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6138.png"
    },
    {
      "chainId": 1,
      "name": "sUSD",
      "symbol": "sUSD",
      "address": "0x57ab1ec28d129707052df4df418d58a2d46d5f51",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2927.png"
    },
    {
      "chainId": 1,
      "name": "Swerve",
      "symbol": "SWRV",
      "address": "0xb8baa0e4287890a5f79863ab62b7f175cecbd433",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6901.png"
    },
    {
      "chainId": 1,
      "name": "Zap",
      "symbol": "ZAP",
      "address": "0x6781a0f84c7e9e846dcb84a9a5bd49333067b104",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2363.png"
    },
    {
      "chainId": 1,
      "name": "Keep Network",
      "symbol": "KEEP",
      "address": "0x85eee30c52b0b379b046fb0f85f4f3dc3009afec",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5566.png"
    },
    {
      "chainId": 1,
      "name": "pNetwork",
      "symbol": "PNT",
      "address": "0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5794.png"
    },
    {
      "chainId": 1,
      "name": "Meta",
      "symbol": "MTA",
      "address": "0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5748.png"
    },
    {
      "chainId": 1,
      "name": "Monolith",
      "symbol": "TKN",
      "address": "0xaaaf91d9b90df800df4f55c205fd6989c977e73a",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1660.png"
    },
    {
      "chainId": 1,
      "name": "SUKU",
      "symbol": "SUKU",
      "address": "0x0763fdccf1ae541a5961815c0872a8c5bc6de4d7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6180.png"
    },
    {
      "chainId": 1,
      "name": "Hakka.Finance",
      "symbol": "HAKKA",
      "address": "0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6622.png"
    },
    {
      "chainId": 1,
      "name": "Ferrum Network",
      "symbol": "FRM",
      "address": "0xe5caef4af8780e59df925470b050fb23c43ca68c",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4228.png"
    },
    {
      "chainId": 1,
      "name": "Metronome",
      "symbol": "MET",
      "address": "0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2873.png"
    },
    {
      "chainId": 1,
      "name": "Hydro Protocol",
      "symbol": "HOT",
      "address": "0x9af839687f6c94542ac5ece2e317daae355493a1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2430.png"
    },
    {
      "chainId": 1,
      "name": "SwftCoin",
      "symbol": "SWFTC",
      "address": "0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2341.png"
    },
    {
      "chainId": 1,
      "name": "Jarvis Network",
      "symbol": "JRT",
      "address": "0x8a9c67fee641579deba04928c4bc45f66e26343a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5187.png"
    },
    {
      "chainId": 1,
      "name": "Datamine",
      "symbol": "DAM",
      "address": "0xf80d589b3dbe130c270a69f1a69d050f268786df",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2260.png"
    },
    {
      "chainId": 1,
      "name": "YAM V3",
      "symbol": "YAM",
      "address": "0x0AaCfbeC6a24756c20D41914F2caba817C0d8521",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7131.png"
    },
    {
      "chainId": 1,
      "name": "MANTRA DAO",
      "symbol": "OM",
      "address": "0x2baecdf43734f22fd5c152db08e3c27233f0c7d2",
      "decimals": 18
    },
    {
      "chainId": 1,
      "name": "The Tokenized Bitcoin",
      "symbol": "imBTC",
      "address": "0x3212b29e33587a00fb1c83346f5dbfa69a458923",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5292.png"
    },
    {
      "chainId": 1,
      "name": "renBTC",
      "symbol": "renBTC",
      "address": "0xeb4c2781e4eba804ce9a9803c67d0893436bb27d",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5777.png"
    },
    {
      "chainId": 1,
      "name": "mStable USD",
      "symbol": "mUSD",
      "address": "0xe2f2a5c287993345a840db3b0845fbc70f5935a5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5747.png"
    },
    {
      "chainId": 1,
      "name": "Harvest Finance",
      "symbol": "FARM",
      "address": "0xa0246c9032bc3a600820415ae600c6388619a14d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6859.png"
    },
    {
      "chainId": 1,
      "name": "YFDAI.FINANCE",
      "symbol": "Yf-DAI",
      "address": "0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6938.png"
    },
    {
      "chainId": 1,
      "name": "DXdao",
      "symbol": "DXD",
      "address": "0xa1d65e8fb6e87b60feccbc582f7f97804b725521",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5589.png"
    },
    {
      "chainId": 1,
      "name": "MCDEX",
      "symbol": "MCB",
      "address": "0x4e352cf164e64adcbad318c3a1e222e9eba4ce42",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5956.png"
    },
    {
      "chainId": 1,
      "name": "Shroom.Finance",
      "symbol": "SHROOM",
      "address": "0xed0439eacf4c4965ae4613d77a5c2efe10e5f183",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6891.png"
    },
    {
      "chainId": 1,
      "name": "Lien",
      "symbol": "LIEN",
      "address": "0xab37e1358b639fd877f015027bb62d3ddaa7557e",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6705.png"
    },
    {
      "chainId": 1,
      "name": "sETH",
      "symbol": "sETH",
      "address": "0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5765.png"
    },
    {
      "chainId": 1,
      "name": "sBTC",
      "symbol": "sBTC",
      "address": "0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5764.png"
    },
    {
      "chainId": 1,
      "name": "Reserve",
      "symbol": "RSV",
      "address": "0x196f4727526ea7fb1e17b2071b3d8eaa38486988",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6727.png"
    },
    {
      "chainId": 1,
      "name": "Compound Dai",
      "symbol": "cDAI",
      "address": "0x5d3a536e4d6dbd6114cc1ead35777bab948e3643",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5263.png"
    },
    {
      "chainId": 1,
      "name": "pTokens BTC",
      "symbol": "pBTC",
      "address": "0x5228a22e72ccc52d415ecfd199f99d0665e7733b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5434.png"
    },
    {
      "chainId": 1,
      "name": "Compound USDT",
      "symbol": "cUSDT",
      "address": "0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5745.png"
    },
    {
      "chainId": 1,
      "name": "Compound SAI",
      "symbol": "cDAI",
      "address": "0xf5dce57282a584d2746faf1593d3121fcac444dc",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5264.png"
    },
    {
      "chainId": 1,
      "name": "Compound USD Coin",
      "symbol": "cUSDC",
      "address": "0x39aa39c021dfbae8fac545936693ac917d5e7563",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5265.png"
    },
    {
      "chainId": 1,
      "name": "Compound Ether",
      "symbol": "cETH",
      "address": "0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5636.png"
    },
    {
      "chainId": 1,
      "name": "Compound Basic Attention Token",
      "symbol": "cBAT",
      "address": "0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5742.png"
    },
    {
      "chainId": 1,
      "name": "Compound 0x",
      "symbol": "cZRX",
      "address": "0xb3319f5d18bc0d84dd1b4825dcde5d5f7266d407",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5743.png"
    },
    {
      "chainId": 1,
      "name": "Compound Wrapped BTC",
      "symbol": "cWBTC",
      "address": "0xc11b1268c1a384e55c48c2391d8d480264a3a7f4",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5744.png"
    },
    {
      "chainId": 1,
      "name": "Compound Augur",
      "symbol": "cREP",
      "address": "0x158079ee67fce2f58472a96584a73c7ab9ac95c1",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5746.png"
    },
    {
      "chainId": 1,
      "name": "iETH",
      "symbol": "iETH",
      "address": "0xa9859874e1743a32409f75bb11549892138bba1e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6188.png"
    },
    {
      "chainId": 1,
      "name": "PieDAO BTC++",
      "symbol": "BTC++",
      "address": "0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5715.png"
    },
    {
      "chainId": 1,
      "name": "DiFy.Finance",
      "symbol": "YFIII",
      "address": "0x4be40bc9681d0a7c24a99b4c92f85b9053fc2a45",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7143.png"
    },
    {
      "chainId": 1,
      "name": "Lua Token",
      "symbol": "LUA",
      "address": "0xb1f66997a5760428d3a87d68b90bfe0ae64121cc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7216.png"
    },
    {
      "chainId": 1,
      "name": "yAxis",
      "symbol": "YAX",
      "address": "0xb1dc9124c395c1e97773ab855d66e879f053a289",
      "decimals": 18
    },
    {
      "chainId": 1,
      "name": "cVault.finance",
      "symbol": "CORE",
      "address": "0x62359ed7505efc61ff1d56fef82158ccaffa23d7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7242.png"
    },
    {
      "chainId": 1,
      "name": "Orion Protocol",
      "symbol": "ORN",
      "address": "0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5631.png"
    },
    {
      "chainId": 1,
      "name": "Aave",
      "symbol": "AAVE",
      "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"
    },
    {
      "chainId": 1,
      "name": "Loki",
      "symbol": "wLOKI",
      "address": "0x5ff668ddfdcbb7505f41ed56e626e4d4719dcb26",
      "decimals": 9,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x5ff668ddfDcBB7505f41ED56e626E4D4719DcB26/logo.png"
    },
    {
      "chainId": 1,
      "name": "Index Cooperative",
      "symbol": "INDEX",
      "address": "0x0954906da0bf32d5479e25f46056d22f08464cab",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7336.png"
    },
    {
      "chainId": 1,
      "name": "Perpetual Protocol",
      "symbol": "PERP",
      "address": "0xbc396689893d065f41bc2c6ecbee5e0085233447",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6950.png"
    },
    {
      "chainId": 1,
      "name": "DODO",
      "symbol": "DODO",
      "address": "0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7224.png"
    },
    {
      "chainId": 1,
      "name": "EasyFi",
      "symbol": "EASY",
      "address": "0x913d8adf7ce6986a8cbfee5a54725d9eea4f0729",
      "decimals": 18
    },
    {
      "chainId": 1,
      "name": "ZeroSwap",
      "symbol": "ZEE",
      "address": "0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7438.png"
    },
    {
      "chainId": 1,
      "name": "DeFi Pulse Index",
      "symbol": "DPI",
      "address": "0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7055.png"
    },
    {
      "chainId": 1,
      "name": "Injective Protocol",
      "symbol": "INJ",
      "address": "0xe28b3b32b6c345a34ff64674606124dd5aceca30",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png"
    },
    {
      "chainId": 1,
      "name": "Single Collateral DAI",
      "symbol": "SAI",
      "address": "0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2308.png"
    },
    {
      "chainId": 1,
      "name": "Aurora DAO",
      "symbol": "AURA",
      "address": "0xcdcfc0f66c522fd086a1b725ea3c0eeb9f9e8814",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2423.png"
    },
    {
      "chainId": 1,
      "name": "Aave MANA",
      "symbol": "aMANA",
      "address": "0x6fce4a401b6b80ace52baaefe4421bd188e76f6f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5757.png"
    },
    {
      "chainId": 1,
      "name": "Aave USDT",
      "symbol": "aUSDT",
      "address": "0x71fc860f7d3a592a4a98740e39db31d25db65ae8",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5761.png"
    },
    {
      "chainId": 1,
      "name": "Aave DAI",
      "symbol": "aDAI",
      "address": "0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5763.png"
    },
    {
      "chainId": 1,
      "name": "iBCH",
      "symbol": "iBCH",
      "address": "0xf6e9b246319ea30e8c2fa2d1540aaebf6f9e1b89",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5773.png"
    },
    {
      "chainId": 1,
      "name": "Cap",
      "symbol": "CAP",
      "address": "0x43044f861ec040db59a7e324c40507addb673142",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5809.png"
    },
    {
      "chainId": 1,
      "name": "Frontier",
      "symbol": "FRONT",
      "address": "0xf8c3527cc04340b208c854e985240c02f7b7793f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5893.png"
    },
    {
      "chainId": 1,
      "name": "Akropolis Delphi",
      "symbol": "ADEL",
      "address": "0x94d863173ee77439e4292284ff13fad54b3ba182",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6852.png"
    },
    {
      "chainId": 1,
      "name": "DefiDollar",
      "symbol": "DUSD",
      "address": "0x5bc25f649fc4e26069ddf4cf4010f9f706c23831",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6881.png"
    },
    {
      "chainId": 1,
      "name": "Hegic",
      "symbol": "HEGIC",
      "address": "0x584bc13c7d411c00c01a62e8019472de68768430",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6929.png"
    },
    {
      "chainId": 1,
      "name": "Hedget",
      "symbol": "HGET",
      "address": "0x7968bc6a03017ea2de509aaa816f163db0f35148",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6949.png"
    },
    {
      "chainId": 1,
      "name": "Pickle Finance",
      "symbol": "PICKLE",
      "address": "0x429881672b9ae42b8eba0e26cd9c73711b891ca5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7022.png"
    },
    {
      "chainId": 1,
      "name": "Uniswap",
      "symbol": "UNI",
      "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      "chainId": 1,
      "name": "dHedge DAO",
      "symbol": "DHT",
      "address": "0xca1207647ff814039530d7d35df0e1dd2e91fa84",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7094.png"
    },
    {
      "chainId": 1,
      "name": "Unisocks",
      "symbol": "SOCKS",
      "address": "0x23b608675a2b2fb1890d3abbd85c5775c51691d5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7095.png"
    },
    {
      "chainId": 1,
      "name": "Rotten",
      "symbol": "ROT",
      "address": "0xd04785c4d8195e4a54d9dec3a9043872875ae9e2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7164.png"
    },
    {
      "chainId": 1,
      "name": "Polkastarter",
      "symbol": "POLS",
      "address": "0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7208.png"
    },
    {
      "chainId": 1,
      "name": "PieDAO DOUGH v2",
      "symbol": "DOUGH",
      "address": "0xad32a8e6220741182940c5abf610bde99e737b2d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7284.png"
    },
    {
      "chainId": 1,
      "name": "Nsure.Network",
      "symbol": "Nsure",
      "address": "0x20945ca1df56d237fd40036d47e866c7dccd2114",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7231.png"
    },
    {
      "chainId": 1,
      "name": "Keep4r",
      "symbol": "KP4R",
      "address": "0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7582.png"
    },
    {
      "chainId": 1,
      "name": "APY.Finance",
      "symbol": "APY",
      "address": "0x95a4492f028aa1fd432ea71146b433e7b4446611",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7227.png"
    },
    {
      "chainId": 1,
      "name": "tBTC",
      "symbol": "TBTC",
      "address": "0x8daebade922df735c38c80c7ebd708af50815faa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5776.png"
    },
    {
      "chainId": 1,
      "name": "Interest Bearing ETH",
      "symbol": "ibETH",
      "address": "0x67b66c99d3eb37fa76aa3ed1ff33e8e39f0b9c7a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7675.png"
    },
    {
      "chainId": 1,
      "name": "saffron.finance",
      "symbol": "SFI",
      "address": "0xb753428af26e81097e7fd17f40c88aaa3e04902c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7617.png"
    },
    {
      "chainId": 1,
      "name": "ORO",
      "symbol": "ORO",
      "address": "0xc3eb2622190c57429aac3901808994443b64b466",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7684.png"
    },
    {
      "chainId": 1,
      "name": "Alpha Finance Lab",
      "symbol": "ALPHA",
      "address": "0xa1faa113cbe53436df28ff0aee54275c13b40975",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7232.png"
    },
    {
      "chainId": 1,
      "name": "Quickswap",
      "symbol": "QUICK [OLD]",
      "address": "0x6c28aef8977c9b773996d0e8376d2ee379446f2f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8206.png"
    },
    {
      "chainId": 1,
      "name": "Quickswap",
      "symbol": "QUICK",
      "address": "0xd2ba23de8a19316a638dc1e7a9adda1d74233368",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8206.png"
    },
    {
      "chainId": 1,
      "name": "C98",
      "symbol": "C98",
      "address": "0xAE12C5930881c53715B369ceC7606B70d8EB229f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10903.png"
    },
    {
      "chainId": 1,
      "name": "Cronos Coin",
      "symbol": "CRO",
      "address": "0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png"
    },
    {
      "chainId": 1,
      "name": "FLEX Coin",
      "symbol": "FLEX",
      "address": "0xFcF8eda095e37A41e002E266DaAD7efC1579bc0A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5190.png"
    },
    {
      "chainId": 1,
      "name": "Alchemix",
      "symbol": "ALCX",
      "address": "0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8613.png"
    },
    {
      "chainId": 1,
      "name": "Cellframe Token",
      "symbol": "CELL",
      "address": "0x26c8AFBBFE1EBaca03C2bB082E69D0476Bffe099",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8992.png"
    },
    {
      "chainId": 1,
      "name": "Deeper Network",
      "symbol": "DPR",
      "address": "0xf3AE5d769e153Ef72b4e3591aC004E89F48107a1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8894.png"
    },
    {
      "chainId": 1,
      "name": "FLOKI",
      "symbol": "FLOKI",
      "address": "0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10804.png"
    },
    {
      "chainId": 1,
      "name": "Nexo",
      "symbol": "NEXO",
      "address": "0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2694.png"
    },
    {
      "chainId": 1,
      "name": "Guild of Guardians",
      "symbol": "GOG",
      "address": "0x9AB7bb7FdC60f4357ECFef43986818A2A3569c62",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10630.png"
    },
    {
      "chainId": 1,
      "name": "Gods Unchained",
      "symbol": "GODS",
      "address": "0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10631.png"
    },
    {
      "chainId": 1,
      "name": "Immutable X",
      "symbol": "IMX",
      "address": "0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10603.png"
    },
    {
      "chainId": 1,
      "name": "Swarm",
      "symbol": "BZZ",
      "address": "0x19062190B1925b5b6689D7073fDfC8c2976EF8Cb",
      "decimals": 16,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10293.png"
    },
    {
      "chainId": 1,
      "name": "AllianceBlock Token",
      "symbol": "ALBT",
      "address": "0x00a8b738E453fFd858a7edf03bcCfe20412f0Eb0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6957.png"
    },
    {
      "chainId": 1,
      "name": "RadioCaca",
      "symbol": "RACA",
      "address": "0x12BB890508c125661E03b09EC06E404bc9289040",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11346.png"
    },
    {
      "chainId": 1,
      "name": "Magic Internet Money",
      "symbol": "MIM",
      "address": "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/162.png"
    },
    {
      "chainId": 1,
      "name": "Mirror Protocol",
      "symbol": "MIR",
      "address": "0x09a3EcAFa817268f77BE1283176B946C4ff2E608",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7857.png"
    },
    {
      "chainId": 1,
      "name": "Origin Protocol",
      "symbol": "OGN",
      "address": "0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5117.png"
    },
    {
      "chainId": 1,
      "name": "Covalent",
      "symbol": "CQT",
      "address": "0xD417144312DbF50465b1C641d016962017Ef6240",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7411.png"
    },
    {
      "chainId": 1,
      "name": "Frax Share",
      "symbol": "FXS",
      "address": "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png"
    },
    {
      "chainId": 1,
      "name": "Ooki Protocol",
      "symbol": "OOKI",
      "address": "0x0De05F6447ab4D22c8827449EE4bA2D5C288379B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16434.png"
    },
    {
      "chainId": 1,
      "name": "MATH",
      "symbol": "MATH",
      "address": "0x08d967bb0134F2d07f7cfb6E246680c53927DD30",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5616.png"
    },
    {
      "chainId": 1,
      "name": "HEX",
      "symbol": "HEX",
      "address": "0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5015.png"
    },
    {
      "chainId": 1,
      "name": "Saitama V2",
      "symbol": "SAITAMA",
      "address": "0xce3f08e664693ca792cace4af1364d5e220827b2",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10498.png"
    },
    {
      "chainId": 1,
      "name": "Synapse",
      "symbol": "SYN",
      "address": "0x0f2D719407FdBeFF09D87557AbB7232601FD9F29",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png"
    },
    {
      "chainId": 1,
      "name": "X2Y2Token",
      "symbol": "X2Y2",
      "address": "0x1E4EDE388cbc9F4b5c79681B7f94d36a11ABEBC9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18106.png"
    },
    {
      "chainId": 1,
      "name": "Efinity Token",
      "symbol": "EFI",
      "address": "0x656C00e1BcD96f256F224AD9112FF426Ef053733",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8985.png"
    },
    {
      "chainId": 1,
      "name": "Orion Protocol",
      "symbol": "ORN",
      "address": "0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5631.png"
    },
    {
      "chainId": 1,
      "name": "TrueFi",
      "symbol": "TRU",
      "address": "0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7725.png"
    },
    {
      "chainId": 1,
      "name": "Radicle",
      "symbol": "RAD",
      "address": "0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6843.png"
    },
    {
      "chainId": 1,
      "name": "Vader Protocol",
      "symbol": "VADER",
      "address": "0x2602278EE1882889B946eb11DC0E810075650983",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15959.png"
    },
    {
      "chainId": 1,
      "name": "Flow",
      "symbol": "FLOW",
      "address": "0x5c147e74D63B1D31AA3Fd78Eb229B65161983B2b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4558.png"
    },
    {
      "chainId": 1,
      "name": "ApeCoin",
      "symbol": "APE",
      "address": "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18876.png"
    },
    {
      "chainId": 1,
      "name": "Axie Infinity",
      "symbol": "AXS",
      "address": "0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png"
    },
    {
      "chainId": 1,
      "name": "Enjin Coin",
      "symbol": "ENJ",
      "address": "0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2130.png"
    },
    {
      "chainId": 1,
      "name": "Gala",
      "symbol": "GALA",
      "address": "0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7080.png"
    },
    {
      "chainId": 1,
      "name": "APENFT",
      "symbol": "NFT",
      "address": "0x198d14F2Ad9CE69E76ea330B374DE4957C3F850a",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9816.png"
    },
    {
      "chainId": 1,
      "name": "Illuvium",
      "symbol": "ILV",
      "address": "0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8719.png"
    },
    {
      "chainId": 1,
      "name": "CEEK VR",
      "symbol": "CEEK",
      "address": "0xb056c38f6b7Dc4064367403E26424CD2c60655e1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2856.png"
    },
    {
      "chainId": 1,
      "name": "Chromia",
      "symbol": "CHR",
      "address": "0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png"
    },
    {
      "chainId": 1,
      "name": "Ultra",
      "symbol": "UOS",
      "address": "0xD13c7342e1ef687C5ad21b27c2b65D772cAb5C8c",
      "decimals": 4,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4189.png"
    },
    {
      "chainId": 1,
      "name": "LOOKS",
      "symbol": "LOOKS",
      "address": "0xf4d2888d29D722226FafA5d9B24F9164c092421E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17081.png"
    },
    {
      "chainId": 1,
      "name": "LOOKS",
      "symbol": "LOOKS",
      "address": "0xf4d2888d29D722226FafA5d9B24F9164c092421E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17081.png"
    },
    {
      "chainId": 1,
      "name": "Vulcan Forged PYR",
      "symbol": "PYR",
      "address": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9308.png"
    },
    {
      "chainId": 1,
      "name": "MyNeighborAlice",
      "symbol": "ALICE",
      "address": "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8766.png"
    },
    {
      "chainId": 1,
      "name": "SuperFarm",
      "symbol": "SUPER",
      "address": "0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8290.png"
    },
    {
      "chainId": 1,
      "name": "LUKSO",
      "symbol": "LYXe",
      "address": "0xA8b919680258d369114910511cc87595aec0be6D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5625.png"
    },
    {
      "chainId": 1,
      "name": "Aavegotchi",
      "symbol": "GHST",
      "address": "0x3F382DbD960E3a9bbCeaE22651E88158d2791550",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7046.png"
    },
    {
      "chainId": 1,
      "name": "Telos",
      "symbol": "TLOS",
      "address": "0x7825e833D495F3d1c28872415a4aee339D26AC88",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4660.png"
    },
    {
      "chainId": 1,
      "name": "Mask Network",
      "symbol": "MASK",
      "address": "0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8536.png"
    },
    {
      "chainId": 1,
      "name": "UFO Gaming",
      "symbol": "UFO",
      "address": "0x249e38Ea4102D0cf8264d3701f1a0E39C4f2DC3B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10729.png"
    },
    {
      "chainId": 1,
      "name": "Alethea Artificial Liquid Intelligence Token",
      "symbol": "ALI",
      "address": "0x6B0b3a982b4634aC68dD83a4DBF02311cE324181",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16876.png"
    },
    {
      "chainId": 1,
      "name": "Victoria VR",
      "symbol": "VR",
      "address": "0x7d5121505149065b562C789A0145eD750e6E8cdD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14822.png"
    },
    {
      "chainId": 1,
      "name": "Verasity",
      "symbol": "VRA",
      "address": "0xF411903cbC70a74d22900a5DE66A2dda66507255",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3816.png"
    },
    {
      "chainId": 1,
      "name": "Boson Protocol",
      "symbol": "BOSON",
      "address": "0xC477D038d5420C6A9e0b031712f61c5120090de9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8827.png"
    },
    {
      "chainId": 1,
      "name": "GuildFi",
      "symbol": "GF",
      "address": "0xAaEf88cEa01475125522e117BFe45cF32044E238",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15585.png"
    },
    {
      "chainId": 1,
      "name": "Dotmoovs",
      "symbol": "MOOV",
      "address": "0x24EC2Ca132abf8F6f8a6E24A1B97943e31f256a7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10046.png"
    },
    {
      "chainId": 1,
      "name": "REVV",
      "symbol": "REVV",
      "address": "0x557B933a7C2c45672B610F8954A3deB39a51A8Ca",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6993.png"
    },
    {
      "chainId": 1,
      "name": "Polychain Monsters",
      "symbol": "PMON",
      "address": "0x1796ae0b0fa4862485106a0de9b654eFE301D0b2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8968.png"
    },
    {
      "chainId": 1,
      "name": "Exeedme",
      "symbol": "XED",
      "address": "0xee573a945B01B788B9287CE062A0CFC15bE9fd86",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8163.png"
    },
    {
      "chainId": 1,
      "name": "Rubic",
      "symbol": "RBC",
      "address": "0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7219.png"
    },
    {
      "chainId": 1,
      "name": "Binance USD",
      "symbol": "BUSD",
      "address": "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 1,
      "name": "Cartesi",
      "symbol": "CTSI",
      "address": "0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5444.png"
    },
    {
      "chainId": 1,
      "name": "Dogelon Mars",
      "symbol": "ELON",
      "address": "0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9436.png"
    },
    {
      "chainId": 1,
      "name": "PlayDapp",
      "symbol": "PLA",
      "address": "0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7461.png"
    },
    {
      "chainId": 1,
      "name": "Luna Inu",
      "symbol": "LINU",
      "address": "0x78132543D8E20D2417d8a07D9ae199D458A0D581",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20550.png"
    },
    {
      "chainId": 1,
      "name": "Railgun",
      "symbol": "RAIL",
      "address": "0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10854.png"
    },
    {
      "chainId": 1,
      "name": "Euler Finance",
      "symbol": "EUL",
      "address": "0xd9Fcd98c322942075A5C3860693e9f4f03AAE07b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14280.png"
    },
    {
      "chainId": 1,
      "name": "Render Token",
      "symbol": "RNDR",
      "address": "0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5690.png"
    },
    {
      "chainId": 1,
      "name": "XYO",
      "symbol": "XYO",
      "address": "0x55296f69f40Ea6d20E478533C15A6B08B654E758",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2765.png"
    },
    {
      "chainId": 1,
      "name": "1inch Network",
      "symbol": "1INCH",
      "address": "0x111111111117dC0aa78b770fA6A738034120C302",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png"
    },
    {
      "chainId": 1,
      "name": "Adshares",
      "symbol": "ADS",
      "address": "0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A",
      "decimals": 11,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1883.png"
    },
    {
      "chainId": 1,
      "name": "Angle Protocol",
      "symbol": "agEUR",
      "address": "0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15024.png"
    },
    {
      "chainId": 1,
      "name": "InsurAce",
      "symbol": "INSUR",
      "address": "0x544c42fBB96B39B21DF61cf322b5EDC285EE7429",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8799.png"
    },
    {
      "chainId": 1,
      "name": "Fei USD",
      "symbol": "FEI",
      "address": "0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8642.png"
    },
    {
      "chainId": 1,
      "name": "Lido DAO",
      "symbol": "LDO",
      "address": "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8000.png"
    },
    {
      "chainId": 1,
      "name": "Ankr",
      "symbol": "ANKR",
      "address": "0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png"
    },
    {
      "chainId": 1,
      "name": "DAO Maker",
      "symbol": "DAO",
      "address": "0x0f51bb10119727a7e5ea3538074fb341f56b09ad",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8420.png"
    },
    {
      "chainId": 1,
      "name": "Fantom",
      "symbol": "FTM",
      "address": "0x4E15361FD6b4BB609Fa63C81A2be19d873717870",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png"
    },
    {
      "chainId": 1,
      "name": "chiliZ",
      "symbol": "CHZ",
      "address": "0x3506424F91fD33084466F402d5D97f05F8e3b4AF",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4066.png"
    },
    {
      "chainId": 1,
      "name": "SHIBA INU",
      "symbol": "SHIB",
      "address": "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png"
    },
    {
      "chainId": 1,
      "name": "Smooth Love Potion",
      "symbol": "SLP",
      "address": "0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25",
      "decimals": 0,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5824.png"
    },
    {
      "chainId": 1,
      "name": "SAND",
      "symbol": "SAND",
      "address": "0x3845badAde8e6dFF049820680d1F14bD3903a5d0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6210.png"
    },
    {
      "chainId": 1,
      "name": "FTX Token",
      "symbol": "FTT",
      "address": "0x50D1c9771902476076eCFc8B2A83Ad6b9355a4c9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4195.png"
    },
    {
      "chainId": 1,
      "name": "MATIC Token",
      "symbol": "MATIC",
      "address": "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
      "chainId": 1,
      "name": "Graph Token",
      "symbol": "GRT",
      "address": "0xc944E90C64B2c07662A292be6244BDf05Cda44a7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png"
    },
    {
      "chainId": 1,
      "name": "Basic Attention Token",
      "symbol": "BAT",
      "address": "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1697.png"
    },
    {
      "chainId": 1,
      "name": "OMG Network",
      "symbol": "OMG",
      "address": "0xd26114cd6EE289AccF82350c8d8487fedB8A0C07",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1808.png"
    },
    {
      "chainId": 1,
      "name": "Polymath",
      "symbol": "POLY",
      "address": "0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2496.png"
    },
    {
      "chainId": 1,
      "name": "Aragon Network Token",
      "symbol": "ANT",
      "address": "0xa117000000f279D81A1D3cc75430fAA017FA5A2e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1680.png"
    },
    {
      "chainId": 1,
      "name": "Holo Token",
      "symbol": "HOT",
      "address": "0x6c6EE5e31d828De241282B9606C8e98Ea48526E2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2682.png"
    },
    {
      "chainId": 1,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 1,
      "name": "Volt Inu V2",
      "symbol": "VOLT",
      "address": "0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19650.png"
    },
    {
      "chainId": 1,
      "name": "Kishu Inu",
      "symbol": "KISHU",
      "address": "0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9386.png"
    },
    {
      "chainId": 1,
      "name": "Tenshi",
      "symbol": "TENSHI",
      "address": "0x52662717e448be36Cb54588499D5A8328BD95292",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11149.png"
    },
    {
      "chainId": 1,
      "name": "Zombie Inu",
      "symbol": "ZINU",
      "address": "0xc50EF449171a51FbeAFd7c562b064B6471C36caA",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13382.png"
    },
    {
      "chainId": 1,
      "name": "PureFi Protocol",
      "symbol": "UFI",
      "address": "0xcDa4e840411C00a614aD9205CAEC807c7458a0E3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10973.png"
    },
    {
      "chainId": 1,
      "name": "Bolide",
      "symbol": "BLID",
      "address": "0x8A7aDc1B690E81c758F1BD0F72DFe27Ae6eC56A5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20765.png"
    },
    {
      "chainId": 1,
      "name": "Atomic Wallet Coin",
      "symbol": "AWC",
      "address": "0xaD22f63404f7305e4713CcBd4F296f34770513f4",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3667.png"
    },
    {
      "chainId": 1,
      "name": "Stargate Finance",
      "symbol": "STG",
      "address": "0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18934.png"
    },
    {
      "chainId": 1,
      "name": "Shiba Predator",
      "symbol": "QOM",
      "address": "0xa71d0588EAf47f12B13cF8eC750430d21DF04974",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18919.png"
    },
    {
      "chainId": 1,
      "name": "Doge Killer",
      "symbol": "LEASH",
      "address": "0x27C70Cd1946795B66be9d954418546998b546634",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9286.png"
    },
    {
      "chainId": 1,
      "name": "Akita Inu",
      "symbol": "AKITA",
      "address": "0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8378.png"
    },
    {
      "chainId": 1,
      "name": "ChangeNOW Token",
      "symbol": "NOW",
      "address": "0xE9A95d175a5f4C9369f3b74222402eB1b837693b",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3893.png"
    },
    {
      "chainId": 1,
      "name": "Occam.Fi",
      "symbol": "OCC",
      "address": "0x2F109021aFe75B949429fe30523Ee7C0D5B27207",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9191.png"
    },
    {
      "chainId": 1,
      "name": "XDAO",
      "symbol": "XDAO",
      "address": "0x71eeba415a523f5c952cc2f06361d5443545ad28",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21760.png"
    },
    {
      "chainId": 1,
      "name": "Wrapped TON Coin",
      "symbol": "WTON",
      "address": "0x582d872A1B094FC48F5DE31D3B73F2D9bE47def1",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png"
    },
    {
      "chainId": 1,
      "name": "Axelar",
      "symbol": "AXL",
      "address": "0x467719aD09025FcC6cF6F8311755809d45a5E5f3",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17799.png"
    },
    {
      "chainId": 1,
      "name": "Pepe",
      "symbol": "PEPE",
      "address": "0x6982508145454ce325ddbe47a25d4ec3d2311933",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24478.png"
    },
    {
      "chainId": 1,
      "name": "crvUSD",
      "symbol": "crvUSD",
      "address": "0x4cd5331522ce2dcab0854c6f6e1d9a38f86c584f",
      "decimals": 9,
      "logoURI": "https://icons.llamao.fi/icons/protocols/crvusd?w=48&h=48"
    },
    {
      "chainId": 1,
      "name": "Arbitrum",
      "symbol": "ARB",
      "address": "0xB50721BCf8d664c30412Cfbc6cf7a15145234ad1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png"
    },
    {
      "chainId": 1,
      "name": "Frax",
      "symbol": "FRAX",
      "address": "0x853d955acef822db058eb8505911ed77f175b99e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    },
    {
      "chainId": 1,
      "name": "Arkham",
      "symbol": "ARKM",
      "address": "0x6e2a43be0b1d33b726f0ca3b8de60b3482b8b050",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/27565.png"
    },
    {
      "chainId": 1,
      "name": "Mantle",
      "symbol": "MNT",
      "address": "0x3c3a81e81dc49a522a592e7622a7e711c06bf354",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/27075.png"
    },
    {
      "chainId": 1,
      "name": "BitDAO",
      "symbol": "BIT",
      "address": "0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/11221.png"
    },
    {
      "chainId": 1,
      "name": "Liquity USD",
      "symbol": "LUSD",
      "address": "0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/9566.png"
    },
    {
      "chainId": 1,
      "name": "Rocket Pool",
      "symbol": "RPL",
      "address": "0xd33526068d116ce69f19a9ee46f0bd304f21a51f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/2943.png"
    },
    {
      "chainId": 1,
      "name": "dYdX",
      "symbol": "DYDX",
      "address": "0x92d6c1e31e14520e676a687f0a93788b716beff5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/11156.png"
    },
    {
      "chainId": 1,
      "name": "Ankr Staked ETH",
      "symbol": "ankrETH",
      "address": "0xe95a203b1a91a908f9b9ce46459d101078c2c3cb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/8100.png"
    },
    {
      "chainId": 1,
      "name": "Maverick Protocol",
      "symbol": "MAV",
      "address": "0x7448c7456a97769F6cD04F1E83A4a23cCdC46aBD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/18037.png"
    },
    {
      "chainId": 1,
      "name": "CyberConnect",
      "symbol": "CYBER",
      "address": "0x14778860E937f509e651192a90589dE711Fb88a9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24781.png"
    },
    {
      "chainId": 1,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x9ad37205d608B8b219e6a2573f922094CEc5c200",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 1,
      "name": "Staked Lido Ether",
      "symbol": "stETH",
      "address": "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/8085.png"
    },
    {
      "chainId": 1,
      "name": "Staked Lido MATIC",
      "symbol": "stMATIC",
      "address": "0x9ee91F9f426fA633d227f7a9b000E28b9dfd8599",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/18688.png"
    },
    {
      "chainId": 1,
      "name": "Wrapped liquid staked Ether",
      "symbol": "wstETH",
      "address": "0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 1,
      "name": "Polygon Ecosystem Token",
      "symbol": "POL",
      "address": "0x455e53cbb86018ac2b8092fdcd39d8444affc3f6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/28321.png"
    },
    {
      "chainId": 1,
      "name": "First Digital USD",
      "symbol": "FDUSD",
      "address": "0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/26081.png"
    },
    {
      "chainId": 1,
      "name": "Verse",
      "symbol": "VERSE",
      "address": "0x249cA82617eC3DfB2589c4c17ab7EC9765350a18",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22929.png"
    },
    {
      "chainId": 1,
      "name": "TrueUSD",
      "symbol": "TUSD",
      "address": "0x0000000000085d4780B73119b644AE5ecd22b376",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png"
    },
    {
      "chainId": 1,
      "name": "Coinbase Wrapped Staked ETH",
      "symbol": "cbETH",
      "address": "0xbe9895146f7af43049ca1c1ae358b0541ea49704",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21535.png"
    },
    {
      "chainId": 1,
      "name": "StakeStone Ether",
      "symbol": "STONE",
      "address": "0x7122985656e38BDC0302Db86685bb972b145bD3C",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/33103/standard/200_200.png"
    },
    {
      "chainId": 1,
      "name": "Wrapped Mountain Protocol USD",
      "symbol": "wUSDM",
      "address": "0x57F5E098CaD7A3D1Eed53991D4d66C45C9AF7812",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/33785/standard/wUSDM_PNG_240px.png"
    },
    {
      "chainId": 1,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0xbf5495Efe5DB9ce00f80364C8B423567e58d2110",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 1,
      "name": "ether.fi",
      "symbol": "eETH",
      "address": "0x35fA164735182de50811E8e2E824cFb9B6118ac2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/28568.png"
    },
    {
      "chainId": 1,
      "name": "Wrapped eETH",
      "symbol": "weETH",
      "address": "0xCd5fE23C85820F7B72D0926FC9b05b43E359b7ee",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/28695.png"
    },
    {
      "chainId": 1,
      "name": "Restaked Swell Ethereum",
      "symbol": "rswETH",
      "address": "0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29974.png"
    },
    {
      "chainId": 1,
      "name": "Staked Frax Ether",
      "symbol": "sfrxETH",
      "address": "0xac3E018457B222d93114458476f3E3416Abbe38F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23177.png"
    },
    {
      "chainId": 1,
      "name": "StakeWise Staked ETH",
      "symbol": "osETH",
      "address": "0xf1C9acDc66974dFB6dEcB12aA385b9cD01190E38",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/28476.png"
    },
    {
      "chainId": 1,
      "name": "Kelp DAO Restaked ETH",
      "symbol": "rsETH",
      "address": "0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29242.png"
    },
    {
      "chainId": 1,
      "name": "ether.fi governance token",
      "symbol": "ETHFI",
      "address": "0xFe0c30065B384F05761f15d0CC899D4F9F9Cc0eB",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29814.png"
    },
    {
      "chainId": 1,
      "name": "StaFi",
      "symbol": "FIS",
      "address": "0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5882.png"
    },
    {
      "chainId": 1,
      "name": "pSTAKE Finance",
      "symbol": "PSTAKE",
      "address": "0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15996.png"
    },
    {
      "chainId": 1,
      "name": "Mantle Staked Ether",
      "symbol": "mETH",
      "address": "0xd5F7838F5C461fefF7FE49ea5ebaF7728bB0ADfa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29035.png"
    },
    {
      "chainId": 1,
      "name": "Stader ETHx",
      "symbol": "ETHx",
      "address": "0xA35b1B31Ce002FBF2058D22F30f95D405200A15b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/27566.png"
    },
    {
      "chainId": 1,
      "name": "BENQI Liquid Staked AVAX ",
      "symbol": "SAVAX",
      "address": "0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18523.png"
    },
    {
      "chainId": 1,
      "address": "0xdcee70654261af21c44c093c300ed3bb97b78192",
      "symbol": "WOETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1724307653414_5592235136781216.png",
      "name": "Wrapped OETH"
    },
    {
      "chainId": 1,
      "address": "0x88909d489678dd17aa6d9609f89b0419bf78fd9a",
      "symbol": "L3",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1722565780090_5725667318717538.png",
      "name": "Layer3"
    },
    {
      "chainId": 1,
      "address": "0xd3043d66afe00344c115f7f81d18277c5c718ff8",
      "symbol": "omUSD",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1720009331601_4875478558657673.png",
      "name": "OpenMoney USD"
    },
    {
      "chainId": 1,
      "address": "0x6c3ea9036406852006290770bedfcaba0e23a0e8",
      "symbol": "PYUSD",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719913392330_07998414788865982.png",
      "name": "PayPal USD"
    },
    {
      "chainId": 1,
      "address": "0x48afbbd342f64ef8a9ab1c143719b63c2ad81710",
      "symbol": "mpETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719495546997_03277336870382341.png",
      "name": "MetaPool ETH"
    },
    {
      "chainId": 1,
      "address": "0x193f4A4a6ea24102F49b931DEeeb931f6E32405d",
      "symbol": "TLOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719399855661_8919824593872174.png",
      "name": "Telos"
    },
    {
      "chainId": 1,
      "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
      "symbol": "ZRO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719303307546_4114177529382448.jpg",
      "name": "LayerZero"
    },
    {
      "chainId": 1,
      "address": "0x40D16FC0246aD3160Ccc09B8D0D3A2cD28aE6C2f",
      "symbol": "GHO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1721714439489_04254625966456427.png",
      "name": "GHO"
    },
    {
      "chainId": 1,
      "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
      "symbol": "USDM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714669163244_43206728356353064.png",
      "name": "Mountain Protocol USDM"
    },
    {
      "chainId": 1,
      "address": "0x5afe3855358e112b5647b952709e6165e1c1eeee",
      "symbol": "SAFE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1713874335178_9641760115734794.jpeg",
      "name": "Gnosis Safe"
    },
    {
      "chainId": 1,
      "address": "0xb528edbef013aff855ac3c50b381f253af13b997",
      "symbol": "AEVO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1710397558897_7642775570939047.png",
      "name": "Aevo"
    },
    {
      "chainId": 1,
      "address": "0x4c9edd5852cd905f086c759e8383e09bff1e68b3",
      "symbol": "USDe",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1709832776512_7650613519873195.png",
      "name": "Ethena USD"
    },
    {
      "chainId": 1,
      "address": "0x3429d03c6f7521aec737a0bbf2e5ddcef2c3ae31",
      "symbol": "PIXEL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1708368806434_23746649924069296.png",
      "name": "Pixels"
    },
    {
      "chainId": 1,
      "address": "0x77e06c9eccf2e797fd462a92b6d7642ef85b0a44",
      "symbol": "wTAO",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1706185196311_04633580944934357.png",
      "name": "Wrapped TAO"
    },
    {
      "chainId": 1,
      "address": "0xfaba6f8e4a5e8ab82f62fe7c39859fa577269be3",
      "symbol": "Ondo",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1705994321276_9588586687768992.jpg",
      "name": "Ondo"
    },
    {
      "chainId": 1,
      "address": "0xf8ebf4849f1fa4faf0dff2106a173d3a6cb2eb3a",
      "symbol": "Troll",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1705978483214_7892808805447311.jpeg",
      "name": "Troll"
    },
    {
      "chainId": 1,
      "address": "0x15e6e0d4ebeac120f9a97e71faa6a0235b85ed12",
      "symbol": "SAVM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1705913019427_33887360566967084.png",
      "name": "SatoshiVM"
    },
    {
      "chainId": 1,
      "address": "0x99a01a4d6a4d621094983050d9a2f10b2912e53d",
      "symbol": "VRSW",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704720444705_37949012022753004.jpeg",
      "name": "VirtuSwap"
    },
    {
      "chainId": 1,
      "address": "0xda31d0d1bc934fc34f7189e38a413ca0a5e8b44f",
      "symbol": "BSSB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1701935208632_7075732040167757.png",
      "name": "BitStable"
    },
    {
      "chainId": 1,
      "address": "0x826180541412d574cf1336d22c0c0a287822678a",
      "symbol": "FLIP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700831884231_5246108401251248.png",
      "name": "Chainflip"
    },
    {
      "chainId": 1,
      "address": "0x96F6eF951840721AdBF46Ac996b59E0235CB985C",
      "symbol": "USDY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1699420333412_10580539157047197.png",
      "name": "Ondo US Dollar Yield"
    },
    {
      "chainId": 1,
      "address": "0xa89b728708be04f57c7a33c6f790b6f077298e26",
      "symbol": "BART",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1699373479731_5703590913312755.jpg",
      "name": "ReptilianZuckerBidenBartcoin"
    },
    {
      "chainId": 1,
      "address": "0x53805a76e1f5ebbfe7115f16f9c87c2f7e633726",
      "symbol": "FETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1699297373943_05337429282284889.jpg",
      "name": "f(x) ETH"
    },
    {
      "chainId": 1,
      "address": "0x83f20f44975d03b1b09e64809b757c47f942beea",
      "symbol": "sDAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1698306953163_8347335575542754.jpeg",
      "name": "Savings Dai"
    },
    {
      "chainId": 1,
      "address": "0xc98d64da73a6616c42117b582e832812e7b8d57f",
      "symbol": "RSS3",
      "decimals": 18,
      "logoURI": "",
      "name": "RSS3"
    },
    {
      "chainId": 1,
      "address": "0xe063f04f280c60aeca68b38341c2eecbec703ae2",
      "symbol": "XETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1698165387286_5503170816291103.png",
      "name": "f(x) Protocol Leveraged ETH"
    },
    {
      "chainId": 1,
      "address": "0x4b9278b94a1112cad404048903b8d343a810b07e",
      "symbol": "HIFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1695008227380_6118098326162262.jpeg",
      "name": "Hifi Finance"
    },
    {
      "chainId": 1,
      "address": "0xfe67a4450907459c3e1fff623aa927dd4e28c67a",
      "symbol": "NEXT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1693929904922_6081739176352485.jpg",
      "name": "Connext"
    },
    {
      "chainId": 1,
      "address": "0x01e87d74b11f656a673a3e7c441425816213eb0c",
      "symbol": "HOTDOG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1693913745973_4612792335521665.png",
      "name": "Sonic"
    },
    {
      "chainId": 1,
      "address": "0x0001a500a6b18995b03f44bb040a5ffc28e45cb0",
      "symbol": "OLAS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692889559727_6926133619408161.jpg",
      "name": "Autonolas"
    },
    {
      "chainId": 1,
      "address": "0x808507121b80c02388fad14726482e061b8da827",
      "symbol": "PENDLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692867013275_14364281355341824.jpg",
      "name": "Pendle"
    },
    {
      "chainId": 1,
      "address": "0x5de8ab7e27f6e7a1fff3e5b337584aa43961beef",
      "symbol": "SDEX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692240788720_30258778478769877.jpg",
      "name": "SmarDex"
    },
    {
      "chainId": 1,
      "address": "0x3231cb76718cdef2155fc47b5286d82e6eda273f",
      "symbol": "EURE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692240700369_9163719445856027.jpg",
      "name": "Monerium EUR emoney"
    },
    {
      "chainId": 1,
      "address": "0x856c4efb76c1d1ae02e20ceb03a2a6a08b0b8dc3",
      "symbol": "OETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692172983105_3371657720459289.jpg",
      "name": "Origin Ether"
    },
    {
      "chainId": 1,
      "address": "0x1936c91190e901b7dd55229a574ae22b58ff498a",
      "symbol": "MEVFREE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690973396907_22881948238644223.png",
      "name": "MEVFree"
    },
    {
      "chainId": 1,
      "address": "0xf819d9cb1c2a819fd991781a822de3ca8607c3c9",
      "symbol": "UNIBOT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690956627154_9094476304137329.png",
      "name": "Unibot"
    },
    {
      "chainId": 1,
      "address": "0xb478c6245e3d85d6ec3486b62ea872128d562541",
      "symbol": "LOOTBOT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690956590427_6744240352128519.png",
      "name": "LootBot"
    },
    {
      "chainId": 1,
      "address": "0x68a47fe1cf42eba4a030a10cd4d6a1031ca3ca0a",
      "symbol": "TET",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690946961572_32555751148955236.JPG",
      "name": "Tectum"
    },
    {
      "chainId": 1,
      "address": "0x163f8c2467924be0ae7b5347228cabf260318753",
      "symbol": "WLD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690252228953_8965120070011079.png",
      "name": "Worldcoin"
    },
    {
      "chainId": 1,
      "address": "0x2fb652314C3d850E9049057bBE9813F1eEe882d3",
      "symbol": "RVF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1689584409068_6076284771081315.jpg",
      "name": "RocketX exchange V2"
    },
    {
      "chainId": 1,
      "address": "0x888cea2bbdd5d47a4032cf63668d7525c74af57a",
      "symbol": "POOF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1685675498436_7450915882918752.jpg",
      "name": "Poof Token"
    },
    {
      "chainId": 1,
      "address": "0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E",
      "symbol": "crvUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684743718551_16770399423912785.png",
      "name": "Curve.Fi USD Stablecoin"
    },
    {
      "chainId": 1,
      "address": "0x12970e6868f88f6557b76120662c1b3e50a646bf",
      "symbol": "LADYS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684407051058_03586886856291649.JPG",
      "name": "Milady Meme Coin"
    },
    {
      "chainId": 1,
      "address": "0xf951E335afb289353dc249e82926178EaC7DEd78",
      "symbol": "SwETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684382855608_536128279973269.jpeg",
      "name": "Swell Ethereum"
    },
    {
      "chainId": 1,
      "address": "0x9559aaa82d9649c7a7b220e7c461d2e74c9a3593",
      "symbol": "StafirETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1682061798342_20857244772870165.jpg",
      "name": "StaFi Staked ETH"
    },
    {
      "chainId": 1,
      "address": "0xf33893de6eb6ae9a67442e066ae9abd228f5290c",
      "symbol": "GRV",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681183301382_08674893855486832.png",
      "name": "GroveCoin"
    },
    {
      "chainId": 1,
      "address": "0x2dff88a56767223a5529ea5960da7a3f5f766406",
      "symbol": "ID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679537729513_12546800738085695.png",
      "name": "SPACE ID"
    },
    {
      "chainId": 1,
      "address": "0xc97d6c52f3add91fa1c5287a453d7444aecbca83",
      "symbol": "DZOO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678965563627_5134776862568691.png",
      "name": "Degen Zoo"
    },
    {
      "chainId": 1,
      "address": "0x562e362876c8aee4744fc2c6aac8394c312d215d",
      "symbol": "OPTI",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678356368243_0540787044357407.png",
      "name": "Optimus AI"
    },
    {
      "chainId": 1,
      "address": "0x777172d858dc1599914a1c4c6c9fc48c99a60990",
      "symbol": "SOLID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1677826604619_5472095355493405.jpg",
      "name": "Solidly"
    },
    {
      "chainId": 1,
      "address": "0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b",
      "symbol": "RLY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1677825118014_4897350742051445.png",
      "name": "Rally"
    },
    {
      "chainId": 1,
      "address": "0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d",
      "symbol": "LQTY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1677654216478_8952377716630286.jpg",
      "name": "Liquity"
    },
    {
      "chainId": 1,
      "address": "0xac57de9c1a09fec648e93eb98875b212db0d460b",
      "symbol": "BABYDOGE",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676967619691_054299759136339665.png",
      "name": "Baby Doge Coin"
    },
    {
      "chainId": 1,
      "address": "0x01ba67aac7f75f647d94220cc98fb30fcc5105bf",
      "symbol": "LYRA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676630448116_8059013491494524.png",
      "name": "Lyra Finance"
    },
    {
      "chainId": 1,
      "address": "0x3472a5a71965499acd81997a54bba8d852c6e53d",
      "symbol": "BADGER",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679562687073_4051000628560426.png",
      "name": "Badger DAO"
    },
    {
      "chainId": 1,
      "address": "0xc0c293ce456ff0ed870add98a0828dd4d2903dbf",
      "symbol": "AURA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679561821560_8836640095120474.png",
      "name": "Aura Finance"
    },
    {
      "chainId": 1,
      "address": "0x5283d291dbcf85356a21ba090e6db59121208b44",
      "symbol": "BLUR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676426576315_7371118542267461.png",
      "name": "Blur"
    },
    {
      "chainId": 1,
      "address": "0x5E8422345238F34275888049021821E8E08CAa1f",
      "symbol": "frxETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683515339242_2895200648646872.jpg",
      "name": "Frax Ether"
    },
    {
      "chainId": 1,
      "address": "0x9d65ff81a3c488d585bbfb0bfe3c7707c7917f54",
      "symbol": "SSV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676017038971_8372006873173186.png",
      "name": "SSV Network"
    },
    {
      "chainId": 1,
      "address": "0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e",
      "symbol": "BDP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1675855063474_31380294718429824.png",
      "name": "Big Data Protocol "
    },
    {
      "chainId": 1,
      "address": "0x5b7533812759b45c2b44c19e320ba2cd2681b542",
      "symbol": "AGIX",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1675853967513_9225927775828351.jpg",
      "name": "SingularityNET"
    },
    {
      "chainId": 1,
      "address": "0xa735a3af76cc30791c61c10d585833829d36cbe0",
      "symbol": "IMGNAI",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1675505839803_1393113605301073.png",
      "name": "Image Generation AI"
    },
    {
      "chainId": 1,
      "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
      "symbol": "BOB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1674201575560_9115292046203958.png",
      "name": "BOB"
    },
    {
      "chainId": 1,
      "address": "0xfd0205066521550d7d7ab19da8f72bb004b4c341",
      "symbol": "LIT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1673923536943_16241034345995753.jpg",
      "name": "Timeless"
    },
    {
      "chainId": 1,
      "address": "0x9813037ee2218799597d83d4a5b6f3b6778218d9",
      "symbol": "BONE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1672920486628_14205872752697735.png",
      "name": "Bone ShibaSwap"
    },
    {
      "chainId": 1,
      "address": "0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107",
      "symbol": "GOVI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1672110687955_2929664424205205.jpg",
      "name": "CVI"
    },
    {
      "chainId": 1,
      "address": "0x4691937a7508860F876c9c0a2a617E7d9E945D4B",
      "symbol": "WOO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671699433829_23225652273374475.png",
      "name": "WOO Network"
    },
    {
      "chainId": 1,
      "address": "0x2370f9d504c7a6E775bf6E14B3F12846b594cD53",
      "symbol": "JPYC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671692674440_9993372852991087.png",
      "name": "JPY Coin"
    },
    {
      "chainId": 1,
      "address": "0xae78736cd615f374d3085123a210448e74fc6393",
      "symbol": "RETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1670566661126_965654588128356.png",
      "name": "Rocket Pool ETH"
    },
    {
      "chainId": 1,
      "address": "0x20bc832ca081b91433ff6c17f85701b6e92486c5",
      "symbol": "rETH2",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1670566564112_9861805695681598.png",
      "name": "Token StakeWise Reward ETH2"
    },
    {
      "chainId": 1,
      "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png",
      "name": "USD Coin"
    },
    {
      "chainId": 1,
      "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1663566104020_8869918182521481.svg",
      "name": "Wrapped Ether"
    },
    {
      "chainId": 1,
      "address": "0x77777777772cf0455fb38ee0e75f38034dfa50de",
      "symbol": "XY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669284780127_42151395276298254.png",
      "name": "XY Finance"
    },
    {
      "chainId": 1,
      "address": "0xb3999f658c0391d94a37f7ff328f3fec942bcadc",
      "symbol": "HFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669117476646_7146054798680197.jpg",
      "name": "Hashflow"
    },
    {
      "chainId": 1,
      "address": "0x65ef703f5594d2573eb71aaf55bc0cb548492df4",
      "symbol": "MULTI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669114798325_6203856236857588.png",
      "name": "Multichain"
    },
    {
      "chainId": 1,
      "address": "0xc56c2b7e71b54d38aab6d52e94a04cbfa8f604fa",
      "symbol": "ZUSD",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669025740931_8474358298913196.png",
      "name": "Z.com USD"
    },
    {
      "chainId": 1,
      "address": "0xc08512927d12348f6620a698105e1baac6ecd911",
      "symbol": "GYEN",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669025544447_7855697193583338.png",
      "name": "GMO JPY"
    },
    {
      "chainId": 1,
      "address": "0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9",
      "symbol": "SIS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1668766305449_3401409710851502.jpg",
      "name": "Symbiosis Finance"
    },
    {
      "chainId": 1,
      "address": "0x4123a133ae3c521fd134d7b13a2dec35b56c2463",
      "symbol": "QRDO",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1668215197123_2832419181148085.png",
      "name": "Qredo"
    },
    {
      "chainId": 1,
      "address": "0x06450dee7fd2fb8e39061434babcfc05599a6fb8",
      "symbol": "XEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1667898365308_4432821220808989.png",
      "name": "XEN Crypto"
    },
    {
      "chainId": 1,
      "address": "0x721a1b990699ee9d90b6327faad0a3e840ae8335",
      "symbol": "LOOT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1667890228681_23556197035547388.png",
      "name": "Lootex"
    },
    {
      "chainId": 1,
      "address": "0x79C7EF95aD32DcD5ECadB231568Bb03dF7824815",
      "symbol": "ARV",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1665457004993_019767397414035992.png",
      "name": "Ariva"
    },
    {
      "chainId": 1,
      "address": "0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6",
      "symbol": "USDD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1651924593436_11920442199897563.png",
      "name": "USDD"
    },
    {
      "chainId": 1,
      "address": "0x26dcFbFa8Bc267b250432c01C982Eaf81cC5480C",
      "symbol": "ankrMATIC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1653739570902_2935634424552658.png",
      "name": "Ankr Staked MATIC"
    },
    {
      "chainId": 1,
      "address": "0xa693B19d2931d498c5B318dF961919BB4aee87a5",
      "symbol": "UST",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1652148860992_19465618074296387.png",
      "name": "Wormhole-UST"
    },
    {
      "chainId": 1,
      "address": "0xf0f9d895aca5c8678f706fb8216fa22957685a13",
      "symbol": "CULT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650015332892_6009483193098999.png",
      "name": "Cult DAO"
    },
    {
      "chainId": 1,
      "address": "0xe6602b34d8510b033e000975b3322537c7172441",
      "symbol": "FRR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642943926005_8067235013017533.jpg",
      "name": "Frontrow"
    },
    {
      "chainId": 1,
      "address": "0x9e46a38f5daabe8683e10793b06749eef7d733d1",
      "symbol": "NCT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642565812320_16986004049342518.png",
      "name": "PolySwarm"
    },
    {
      "chainId": 1,
      "address": "0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d",
      "symbol": "FUSE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641397151253_3495073377359781.png",
      "name": "Fuse"
    },
    {
      "chainId": 1,
      "address": "0x505b5eda5e25a67e1c24a2bf1a527ed9eb88bf04",
      "symbol": "CWEB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641272650134_8834514311784676.png",
      "name": "Coinweb"
    },
    {
      "chainId": 1,
      "address": "0xa47c8bf37f92abed4a126bda807a7b7498661acd",
      "symbol": "UST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641122792348_1354932801878106.png",
      "name": "Wrapped UST Token"
    },
    {
      "chainId": 1,
      "address": "0x9e32b13ce7f2e80a01932b42553652e053d6ed8e",
      "symbol": "METIS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640617344162_3807376965536575.png",
      "name": "Metis Token"
    },
    {
      "chainId": 1,
      "address": "0x3b484b82567a09e2588a13d54d032153f0c0aee0",
      "symbol": "SOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640529068099_7339586415132595.jpg",
      "name": "OpenDAO"
    },
    {
      "chainId": 1,
      "address": "0x91dfbee3965baaee32784c2d546b7a0c62f268c9",
      "symbol": "BONDLY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640080041318_5743057536684948.png",
      "name": "Bondly"
    },
    {
      "chainId": 1,
      "address": "0x1CC30e2EAc975416060Ec6FE682041408420d414",
      "symbol": "KOL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640052278270_36124752508396885.png",
      "name": "Kollect"
    },
    {
      "chainId": 1,
      "address": "0x71Ab77b7dbB4fa7e017BC15090b2163221420282",
      "symbol": "HIGH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639708897185_36657692058712255.png",
      "name": "Highstreet token"
    },
    {
      "chainId": 1,
      "address": "0x2e9d63788249371f1dfc918a52f8d799f4a38c94",
      "symbol": "TOKE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639553198715_3697399739904945.png",
      "name": "Tokemak"
    },
    {
      "chainId": 1,
      "address": "0xf17e65822b568b3903685a7c9f496cf7656cc6c2",
      "symbol": "BICO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639019133083_027415277814302863.png",
      "name": "Biconomy Token"
    },
    {
      "chainId": 1,
      "address": "0xf99d58e463a2e07e5692127302c20a191861b4d6",
      "symbol": "ANY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638628066261_5600434234924558.jpg",
      "name": "Anyswap"
    },
    {
      "chainId": 1,
      "address": "0x949d48eca67b17269629c7194f4b727d4ef9e5d6",
      "symbol": "MC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638420252654_06197923298757746.png",
      "name": "Merit Circle"
    },
    {
      "chainId": 1,
      "address": "0x7a58c0be72be218b41c608b7fe7c5bb630736c71",
      "symbol": "PEOPLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637894997297_39905377685873034.jpg",
      "name": "ConstitutionDAO "
    },
    {
      "chainId": 1,
      "address": "0xff20817765cb7f73d4bde2e66e067e58d11095c2",
      "symbol": "AMP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637718729359_052946347718468845.png",
      "name": "Amp"
    },
    {
      "chainId": 1,
      "address": "0x72b886d09c117654ab7da13a14d603001de0b777",
      "symbol": "XDEFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637670592815_6585719424946173.jpg",
      "name": "XDEFI"
    },
    {
      "chainId": 1,
      "address": "0xc581b735a1688071a1746c968e0798d642ede491",
      "symbol": "EURT",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1661149583532_5601010157808881.png",
      "name": "Euro Tether"
    },
    {
      "chainId": 1,
      "address": "0xfe2e637202056d30016725477c5da089ab0a043a",
      "symbol": "SETH2",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637670293810_9919823959725913.png",
      "name": "StakeWise Staked ETH2"
    },
    {
      "chainId": 1,
      "address": "0xcafe001067cdef266afb7eb5a286dcfd277f3de5",
      "symbol": "PSP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637048758828_9802368811178295.png",
      "name": "Paraswap"
    },
    {
      "chainId": 1,
      "address": "0xc5019e129b75d380d3d837b8e609dec6c8f5d044",
      "symbol": "GN",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636698211080_16966893102938707.png",
      "name": "GN"
    },
    {
      "chainId": 1,
      "address": "0xbc7250c8c3eca1dfc1728620af835fca489bfdf3",
      "symbol": "GM",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636698229196_08244912681377103.png",
      "name": "GM"
    },
    {
      "chainId": 1,
      "address": "0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72",
      "symbol": "ENS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636425568161_026790053758710908.png",
      "name": "Ethereum Name Service"
    },
    {
      "chainId": 1,
      "address": "0x2da719db753dfa10a62e140f436e1d67f2ddb0d6",
      "symbol": "CERE",
      "decimals": 10,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636424977825_5595788980190983.png",
      "name": "CERE Network "
    },
    {
      "chainId": 1,
      "address": "0x081131434f93063751813c619ecca9c4dc7862a3",
      "symbol": "DAR",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636026917864_4450657201941066.jpg",
      "name": "Dalarnia"
    },
    {
      "chainId": 1,
      "address": "0x967da4048cd07ab37855c090aaf366e4ce1b9f48",
      "symbol": "OCEAN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635845473882_17599258515189642.png",
      "name": "Ocean Protocol"
    },
    {
      "chainId": 1,
      "address": "0x8f041a3940a5e6fb580075c3774e15fcfa0e1618",
      "symbol": "ONEWING",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635752067919_7233413982445538.png",
      "name": "oneWING"
    },
    {
      "chainId": 1,
      "address": "0xb6ed7644c69416d67b522e20bc294a9a9b405b31",
      "symbol": "0xBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb6ed7644c69416d67b522e20bc294a9a9b405b31.png",
      "name": "0xBitcoin Token"
    },
    {
      "chainId": 1,
      "address": "0x07597255910a51509ca469568b048f2597e72504",
      "symbol": "1UP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x07597255910a51509ca469568b048f2597e72504.png",
      "name": "Uptrennd"
    },
    {
      "chainId": 1,
      "address": "0xe48972fcd82a274411c01834e2f031d4377fa2c0",
      "symbol": "2KEY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xe48972fcd82a274411c01834e2f031d4377fa2c0.png",
      "name": "TwoKeyEconomy"
    },
    {
      "chainId": 1,
      "address": "0xfeea0bdd3d07eb6fe305938878c0cadbfa169042",
      "symbol": "8PAY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634696203575_3024182791535941.png",
      "name": "8pay"
    },
    {
      "chainId": 1,
      "address": "0x9a794dc1939f1d78fa48613b89b8f9d0a20da00e",
      "symbol": "ABX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9a794dc1939f1d78fa48613b89b8f9d0a20da00e.png",
      "name": "Arbidex"
    },
    {
      "chainId": 1,
      "address": "0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6",
      "symbol": "ABYSS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6.png",
      "name": "Abyss"
    },
    {
      "chainId": 1,
      "address": "0x06147110022b768ba8f99a8f385df11a151a9cc8",
      "symbol": "ACE",
      "decimals": 0,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x06147110022b768ba8f99a8f385df11a151a9cc8.png",
      "name": "ACE"
    },
    {
      "chainId": 1,
      "address": "0xade00c28244d5ce17d72e40330b1c318cd12b7c3",
      "symbol": "ADX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xade00c28244d5ce17d72e40330b1c318cd12b7c3.png",
      "name": "AdEx Network"
    },
    {
      "chainId": 1,
      "address": "0xa704fce7b309ec09df16e2f5ab8caf6fe8a4baa9",
      "symbol": "AGRI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa704fce7b309ec09df16e2f5ab8caf6fe8a4baa9.png",
      "name": "AgriChain"
    },
    {
      "chainId": 1,
      "address": "0x37e8789bb9996cac9156cd5f5fd32599e6b91289",
      "symbol": "AID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x37e8789bb9996cac9156cd5f5fd32599e6b91289.png",
      "name": "AidCoin"
    },
    {
      "chainId": 1,
      "address": "0xc0134b5b924c2fca106efb33c45446c466fbe03e",
      "symbol": "ALEPH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634696583689_8146654283626338.png",
      "name": "aleph.im"
    },
    {
      "chainId": 1,
      "address": "0x737f98ac8ca59f2c68ad658e3c3d8c8963e40a4c",
      "symbol": "AMN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x737f98ac8ca59f2c68ad658e3c3d8c8963e40a4c.png",
      "name": "Amon"
    },
    {
      "chainId": 1,
      "address": "0xcd62b1c403fa761baadfc74c525ce2b51780b184",
      "symbol": "ANJ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xcd62b1c403fa761baadfc74c525ce2b51780b184.png",
      "name": "Aragon Network Juror"
    },
    {
      "chainId": 1,
      "address": "0x3c45b24359fb0e107a4eaa56bd0f2ce66c99a0e5",
      "symbol": "ANK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3c45b24359fb0e107a4eaa56bd0f2ce66c99a0e5.png",
      "name": "Apple Network"
    },
    {
      "chainId": 1,
      "address": "0xeeee2a622330e6d2036691e983dee87330588603",
      "symbol": "ASKO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xeeee2a622330e6d2036691e983dee87330588603.png",
      "name": "Askobar Network"
    },
    {
      "chainId": 1,
      "address": "0x27054b13b1b798b345b591a4d22e6562d47ea75a",
      "symbol": "AST",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x27054b13b1b798b345b591a4d22e6562d47ea75a.png",
      "name": "AirSwap"
    },
    {
      "chainId": 1,
      "address": "0x821144518dfe9e7b44fcf4d0824e15e8390d4637",
      "symbol": "ATIS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x821144518dfe9e7b44fcf4d0824e15e8390d4637.png",
      "name": "ATIS Token"
    },
    {
      "chainId": 1,
      "address": "0xc12d099be31567add4e4e4d0d45691c3f58f5663",
      "symbol": "AUC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc12d099be31567add4e4e4d0d45691c3f58f5663.png",
      "name": "Auctus"
    },
    {
      "chainId": 1,
      "address": "0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a",
      "symbol": "BAC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a.png",
      "name": "BAC"
    },
    {
      "chainId": 1,
      "address": "0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5",
      "symbol": "BCDT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5.png",
      "name": "Blockchain Certified Data Token"
    },
    {
      "chainId": 1,
      "address": "0x5732046a883704404f284ce41ffadd5b007fd668",
      "symbol": "BLZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5732046a883704404f284ce41ffadd5b007fd668.png",
      "name": "Bluzelle"
    },
    {
      "chainId": 1,
      "address": "0x5Dc02Ea99285E17656b8350722694c35154DB1E8",
      "symbol": "BOND",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5dc02ea99285e17656b8350722694c35154db1e8.png",
      "name": "BOND"
    },
    {
      "chainId": 1,
      "address": "0x780116d91e5592e58a3b3c76a351571b39abcec6",
      "symbol": "BOXX",
      "decimals": 15,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x780116d91e5592e58a3b3c76a351571b39abcec6.png",
      "name": "Blockparty"
    },
    {
      "chainId": 1,
      "address": "0xb683d83a532e2cb7dfa5275eed3698436371cc9f",
      "symbol": "BTU",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb683d83a532e2cb7dfa5275eed3698436371cc9f.png",
      "name": "BTU Protocol"
    },
    {
      "chainId": 1,
      "address": "0x56d811088235f11c8920698a204a5010a788f4b3",
      "symbol": "BZRX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x56d811088235f11c8920698a204a5010a788f4b3.png",
      "name": "bZx Protocol Token"
    },
    {
      "chainId": 1,
      "address": "0x1d462414fe14cf489c7a21cac78509f4bf8cd7c0",
      "symbol": "CAN",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1d462414fe14cf489c7a21cac78509f4bf8cd7c0.png",
      "name": "CanYa"
    },
    {
      "chainId": 1,
      "address": "0x1234567461d3f8db7496581774bd869c83d51c93",
      "symbol": "CAT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1234567461d3f8db7496581774bd869c83d51c93.png",
      "name": "BitClave"
    },
    {
      "chainId": 1,
      "address": "0xcf8f9555d55ce45a3a33a81d6ef99a2a2e71dee2",
      "symbol": "CBIX7",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xcf8f9555d55ce45a3a33a81d6ef99a2a2e71dee2.png",
      "name": "CBI Index 7"
    },
    {
      "chainId": 1,
      "address": "0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3",
      "symbol": "CC10",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3.png",
      "name": "Cryptocurrency Top 10 Tokens Index"
    },
    {
      "chainId": 1,
      "address": "0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d",
      "symbol": "CEL",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.png",
      "name": "Celsius"
    },
    {
      "chainId": 1,
      "address": "0x06af07097c9eeb7fd685c692751d5c66db49c215",
      "symbol": "CHAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x06af07097c9eeb7fd685c692751d5c66db49c215.png",
      "name": "Chai Token"
    },
    {
      "chainId": 1,
      "address": "0xd4c435f5b09f855c3317c8524cb1f586e42795fa",
      "symbol": "CND",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd4c435f5b09f855c3317c8524cb1f586e42795fa.png",
      "name": "Cindicator"
    },
    {
      "chainId": 1,
      "address": "0xc4c7Ea4FAB34BD9fb9a5e1B1a98Df76E26E6407c",
      "symbol": "COCOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c.png",
      "name": "CocosTokenV2"
    },
    {
      "chainId": 1,
      "address": "0xc76fb75950536d98fa62ea968e1d6b45ffea2a55",
      "symbol": "COL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc76fb75950536d98fa62ea968e1d6b45ffea2a55.png",
      "name": "COL"
    },
    {
      "chainId": 1,
      "address": "0xffffffff2ba8f66d4e51811c5190992176930278",
      "symbol": "COMBO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xffffffff2ba8f66d4e51811c5190992176930278.png",
      "name": "Furucombo"
    },
    {
      "chainId": 1,
      "address": "0x5c872500c00565505f3624ab435c222e558e9ff8",
      "symbol": "COT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5c872500c00565505f3624ab435c222e558e9ff8.png",
      "name": "CoTrader"
    },
    {
      "chainId": 1,
      "address": "0x13339fd07934cd674269726edf3b5ccee9dd93de",
      "symbol": "CUR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x13339fd07934cd674269726edf3b5ccee9dd93de.png",
      "name": "CurToken"
    },
    {
      "chainId": 1,
      "address": "0x41e5560054824ea6b0732e656e3ad64e20e94e45",
      "symbol": "CVC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x41e5560054824ea6b0732e656e3ad64e20e94e45.png",
      "name": "Civic"
    },
    {
      "chainId": 1,
      "address": "0xbe428c3867f05dea2a89fc76a102b544eac7f772",
      "symbol": "CVT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbe428c3867f05dea2a89fc76a102b544eac7f772.png",
      "name": "CVT"
    },
    {
      "chainId": 1,
      "address": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
      "symbol": "CVX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640228886775_4384365746491985.png",
      "name": "Convex"
    },
    {
      "chainId": 1,
      "address": "0xac0104cca91d167873b8601d2e71eb3d4d8c33e0",
      "symbol": "CWS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xac0104cca91d167873b8601d2e71eb3d4d8c33e0.png",
      "name": "CWS"
    },
    {
      "chainId": 1,
      "address": "0x0cf0ee63788a0849fe5297f3407f701e122cc023",
      "symbol": "DATA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0cf0ee63788a0849fe5297f3407f701e122cc023.png",
      "name": "Streamr"
    },
    {
      "chainId": 1,
      "address": "0xFbEEa1C75E4c4465CB2FCCc9c6d6afe984558E20",
      "symbol": "DDIM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20.png",
      "name": "DuckDaoDime"
    },
    {
      "chainId": 1,
      "address": "0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3",
      "symbol": "DEC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3.png",
      "name": "Decentr"
    },
    {
      "chainId": 1,
      "address": "0xfa6de2697d59e88ed7fc4dfe5a33dac43565ea41",
      "symbol": "DEFI5",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634696737401_6295364568629955.png",
      "name": "DEFI Top 5 Tokens Index"
    },
    {
      "chainId": 1,
      "address": "0x9fa69536d1cda4a04cfb50688294de75b505a9ae",
      "symbol": "DERC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9fa69536d1cda4a04cfb50688294de75b505a9ae.png",
      "name": "DeRace"
    },
    {
      "chainId": 1,
      "address": "0x5caf454ba92e6f2c929df14667ee360ed9fd5b26",
      "symbol": "DEV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5caf454ba92e6f2c929df14667ee360ed9fd5b26.png",
      "name": "Dev"
    },
    {
      "chainId": 1,
      "address": "0xfb7b4564402e5500db5bb6d63ae671302777c75a",
      "symbol": "DEXT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634697433873_015183141108803921.png",
      "name": "DexTools"
    },
    {
      "chainId": 1,
      "address": "0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf",
      "symbol": "DGX",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf.png",
      "name": "Digix Gold Token"
    },
    {
      "chainId": 1,
      "address": "0xed91879919b71bb6905f23af0a68d231ecf87b14",
      "symbol": "DMG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xed91879919b71bb6905f23af0a68d231ecf87b14.png",
      "name": "DMM: Governance"
    },
    {
      "chainId": 1,
      "address": "0x5f6c5c2fb289db2228d159c69621215e354218d7",
      "symbol": "DMOD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634697540739_3272200377083141.png",
      "name": "DMOD"
    },
    {
      "chainId": 1,
      "address": "0xf29992d7b589a0a6bd2de7be29a97a6eb73eaf85",
      "symbol": "DMST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf29992d7b589a0a6bd2de7be29a97a6eb73eaf85.png",
      "name": "DMScript"
    },
    {
      "chainId": 1,
      "address": "0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9",
      "symbol": "DONUT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9.png",
      "name": "DONUT"
    },
    {
      "chainId": 1,
      "address": "0x0a913bead80f321e7ac35285ee10d9d922659cb7",
      "symbol": "DOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0a913bead80f321e7ac35285ee10d9d922659cb7.png",
      "name": "DOS Network Token"
    },
    {
      "chainId": 1,
      "address": "0xac3211a5025414af2866ff09c23fc18bc97e79b1",
      "symbol": "DOV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xac3211a5025414af2866ff09c23fc18bc97e79b1.png",
      "name": "DOV"
    },
    {
      "chainId": 1,
      "address": "0x661ab0ed68000491d98c796146bcf28c20d7c559",
      "symbol": "DOWS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x661ab0ed68000491d98c796146bcf28c20d7c559.png",
      "name": "DOWS"
    },
    {
      "chainId": 1,
      "address": "0x9af4f26941677c706cfecf6d3379ff01bb85d5ab",
      "symbol": "DRT",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9af4f26941677c706cfecf6d3379ff01bb85d5ab.png",
      "name": "DomRaider"
    },
    {
      "chainId": 1,
      "address": "0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe",
      "symbol": "DSLA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe.png",
      "name": "DSLA"
    },
    {
      "chainId": 1,
      "address": "0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190",
      "symbol": "DTH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190.png",
      "name": "Dether"
    },
    {
      "chainId": 1,
      "address": "0xc20464e0c373486d2b3335576e83a218b1618a5e",
      "symbol": "DTRC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc20464e0c373486d2b3335576e83a218b1618a5e.png",
      "name": "Datarius Credit"
    },
    {
      "chainId": 1,
      "address": "0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f",
      "symbol": "DUCK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f.png",
      "name": "DLP Duck Token"
    },
    {
      "chainId": 1,
      "address": "0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c",
      "symbol": "DZAR",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c.png",
      "name": "Digital Rand"
    },
    {
      "chainId": 1,
      "address": "0x9b62ec1453cea5dde760aaf662048ca6eeb66e7f",
      "symbol": "ECELL",
      "decimals": 2,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634696998913_8222706681114342.png",
      "name": "ECELL"
    },
    {
      "chainId": 1,
      "address": "0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43",
      "symbol": "ELET",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43.png",
      "name": "Elementeum Games"
    },
    {
      "chainId": 1,
      "address": "0xbf2179859fc6d5bee9bf9158632dc51678a4100e",
      "symbol": "ELF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbf2179859fc6d5bee9bf9158632dc51678a4100e.png",
      "name": "Aelf"
    },
    {
      "chainId": 1,
      "address": "0xa462d0e6bb788c7807b1b1c96992ce1f7069e195",
      "symbol": "EQMT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa462d0e6bb788c7807b1b1c96992ce1f7069e195.png",
      "name": "EQUUSMiningToken"
    },
    {
      "chainId": 1,
      "address": "0xc28e931814725bbeb9e670676fabbcb694fe7df2",
      "symbol": "EQUAD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc28e931814725bbeb9e670676fabbcb694fe7df2.png",
      "name": "Quadrant Protocol"
    },
    {
      "chainId": 1,
      "address": "0xd6a55c63865affd67e2fb9f284f87b7a9e5ff3bd",
      "symbol": "ESH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd6a55c63865affd67e2fb9f284f87b7a9e5ff3bd.png",
      "name": "Switch"
    },
    {
      "chainId": 1,
      "address": "0xeeeeeeeee2af8d0e1940679860398308e0ef24d6",
      "symbol": "ETHV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xeeeeeeeee2af8d0e1940679860398308e0ef24d6.png",
      "name": "Ethverse Token"
    },
    {
      "chainId": 1,
      "address": "0xdb25f211ab05b1c97d595516f45794528a807ad8",
      "symbol": "EURS",
      "decimals": 2,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xdb25f211ab05b1c97d595516f45794528a807ad8.png",
      "name": "STASIS EURO"
    },
    {
      "chainId": 1,
      "address": "0x178c820f862b14f316509ec36b13123da19a6054",
      "symbol": "EWTB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x178c820f862b14f316509ec36b13123da19a6054.png",
      "name": "Energy Web Token Bridged"
    },
    {
      "chainId": 1,
      "address": "0xf04a8ac553fcedb5ba99a64799155826c136b0be",
      "symbol": "FLIXX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf04a8ac553fcedb5ba99a64799155826c136b0be.png",
      "name": "Flixxo"
    },
    {
      "chainId": 1,
      "address": "0x7645ddfeeceda57e41f92679c4acd83c56a81d14",
      "symbol": "FLUX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634699487858_6670155562083306.png",
      "name": "FLUX"
    },
    {
      "chainId": 1,
      "address": "0xb5fe099475d3030dde498c3bb6f3854f762a48ad",
      "symbol": "FNK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb5fe099475d3030dde498c3bb6f3854f762a48ad.png",
      "name": "Finiko"
    },
    {
      "chainId": 1,
      "address": "0xdc5864ede28bd4405aa04d93e05a0531797d9d59",
      "symbol": "FNT",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xdc5864ede28bd4405aa04d93e05a0531797d9d59.png",
      "name": "Falcon"
    },
    {
      "chainId": 1,
      "address": "0xef9cd7882c067686691b6ff49e650b43afbbcc6b",
      "symbol": "FNX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xef9cd7882c067686691b6ff49e650b43afbbcc6b.png",
      "name": "FinNexus"
    },
    {
      "chainId": 1,
      "address": "0x4946fcea7c692606e8908002e55a582af44ac121",
      "symbol": "FOAM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4946fcea7c692606e8908002e55a582af44ac121.png",
      "name": "FOAM Token"
    },
    {
      "chainId": 1,
      "address": "0x29ceddcf0da3c1d8068a7dfbd0fb06c2e438ff70",
      "symbol": "FREL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634699889541_6218818265268933.png",
      "name": "FREL"
    },
    {
      "chainId": 1,
      "address": "0xd559f20296ff4895da39b5bd9add54b442596a61",
      "symbol": "FTX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd559f20296ff4895da39b5bd9add54b442596a61.png",
      "name": "FintruX"
    },
    {
      "chainId": 1,
      "address": "0x419d0d8bdd9af5e606ae2232ed285aff190e711b",
      "symbol": "FUN",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x419d0d8bdd9af5e606ae2232ed285aff190e711b.png",
      "name": "FunFair"
    },
    {
      "chainId": 1,
      "address": "0x4a57e687b9126435a9b19e4a802113e266adebde",
      "symbol": "FXC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4a57e687b9126435a9b19e4a802113e266adebde.png",
      "name": "Flexacoin"
    },
    {
      "chainId": 1,
      "address": "0xd1d2eb1b1e90b638588728b4130137d262c87cae",
      "symbol": "GALA",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637165436073_018213522982339914.png",
      "name": "Gala"
    },
    {
      "chainId": 1,
      "address": "0x301c755ba0fca00b1923768fffb3df7f4e63af31",
      "symbol": "GDC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x301c755ba0fca00b1923768fffb3df7f4e63af31.png",
      "name": "Global Digital Content"
    },
    {
      "chainId": 1,
      "address": "0x6b9f031d718dded0d681c20cb754f97b3bb81b78",
      "symbol": "GEEQ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6b9f031d718dded0d681c20cb754f97b3bb81b78.png",
      "name": "Geeq"
    },
    {
      "chainId": 1,
      "address": "0x543ff227f64aa17ea132bf9886cab5db55dcaddf",
      "symbol": "GEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x543ff227f64aa17ea132bf9886cab5db55dcaddf.png",
      "name": "DAOStack"
    },
    {
      "chainId": 1,
      "address": "0xfb1e5f5e984c28ad7e228cdaa1f8a0919bb6a09b",
      "symbol": "GES",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xfb1e5f5e984c28ad7e228cdaa1f8a0919bb6a09b.png",
      "name": "Galaxy eSolutions"
    },
    {
      "chainId": 1,
      "address": "0x4c327471c44b2dacd6e90525f9d629bd2e4f662c",
      "symbol": "GHOST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4c327471c44b2dacd6e90525f9d629bd2e4f662c.png",
      "name": "GHOST"
    },
    {
      "chainId": 1,
      "address": "0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd",
      "symbol": "GRID",
      "decimals": 12,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd.png",
      "name": "GRID"
    },
    {
      "chainId": 1,
      "address": "0x0000000000b3F879cb30FE243b4Dfee438691c04",
      "symbol": "GST2",
      "decimals": 2,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0000000000b3f879cb30fe243b4dfee438691c04.png",
      "name": "Gastoken.io 2"
    },
    {
      "chainId": 1,
      "address": "0xc5bbae50781be1669306b9e001eff57a2957b09d",
      "symbol": "GTO",
      "decimals": 5,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc5bbae50781be1669306b9e001eff57a2957b09d.png",
      "name": "Gifto"
    },
    {
      "chainId": 1,
      "address": "0x056fd409e1d7a124bd7017459dfea2f387b6d5cd",
      "symbol": "GUSD",
      "decimals": 2,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x056fd409e1d7a124bd7017459dfea2f387b6d5cd.png",
      "name": "Gemini dollar"
    },
    {
      "chainId": 1,
      "address": "0xf1290473e210b2108a85237fbcd7b6eb42cc654f",
      "symbol": "HEDG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf1290473e210b2108a85237fbcd7b6eb42cc654f.png",
      "name": "Hedge Trade"
    },
    {
      "chainId": 1,
      "address": "0xEEF9f339514298C6A857EfCfC1A762aF84438dEE",
      "symbol": "HEZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xeef9f339514298c6a857efcfc1a762af84438dee.png",
      "name": "Hermez Network Token"
    },
    {
      "chainId": 1,
      "address": "0x6f259637dcd74c767781e37bc6133cd6a68aa161",
      "symbol": "HT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6f259637dcd74c767781e37bc6133cd6a68aa161.png",
      "name": "HuobiToken"
    },
    {
      "chainId": 1,
      "address": "0x5274891bec421b39d23760c04a6755ecb444797c",
      "symbol": "IDLEUSDC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xeb66acc3d011056b00ea521f8203580c2e5d3991.png",
      "name": "IdleUSDC"
    },
    {
      "chainId": 1,
      "address": "0xf8e386eda857484f5a12e4b5daa9984e06e73705",
      "symbol": "IND",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf8e386eda857484f5a12e4b5daa9984e06e73705.png",
      "name": "Indorse"
    },
    {
      "chainId": 1,
      "address": "0x3b4f7cb9e60362a49dd04eb0091a374d340e3efd",
      "symbol": "ITAM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3b4f7cb9e60362a49dd04eb0091a374d340e3efd.png",
      "name": "ITAM"
    },
    {
      "chainId": 1,
      "address": "0xbd6467a31899590474ce1e84f70594c53d628e46",
      "symbol": "KAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634698755339_7648597495546063.png",
      "name": "KardiaChain Token"
    },
    {
      "chainId": 1,
      "address": "0xdfe691f37b6264a90ff507eb359c45d55037951c",
      "symbol": "KARMA",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xdfe691f37b6264a90ff507eb359c45d55037951c.png",
      "name": "Karma"
    },
    {
      "chainId": 1,
      "address": "0x4abb9cc67bd3da9eb966d1159a71a0e68bd15432",
      "symbol": "KEL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634699078192_22645254775198143.png",
      "name": "KEL"
    },
    {
      "chainId": 1,
      "address": "0x4cc19356f2d37338b9802aa8e8fc58b0373296e7",
      "symbol": "KEY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4cc19356f2d37338b9802aa8e8fc58b0373296e7.png",
      "name": "SelfKey"
    },
    {
      "chainId": 1,
      "address": "0x6226caa1857afbc6dfb6ca66071eb241228031a1",
      "symbol": "LAR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6226caa1857afbc6dfb6ca66071eb241228031a1.png",
      "name": "Linkart"
    },
    {
      "chainId": 1,
      "address": "0x5102791ca02fc3595398400bfe0e33d7b6c82267",
      "symbol": "LDC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5102791ca02fc3595398400bfe0e33d7b6c82267.png",
      "name": "Lead Coin"
    },
    {
      "chainId": 1,
      "address": "0x1dd80016e3d4ae146ee2ebb484e8edd92dacc4ce",
      "symbol": "LEAD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1dd80016e3d4ae146ee2ebb484e8edd92dacc4ce.png",
      "name": "Lead Token"
    },
    {
      "chainId": 1,
      "address": "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3",
      "symbol": "LEO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3.png",
      "name": "Bitfinex LEO Token"
    },
    {
      "chainId": 1,
      "address": "0xbc194e6f748a222754c3e8b9946922c09e7d4e91",
      "symbol": "LEV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634699312660_6606014097399895.png",
      "name": "LEV"
    },
    {
      "chainId": 1,
      "address": "0x49184e6dae8c8ecd89d8bdc1b950c597b8167c90",
      "symbol": "LIBERTAS",
      "decimals": 2,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x49184e6dae8c8ecd89d8bdc1b950c597b8167c90.png",
      "name": "LIBERTAS"
    },
    {
      "chainId": 1,
      "address": "0x0417912b3a7af768051765040a55bb0925d4ddcf",
      "symbol": "LID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0417912b3a7af768051765040a55bb0925d4ddcf.png",
      "name": "Liquidity Dividends Protocol"
    },
    {
      "chainId": 1,
      "address": "0x66fd97a78d8854fec445cd1c80a07896b0b4851f",
      "symbol": "LMY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x66fd97a78d8854fec445cd1c80a07896b0b4851f.png",
      "name": "Lunch Money"
    },
    {
      "chainId": 1,
      "address": "0x5e3346444010135322268a4630d2ed5f8d09446c",
      "symbol": "LOC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5e3346444010135322268a4630d2ed5f8d09446c.png",
      "name": "LockTrip"
    },
    {
      "chainId": 1,
      "address": "0x9c23d67aea7b95d80942e3836bcdf7e708a747c2",
      "symbol": "LOCI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9c23d67aea7b95d80942e3836bcdf7e708a747c2.png",
      "name": "LOCIcoin"
    },
    {
      "chainId": 1,
      "address": "0x95172ccbe8344fecd73d0a30f54123652981bd6f",
      "symbol": "LOCK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x95172ccbe8344fecd73d0a30f54123652981bd6f.png",
      "name": "Meridian Network"
    },
    {
      "chainId": 1,
      "address": "0x0000000000095413afc295d19edeb1ad7b71c952",
      "symbol": "LON",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0000000000095413afc295d19edeb1ad7b71c952.png",
      "name": "LON Token"
    },
    {
      "chainId": 1,
      "address": "0x42476f744292107e34519f9c357927074ea3f75d",
      "symbol": "LOOM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0.png",
      "name": "Loom Network (NEW)"
    },
    {
      "chainId": 1,
      "address": "0x58b6a8a3302369daec383334672404ee733ab239",
      "symbol": "LPT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x58b6a8a3302369daec383334672404ee733ab239.png",
      "name": "Livepeer Token"
    },
    {
      "chainId": 1,
      "address": "0x4de2573e27E648607B50e1Cfff921A33E4A34405",
      "symbol": "LST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4de2573e27e648607b50e1cfff921a33e4a34405.png",
      "name": "Lendroid Support Token"
    },
    {
      "chainId": 1,
      "address": "0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9",
      "symbol": "LUNA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9.png",
      "name": "Wrapped LUNA Token"
    },
    {
      "chainId": 1,
      "address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
      "symbol": "MANA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png",
      "name": "Mana"
    },
    {
      "chainId": 1,
      "address": "0xb63b606ac810a52cca15e44bb630fd42d8d1d83d",
      "symbol": "MCO",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb63b606ac810a52cca15e44bb630fd42d8d1d83d.png",
      "name": "Monaco"
    },
    {
      "chainId": 1,
      "address": "0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8",
      "symbol": "MCX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8.png",
      "name": "MachiX Token"
    },
    {
      "chainId": 1,
      "address": "0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26",
      "symbol": "MDT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26.png",
      "name": "Measurable Data Token"
    },
    {
      "chainId": 1,
      "address": "0x6710c63432a2de02954fc0f851db07146a6c0312",
      "symbol": "MFG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6710c63432a2de02954fc0f851db07146a6c0312.png",
      "name": "SyncFab"
    },
    {
      "chainId": 1,
      "address": "0xdf2c7238198ad8b389666574f2d8bc411a4b7428",
      "symbol": "MFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xdf2c7238198ad8b389666574f2d8bc411a4b7428.png",
      "name": "Mainframe"
    },
    {
      "chainId": 1,
      "address": "0x4d953cf077c0c95ba090226e59a18fcf97db44ec",
      "symbol": "MINI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4d953cf077c0c95ba090226e59a18fcf97db44ec.png",
      "name": "MINISWAP"
    },
    {
      "chainId": 1,
      "address": "0x83cee9e086a77e492ee0bb93c2b0437ad6fdeccc",
      "symbol": "MNTP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x83cee9e086a77e492ee0bb93c2b0437ad6fdeccc.png",
      "name": "Goldmint"
    },
    {
      "chainId": 1,
      "address": "0x7bd82b320ebc28d8eb3c4f5fa2af7b14da5b90c3",
      "symbol": "MOZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634700095378_888300101439486.png",
      "name": "MOZ"
    },
    {
      "chainId": 1,
      "address": "0x7b0c06043468469967dba22d1af33d77d44056c8",
      "symbol": "MRPH",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x7b0c06043468469967dba22d1af33d77d44056c8.png",
      "name": "Morpheus Network"
    },
    {
      "chainId": 1,
      "address": "0xf433089366899d83a9f26a773d59ec7ecf30355e",
      "symbol": "MTL",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf433089366899d83a9f26a773d59ec7ecf30355e.png",
      "name": "Metal"
    },
    {
      "chainId": 1,
      "address": "0x6251e725cd45fb1af99354035a414a2c0890b929",
      "symbol": "MXT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6251e725cd45fb1af99354035a414a2c0890b929.png",
      "name": "MixTrust"
    },
    {
      "chainId": 1,
      "address": "0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc",
      "symbol": "MYB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc.png",
      "name": "MyBit"
    },
    {
      "chainId": 1,
      "address": "0x87d73e916d7057945c9bcd8cdd94e42a6f47f776",
      "symbol": "NFTX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x87d73e916d7057945c9bcd8cdd94e42a6f47f776.png",
      "name": "NFTX"
    },
    {
      "chainId": 1,
      "address": "0xc813ea5e3b48bebeedb796ab42a30c5599b01740",
      "symbol": "NIOX",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc813ea5e3b48bebeedb796ab42a30c5599b01740.png",
      "name": "Autonio"
    },
    {
      "chainId": 1,
      "address": "0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3",
      "symbol": "NPXS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3.png",
      "name": "Pundi X"
    },
    {
      "chainId": 1,
      "address": "0xee9801669c6138e84bd50deb500827b776777d28",
      "symbol": "O3",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634700229635_8791879627403105.png",
      "name": "O3"
    },
    {
      "chainId": 1,
      "address": "0x383518188c0c6d7730d91b2c03a03c837814a899",
      "symbol": "OHM",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635742164029_6701142479112281.png",
      "name": "Olympus"
    },
    {
      "chainId": 1,
      "address": "0xd341d1680eeee3255b8c4c75bcce7eb57f144dae",
      "symbol": "ONG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd341d1680eeee3255b8c4c75bcce7eb57f144dae.png",
      "name": "onG.social"
    },
    {
      "chainId": 1,
      "address": "0x888888888889c00c67689029d7856aac1065ec11",
      "symbol": "OPIUM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634700296696_3825857579276808.png",
      "name": "Opium Governance Token"
    },
    {
      "chainId": 1,
      "address": "0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca",
      "symbol": "OST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca.png",
      "name": "Open Simple Token"
    },
    {
      "chainId": 1,
      "address": "0x1a5f9352af8af974bfc03399e3767df6370d82e4",
      "symbol": "OWL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1a5f9352af8af974bfc03399e3767df6370d82e4.png",
      "name": "OWL Token"
    },
    {
      "chainId": 1,
      "address": "0xf0fac7104aac544e4a7ce1a55adf2b5a25c65bd1",
      "symbol": "PAMP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf0fac7104aac544e4a7ce1a55adf2b5a25c65bd1.png",
      "name": "Pamp Network"
    },
    {
      "chainId": 1,
      "address": "0xd56dac73a4d6766464b38ec6d91eb45ce7457c44",
      "symbol": "PAN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd56dac73a4d6766464b38ec6d91eb45ce7457c44.png",
      "name": "Panvala pan"
    },
    {
      "chainId": 1,
      "address": "0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06",
      "symbol": "PAR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06.png",
      "name": "Parachute"
    },
    {
      "chainId": 1,
      "address": "0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc",
      "symbol": "PARETO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc.png",
      "name": "Pareto Network Token"
    },
    {
      "chainId": 1,
      "address": "0x8e870d67f660d95d5be530380d0ec0bd388289e1",
      "symbol": "PAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x8e870d67f660d95d5be530380d0ec0bd388289e1.png",
      "name": "Paxos Standard"
    },
    {
      "chainId": 1,
      "address": "0x45804880de22913dafe09f4980848ece6ecbaf78",
      "symbol": "PAXG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x45804880de22913dafe09f4980848ece6ecbaf78.png",
      "name": "Paxos Gold"
    },
    {
      "chainId": 1,
      "address": "0xb97048628db6b661d4c2aa833e95dbe1a905b280",
      "symbol": "PAY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb97048628db6b661d4c2aa833e95dbe1a905b280.png",
      "name": "TenX"
    },
    {
      "chainId": 1,
      "address": "0xeca82185adce47f39c684352b0439f030f860318",
      "symbol": "PERL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671615231445_5817604459616665.png",
      "name": "PERL.eco"
    },
    {
      "chainId": 1,
      "address": "0x3c6ff50c9ec362efa359317009428d52115fe643",
      "symbol": "PERX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3c6ff50c9ec362efa359317009428d52115fe643.png",
      "name": "PeerEx Network"
    },
    {
      "chainId": 1,
      "address": "0x607C794cDa77efB21F8848B7910ecf27451Ae842",
      "symbol": "PIE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x607c794cda77efb21f8848b7910ecf27451ae842.png",
      "name": "DeFiPIE Token"
    },
    {
      "chainId": 1,
      "address": "0xe3818504c1b32bf1557b16c238b2e01fd3149c17",
      "symbol": "PLR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xe3818504c1b32bf1557b16c238b2e01fd3149c17.png",
      "name": "Pillar"
    },
    {
      "chainId": 1,
      "address": "0xd8912c10681d8b21fd3742244f44658dba12264e",
      "symbol": "PLU",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd8912c10681d8b21fd3742244f44658dba12264e.png",
      "name": "Pluton"
    },
    {
      "chainId": 1,
      "address": "0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d",
      "symbol": "PNK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d.png",
      "name": "Kleros"
    },
    {
      "chainId": 1,
      "address": "0x69a95185ee2a045cdc4bcd1b1df10710395e4e23",
      "symbol": "POOLZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x69a95185ee2a045cdc4bcd1b1df10710395e4e23.png",
      "name": "$Poolz Finance"
    },
    {
      "chainId": 1,
      "address": "0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509",
      "symbol": "POWER",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509.png",
      "name": "UniPower"
    },
    {
      "chainId": 1,
      "address": "0x595832f8fc6bf59c85c527fec3740a1b7a361269",
      "symbol": "POWR",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x595832f8fc6bf59c85c527fec3740a1b7a361269.png",
      "name": "Power Ledger"
    },
    {
      "chainId": 1,
      "address": "0x9b85babc0cc89899ccd47e9226a0b1fae577b19e",
      "symbol": "PPB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9b85babc0cc89899ccd47e9226a0b1fae577b19e.png",
      "name": "PPBToken"
    },
    {
      "chainId": 1,
      "address": "0x9041fe5b3fdea0f5e4afdc17e75180738d877a01",
      "symbol": "PRO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9041fe5b3fdea0f5e4afdc17e75180738d877a01.png",
      "name": "ProToken"
    },
    {
      "chainId": 1,
      "address": "0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41",
      "symbol": "PROPS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41.png",
      "name": "Props Token"
    },
    {
      "chainId": 1,
      "address": "0x3c81d482172cc273c3b91dd9d8eb212023d00521",
      "symbol": "PRY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634701133521_805081181511839.png",
      "name": "PRY"
    },
    {
      "chainId": 1,
      "address": "0x3166c570935a7d8554c8f4ea792ff965d2efe1f2",
      "symbol": "QDAO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3166c570935a7d8554c8f4ea792ff965d2efe1f2.png",
      "name": "QDAO"
    },
    {
      "chainId": 1,
      "address": "0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664",
      "symbol": "QKC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664.png",
      "name": "QuarkChain"
    },
    {
      "chainId": 1,
      "address": "0x4a220e6096b25eadb88358cb44068a3248254675",
      "symbol": "QNT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1665314266114_932548675656155.jpg",
      "name": "Quant"
    },
    {
      "chainId": 1,
      "address": "0xe5a3229ccb22b6484594973a03a3851dcd948756",
      "symbol": "RAE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xe5a3229ccb22b6484594973a03a3851dcd948756.png",
      "name": "Receive Access Ecosystem"
    },
    {
      "chainId": 1,
      "address": "0x03ab458634910aad20ef5f1c8ee96f1d6ac54919",
      "symbol": "RAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x03ab458634910aad20ef5f1c8ee96f1d6ac54919.png",
      "name": "Rai Reflex Index"
    },
    {
      "chainId": 1,
      "address": "0xfca59cd816ab1ead66534d82bc21e7515ce441cf",
      "symbol": "RARI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.png",
      "name": "Rarible"
    },
    {
      "chainId": 1,
      "address": "0xfc2c4d8f95002c14ed0a7aa65102cac9e5953b5e",
      "symbol": "RBLX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xfc2c4d8f95002c14ed0a7aa65102cac9e5953b5e.png",
      "name": "Rublix"
    },
    {
      "chainId": 1,
      "address": "0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6",
      "symbol": "RCN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6.png",
      "name": "Ripio"
    },
    {
      "chainId": 1,
      "address": "0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6",
      "symbol": "RDN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6.png",
      "name": "Raiden Network Token"
    },
    {
      "chainId": 1,
      "address": "0x9214ec02cb71cba0ada6896b8da260736a67ab10",
      "symbol": "REAL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9214ec02cb71cba0ada6896b8da260736a67ab10.png",
      "name": "REAL"
    },
    {
      "chainId": 1,
      "address": "0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec",
      "symbol": "REL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec.png",
      "name": "Relevant"
    },
    {
      "chainId": 1,
      "address": "0x83984d6142934bb535793a82adb0a46ef0f66b6d",
      "symbol": "REM",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x83984d6142934bb535793a82adb0a46ef0f66b6d.png",
      "name": "Remme"
    },
    {
      "chainId": 1,
      "address": "0x221657776846890989a759ba2973e427dff5c9bb",
      "symbol": "REP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641310937259_32976041658685973.png",
      "name": "Augur"
    },
    {
      "chainId": 1,
      "address": "0x155040625d7ae3e9cada9a73e3e44f76d3ed1409",
      "symbol": "REVO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x155040625d7ae3e9cada9a73e3e44f76d3ed1409.png",
      "name": "REVO"
    },
    {
      "chainId": 1,
      "address": "0xd291e7a03283640fdc51b121ac401383a46cc623",
      "symbol": "RGT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd291e7a03283640fdc51b121ac401383a46cc623.png",
      "name": "Rari Governance"
    },
    {
      "chainId": 1,
      "address": "0x607f4c5bb672230e8672085532f7e901544a7375",
      "symbol": "RLC",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x607f4c5bb672230e8672085532f7e901544a7375.png",
      "name": "iExec RLC"
    },
    {
      "chainId": 1,
      "address": "0xe17f017475a709de58e976081eb916081ff4c9d5",
      "symbol": "RMPL",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xe17f017475a709de58e976081eb916081ff4c9d5.png",
      "name": "RMPL"
    },
    {
      "chainId": 1,
      "address": "0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2",
      "symbol": "ROCKS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634700497929_4474593154855173.png",
      "name": "ROCKS"
    },
    {
      "chainId": 1,
      "address": "0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a",
      "symbol": "ROOK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xfa5047c9c78b8877af97bdcb85db743fd7313d4a.png",
      "name": "ROOK"
    },
    {
      "chainId": 1,
      "address": "0x8762db106b2c2a0bccb3a80d1ed41273552616e8",
      "symbol": "RSR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x8762db106b2c2a0bccb3a80d1ed41273552616e8.png",
      "name": "Reserve Rights"
    },
    {
      "chainId": 1,
      "address": "0x3d1ba9be9f66b8ee101911bc36d3fb562eac2244",
      "symbol": "RVT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3d1ba9be9f66b8ee101911bc36d3fb562eac2244.png",
      "name": "Rivetz"
    },
    {
      "chainId": 1,
      "address": "0x4156d3342d5c385a87d264f90653733592000581",
      "symbol": "SALT",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4156d3342d5c385a87d264f90653733592000581.png",
      "name": "Salt"
    },
    {
      "chainId": 1,
      "address": "0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098",
      "symbol": "SAN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098.png",
      "name": "Santiment"
    },
    {
      "chainId": 1,
      "address": "0x25377ddb16c79c93b0cbf46809c8de8765f03fcd",
      "symbol": "SBREE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x25377ddb16c79c93b0cbf46809c8de8765f03fcd.png",
      "name": "Synthetic CBDAO"
    },
    {
      "chainId": 1,
      "address": "0xd7631787b4dcc87b1254cfd1e5ce48e96823dee8",
      "symbol": "SCL",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd7631787b4dcc87b1254cfd1e5ce48e96823dee8.png",
      "name": "Sociall"
    },
    {
      "chainId": 1,
      "address": "0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f",
      "symbol": "SDT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634701319918_6545024478329331.png",
      "name": "Stake DAO Token"
    },
    {
      "chainId": 1,
      "address": "0xe25b0bba01dc5630312b6a21927e578061a13f55",
      "symbol": "SHIP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xe25b0bba01dc5630312b6a21927e578061a13f55.png",
      "name": "ShipChain SHIP"
    },
    {
      "chainId": 1,
      "address": "0x3a9fff453d50d4ac52a6890647b823379ba36b9e",
      "symbol": "SHUF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3a9fff453d50d4ac52a6890647b823379ba36b9e.png",
      "name": "Shuffle.Monster V3"
    },
    {
      "chainId": 1,
      "address": "0x744d70fdbe2ba4cf95131626614a1763df805b9e",
      "symbol": "SNT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png",
      "name": "Status"
    },
    {
      "chainId": 1,
      "address": "0x42d6622dece394b54999fbd73d108123806f6a18",
      "symbol": "SPANK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x42d6622dece394b54999fbd73d108123806f6a18.png",
      "name": "SPANK"
    },
    {
      "chainId": 1,
      "address": "0x1dea979ae76f26071870f824088da78979eb91c8",
      "symbol": "SPD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1dea979ae76f26071870f824088da78979eb91c8.png",
      "name": "Spindle"
    },
    {
      "chainId": 1,
      "address": "0xa7fc5d2453e3f68af0cc1b78bcfee94a1b293650",
      "symbol": "SPIKE",
      "decimals": 10,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa7fc5d2453e3f68af0cc1b78bcfee94a1b293650.png",
      "name": "Spiking"
    },
    {
      "chainId": 1,
      "address": "0x20f7a3ddf244dc9299975b4da1c39f8d5d75f05a",
      "symbol": "SPN",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x20f7a3ddf244dc9299975b4da1c39f8d5d75f05a.png",
      "name": "Sapien Network"
    },
    {
      "chainId": 1,
      "address": "0x476c5e26a75bd202a9683ffd34359c0cc15be0ff",
      "symbol": "SRM",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff.png",
      "name": "Serum"
    },
    {
      "chainId": 1,
      "address": "0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25",
      "symbol": "SRN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25.png",
      "name": "Sirin Labs"
    },
    {
      "chainId": 1,
      "address": "0xa7de087329bfcda5639247f96140f9dabe3deed1",
      "symbol": "STA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa7de087329bfcda5639247f96140f9dabe3deed1.png",
      "name": "Statera"
    },
    {
      "chainId": 1,
      "address": "0x56a86d648c435dc707c8405b78e2ae8eb4e60ba4",
      "symbol": "STACK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634701564517_5219754932003637.png",
      "name": "STACK"
    },
    {
      "chainId": 1,
      "address": "0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24",
      "symbol": "STARL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24.png",
      "name": "StarLink"
    },
    {
      "chainId": 1,
      "address": "0xbe9375c6a420d2eeb258962efb95551a5b722803",
      "symbol": "STMX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbe9375c6a420d2eeb258962efb95551a5b722803.png",
      "name": "Kyber StormX Token"
    },
    {
      "chainId": 1,
      "address": "0xe63d6b308bce0f6193aec6b7e6eba005f41e36ab",
      "symbol": "STN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634704130746_1494402537345132.png",
      "name": "STN"
    },
    {
      "chainId": 1,
      "address": "0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac",
      "symbol": "STORJ",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac.png",
      "name": "Storj"
    },
    {
      "chainId": 1,
      "address": "0x990f341946a3fdb507ae7e52d17851b87168017c",
      "symbol": "STRONG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x990f341946a3fdb507ae7e52d17851b87168017c.png",
      "name": "Strong"
    },
    {
      "chainId": 1,
      "address": "0x006bea43baa3f7a6f765f14f10a1a1b08334ef45",
      "symbol": "STX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x006bea43baa3f7a6f765f14f10a1a1b08334ef45.png",
      "name": "Stox"
    },
    {
      "chainId": 1,
      "address": "0xbdeb4b83251fb146687fa19d1c660f99411eefe3",
      "symbol": "SVD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbdeb4b83251fb146687fa19d1c660f99411eefe3.png",
      "name": "Savedroid"
    },
    {
      "chainId": 1,
      "address": "0xcc4304a31d09258b0029ea7fe63d032f52e44efe",
      "symbol": "SWAP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png",
      "name": "TrustSwap Token"
    },
    {
      "chainId": 1,
      "address": "0x92eF4FFBfe0Df030837b65d7FcCFE1ABd6549579",
      "symbol": "SWG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x92ef4ffbfe0df030837b65d7fccfe1abd6549579.png",
      "name": "Swirge"
    },
    {
      "chainId": 1,
      "address": "0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d",
      "symbol": "TAP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d.png",
      "name": "TAP"
    },
    {
      "chainId": 1,
      "address": "0xc27a2f05fa577a83ba0fdb4c38443c0718356501",
      "symbol": "TAU",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc27a2f05fa577a83ba0fdb4c38443c0718356501.png",
      "name": "Lamden Tau"
    },
    {
      "chainId": 1,
      "address": "0x3a92bd396aef82af98ebc0aa9030d25a23b11c6b",
      "symbol": "TBX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3a92bd396aef82af98ebc0aa9030d25a23b11c6b.png",
      "name": "Tokenbox"
    },
    {
      "chainId": 1,
      "address": "0x1c79ab32c66acaa1e9e81952b8aaa581b43e54e7",
      "symbol": "TEAM",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1c79ab32c66acaa1e9e81952b8aaa581b43e54e7.png",
      "name": "TEAM"
    },
    {
      "chainId": 1,
      "address": "0x1453dbb8a29551ade11d89825ca812e05317eaeb",
      "symbol": "TEND",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1453dbb8a29551ade11d89825ca812e05317eaeb.png",
      "name": "Tendies Token"
    },
    {
      "chainId": 1,
      "address": "0x888888848b652b3e3a0f34c96e00eec0f3a23f72",
      "symbol": "TLM",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x888888848b652b3e3a0f34c96e00eec0f3a23f72.png",
      "name": "Alien Worlds Trilium"
    },
    {
      "chainId": 1,
      "address": "0xb0280743b44bf7db4b6be482b2ba7b75e5da096c",
      "symbol": "TNS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb0280743b44bf7db4b6be482b2ba7b75e5da096c.png",
      "name": "Transcodium"
    },
    {
      "chainId": 1,
      "address": "0x6f87d756daf0503d08eb8993686c7fc01dc44fb1",
      "symbol": "TRADE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6f87d756daf0503d08eb8993686c7fc01dc44fb1.png",
      "name": "UniTrade"
    },
    {
      "chainId": 1,
      "address": "0xc7283b66eb1eb5fb86327f08e1b5816b0720212b",
      "symbol": "Tribe",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b.png",
      "name": "TRIBE"
    },
    {
      "chainId": 1,
      "address": "0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb",
      "symbol": "TRND",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb.png",
      "name": "Trendering"
    },
    {
      "chainId": 1,
      "address": "0x57700244b20f84799a31c6c96dadff373ca9d6c5",
      "symbol": "TRUST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x57700244b20f84799a31c6c96dadff373ca9d6c5.png",
      "name": "TRUST DAO"
    },
    {
      "chainId": 1,
      "address": "0x2c537e5624e4af88a7ae4060c022609376c8d0eb",
      "symbol": "TRYB",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x2c537e5624e4af88a7ae4060c022609376c8d0eb.png",
      "name": "BiLira"
    },
    {
      "chainId": 1,
      "address": "0x734c90044a0ba31b3f2e640c10dc5d3540499bfd",
      "symbol": "TSX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634703166846_13459735724776078.png",
      "name": "TSX"
    },
    {
      "chainId": 1,
      "address": "0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988",
      "symbol": "TVK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988.png",
      "name": "Terra Virtua Kolect"
    },
    {
      "chainId": 1,
      "address": "0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e",
      "symbol": "UBT",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e.png",
      "name": "UniBright"
    },
    {
      "chainId": 1,
      "address": "0xf29e46887ffae92f1ff87dfe39713875da541373",
      "symbol": "UNC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf29e46887ffae92f1ff87dfe39713875da541373.png",
      "name": "UniCrypt"
    },
    {
      "chainId": 1,
      "address": "0x441761326490cacf7af299725b6292597ee822c2",
      "symbol": "UNFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634702180726_07549521334011788.png",
      "name": "Unifi Protocol DAO"
    },
    {
      "chainId": 1,
      "address": "0x6ba460ab75cd2c56343b3517ffeba60748654d26",
      "symbol": "UP",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6ba460ab75cd2c56343b3517ffeba60748654d26.png",
      "name": "UpToken"
    },
    {
      "chainId": 1,
      "address": "0xc86d054809623432210c107af2e3f619dcfbf652",
      "symbol": "UPP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc86d054809623432210c107af2e3f619dcfbf652.png",
      "name": "Sentinel Protocol"
    },
    {
      "chainId": 1,
      "address": "0x309627af60f0926daa6041b8279484312f2bf060",
      "symbol": "USDB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x309627af60f0926daa6041b8279484312f2bf060.png",
      "name": "USDB"
    },
    {
      "chainId": 1,
      "address": "0x4954db6391f4feb5468b6b943d4935353596aec9",
      "symbol": "USDQ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4954db6391f4feb5468b6b943d4935353596aec9.png",
      "name": "USDQ"
    },
    {
      "chainId": 1,
      "address": "0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe",
      "symbol": "USDS",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe.png",
      "name": "StableUSD"
    },
    {
      "chainId": 1,
      "address": "0xeb269732ab75a6fd61ea60b06fe994cd32a83549",
      "symbol": "USDx",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xeb269732ab75a6fd61ea60b06fe994cd32a83549.png",
      "name": "dForce"
    },
    {
      "chainId": 1,
      "address": "0x340d2bde5eb28c1eed91b2f790723e3b160613b7",
      "symbol": "VEE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x340d2bde5eb28c1eed91b2f790723e3b160613b7.png",
      "name": "BLOCKv"
    },
    {
      "chainId": 1,
      "address": "0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724",
      "symbol": "VIB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724.png",
      "name": "Viberate"
    },
    {
      "chainId": 1,
      "address": "0x6b785a0322126826d8226d77e173d75dafb84d11",
      "symbol": "VLT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6b785a0322126826d8226d77e173d75dafb84d11.png",
      "name": "Bankroll Vault"
    },
    {
      "chainId": 1,
      "address": "0x456ae45c0ce901e2e7c99c0718031cec0a7a59ff",
      "symbol": "VSN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x456ae45c0ce901e2e7c99c0718031cec0a7a59ff.png",
      "name": "Vision Network"
    },
    {
      "chainId": 1,
      "address": "0x27f610bf36eca0939093343ac28b1534a721dbb4",
      "symbol": "WAND",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x27f610bf36eca0939093343ac28b1534a721dbb4.png",
      "name": "WandX"
    },
    {
      "chainId": 1,
      "address": "0x9355372396e3f6daf13359b7b607a3374cc638e0",
      "symbol": "WHALE",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9355372396e3f6daf13359b7b607a3374cc638e0.png",
      "name": "WHALE"
    },
    {
      "chainId": 1,
      "address": "0x667088b212ce3d06a1b553a7221e1fd19000d9af",
      "symbol": "WINGS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x667088b212ce3d06a1b553a7221e1fd19000d9af.png",
      "name": "Wings"
    },
    {
      "chainId": 1,
      "address": "0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6",
      "symbol": "WISE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png",
      "name": "Wise Token"
    },
    {
      "chainId": 1,
      "address": "0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c",
      "symbol": "X8X",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c.png",
      "name": "x8currency"
    },
    {
      "chainId": 1,
      "address": "0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27",
      "symbol": "XAMP",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27.png",
      "name": "Antiample"
    },
    {
      "chainId": 1,
      "address": "0x28dee01d53fed0edf5f6e310bf8ef9311513ae40",
      "symbol": "XBP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x28dee01d53fed0edf5f6e310bf8ef9311513ae40.png",
      "name": "BlitzPredict"
    },
    {
      "chainId": 1,
      "address": "0xb4272071ecadd69d933adcd19ca99fe80664fc08",
      "symbol": "XCHF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xb4272071ecadd69d933adcd19ca99fe80664fc08.png",
      "name": "CryptoFranc"
    },
    {
      "chainId": 1,
      "address": "0xabe580e7ee158da464b51ee1a83ac0289622e6be",
      "symbol": "XFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xabe580e7ee158da464b51ee1a83ac0289622e6be.png",
      "name": "Offshift"
    },
    {
      "chainId": 1,
      "address": "0x0f7f961648ae6db43c75663ac7e5414eb79b5704",
      "symbol": "XIO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0f7f961648ae6db43c75663ac7e5414eb79b5704.png",
      "name": "XIO Network"
    },
    {
      "chainId": 1,
      "address": "0xbc86727e770de68b1060c91f6bb6945c73e10388",
      "symbol": "XNK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbc86727e770de68b1060c91f6bb6945c73e10388.png",
      "name": "Ink Protocol"
    },
    {
      "chainId": 1,
      "address": "0x40fd72257597aa14c7231a7b1aaa29fce868f677",
      "symbol": "XOR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x40fd72257597aa14c7231a7b1aaa29fce868f677.png",
      "name": "Sora Token"
    },
    {
      "chainId": 1,
      "address": "0xd7efb00d12c2c13131fd319336fdf952525da2af",
      "symbol": "XPR",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xd7efb00d12c2c13131fd319336fdf952525da2af.png",
      "name": "Proton"
    },
    {
      "chainId": 1,
      "address": "0x7de91b204c1c737bcee6f000aaa6569cf7061cb7",
      "symbol": "XRT",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x7de91b204c1c737bcee6f000aaa6569cf7061cb7.png",
      "name": "Robonomics"
    },
    {
      "chainId": 1,
      "address": "0x25f8087ead173b73d6e8b84329989a8eea16cf73",
      "symbol": "YGG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x25f8087ead173b73d6e8b84329989a8eea16cf73.png",
      "name": "Yield Guild Games"
    },
    {
      "chainId": 1,
      "address": "0x4aac461c86abfa71e9d00d9a2cde8d74e4e1aeea",
      "symbol": "ZINC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4aac461c86abfa71e9d00d9a2cde8d74e4e1aeea.png",
      "name": "ZINC"
    },
    {
      "chainId": 1,
      "address": "0xedd7c94fd7b4971b916d15067bc454b9e1bad980",
      "symbol": "ZIPT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xedd7c94fd7b4971b916d15067bc454b9e1bad980.png",
      "name": "Zippie"
    },
    {
      "chainId": 1,
      "address": "0xc75f15ada581219c95485c578e124df3985e4ce0",
      "symbol": "ZZZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xc75f15ada581219c95485c578e124df3985e4ce0.png",
      "name": "zzz.finance"
    },
    {
      "chainId": 1,
      "address": "0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00",
      "symbol": "aBAT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00.png",
      "name": "Aave Interest bearing BAT"
    },
    {
      "chainId": 1,
      "address": "0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04",
      "symbol": "aETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636358431888_8450432638405363.png",
      "name": "Aave Interest bearing ETH"
    },
    {
      "chainId": 1,
      "address": "0x9D91BE44C06d373a8a226E1f3b146956083803eB",
      "symbol": "aKNC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9d91be44c06d373a8a226e1f3b146956083803eb.png",
      "name": "Aave Interest bearing KNC"
    },
    {
      "chainId": 1,
      "address": "0xA64BD6C70Cb9051F6A9ba1F163Fdc07E0DfB5F84",
      "symbol": "aLINK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84.png",
      "name": "Aave Interest bearing LINK"
    },
    {
      "chainId": 1,
      "address": "0x7deB5e830be29F91E298ba5FF1356BB7f8146998",
      "symbol": "aMKR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x7deb5e830be29f91e298ba5ff1356bb7f8146998.png",
      "name": "Aave Interest bearing MKR"
    },
    {
      "chainId": 1,
      "address": "0x71010A9D003445aC60C4e6A7017c1E89A477B438",
      "symbol": "aREP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x71010a9d003445ac60c4e6a7017c1e89a477b438.png",
      "name": "Aave Interest bearing REP"
    },
    {
      "chainId": 1,
      "address": "0x328C4c80BC7aCa0834Db37e6600A6c49E12Da4DE",
      "symbol": "aSNX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x328c4c80bc7aca0834db37e6600a6c49e12da4de.png",
      "name": "Aave Interest bearing SNX"
    },
    {
      "chainId": 1,
      "address": "0x625ae63000f46200499120b906716420bd059240",
      "symbol": "aSUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x625ae63000f46200499120b906716420bd059240.png",
      "name": "Aave Interest bearing SUSD"
    },
    {
      "chainId": 1,
      "address": "0x4DA9b813057D04BAef4e5800E36083717b4a0341",
      "symbol": "aTUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x4da9b813057d04baef4e5800e36083717b4a0341.png",
      "name": "Aave Interest bearing TUSD"
    },
    {
      "chainId": 1,
      "address": "0x9bA00D6856a4eDF4665BcA2C2309936572473B7E",
      "symbol": "aUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x9ba00d6856a4edf4665bca2c2309936572473b7e.png",
      "name": "Aave Interest bearing USDC"
    },
    {
      "chainId": 1,
      "address": "0xFC4B8ED459e00e5400be803A9BB3954234FD50e3",
      "symbol": "aWBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xfc4b8ed459e00e5400be803a9bb3954234fd50e3.png",
      "name": "Aave Interest bearing WBTC"
    },
    {
      "chainId": 1,
      "address": "0x6Fb0855c404E09c47C3fBCA25f08d4E41f9F062f",
      "symbol": "aZRX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f.png",
      "name": "Aave Interest bearing ZRX"
    },
    {
      "chainId": 1,
      "address": "0x6a4ffaafa8dd400676df8076ad6c724867b0e2e8",
      "symbol": "bDAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6a4ffaafa8dd400676df8076ad6c724867b0e2e8.png",
      "name": "BTU Incentivized DAI"
    },
    {
      "chainId": 1,
      "address": "0x7b123f53421b1bf8533339bfbdc7c98aa94163db",
      "symbol": "buidl",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x7b123f53421b1bf8533339bfbdc7c98aa94163db.png",
      "name": "dfohub"
    },
    {
      "chainId": 1,
      "address": "0xbd2949f67dcdc549c6ebe98696449fa79d988a9f",
      "symbol": "eMTRG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbd2949f67dcdc549c6ebe98696449fa79d988a9f.png",
      "name": "Meter Governance mapped by Meter.io"
    },
    {
      "chainId": 1,
      "address": "0x6468e79A80C0eaB0F9A2B574c8d5bC374Af59414",
      "symbol": "eXRD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x6468e79a80c0eab0f9a2b574c8d5bc374af59414.png",
      "name": "E-RADIX"
    },
    {
      "chainId": 1,
      "address": "0x77f973FCaF871459aa58cd81881Ce453759281bC",
      "symbol": "fiETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x77f973fcaf871459aa58cd81881ce453759281bc.png",
      "name": "bZx ETH iToken"
    },
    {
      "chainId": 1,
      "address": "0xBd56E9477Fc6997609Cf45F84795eFbDAC642Ff1",
      "symbol": "fiREP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xbd56e9477fc6997609cf45f84795efbdac642ff1.png",
      "name": "bZx REP iToken"
    },
    {
      "chainId": 1,
      "address": "0xF013406A0B1d544238083DF0B93ad0d2cBE0f65f",
      "symbol": "fiUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf013406a0b1d544238083df0b93ad0d2cbe0f65f.png",
      "name": "bZx USDC iToken"
    },
    {
      "chainId": 1,
      "address": "0xBA9262578EFef8b3aFf7F60Cd629d6CC8859C8b5",
      "symbol": "fiWBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xba9262578efef8b3aff7f60cd629d6cc8859c8b5.png",
      "name": "bZx WBTC iToken"
    },
    {
      "chainId": 1,
      "address": "0xA7Eb2bc82df18013ecC2A6C533fc29446442EDEe",
      "symbol": "fiZRX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xa7eb2bc82df18013ecc2a6c533fc29446442edee.png",
      "name": "bZx ZRX iToken"
    },
    {
      "chainId": 1,
      "address": "0x14d10003807ac60d07bb0ba82caeac8d2087c157",
      "symbol": "iDEFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x14d10003807ac60d07bb0ba82caeac8d2087c157.png",
      "name": "Synth iDEFI"
    },
    {
      "chainId": 1,
      "address": "0x9288a57d6c5644b25569e9d1d5e9e328228d26c6",
      "symbol": "mBTM",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634705490428_8148326090310605.png",
      "name": "mBTM"
    },
    {
      "chainId": 1,
      "address": "0x06301057d77d54b6e14c7faffb11ffc7cab4eaa7",
      "symbol": "mDAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x06301057d77d54b6e14c7faffb11ffc7cab4eaa7.png",
      "name": "DMM: DAI"
    },
    {
      "chainId": 1,
      "address": "0xdf9307dff0a1b57660f60f9457d32027a55ca0b2",
      "symbol": "mETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xdf9307dff0a1b57660f60f9457d32027a55ca0b2.png",
      "name": "DMM: ETH"
    },
    {
      "chainId": 1,
      "address": "0x8fb00fdebb4e83f2c58b3bcd6732ac1b6a7b7221",
      "symbol": "oORN",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634704367569_15150291709452413.png",
      "name": "Old Orion Protocol"
    },
    {
      "chainId": 1,
      "address": "0x62199B909FB8B8cf870f97BEf2cE6783493c4908",
      "symbol": "pBTC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x5228a22e72ccc52d415ecfd199f99d0665e7733b.png",
      "name": "pTokens BTC"
    },
    {
      "chainId": 1,
      "address": "0xA8b12Cc90AbF65191532a12bb5394A714A46d358",
      "symbol": "pBTC35A",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634701971396_22769014168231805.png",
      "name": "POW BTC-35W/T"
    },
    {
      "chainId": 1,
      "address": "0xcb46c550539ac3db72dc7af7c89b11c306c727c2",
      "symbol": "pONT",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xcb46c550539ac3db72dc7af7c89b11c306c727c2.png",
      "name": "Poly Ontology Toke"
    },
    {
      "chainId": 1,
      "address": "0xDb0f18081b505A7DE20B18ac41856BCB4Ba86A1a",
      "symbol": "pWING",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634701785987_5608612087913547.png",
      "name": "Poly Ontology Wing Token"
    },
    {
      "chainId": 1,
      "address": "0x459086f2376525bdceba5bdda135e4e9d3fef5bf",
      "symbol": "renBCH",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x459086f2376525bdceba5bdda135e4e9d3fef5bf.png",
      "name": "renBCH"
    },
    {
      "chainId": 1,
      "address": "0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2",
      "symbol": "renZEC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2.png",
      "name": "renZEC"
    },
    {
      "chainId": 1,
      "address": "0xe1afe1fd76fd88f78cbf599ea1846231b8ba3b6b",
      "symbol": "sDEFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634701446804_697181923882993.png",
      "name": "Synth sDEFI"
    },
    {
      "chainId": 1,
      "address": "0x12f649a9e821f90bb143089a6e56846945892ffb",
      "symbol": "uDOO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x12f649a9e821f90bb143089a6e56846945892ffb.png",
      "name": "uDOO"
    },
    {
      "chainId": 1,
      "address": "0xf83ae621a52737e3ef9307af91df834ed8431ac3",
      "symbol": "uvwFi",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0xf83ae621a52737e3ef9307af91df834ed8431ac3.png",
      "name": "uvwFi.finance"
    },
    {
      "chainId": 1,
      "address": "0x3383c5a8969Dc413bfdDc9656Eb80A1408E4bA20",
      "symbol": "wANATHA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x3383c5a8969dc413bfddc9656eb80a1408e4ba20.png",
      "name": "Wrapped ANATHA"
    },
    {
      "chainId": 1,
      "address": "0x0d438f3b5175bebc262bf23753c1e53d03432bde",
      "symbol": "wNXM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x0d438f3b5175bebc262bf23753c1e53d03432bde.png",
      "name": "Wrapped NXM"
    },
    {
      "chainId": 1,
      "address": "0x892a6f9df0147e5f079b0993f486f9aca3c87881",
      "symbol": "xFUND",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/0x892a6f9df0147e5f079b0993f486f9aca3c87881.png",
      "name": "unification.com/xfund"
    },
    {
      "chainId": 1,
      "address": "0x090185f2135308bad17527004364ebcc2d37e5f6",
      "symbol": "SPELL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635076139888_9072395506731836.png",
      "name": "Spell Token"
    },
    {
      "chainId": 1,
      "address": "0x6123b0049f904d730db3c36a31167d9d4121fa6b",
      "symbol": "RBN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635076467753_0303745636106596.png",
      "name": " Ribbon"
    },
    {
      "chainId": 1,
      "address": "0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3",
      "symbol": "Fodl",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635084300280_47639698700428235.png",
      "name": "FODL Finance"
    },
    {
      "chainId": 1,
      "address": "0x9534ad65fb398E27Ac8F4251dAe1780B989D136e",
      "symbol": "PYR(Old)",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1674817235072_9135667833080536.png",
      "name": "Vulcan Forged(Old)"
    },
    {
      "chainId": 1,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    }
  ],
  "10": [
    {
      "chainId": 10,
      "name": "0xBitcoin",
      "symbol": "0xBTC",
      "address": "0xe0BB0D3DE8c10976511e5030cA403dBf4c25165B",
      "decimals": 8,
      "logoURI": "https://ethereum-optimism.github.io/data/0xBTC/logo.png"
    },
    {
      "chainId": 10,
      "name": "Aave Token",
      "symbol": "AAVE",
      "address": "0x76FB31fb4af56892A25e32cFC43De717950c9278",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/AAVE/logo.svg"
    },
    {
      "chainId": 10,
      "name": "AladdinCRV",
      "symbol": "aCRV",
      "address": "0x764ad60e1b81f6cacfec1a2926393d688d4493e6",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/aCRV/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Across Protocol Token",
      "symbol": "ACX",
      "address": "0xff733b2a3557a7ed6697007ab5d11b79fdd1b76b",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/ACX/logo.png"
    },
    {
      "chainId": 10,
      "name": "Aelin Protocol",
      "symbol": "AELIN",
      "address": "0x61BAADcF22d2565B0F471b291C475db5555e0b76",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16991.png"
    },
    {
      "chainId": 10,
      "name": "Alchemix ETH",
      "symbol": "alETH",
      "address": "0x3e29d3a9316dab217754d13b28646b76607c5f04",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/alETH/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Alongside Crypto Market Index",
      "symbol": "AMKT",
      "address": "0xC27D9BC194a648fE3069955a5126699C4e49351C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22999.png"
    },
    {
      "chainId": 10,
      "name": "Balancer",
      "symbol": "BAL",
      "address": "0xFE8B128bA8C78aabC59d4c64cEE7fF28e9379921",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5728.png"
    },
    {
      "chainId": 10,
      "name": "Bankless Token",
      "symbol": "BANK",
      "address": "0x29FAF5905bfF9Cfcc7CF56a5ed91E0f091F8664B",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/BANK/logo.png"
    },
    {
      "chainId": 10,
      "name": "BAXagent Coin",
      "symbol": "BAXA",
      "address": "0xDb9888b842408B0b8eFa1f5477bD9F351754999E",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/BAXA/logo.svg"
    },
    {
      "chainId": 10,
      "name": "BitANT",
      "symbol": "BitANT",
      "address": "0x5029C236320b8f15eF0a657054B84d90bfBEDED3",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/BitANT/logo.png"
    },
    {
      "chainId": 10,
      "name": "BitBTC",
      "symbol": "BitBTC",
      "address": "0xc98B98d17435AA00830c87eA02474C5007E1f272",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/BitBTC/logo.png"
    },
    {
      "chainId": 10,
      "name": "BOB Token",
      "symbol": "BOB",
      "address": "0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21882.png"
    },
    {
      "chainId": 10,
      "name": "BarnBridge Governance Token",
      "symbol": "BOND",
      "address": "0x3e7eF8f50246f725885102E8238CBba33F276747",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/BOND/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Coinbase Wrapped Staked ETH",
      "symbol": "cbETH",
      "address": "0xaddb6a0412de1ba0f936dcaeb8aaa24578dcf3b2",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/cbETH/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Chi USD",
      "symbol": "CHI",
      "address": "0xCa0E54b636DB823847B29F506BFFEE743F57729D",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/CHI/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Collab.Land",
      "symbol": "COLLAB",
      "address": "0x8B21e9b7dAF2c4325bf3D18c1BeB79A347fE902A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23649.png"
    },
    {
      "chainId": 10,
      "name": "Curve DAO Token",
      "symbol": "CRV",
      "address": "0x0994206dfe8de6ec6920ff4d779b0d950605fb53",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/CRV/logo.png"
    },
    {
      "chainId": 10,
      "name": "Cartesi Token",
      "symbol": "CTSI",
      "address": "0xEc6adef5E1006bb305bB1975333e8fc4071295bf",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5444.png"
    },
    {
      "chainId": 10,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 10,
      "name": "Dentacoin",
      "symbol": "DCN",
      "address": "0x1da650c3b2daa8aa9ff6f661d4156ce24d08a062",
      "decimals": 0,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1876.png"
    },
    {
      "chainId": 10,
      "name": "dForce",
      "symbol": "DF",
      "address": "0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/DF/logo.svg"
    },
    {
      "chainId": 10,
      "name": "dHEDGE DAO Token",
      "symbol": "DHT",
      "address": "0xAF9fE3B5cCDAe78188B1F8b9a49Da7ae9510F151",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7094.png"
    },
    {
      "chainId": 10,
      "name": "Dola USD Stablecoin",
      "symbol": "DOLA",
      "address": "0x8aE125E8653821E851F12A49F7765db9a9ce7384",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19191.png"
    },
    {
      "chainId": 10,
      "name": "Unit protocol",
      "symbol": "DUCK",
      "address": "0x0e49ca6ea763190084c846d3fc18f28bc2ac689a",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/DUCK/logo.png"
    },
    {
      "chainId": 10,
      "name": "Ethereum Name Service",
      "symbol": "ENS",
      "address": "0x65559aA14915a70190438eF90104769e5E890A00",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/ENS/logo.png"
    },
    {
      "chainId": 10,
      "name": "Equalizer",
      "symbol": "EQZ",
      "address": "0x81ab7e0d570b01411fcc4afd3d50ec8c241cb74b",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/EQZ/logo.png"
    },
    {
      "chainId": 10,
      "name": "Erica Social Token",
      "symbol": "EST",
      "address": "0x7b0bcC23851bBF7601efC9E9FE532Bf5284F65d3",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/EST/logo.png"
    },
    {
      "chainId": 10,
      "name": "StaFi Token",
      "symbol": "FIS",
      "address": "0xD8737CA46aa6285dE7B8777a8e3db232911baD41",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/FIS/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Flashstake",
      "symbol": "FLASH",
      "address": "0x86bEA60374f220dE9769b2fEf2db725bc1cDd335",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/FLASH/logo.svg"
    },
    {
      "chainId": 10,
      "name": "FRAX",
      "symbol": "FRAX",
      "address": "0x2E3D870790dC77A83DD1d18184Acc7439A53f475",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    },
    {
      "chainId": 10,
      "name": "Frax Ether",
      "symbol": "frxETH",
      "address": "0x6806411765Af15Bddd26f8f544A34cC40cb9838B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23225.png"
    },
    {
      "chainId": 10,
      "name": "FXS",
      "symbol": "FXS",
      "address": "0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/FXS/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Giveth Token",
      "symbol": "GIV",
      "address": "0x528CDc92eAB044E1E39FE43B9514bfdAB4412B98",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17207.png"
    },
    {
      "chainId": 10,
      "name": "Rigo Token",
      "symbol": "GRG",
      "address": "0xEcF46257ed31c329F204Eb43E254C609dee143B3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4927.png"
    },
    {
      "chainId": 10,
      "name": "Gitcoin",
      "symbol": "GTC",
      "address": "0x1eba7a6a72c894026cd654ac5cdcf83a46445b08",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/GTC/logo.svg"
    },
    {
      "chainId": 10,
      "name": "GMO JPY",
      "symbol": "GYEN",
      "address": "0x589d35656641d6aB57A545F08cf473eCD9B6D5F7",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8771.png"
    },
    {
      "chainId": 10,
      "name": "Geyser",
      "symbol": "GYSR",
      "address": "0x117cFd9060525452db4A34d51c0b3b7599087f05",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/GYSR/logo.png"
    },
    {
      "chainId": 10,
      "name": "HairDAO Token",
      "symbol": "HAIR",
      "address": "0x5465145a47260D5E715733997333A175D97285Bb",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/HAIR/logo.svg"
    },
    {
      "chainId": 10,
      "name": "HanChain",
      "symbol": "HAN",
      "address": "0x50Bce64397C75488465253c0A034b8097FeA6578",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23146.png"
    },
    {
      "chainId": 10,
      "name": "DAOhaus Token on Optimism",
      "symbol": "HAUS",
      "address": "0x01B8b6384298D4848E3BE63D4C9D17830EeE488A",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/HAUS/logo.png"
    },
    {
      "chainId": 10,
      "name": "HOP",
      "symbol": "HOP",
      "address": "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20547.png"
    },
    {
      "chainId": 10,
      "name": "Impermax",
      "symbol": "IBEX",
      "address": "0xBB6BBaa0F6D839A00c82B10747aBc3b7E2eEcc82",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/IBEX/logo.png"
    },
    {
      "chainId": 10,
      "name": "Kyber Network Crystal v2",
      "symbol": "KNC",
      "address": "0xa00E3A3511aAC35cA78530c85007AFCd31753819",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9444.png"
    },
    {
      "chainId": 10,
      "name": "Kromatika",
      "symbol": "KROM",
      "address": "0xf98dcd95217e15e05d8638da4c91125e59590b07",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14745.png"
    },
    {
      "chainId": 10,
      "name": "Lido DAO Token",
      "symbol": "LDO",
      "address": "0xFdb794692724153d1488CcdBE0C56c252596735F",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/LDO/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Chainlink",
      "symbol": "LINK",
      "address": "0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 10,
      "name": "Theranos Coin",
      "symbol": "LIZ",
      "address": "0x3bB4445D30AC020a84c1b5A8A2C6248ebC9779D0",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/LIZ/logo.png"
    },
    {
      "chainId": 10,
      "name": "Loopfi",
      "symbol": "LPF",
      "address": "0x0B3e851cf6508A16266BC68A651ea68b31ef673b",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/LPF/logo.svg"
    },
    {
      "chainId": 10,
      "name": "LoopringCoin V2",
      "symbol": "LRC",
      "address": "0xFEaA9194F9F8c1B65429E31341a103071464907E",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/LRC/logo.png"
    },
    {
      "chainId": 10,
      "name": "LUSD Stablecoin",
      "symbol": "LUSD",
      "address": "0xc40f949f8a4e094d1b49a23ea9241d289b7b2819",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/LUSD/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Lyra",
      "symbol": "LYRA",
      "address": "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15447.png"
    },
    {
      "chainId": 10,
      "name": "Mask Network",
      "symbol": "MASK",
      "address": "0x3390108E913824B8eaD638444cc52B9aBdF63798",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/MASK/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Maker",
      "symbol": "MKR",
      "address": "0xab7badef82e9fe11f6f33f87bc9bc2aa27f2fcb5",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/MKR/logo.png"
    },
    {
      "chainId": 10,
      "name": "Mochi",
      "symbol": "MOCHI",
      "address": "0x77D40CBc27f912dcDbF4348cAf87B427c4D02486",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/MOCHI/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Monetum",
      "symbol": "MOM",
      "address": "0x5e70AfFE232e2919792f77EB94e566db0320fa88",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/MOM/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Meta",
      "symbol": "MTA",
      "address": "0x929b939f8524c3be977af57a4a0ad3fb1e374b50",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/MTA/logo.svg"
    },
    {
      "chainId": 10,
      "name": "NFTEarth",
      "symbol": "NFTE",
      "address": "0xc96f4F893286137aC17e07Ae7F217fFca5db3AB6",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/NFTE/logo.png"
    },
    {
      "chainId": 10,
      "name": "Optimism",
      "symbol": "OP",
      "address": "0x4200000000000000000000000000000000000042",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png"
    },
    {
      "chainId": 10,
      "name": "Paper",
      "symbol": "PAPER",
      "address": "0x00F932F0FE257456b32dedA4758922E56A4F4b42",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/PAPER/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Perpetual",
      "symbol": "PERP",
      "address": "0x9e1028F5F1D5eDE59748FFceE5532509976840E0",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/PERP/logo.png"
    },
    {
      "chainId": 10,
      "name": "PhunToken",
      "symbol": "PHTK",
      "address": "0xFa956eB0c4b3E692aD5a6B2f08170aDE55999ACa",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/PHTK/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Popcorn",
      "symbol": "POP",
      "address": "0x6F0fecBC276de8fC69257065fE47C5a03d986394",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13920.png"
    },
    {
      "chainId": 10,
      "name": "Premia",
      "symbol": "PREMIA",
      "address": "0x374Ad0f47F4ca39c78E5Cc54f1C9e426FF8f231A",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/PREMIA/logo.svg"
    },
    {
      "chainId": 10,
      "name": "ParaSwap",
      "symbol": "PSP",
      "address": "0xd3594E879B358F430E20F82bea61e83562d49D48",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14534.png"
    },
    {
      "chainId": 10,
      "name": "Rai Reflex Index",
      "symbol": "RAI",
      "address": "0x7FB688CCf682d58f86D7e38e03f9D22e7705448B",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/RAI/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Rocket Pool ETH",
      "symbol": "rETH",
      "address": "0x9bcef72be871e61ed4fbbc7630889bee758eb81d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15060.png"
    },
    {
      "chainId": 10,
      "name": "StaFi Staked ETH",
      "symbol": "rETH",
      "address": "0x6c2f7b6110a37b3B0fbdd811876be368df02E8B0",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/rETH_StaFi/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Rari Governance Token",
      "symbol": "RGT",
      "address": "0xb548f63d4405466b36c0c0ac3318a22fdcec711a",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/RGT/logo.png"
    },
    {
      "chainId": 10,
      "name": "Sarcophagus",
      "symbol": "SARCO",
      "address": "0x7c6b91d9be155a6db01f749217d76ff02a7227f2",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/SARCO/logo.png"
    },
    {
      "chainId": 10,
      "name": "Synthetic Bitcoin",
      "symbol": "sBTC",
      "address": "0x298B9B95708152ff6968aafd889c6586e9169f1D",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/sBTC/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Saddle DAO",
      "symbol": "SDL",
      "address": "0xAe31207aC34423C41576Ff59BFB4E036150f9cF7",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/SDL/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Synthetic Ether",
      "symbol": "sETH",
      "address": "0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5765.png"
    },
    {
      "chainId": 10,
      "name": "Synthetic Chainlink",
      "symbol": "sLINK",
      "address": "0xc5Db22719A06418028A40A9B5E9A7c02959D0d08",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6190.png"
    },
    {
      "chainId": 10,
      "name": "Synthetix",
      "symbol": "SNX",
      "address": "0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png"
    },
    {
      "chainId": 10,
      "name": "SPANK",
      "symbol": "SPANK",
      "address": "0xcfD1D50ce23C46D3Cf6407487B2F8934e96DC8f9",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/SPANK/logo.png"
    },
    {
      "chainId": 10,
      "name": "Staked Lyra",
      "symbol": "stkLYRA",
      "address": "0x0F5d45a7023612e9e244fe84FAc5fCf3740d1492",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/stkLYRA/logo.svg"
    },
    {
      "chainId": 10,
      "name": "SUKU",
      "symbol": "SUKU",
      "address": "0xef6301da234fc7b0545c6e877d3359fe0b9e50a4",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/SUKU/logo.png"
    },
    {
      "chainId": 10,
      "name": "Synthetix USD",
      "symbol": "sUSD",
      "address": "0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2927.png"
    },
    {
      "chainId": 10,
      "name": "SushiToken",
      "symbol": "SUSHI",
      "address": "0x3eaEb77b03dBc0F6321AE1b72b2E9aDb0F60112B",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/SUSHI/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Optimistic Thales Token",
      "symbol": "THALES",
      "address": "0x217D47011b23BB961eB6D93cA9945B7501a5BB11",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11973.png"
    },
    {
      "chainId": 10,
      "name": "TheDAO",
      "symbol": "TheDAO",
      "address": "0xd8f365c2c85648f9b89d9f1bf72c0ae4b1c36cfd",
      "decimals": 16,
      "logoURI": "https://ethereum-optimism.github.io/data/TheDAO/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Tellor",
      "symbol": "TRB",
      "address": "0xaf8ca653fa2772d58f4368b0a71980e9e3ceb888",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/TRB/logo.png"
    },
    {
      "chainId": 10,
      "name": "TrueUSD",
      "symbol": "TUSD",
      "address": "0xcB59a0A753fDB7491d5F3D794316F1adE197B21E",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/TUSD/logo.png"
    },
    {
      "chainId": 10,
      "name": "Universal Basic Income",
      "symbol": "UBI",
      "address": "0xbb586ed34974b15049a876fd5366a4c2d1203115",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/UBI/logo.svg"
    },
    {
      "chainId": 10,
      "name": "UMA Voting Token v1",
      "symbol": "UMA",
      "address": "0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/UMA/logo.png"
    },
    {
      "chainId": 10,
      "name": "Uniswap",
      "symbol": "UNI",
      "address": "0x6fd9d7ad17242c41f7131d257212c54a0e816691",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/UNI/logo.png"
    },
    {
      "chainId": 10,
      "name": "USD Coin (Bridged from Ethereum)",
      "symbol": "USDC.e",
      "address": "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 10,
      "name": "Decentralized USD",
      "symbol": "USDD",
      "address": "0x7113370218f31764C1B6353BDF6004d86fF6B9cc",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/USDD/logo.png"
    },
    {
      "chainId": 10,
      "name": "USD+",
      "symbol": "USD+",
      "address": "0x73cb180bf0521828d8849bc8CF2B920918e23032",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20317.png"
    },
    {
      "chainId": 10,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 10,
      "name": "UST (Wormhole)",
      "symbol": "UST",
      "address": "0xBA28feb4b6A6b81e3F26F08b83a19E715C4294fd",
      "decimals": 6,
      "logoURI": "https://ethereum-optimism.github.io/data/UST/logo.png"
    },
    {
      "chainId": 10,
      "name": "dForce USD",
      "symbol": "USX",
      "address": "0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/USX/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Validator",
      "symbol": "VALX",
      "address": "0x0A9AAa1c7542b42233AC7FfdA364E97adE21f160",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/VALX/logo.png"
    },
    {
      "chainId": 10,
      "name": "veKwenta",
      "symbol": "veKWENTA",
      "address": "0x678d8f4Ba8DFE6bad51796351824DcCECeAefF2B",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/veKWENTA/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Velodrome",
      "symbol": "VELO",
      "address": "0x3c8B650257cFb5f272f799F5e2b4e65093a11a05",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20435.png"
    },
    {
      "chainId": 10,
      "name": "VitaDAO Token",
      "symbol": "VITA",
      "address": "0x7d14206c937e70e19e3a5b94011faf0d5b3928e2",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/VITA/logo.svg"
    },
    {
      "chainId": 10,
      "name": "WardenSwap",
      "symbol": "WAD",
      "address": "0x703D57164CA270b0B330A87FD159CfEF1490c0a5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16552.png"
    },
    {
      "chainId": 10,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 10,
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0x4200000000000000000000000000000000000006",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 10,
      "name": "Wootrade Network",
      "symbol": "WOO",
      "address": "0x871f2F2ff935FD1eD867842FF2a7bfD051A5E527",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/WOO/logo.svg"
    },
    {
      "chainId": 10,
      "name": "Wrapped liquid staked Ether 2.0",
      "symbol": "wstETH",
      "address": "0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 10,
      "name": "wTBT(Bridge Token)",
      "symbol": "wTBT",
      "address": "0xdb4eA87fF83eB1c80b8976FC47731Da6a31D35e5",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/wTBT/logo.svg"
    },
    {
      "chainId": 10,
      "name": "CryptoFranc",
      "symbol": "XCHF",
      "address": "0xE4F27b04cC7729901876B44f4EAA5102EC150265",
      "decimals": 18,
      "logoURI": "https://ethereum-optimism.github.io/data/XCHF/logo.png"
    },
    {
      "chainId": 10,
      "name": "0x Protocol Token",
      "symbol": "ZRX",
      "address": "0xD1917629B3E6A72E6772Aab5dBe58Eb7FA3C2F33",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/28437.png"
    },
    {
      "chainId": 10,
      "name": "Z.com USD",
      "symbol": "ZUSD",
      "address": "0x6e4cc0Ab2B4d2eDAfa6723cFA1582229F1Dd1Be1",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8772.png"
    },
    {
      "chainId": 10,
      "name": "CyberConnect",
      "symbol": "CYBER",
      "address": "0x14778860e937f509e651192a90589de711fb88a9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24781.png"
    },
    {
      "chainId": 10,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 10,
      "name": "Staked Frax Ether",
      "symbol": "sfrxETH",
      "address": "0x484c2D6e3cDd945a8B2DF735e079178C1036578c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23177.png"
    },
    {
      "chainId": 10,
      "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
      "symbol": "USDM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714669224779_8860677574156777.png",
      "name": "Mountain Protocol USDM"
    },
    {
      "chainId": 10,
      "address": "0x17aabf6838a6303fc6e9c5a227dc1eb6d95c829a",
      "symbol": "TUX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704705601073_7591587543130598.png",
      "name": "Magicaltux"
    },
    {
      "chainId": 10,
      "address": "0xAeAeeD23478c3a4b798e4ed40D8B7F41366Ae861",
      "symbol": "ANKR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700546919743_6759040021697091.png",
      "name": "Ankr Network"
    },
    {
      "chainId": 10,
      "address": "0xe05a08226c49b636acf99c40da8dc6af83ce5bb3",
      "symbol": "ankrETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700546255790_6932460389861634.png",
      "name": "Ankr Staked ETH "
    },
    {
      "chainId": 10,
      "address": "0xdc6ff44d5d932cbd77b52e5612ba0529dc6226f1",
      "symbol": "WLD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690252425087_3762727589739008.png",
      "name": "Worldcoin"
    },
    {
      "chainId": 10,
      "address": "0x47536f17f4ff30e64a96a7555826b8f9e66ec468",
      "symbol": "MMY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681872524045_8395772736492524.jpg",
      "name": "Mummy Finance"
    },
    {
      "chainId": 10,
      "address": "0x1db2466d9f5e10d7090e7152b68d62703a2245f0",
      "symbol": "SONNE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678157558652_4545641434548.png",
      "name": "Sonne Finance"
    },
    {
      "chainId": 10,
      "address": "0xcdb4bb51801a1f399d4402c61bc098a72c382e65",
      "symbol": "OPX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669260975578_13899480892814853.png",
      "name": "OPX Finance"
    },
    {
      "chainId": 10,
      "address": "0x97513e975a7fa9072c72c92d8000b0db90b163c5",
      "symbol": "BEETS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679562318561_8433247125066119.png",
      "name": "Beethoven X"
    },
    {
      "chainId": 10,
      "address": "0xdFA46478F9e5EA86d57387849598dbFB2e964b02",
      "symbol": "MAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1656666717016_08467737500105033.png",
      "name": "miMATIC"
    },
    {
      "chainId": 10,
      "address": "0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db",
      "symbol": "VELO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1656664301096_4133387415807357.jpg",
      "name": "Velodrome Finance"
    },
    {
      "chainId": 10,
      "address": "0x296f55f8fb28e498b858d0bcda06d955b2cb3f97",
      "symbol": "STG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1649388025332_7843963542104893.png",
      "name": "Stargate Finance"
    }
  ],
  "25": [
    {
      "chainId": 25,
      "name": "BNB",
      "symbol": "BNB",
      "address": "0xfA9343C3897324496A05fC75abeD6bAC29f8A40f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 25,
      "name": "Meerkat Shares",
      "symbol": "MSHARE",
      "address": "0xf8b9facB7B4410F5703Eb29093302f2933D6E1Aa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18472.png"
    },
    {
      "chainId": 25,
      "name": "RadioShack",
      "symbol": "RADIO",
      "address": "0xf899e3909B4492859d44260E1de41A9E663e70F5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19006.png"
    },
    {
      "chainId": 25,
      "name": "Echelon",
      "symbol": "ECH",
      "address": "0xf69B774BE9ACA90e87D2788BE7E6b1D492fA7ACd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20047.png"
    },
    {
      "chainId": 25,
      "name": "Crown Finance",
      "symbol": "CRN",
      "address": "0xf287E8999fcbea602D2c5DD699ea2FF7f3685964",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20592.png"
    },
    {
      "chainId": 25,
      "name": "Elk Finance",
      "symbol": "ELK",
      "address": "0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10095.png"
    },
    {
      "chainId": 25,
      "name": "GDRT",
      "symbol": "GDRT",
      "address": "0xeC0d0f2D7dDF5e6F1Ed18711fE5DD5C790E1C4d6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21088.png"
    },
    {
      "chainId": 25,
      "name": "Bonded Cronos",
      "symbol": "BCRO",
      "address": "0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20820.png"
    },
    {
      "chainId": 25,
      "name": "Beefy Finance",
      "symbol": "BIFI",
      "address": "0xe6801928061CDbE32AC5AD0634427E140EFd05F9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21122.png"
    },
    {
      "chainId": 25,
      "name": "Cross USD Stablecoin",
      "symbol": "xUSD",
      "address": "0xe6052a9a4c0A2f14ADC9876A3A1a7b2882f5F139",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xe6052a9a4c0A2f14ADC9876A3A1a7b2882f5F139/logo.png"
    },
    {
      "chainId": 25,
      "name": "Bank Of Cronos",
      "symbol": "BOC",
      "address": "0xe5786DDFc4D6DcA0973D1c5b02987cBbac66ed87",
      "decimals": 9,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xe5786DDFc4D6DcA0973D1c5b02987cBbac66ed87/logo.png"
    },
    {
      "chainId": 25,
      "name": "WETH",
      "symbol": "WETH",
      "address": "0xe44Fd7fCb2b1581822D0c862B68222998a0c299a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 25,
      "name": "Crodex",
      "symbol": "CRX",
      "address": "0xe243CCab9E66E6cF1215376980811ddf1eb7F689",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15069.png"
    },
    {
      "chainId": 25,
      "name": "DexPad",
      "symbol": "DXP",
      "address": "0xe0c41FF9a7032de445771E12C14868CbE061C993",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/23106/thumb/dxp.png?1643236999"
    },
    {
      "chainId": 25,
      "name": "V3S Share",
      "symbol": "VSHARE",
      "address": "0xdcC261c03cD2f33eBea404318Cdc1D9f8b78e1AD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18650.png"
    },
    {
      "chainId": 25,
      "name": "MintMe com Coin",
      "symbol": "MINTME",
      "address": "0xd652776dE7Ad802be5EC7beBfafdA37600222B48",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801"
    },
    {
      "chainId": 25,
      "name": "FortuneDAO",
      "symbol": "FORT",
      "address": "0xcA558149225Fb386B9c26716e8C35a650C74d35e",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15742.png"
    },
    {
      "chainId": 25,
      "name": "Cronosphere",
      "symbol": "SPHERE",
      "address": "0xc9FDE867a14376829Ab759F4C4871F67e2d3E441",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17909.png"
    },
    {
      "chainId": 25,
      "name": "Matic",
      "symbol": "MATIC",
      "address": "0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png"
    },
    {
      "chainId": 25,
      "name": "IMPRESSO",
      "symbol": "ESSO",
      "address": "0xc82eA25896F065cB6E3ae298cD1f23FE58516A35",
      "decimals": 8,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xc82eA25896F065cB6E3ae298cD1f23FE58516A35/logo.svg"
    },
    {
      "chainId": 25,
      "name": "CROPPERFIELD",
      "symbol": "CROPPERFIELD",
      "address": "0xc40469cCcb76e080d26B84f460753Bc0cf57f90b",
      "decimals": 18,
      "logoURI": "https://storage.googleapis.com/xy-finance-images/token-list/cropperfield_32.png"
    },
    {
      "chainId": 25,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xc21223249CA28397B4B6541dfFaEcC539BfF0c59",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 25,
      "name": "PhotonSwap",
      "symbol": "PHOTON",
      "address": "0xbdd4e5660839a088573191A9889A262c0Efc0983",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17640.png"
    },
    {
      "chainId": 25,
      "name": "SHIBA INU",
      "symbol": "SHIB",
      "address": "0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee/logo.png"
    },
    {
      "chainId": 25,
      "name": "CronaSwap",
      "symbol": "CRONA",
      "address": "0xadbd1231fb360047525BEdF962581F3eee7b49fe",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14625.png"
    },
    {
      "chainId": 25,
      "name": "MATIC",
      "symbol": "MATIC",
      "address": "0xad79AC3c5a5c15C6B9194F5568e451b3fc3C2B40",
      "decimals": 18,
      "logoURI": "https://cloudstorage.openocean.finance/images/1650434638704_045322303327145264.png"
    },
    {
      "chainId": 25,
      "name": "Crolon Mars",
      "symbol": "CLMRS",
      "address": "0xaAd00d36Dbc8343C3505Ba51418a43D3622D2964",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21588.png"
    },
    {
      "chainId": 25,
      "name": "Horgi",
      "symbol": "HORGI",
      "address": "0xa3089f1462426398eb586E07700ae08Aba7324C4",
      "decimals": 16,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19110.png"
    },
    {
      "chainId": 25,
      "name": "Croissant Games",
      "symbol": "CROISSANT",
      "address": "0xa0C3c184493f2Fae7d2f2Bd83F195a1c300FA353",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17465.png"
    },
    {
      "chainId": 25,
      "name": "DarkCrypto Foundation",
      "symbol": "DCF",
      "address": "0xFa202a63234A7089EDdbFEee83c378ba248f0e9a",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/27488/thumb/Main_Token.png?1664252242"
    },
    {
      "chainId": 25,
      "name": "DuckyDefi",
      "symbol": "DEGG",
      "address": "0xFD71FC52D34eD1Cfc8363e5528285B12b6b942c2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20501.png"
    },
    {
      "chainId": 25,
      "name": "Mad Meerkat Optimizer",
      "symbol": "MMO",
      "address": "0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2001.png"
    },
    {
      "chainId": 25,
      "name": "Dai",
      "symbol": "DAI",
      "address": "0xF2001B145b43032AAF5Ee2884e456CCd805F677D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 25,
      "name": "Lazy Horse Race Club",
      "symbol": "LHRC",
      "address": "0xEfD450027bD0EE84AfaFbC75f5A80C367eFBF651",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17093.png"
    },
    {
      "chainId": 25,
      "name": "DarkCrystl",
      "symbol": "DARKCRYSTL",
      "address": "0xEfA1FABC2AB6219174aD1c912F56f7de53cDc1E1",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/26486/thumb/1-modified.png?1658281857"
    },
    {
      "chainId": 25,
      "name": "xyETH",
      "symbol": "xyETH",
      "address": "0xEf367cdB37Ce3F43130fB9651d3db8fBF6045c87",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880"
    },
    {
      "chainId": 25,
      "name": "Darkness Share",
      "symbol": "NESS",
      "address": "0xE727240728C1a5f95437b8b50AFDd0EA4AE5F0c8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18753.png"
    },
    {
      "chainId": 25,
      "name": "Galaxy Heroes",
      "symbol": "GHC",
      "address": "0xE6BF0E14e33A469F2b0640B53949A9F90E675135",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "Bitcoin BR",
      "symbol": "BTCBR",
      "address": "0xE57425F1598f9b0d6219706b77f4b3DA573a3695",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16251.png"
    },
    {
      "chainId": 25,
      "name": "Old Elk",
      "symbol": "oELK",
      "address": "0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C",
      "decimals": 18,
      "logoURI": "https://elk.finance/tokens/logos/cronos/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png"
    },
    {
      "chainId": 25,
      "name": "Tectonic",
      "symbol": "TONIC",
      "address": "0xDD73dEa10ABC2Bff99c60882EC5b2B81Bb1Dc5B2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16430.png"
    },
    {
      "chainId": 25,
      "name": "CRONEX",
      "symbol": "CRONEX",
      "address": "0xDB2Bb0A1481FCF0df33720d80A660dD4d060B6c0",
      "decimals": 9,
      "logoURI": "https://storage.googleapis.com/xy-finance-images/token-list/Cronex.png"
    },
    {
      "chainId": 25,
      "name": "CronosNode",
      "symbol": "CRON",
      "address": "0xD8eB4845B094e05f4d01d7BB3468238076F744f1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20280.png"
    },
    {
      "chainId": 25,
      "name": "10SHARE",
      "symbol": "10SHARE",
      "address": "0xD8d40DcEE0C2B486EEBd1FEDb3F507B011De7Ff0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20857.png"
    },
    {
      "chainId": 25,
      "name": "Tiger Scrub Money  OLD ",
      "symbol": "TIGER",
      "address": "0xD6597AA36DD90d7fCcBd7B8A228F2d5CdC88eAd0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19409.png"
    },
    {
      "chainId": 25,
      "name": "Betify",
      "symbol": "BETIFY",
      "address": "0xD465b6B4937D768075414D413e981Af0b49349Cc",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18215.png"
    },
    {
      "chainId": 25,
      "name": "Croblanc",
      "symbol": "CROBLANC",
      "address": "0xD3ceCBE5639D05Aed446Da11F08D495CA6bF359F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17363.png"
    },
    {
      "chainId": 25,
      "name": "DNA Dollar",
      "symbol": "DNA",
      "address": "0xCc57F84637B441127f2f74905b9d99821b47b20c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17251.png"
    },
    {
      "chainId": 25,
      "name": "Crystl Finance",
      "symbol": "CRYSTL",
      "address": "0xCbDE0E17d14F49e10a10302a32d17AE88a7Ecb8B",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xCbDE0E17d14F49e10a10302a32d17AE88a7Ecb8B/logo.png"
    },
    {
      "chainId": 25,
      "name": "Corgi",
      "symbol": "CORGI",
      "address": "0xCC6e924721F75af3A8F8282216569276f3F2a104",
      "decimals": 9,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xCC6e924721F75af3A8F8282216569276f3F2a104/logo.png"
    },
    {
      "chainId": 25,
      "name": "SaharaDAO",
      "symbol": "MNG",
      "address": "0xC9b23289c60783649AA327732FCCEc2f5d0aC466",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20706.png"
    },
    {
      "chainId": 25,
      "name": "V3SToken",
      "symbol": "V3S",
      "address": "0xC7e99a2f064Cf86aF91Db252a9D8Bc16e6fE7427",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xC7e99a2f064Cf86aF91Db252a9D8Bc16e6fE7427/logo.png"
    },
    {
      "chainId": 25,
      "name": "Binance USD",
      "symbol": "BUSD",
      "address": "0xC74D59A548ecf7fc1754bb7810D716E9Ac3e3AE5",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766"
    },
    {
      "chainId": 25,
      "name": "Crogecoin",
      "symbol": "CROGE",
      "address": "0xC4a174cCb5fb54a6721e11e0Ca961e42715023F9",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19393.png"
    },
    {
      "chainId": 25,
      "name": "Kragger Inu",
      "symbol": "KINU",
      "address": "0xC0d4bB262d63A95f85Ae841b4e2B0dACc54Fdc0a",
      "decimals": 2,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19626.png"
    },
    {
      "chainId": 25,
      "name": "MMFp",
      "symbol": "MMFp",
      "address": "0xBDC8fd437C489Ca3c6DA3B5a336D11532a532303",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "Mad Meerkat ETF",
      "symbol": "METF",
      "address": "0xB8Df27c687c6af9aFE845A2aFAD2D01e199f4878",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18763.png"
    },
    {
      "chainId": 25,
      "name": "Cosmos Hub",
      "symbol": "ATOM",
      "address": "0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png"
    },
    {
      "chainId": 25,
      "name": "Kaguni",
      "symbol": "Kagu",
      "address": "0xB73b49aAb0B354A7868f9AD36c7a6AC357E20492",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xB73b49aAb0B354A7868f9AD36c7a6AC357E20492/logo.png"
    },
    {
      "chainId": 25,
      "name": "Fantom",
      "symbol": "FTM",
      "address": "0xB44a9B6905aF7c801311e8F4E76932ee959c663C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10240.png"
    },
    {
      "chainId": 25,
      "name": "CroArmy",
      "symbol": "Army",
      "address": "0xB20115B8B176D72F077C4f93e95492870240a0b2",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "Liquidus",
      "symbol": "LIQ",
      "address": "0xABd380327Fe66724FFDa91A87c772FB8D00bE488",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12546.png"
    },
    {
      "chainId": 25,
      "name": "CRODOGE",
      "symbol": "CRODOGE",
      "address": "0xA7aaf2d57179BdA516374b9947e608D01F6ED0A4",
      "decimals": 4,
      "logoURI": "https://elk.finance/tokens/logos/cronos/0xA7aaf2d57179BdA516374b9947e608D01F6ED0A4/logo.png"
    },
    {
      "chainId": 25,
      "name": "Magus Nodes",
      "symbol": "MAGUS",
      "address": "0xA57ca211cd6820bd3d930978271538d07e31A212",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20347.png"
    },
    {
      "chainId": 25,
      "name": "Agile",
      "symbol": "AGL",
      "address": "0xA4434AFEae0dECb9820D906BF01b13291D00651a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16409.png"
    },
    {
      "chainId": 25,
      "name": "EOS",
      "symbol": "EOS",
      "address": "0xA37caA841072a305a0799718aFA16cd504C52118",
      "decimals": 4,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xA37caA841072a305a0799718aFA16cd504C52118/logo.png"
    },
    {
      "chainId": 25,
      "name": "V3S-Peg Tectonic",
      "symbol": "V3TONIC",
      "address": "0xA29EB0318F522ca98FdB5cEdc0c7549526625517",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xA29EB0318F522ca98FdB5cEdc0c7549526625517/logo.png"
    },
    {
      "chainId": 25,
      "name": "DAPPSY",
      "symbol": "APP",
      "address": "0x9fB83c0635De2E815fd1c21b3a292277540C2e8d",
      "decimals": 8,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "PolyDoge",
      "symbol": "PolyDoge",
      "address": "0x9b8077C6590b560f1A9D60512648277D29b35A3B",
      "decimals": 18,
      "logoURI": "https://cloudstorage.openocean.finance/images/1650435434916_5749926628730133.png"
    },
    {
      "chainId": 25,
      "name": "SafeBLAST",
      "symbol": "BLAST",
      "address": "0x9D3E3624b3de482331F4f091Fd8b76D3F344bd8C",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9967.png"
    },
    {
      "chainId": 25,
      "name": "DarkCrypto Share",
      "symbol": "SKY",
      "address": "0x9D3BBb0e988D9Fb2d55d07Fe471Be2266AD9c81c",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/22517/small/logo-token-2.e9c15b63.png?1641968423"
    },
    {
      "chainId": 25,
      "name": "Ripae",
      "symbol": "PAE",
      "address": "0x99415856B37bE9E75C0153615C7954f9DDb97A6E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7821.png"
    },
    {
      "chainId": 25,
      "name": "DOT",
      "symbol": "DOT",
      "address": "0x994047FE66406CbD646cd85B990E11D7F5dB8fC7",
      "decimals": 10,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x994047FE66406CbD646cd85B990E11D7F5dB8fC7/logo.png"
    },
    {
      "chainId": 25,
      "name": "Annex Finance",
      "symbol": "ANN",
      "address": "0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11583.png"
    },
    {
      "chainId": 25,
      "name": "Grave",
      "symbol": "GRVE",
      "address": "0x9885488cD6864DF90eeBa6C5d07B35f08CEb05e9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18530.png"
    },
    {
      "chainId": 25,
      "name": "Orbs",
      "symbol": "ORBS",
      "address": "0x9873795F5DAb11e1c0342C4a58904c59827ede0c",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "MMFinance  Cronos ",
      "symbol": "MMF",
      "address": "0x97749c9B61F878a880DfE312d2594AE07AEd7656",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16519.png"
    },
    {
      "chainId": 25,
      "name": "RMAI",
      "symbol": "RMAI",
      "address": "0x9700104AC74C0d52E9441f3324AdC97059EE429b",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "Mad USD",
      "symbol": "MUSD",
      "address": "0x95aEaF383E2e86A47c11CffdE1F7944eCB2C38C2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20025.png"
    },
    {
      "chainId": 25,
      "name": "xCRX",
      "symbol": "XCRX",
      "address": "0x9315054f01bF8c13EE67C8498Af09A1933cbF24c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22456.png"
    },
    {
      "chainId": 25,
      "name": "LUNA Token",
      "symbol": "LUNA",
      "address": "0x9278C8693e7328bef49804BacbFb63253565dffD",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11178.png"
    },
    {
      "chainId": 25,
      "name": "CroATOM",
      "symbol": "CroATOM",
      "address": "0x8b0D1164e98DD0592598B56C1Baa86462c73A2d1",
      "decimals": 9,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x8b0D1164e98DD0592598B56C1Baa86462c73A2d1/logo.png"
    },
    {
      "chainId": 25,
      "name": "TrueUSD",
      "symbol": "TUSD",
      "address": "0x87EFB3ec1576Dec8ED47e58B832bEdCd86eE186e",
      "decimals": 18,
      "logoURI": "https://static.debank.com/image/cro_token/logo_url/0x87efb3ec1576dec8ed47e58b832bedcd86ee186e/100307a7dfc8a5281a76beda99c17cd8.png"
    },
    {
      "chainId": 25,
      "name": "CGO",
      "symbol": "CGO",
      "address": "0x869c3e35Ef9E5F50003D9a2A80f1D61d670D4CB6",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/26058/thumb/cgo_logo_200.png?1655454967"
    },
    {
      "chainId": 25,
      "name": "DarkCrypto",
      "symbol": "DARK",
      "address": "0x83b2AC8642aE46FC2823Bc959fFEB3c1742c48B5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17118.png"
    },
    {
      "chainId": 25,
      "name": "CroNodes",
      "symbol": "CRN",
      "address": "0x8174BaC1453c3AC7CaED909c20ceaDeb5E1CDA00",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17226.png"
    },
    {
      "chainId": 25,
      "name": "CRO Predict",
      "symbol": "CRP",
      "address": "0x7b8aD6d7560FAcd1959cfb4b4163D7d297c4bFc0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19298.png"
    },
    {
      "chainId": 25,
      "name": "ApeCoin",
      "symbol": "APE",
      "address": "0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7/logo.png"
    },
    {
      "chainId": 25,
      "name": "CbrdogToken",
      "symbol": "CBRDOG",
      "address": "0x7a6a832eB5F58245F7d75eD980cED849D69A98FD",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x7a6a832eB5F58245F7d75eD980cED849D69A98FD/logo.png"
    },
    {
      "chainId": 25,
      "name": "Onino",
      "symbol": "ONI",
      "address": "0x7D3B448c1242b563c65e8DAfCc13557c3069B5b6",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "CroMoon",
      "symbol": "CroMoon",
      "address": "0x7D30c36f845d1dEe79f852abF3A8A402fAdF3b53",
      "decimals": 9,
      "logoURI": "https://static.debank.com/image/cro_token/logo_url/0x7d30c36f845d1dee79f852abf3a8a402fadf3b53/935c8992b5198034a6f09c47f587d64c.png"
    },
    {
      "chainId": 25,
      "name": "MSHARE",
      "symbol": "MSHARE",
      "address": "0x7D09a42045359Aa85488bC07D0ADa83E22d50017",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "Defira  Cronos ",
      "symbol": "FIRA",
      "address": "0x7ABa852082b6F763E13010CA33B5D9Ea4EeE2983",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22388.png"
    },
    {
      "chainId": 25,
      "name": "OpenLeverage",
      "symbol": "OLE",
      "address": "0x76a3D96726C0ED756eA420d239d3Feb998EBf528",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "DoKEN",
      "symbol": "DoKEN",
      "address": "0x76C1E6ABB673E535A7EbceE2EEA968ed88022202",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "AVAX",
      "symbol": "AVAX",
      "address": "0x765277EebeCA2e31912C9946eAe1021199B39C61",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 25,
      "name": "The Laboratory",
      "symbol": "LAB",
      "address": "0x75F55E718Ea51191966f256893bD96Db2DE9eE94",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/25176/thumb/Lab_Logo_circular.png?1650527640"
    },
    {
      "chainId": 25,
      "name": "CROTerra",
      "symbol": "CROTERRA",
      "address": "0x74F53e67D68A348611979e3012EDf9781C437529",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x74F53e67D68A348611979e3012EDf9781C437529/logo.png"
    },
    {
      "chainId": 25,
      "name": "AvtoCROSS",
      "symbol": "CROSS",
      "address": "0x6ef20cA7E493c52095e892DAB78a7FD0e7e2a279",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x6ef20cA7E493c52095e892DAB78a7FD0e7e2a279/logo.png"
    },
    {
      "chainId": 25,
      "name": "CroNodes Finance",
      "symbol": "CRNF",
      "address": "0x6eDd4e7A5bd9688AAb374E5A0447c2A2FF10fAEA",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x6eDd4e7A5bd9688AAb374E5A0447c2A2FF10fAEA/logo.png"
    },
    {
      "chainId": 25,
      "name": "BUSD",
      "symbol": "BUSD",
      "address": "0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 25,
      "name": "ToxicDeer Share",
      "symbol": "XDSHARE",
      "address": "0x6F715158d4B1468528DA002F5941c72fe4159520",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20563.png"
    },
    {
      "chainId": 25,
      "name": "ToxicDeer Finance",
      "symbol": "DEER",
      "address": "0x6D6D6ba0c7e7DBaFffeC82b1ddF92e271650a63A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20462.png"
    },
    {
      "chainId": 25,
      "name": "Hibiki Finance",
      "symbol": "HIBIKI",
      "address": "0x6B66fCB66Dba37F99876a15303b759c73fc54ed0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16069.png"
    },
    {
      "chainId": 25,
      "name": "Loot Dollar",
      "symbol": "LOOTDOLLAR",
      "address": "0x68C4FD0c9dE98f05D206cd04c958a0Ec14cE016e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20148.png"
    },
    {
      "chainId": 25,
      "name": "Shack",
      "symbol": "SHACK",
      "address": "0x671D2E0E1a9c8E117A071bFC5763aF3fBe9bDF0b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19952.png"
    },
    {
      "chainId": 25,
      "name": "Gaur Shares",
      "symbol": "GSHARE",
      "address": "0x66ec6E9F61ac288f5BA661CD9a2dBe3aBf9871C9",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/22900/thumb/gshare.png?1653810600"
    },
    {
      "chainId": 25,
      "name": "Tether",
      "symbol": "USDT",
      "address": "0x66e428c3f67a68878562e79A0234c1F83c208770",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 25,
      "name": "Darkness Dollar",
      "symbol": "DUSD",
      "address": "0x6582C738660bf0701f05b04DcE3c4E5Fcfcda47a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18829.png"
    },
    {
      "chainId": 25,
      "name": "Savanna",
      "symbol": "SVN",
      "address": "0x654bAc3eC77d6dB497892478f854cF6e8245DcA9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18183.png"
    },
    {
      "chainId": 25,
      "name": "BUILD",
      "symbol": "BUILD",
      "address": "0x6467df17771AB26D1825bF0891b3c421D92EBC1d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21113.png"
    },
    {
      "chainId": 25,
      "name": "Metaverse Hub",
      "symbol": "MHUB",
      "address": "0x63E65C6d7c43F1BA6188943Cc89ba4b002a2AD0D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21112.png"
    },
    {
      "chainId": 25,
      "name": "Salem Finance",
      "symbol": "SALEM",
      "address": "0x637CB66526244029407046867E1E0DFD28b2294E",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x637CB66526244029407046867E1E0DFD28b2294E/logo.png"
    },
    {
      "chainId": 25,
      "name": "UNI",
      "symbol": "UNI",
      "address": "0x5fC17416925789E0852FBFcd81c490ca4abc51F9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5113.png"
    },
    {
      "chainId": 25,
      "name": "PegasusDAO",
      "symbol": "SUS",
      "address": "0x5b5Fe1238aca91C65683aCd7f9D9Bf922e271EAA",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16243.png"
    },
    {
      "chainId": 25,
      "name": "Wrapped CRO",
      "symbol": "WCRO",
      "address": "0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14532.png"
    },
    {
      "chainId": 25,
      "name": "Crypto Kart Racing",
      "symbol": "CKRACING",
      "address": "0x5B3c7b96ea7be0367d054a8ef62557d4f4Dd0fE7",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/25443/thumb/CKR-logo-200x200-1.png?1651755064"
    },
    {
      "chainId": 25,
      "name": "Phenix Finance  OLD ",
      "symbol": "PHNX",
      "address": "0x57d06bB1e3B60C875cD3A4445a53217F9B44d390",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20837.png"
    },
    {
      "chainId": 25,
      "name": "CROSHIBA",
      "symbol": "CROSHIBA",
      "address": "0x5617EA36c5da5dAFaB51A8634B02609459fEE94F",
      "decimals": 4,
      "logoURI": "https://elk.finance/tokens/logos/cronos/0x5617EA36c5da5dAFaB51A8634B02609459fEE94F/logo.png"
    },
    {
      "chainId": 25,
      "name": "CroBank",
      "symbol": "BANK",
      "address": "0x55210C2a69b4c52a9d9289A257D54d35C4a2d2eC",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18157.png"
    },
    {
      "chainId": 25,
      "name": "Phenix Finance",
      "symbol": "PHNX",
      "address": "0x52438441073A33b6FcBc70fa3F3F334F2dA19906",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22245.png"
    },
    {
      "chainId": 25,
      "name": "Mad Meerkat Optimizer",
      "symbol": "MMO",
      "address": "0x50c0C5bda591bc7e89A342A3eD672FB59b3C46a7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16797.png"
    },
    {
      "chainId": 25,
      "name": "Singular.farm",
      "symbol": "DUO",
      "address": "0x4ff6334aa95aFfC85F09738eEfc866cBEA7DC7c6",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x4ff6334aa95aFfC85F09738eEfc866cBEA7DC7c6/logo.png"
    },
    {
      "chainId": 25,
      "name": "DeFi Degen Land",
      "symbol": "DDL",
      "address": "0x4e60216965e37e54DC94A1D9e473211e9DBC35bA",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/20871/thumb/0inlfwNU_400x400.jpg?1637809911"
    },
    {
      "chainId": 25,
      "name": "Cougar Token",
      "symbol": "CGS",
      "address": "0x4e57e27e4166275Eb7f4966b42A201d76e481B03",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13106.png"
    },
    {
      "chainId": 25,
      "name": "CROTAMA",
      "symbol": "CROTAMA",
      "address": "0x4c76a10A658d71AdB6431765a0a3cA13B6e2D491",
      "decimals": 2,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19361.png"
    },
    {
      "chainId": 25,
      "name": "DoKEN V2",
      "symbol": "DKN",
      "address": "0x4a1F7d14d68B8D5c1604ad235f06e57054435ed9",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/20283/thumb/Qu5sof9.png?1638363195"
    },
    {
      "chainId": 25,
      "name": "Lion Scrub Money  OLD ",
      "symbol": "LION",
      "address": "0x49fB98F9b4a3183Cd88e7a115144fdf00fa6fB95",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19410.png"
    },
    {
      "chainId": 25,
      "name": "Pendle",
      "symbol": "PENDLE",
      "address": "0x49c3bBB239f4FB44327073510f4bA72D207a81D6",
      "decimals": 18,
      "logoURI": null
    },
    {
      "chainId": 25,
      "name": "MMF Money",
      "symbol": "BURROW",
      "address": "0x49ab7ca4D2Cf6777c8C56C5E9Edb7D1218AE1299",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20044.png"
    },
    {
      "chainId": 25,
      "name": "StormSwap Finance",
      "symbol": "WIND",
      "address": "0x48713151E5AFB7b4CC45f3653c1c59CF81E88D4B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15038.png"
    },
    {
      "chainId": 25,
      "name": "Argo Finance",
      "symbol": "ARGO",
      "address": "0x47A9D630dc5b28F75d3AF3be3AAa982512Cd89Aa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19900.png"
    },
    {
      "chainId": 25,
      "name": "$ROX$",
      "symbol": "$ROX$",
      "address": "0x46cAe37a4C67Cba4642904cC46344B463c5A531D",
      "decimals": 9,
      "logoURI": "https://storage.googleapis.com/xy-finance-images/token-list/%24ROX%24.PNG"
    },
    {
      "chainId": 25,
      "name": "Alethea Artificial Liquid Intelligence",
      "symbol": "ALI",
      "address": "0x45C135C1CDCE8d25A3B729A28659561385C52671",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16876.png"
    },
    {
      "chainId": 25,
      "name": "EVO",
      "symbol": "EVO",
      "address": "0x42006Ab57701251B580bDFc24778C43c9ff589A1",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135"
    },
    {
      "chainId": 25,
      "name": "Dexioprotocol",
      "symbol": "DEXI",
      "address": "0x3e1bEF464F2600eF94dE4d965c8991e06570d2e8",
      "decimals": 9,
      "logoURI": "https://assets.coingecko.com/coins/images/27075/thumb/dexio-v2.png?1661691044"
    },
    {
      "chainId": 25,
      "name": "Betero",
      "symbol": "BTE",
      "address": "0x3D632d9e1a60a0880Dd45E61f279D919b5748377",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19675.png"
    },
    {
      "chainId": 25,
      "name": "Ferro",
      "symbol": "FER",
      "address": "0x39bC1e38c842C60775Ce37566D03B41A7A66C782",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20716.png"
    },
    {
      "chainId": 25,
      "name": "Hakuna Share",
      "symbol": "MTT",
      "address": "0x388c07066AA6cEa2Be4db58e702333df92c3A074",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20046.png"
    },
    {
      "chainId": 25,
      "name": "Fairy Finance UNICORN",
      "symbol": "UNICORN",
      "address": "0x387c06f6e8d1F65839FCb9171171aBb5F49F8b20",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/27233/thumb/unicorn.90630d4c.png?1662710115"
    },
    {
      "chainId": 25,
      "name": "Crolend",
      "symbol": "CRD",
      "address": "0x34DeB73e57f7be74D2ccA1869d2c721e16C7a32C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22643.png"
    },
    {
      "chainId": 25,
      "name": "Bison",
      "symbol": "BISON",
      "address": "0x3405A1bd46B85c5C029483FbECf2F3E611026e45",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
      "chainId": 25,
      "name": "UtilityCro",
      "symbol": "UCRO",
      "address": "0x30078453DEaD93bdBC31b9A18AC0a6ece171F459",
      "decimals": 9,
      "logoURI": "https://elk.finance/tokens/logos/cronos/0x30078453DEaD93bdBC31b9A18AC0a6ece171F459/logo.png"
    },
    {
      "chainId": 25,
      "name": "DarkGang Finance",
      "symbol": "DARKG",
      "address": "0x2c43d9f78e4132FBe40BEBB8EC5F61377472b2C0",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/24638/thumb/DARKG.png?1648463537"
    },
    {
      "chainId": 25,
      "name": "VVS Finance",
      "symbol": "VVS",
      "address": "0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14519.png"
    },
    {
      "chainId": 25,
      "name": "CronosVerse",
      "symbol": "VRSE",
      "address": "0x2Ba01269EaFCe04c8DCCC4A9887884AB66E4Bcb1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20800.png"
    },
    {
      "chainId": 25,
      "name": "MIM",
      "symbol": "MIM",
      "address": "0x2BC472832Eb20C65F82d6A869db845aB0C0099ba",
      "decimals": 18,
      "logoURI": "https://static.debank.com/image/cro_token/logo_url/0x2bc472832eb20c65f82d6a869db845ab0c0099ba/aa79f041c1e6e1f1405963bae9ac1068.png"
    },
    {
      "chainId": 25,
      "name": "MAI",
      "symbol": "MIMATIC",
      "address": "0x2Ae35c8E3D4bD57e8898FF7cd2bBff87166EF8cb",
      "decimals": 18,
      "logoURI": "https://elk.finance/tokens/logos/cronos/0x2Ae35c8E3D4bD57e8898FF7cd2bBff87166EF8cb/logo.png"
    },
    {
      "chainId": 25,
      "name": "Arcane",
      "symbol": "ARC",
      "address": "0x289B8f552c9fD66f9cEA35B193F7ca73ae24A5d5",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x289B8f552c9fD66f9cEA35B193F7ca73ae24A5d5/logo.png"
    },
    {
      "chainId": 25,
      "name": "MAD Bucks",
      "symbol": "MAD",
      "address": "0x212331e1435A8df230715dB4C02B2a3A0abF8c61",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19281.png"
    },
    {
      "chainId": 25,
      "name": "Empty Set Crypto",
      "symbol": "ESC",
      "address": "0x1eCAF6a3551E5822F142c9689D8F36a9B5F68217",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20053.png"
    },
    {
      "chainId": 25,
      "name": "Mind Music",
      "symbol": "MND",
      "address": "0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12812.png"
    },
    {
      "chainId": 25,
      "name": "miMatic",
      "symbol": "MAI",
      "address": "0x1c965D8E53fb1a448789e2B0FA5abc3EB2c36993",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10238.png"
    },
    {
      "chainId": 25,
      "name": "Paper",
      "symbol": "PAPER",
      "address": "0x1affBc17938a25d245e1B7eC6f2fc949df8E9760",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084"
    },
    {
      "chainId": 25,
      "name": "CRO Max Token",
      "symbol": "CROMAX",
      "address": "0x1985127d1B21af04d09a69Cdcb1dD33fd8961e9c",
      "decimals": 9,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x1985127d1B21af04d09a69Cdcb1dD33fd8961e9c/logo.png"
    },
    {
      "chainId": 25,
      "name": "MarbleVerse",
      "symbol": "RLM",
      "address": "0x19258a1df9E929D02b34621CF52797998aE1Aa27",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19333.png"
    },
    {
      "chainId": 25,
      "name": "CrO cRo",
      "symbol": "CROCRO",
      "address": "0x17C00923Ce44f2748DB6A14f979D55C497A267e4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15327.png"
    },
    {
      "chainId": 25,
      "name": "Poor Quack",
      "symbol": "POOR",
      "address": "0x1703Dd2d4a1b14D0Bcb87C445f3263179d523b16",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16932.png"
    },
    {
      "chainId": 25,
      "name": "dibs.money",
      "symbol": "DIBS",
      "address": "0x165DBb08de0476271714952C3C1F068693bd60D7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9983.png"
    },
    {
      "chainId": 25,
      "name": "Scratch",
      "symbol": "SCRATCH",
      "address": "0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22130.png"
    },
    {
      "chainId": 25,
      "name": "CROSHI",
      "symbol": "CROSHI",
      "address": "0x10faae6A25354544081D1aa3438e66086218d851",
      "decimals": 9,
      "logoURI": "https://storage.googleapis.com/xy-finance-images/token-list/CROSHI.png"
    },
    {
      "chainId": 25,
      "name": "Mimas Finance",
      "symbol": "MIMAS",
      "address": "0x10C9284E6094b71D3CE4E38B8bFfc668199da677",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17189.png"
    },
    {
      "chainId": 25,
      "name": "Wrapped ADA",
      "symbol": "WADA",
      "address": "0x0e517979C2c1c1522ddB0c73905e0D39b3F990c0",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19369.png"
    },
    {
      "chainId": 25,
      "name": "AUTOv2",
      "symbol": "AUTO",
      "address": "0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa/logo.png"
    },
    {
      "chainId": 25,
      "name": "Adamant Token",
      "symbol": "CADDY",
      "address": "0x09ad12552ec45f82bE90B38dFE7b06332A680864",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x09ad12552ec45f82bE90B38dFE7b06332A680864/logo.png"
    },
    {
      "chainId": 25,
      "name": "Mine Shares",
      "symbol": "MINE",
      "address": "0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/26444/thumb/Logotype_Fashion_Store_Logo_Neon_%282%29-modified.png?1658110350"
    },
    {
      "chainId": 25,
      "name": "Single Finance",
      "symbol": "SINGLE",
      "address": "0x0804702a4E749d39A35FDe73d1DF0B1f1D6b8347",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17469.png"
    },
    {
      "chainId": 25,
      "name": "Degen Protocol",
      "symbol": "SH33P",
      "address": "0x0694c9bf930b7456712cEde98bE770e110212B38",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17127.png"
    },
    {
      "chainId": 25,
      "name": "Croking",
      "symbol": "CRK",
      "address": "0x065DE42E28E42d90c2052a1B49e7f83806Af0e1F",
      "decimals": 9,
      "logoURI": "https://assets.coingecko.com/coins/images/22667/small/Logo_200x200.png?1642403624"
    },
    {
      "chainId": 25,
      "name": "Wrapped Bitcoin",
      "symbol": "WBTC",
      "address": "0x062E66477Faf219F25D27dCED647BF57C3107d52",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 25,
      "name": "Gaur Money",
      "symbol": "GAUR",
      "address": "0x046cb616d7a52173e4Da9efF1BFd590550aa3228",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17188.png"
    },
    {
      "chainId": 25,
      "name": "Defido Coin",
      "symbol": "BASE",
      "address": "0x0422b63182798ae9876cae3caBd225d96B284D14",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x0422b63182798ae9876cae3caBd225d96B284D14/logo.png"
    },
    {
      "chainId": 25,
      "name": "Cronavirus",
      "symbol": "CRONAVIRUS",
      "address": "0x039AcEC8659Cc69a35E8A48c618c47128934B575",
      "decimals": 18,
      "logoURI": "https://elk.finance/tokens/logos/cronos/0x039AcEC8659Cc69a35E8A48c618c47128934B575/logo.png"
    },
    {
      "chainId": 25,
      "name": "MetaversePixels",
      "symbol": "MetaPx",
      "address": "0x03627840190152771e86e5E702175B00219F6471",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x03627840190152771e86e5E702175B00219F6471/logo.png"
    },
    {
      "chainId": 25,
      "name": "Dogelon Mars",
      "symbol": "ELON",
      "address": "0x02DCcaf514C98451320a9365C5b46C61d3246ff3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9436.png"
    },
    {
      "chainId": 25,
      "name": "10mb",
      "symbol": "10MB",
      "address": "0x02A8Dc66334B1cc6CD8F28Fe8DBF6b58B49b47B6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20856.png"
    },
    {
      "chainId": 25,
      "name": "Minted",
      "symbol": "MTD",
      "address": "0x0224010BA2d567ffa014222eD960D1fa43B8C8E1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21418.png"
    },
    {
      "chainId": 25,
      "name": "CronosFC",
      "symbol": "GOAL",
      "address": "0x00fe915a5209e74D5a88334cC2daA4541AEC8278",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/24505/thumb/logo_v13.jpg?1647882353"
    },
    {
      "chainId": 25,
      "name": "VersaGames",
      "symbol": "VERSA",
      "address": "0x00D7699b71290094CcB1a5884cD835bD65a78c17",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20549.png"
    },
    {
      "chainId": 25,
      "address": "0xdb7d0a1ec37de1de924f8e8adac6ed338d4404e9",
      "symbol": "VNO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1682408477398_24457574712214702.png",
      "name": "Veno Finance"
    }
  ],
  "30": [
    {
      "chainId": 30,
      "name": "Rootstock Infrastructure Framework",
      "symbol": "RIF",
      "address": "0x2acc95758f8b5f583470ba265eb685a8f45fc9d5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3701.png"
    },
    {
      "chainId": 30,
      "name": "RIF US Dollar",
      "symbol": "USDRIF",
      "address": "0x3a15461d8ae0f0fb5fa2629e9da7d66a794a6e37",
      "decimals": 18,
      "logoURI": "https://cdn1.gfx.xyz/300x3a15461d8ae0f0fb5fa2629e9da7d66a794a6e37.png"
    },
    {
      "chainId": 30,
      "name": "Dollar on Chain",
      "symbol": "DOC",
      "address": "0xe700691dA7b9851F2F35f8b8182c69c53CcaD9Db",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7558.png"
    },
    {
      "chainId": 30,
      "address": "0x542fda317318ebf1d3deaf76e0b632741a7e677d",
      "symbol": "WRBTC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718103714912_8886217409323052.png",
      "name": "Wrapped BTC"
    },
    {
      "chainId": 30,
      "address": "0xef213441a85df4d7acbdae0cf78004e1e486bb96",
      "symbol": "rUSDT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1711531922793_37810034259137226.png",
      "name": "RUSDT"
    },
    {
      "chainId": 30,
      "address": "0xefc78fc7d48b64958315949279ba181c2114abbd",
      "symbol": "SOV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712640076570_05278381509192731.jpg",
      "name": "Sovryn"
    },
    {
      "chainId": 30,
      "address": "0x9ac7fe28967b30e3a4e6e03286d715b42b453d10",
      "symbol": "MOC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714489815015_689794090334668.png",
      "name": "Money On-chain"
    },
    {
      "chainId": 30,
      "address": "0x1d931bf8656d795e50ef6d639562c5bd8ac2b78f",
      "symbol": "ETHs",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712552263660_1804052055477292.png",
      "name": "ETHs"
    },
    {
      "chainId": 30,
      "address": "0xb5999795be0ebb5bab23144aa5fd6a02d080299f",
      "symbol": "XUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712543282248_5866995519947711.png",
      "name": "XUSD (BabelFish)"
    },
    {
      "chainId": 30,
      "address": "0x055a902303746382fbb7d18f6ae0df56efdc5213",
      "symbol": "FISH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712543018668_3372321564799421.png",
      "name": "Babelfish"
    },
    {
      "chainId": 30,
      "address": "0xc1411567d2670e24d9c4daaa7cda95686e1250aa",
      "symbol": "DLLR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712542183587_3204503020963023.png",
      "name": "Sovryn Dollar"
    }
  ],
  "40": [
    {
      "chainId": 40,
      "name": "Wrapped Bitcoin",
      "symbol": "WBTC",
      "address": "0xf390830df829cf22c53c8840554b98eafc5dcbc2",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 40,
      "name": "Ethereum",
      "symbol": "ETH",
      "address": "0xfa9343c3897324496a05fc75abed6bac29f8a40f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 40,
      "name": "Avalanche",
      "symbol": "AVAX",
      "address": "0x7c598c96d02398d89fbcb9d41eab3df0c16f227d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png"
    },
    {
      "chainId": 40,
      "name": "Binance Coin",
      "symbol": "BNB",
      "address": "0x2c78f1b70ccf63cdee49f9233e9faa99d43aa07e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png"
    },
    {
      "chainId": 40,
      "name": "Fantom",
      "symbol": "FTM",
      "address": "0xc1be9a4d5d45beeacae296a7bd5fadbfc14602c4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10240.png"
    },
    {
      "chainId": 40,
      "name": "Polygon",
      "symbol": "MATIC",
      "address": "0x332730a4f6e03d9c55829435f10360e13cfa41ff",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
      "chainId": 40,
      "name": "Sushi",
      "symbol": "SUSHI",
      "address": "0x922d641a426dcffaef11680e5358f34d97d112e1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
      "chainId": 40,
      "name": "Zappy",
      "symbol": "ZAP",
      "address": "0x9A271E3748F59222f5581BaE2540dAa5806b3F77",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18284.png"
    },
    {
      "chainId": 40,
      "name": "Omnidex",
      "symbol": "CHARM",
      "address": "0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18257.png"
    },
    {
      "chainId": 40,
      "name": "DougeCoin",
      "symbol": "DOUGE",
      "address": "0xc6BC7A8dfA0f57Fe7746Ac434c01cD39679b372c",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/elkfinance/tokens/main/logos/telos/0xc6BC7A8dfA0f57Fe7746Ac434c01cD39679b372c/logo.png"
    },
    {
      "chainId": 40,
      "name": "Jesse Rocket Super Coin",
      "symbol": "JRS",
      "address": "0x47be534f78c6408b454d36e75415249ad8d10a60",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/JesseRocketTokenLogo1.png"
    },
    {
      "chainId": 40,
      "name": "TM Coin",
      "symbol": "TM",
      "address": "0xcCf69d549a25d90938B3EEA0D7A2d112c971071B",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/TelosMemeLogo.png"
    },
    {
      "chainId": 40,
      "name": "DogeLos",
      "symbol": "DLOS",
      "address": "0x03Cf39c84C7E5C514Ad4b9Aa49B04255aA0cdf87",
      "decimals": 0,
      "logoURI": "https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/dogelosLogo.png"
    },
    {
      "chainId": 40,
      "name": "TelosFellas",
      "symbol": "TFELLAS",
      "address": "0xF5bA374CF1e85d1a44EC989c5d8A25C4eACA078E",
      "decimals": 9,
      "logoURI": "https://telosfellas.com/images/tf.png"
    },
    {
      "chainId": 40,
      "name": "BabyEvils",
      "symbol": "BEVIL",
      "address": "0x6439DA210988b1fD8DAC8b31CD000a7087416824",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/telosnetwork/images/master/logos_2021/BabyEvilsLogo.png"
    },
    {
      "chainId": 40,
      "name": "Aristotle",
      "symbol": "ARIS",
      "address": "0x1aCA60694d12A99fa5DdD1C78493b6eFd7416601",
      "decimals": 9,
      "logoURI": "https://i.imgur.com/iYmGhdu.png"
    },
    {
      "chainId": 40,
      "name": "Glue Dog",
      "symbol": "DOG",
      "address": "0x5C8F2334BD0e7B76e15a7869E31c1F1A654a2B62",
      "decimals": 18,
      "logoURI": "https://gateway.ipfs.io/ipfs/QmerrZ47zBxoQqkr4gfY53wvoAUqn3JXs6R6VCfE4s9nkK"
    },
    {
      "chainId": 40,
      "name": "Stabledog",
      "symbol": "USDOG",
      "address": "0x470Bb4C2499726DC7329A7874E82b9b3578eA891",
      "decimals": 18,
      "logoURI": "https://glue.dog/wp-content/uploads/2022/02/stabledog-768x630.png"
    },
    {
      "chainId": 40,
      "name": "Big Pig",
      "symbol": "PIG",
      "address": "0xE5dE07ec385B1BD55f6bB02c01860547be9D7C0B",
      "decimals": 18,
      "logoURI": "https://gateway.ipfs.io/ipfs/QmPThPaz3kjWaLjDAZKP8frpzaVm5Rvi5wCcUbag8QhgRL"
    },
    {
      "chainId": 40,
      "name": "Ploutus",
      "symbol": "PLOU",
      "address": "0x8b377acebccf930fec65e9ccb693bdb1be3fd3c0",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/Geilogandet/token-list/cf290bf5297be0842ada7ad10f7f70bbaf718c05/Ploutus.png"
    },
    {
      "chainId": 40,
      "name": "dummy☻DAO",
      "symbol": "DMMY",
      "address": "0x2f15F85a6c346C0a2514Af70075259e503E7137B",
      "decimals": 18,
      "logoURI": "https://omnidex.finance/images/tokens/0x2f15F85a6c346C0a2514Af70075259e503E7137B.svg"
    },
    {
      "chainId": 40,
      "name": "Game Ace Token Extended",
      "symbol": "GATe",
      "address": "0xEC0a873cdBE667E5bD68AF47932c948f872032d6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10950.png"
    },
    {
      "chainId": 40,
      "name": "APISH ME",
      "symbol": "APISH",
      "address": "0x11fbfdf906d32753fa2a083dbd4fb25c1094c6c4",
      "decimals": 9,
      "logoURI": "https://apish.me/images/token.png"
    },
    {
      "chainId": 40,
      "name": "T-Squirrel",
      "symbol": "TSQRL",
      "address": "0xa5E5cf79494643d509A076e3895547086f47a6F1",
      "decimals": 18,
      "logoURI": "https://3hlzh7.dstor.cloud/ipfs/QmbL7uYYp7sZ6URYua1RdWw6MPLR7jnZisMfLbPEhuurKw?filename=TSQRL.png"
    },
    {
      "chainId": 40,
      "name": "ELK",
      "symbol": "ELK",
      "address": "0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10095.png"
    },
    {
      "chainId": 40,
      "name": "syUSDC",
      "symbol": "USDC",
      "address": "0xe6E5f3d264117E030C21920356641DbD5B3d660c",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 40,
      "name": "syWETH",
      "symbol": "WETH",
      "address": "0x63d71E79AdF0886c989A23b04a0E86F1489b6BC3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 40,
      "name": "syUSDT",
      "symbol": "USDT",
      "address": "0x7bD3ffe9f0C9CF08FD60e102FEa455A6EA580276",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 40,
      "address": "0x568524da340579887db50ecf602cd1ba8451b243",
      "symbol": "MST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1721312511685_6276024880769806.png",
      "name": "Meridian MST"
    },
    {
      "chainId": 40,
      "address": "0x8f7D64ea96D729EF24a0F30b4526D47b80d877B9",
      "symbol": "USDM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1721311331604_24778651471959323.png",
      "name": "Meridian USD"
    },
    {
      "chainId": 40,
      "address": "0x7627b27594bc71e6Ab0fCE755aE8931EB1E12DAC",
      "symbol": "BTC.b",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700031756399_9983063256722933.png",
      "name": "Bitcoin"
    },
    {
      "chainId": 40,
      "address": "0xA0fB8cd450c8Fd3a11901876cD5f17eB47C6bc50",
      "symbol": "ETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704870846802_5403972520399198.png",
      "name": "Ethereum"
    },
    {
      "chainId": 40,
      "address": "0x8D97Cea50351Fb4329d591682b148D43a0C3611b",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700031378574_48005236797331885.png",
      "name": "USDC"
    },
    {
      "chainId": 40,
      "address": "0x975ed13fa16857e83e7c493c7741d556eaad4a3f",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704870862896_4680667902868969.png",
      "name": "Tether"
    },
    {
      "chainId": 40,
      "address": "0xaC45EDe2098bc989Dfe0798B4630872006e24c3f",
      "symbol": "SLUSH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700031141802_08165912644407824.png",
      "name": "Swapsicle SLUSH Token"
    },
    {
      "chainId": 40,
      "address": "0x26Ed0F16e777C94A6FE798F9E20298034930Bae8",
      "symbol": "BNB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700030491822_6054695716004808.png",
      "name": "BNB"
    },
    {
      "chainId": 40,
      "address": "0xB4B01216a5Bc8F1C8A33CD990A1239030E60C905",
      "symbol": "STLOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704870943779_4594031637513569.png",
      "name": "Staked TLOS"
    },
    {
      "chainId": 40,
      "address": "0xd102ce6a4db07d247fcc28f366a623df0938ca9e",
      "symbol": "WTLOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704870955222_24722761458587428.png",
      "name": "Wrapped TLOS"
    }
  ],
  "56": [
    {
      "chainId": 56,
      "name": "PancakeSwap Token",
      "symbol": "CAKE",
      "address": "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png"
    },
    {
      "chainId": 56,
      "name": "Alchemy Token",
      "symbol": "ACH",
      "address": "0xBc7d6B50616989655AfD682fb42743507003056D",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6958.png"
    },
    {
      "chainId": 56,
      "name": "Cardano Token",
      "symbol": "ADA",
      "address": "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png"
    },
    {
      "chainId": 56,
      "name": "AdEx Network",
      "symbol": "ADX",
      "address": "0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1768.png"
    },
    {
      "chainId": 56,
      "name": "My Neigbor Alice",
      "symbol": "ALICE",
      "address": "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8766.png"
    },
    {
      "chainId": 56,
      "name": "AlpaToken",
      "symbol": "ALPA",
      "address": "0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03.png"
    },
    {
      "chainId": 56,
      "name": "Alpaca",
      "symbol": "ALPACA",
      "address": "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8707.png"
    },
    {
      "chainId": 56,
      "name": "AlphaToken",
      "symbol": "ALPHA",
      "address": "0xa1faa113cbE53436Df28FF0aEe54275c13B40975",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7232.png"
    },
    {
      "chainId": 56,
      "name": "Ampleforth",
      "symbol": "AMPL",
      "address": "0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F",
      "decimals": 9,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F.png"
    },
    {
      "chainId": 56,
      "name": "Ankr",
      "symbol": "ANKR",
      "address": "0xf307910A4c7bbc79691fD374889b36d8531B08e3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png"
    },
    {
      "chainId": 56,
      "name": "anyMTLX",
      "symbol": "anyMTLX",
      "address": "0x5921DEE8556c4593EeFCFad3CA5e2f618606483b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7256.png"
    },
    {
      "chainId": 56,
      "name": "AgeOfGods",
      "symbol": "AOG",
      "address": "0x40C8225329Bd3e28A043B029E0D07a5344d2C27C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16679.png"
    },
    {
      "chainId": 56,
      "name": "ApolloX Token",
      "symbol": "APX",
      "address": "0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16334.png"
    },
    {
      "chainId": 56,
      "name": "APYSwap",
      "symbol": "APYS",
      "address": "0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8419.png"
    },
    {
      "chainId": 56,
      "name": "ARPA",
      "symbol": "ARPA",
      "address": "0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4039.png"
    },
    {
      "chainId": 56,
      "name": "ARIVA",
      "symbol": "ARV",
      "address": "0x6679eB24F59dFe111864AEc72B443d1Da666B360",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11541.png"
    },
    {
      "chainId": 56,
      "name": "Automata",
      "symbol": "ATA",
      "address": "0xA2120b9e674d3fC3875f415A7DF52e382F141225",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10188.png"
    },
    {
      "chainId": 56,
      "name": "Atletico de Madrid",
      "symbol": "ATM",
      "address": "0x25E9d05365c867E59C1904E7463Af9F312296f9E",
      "decimals": 2,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x25E9d05365c867E59C1904E7463Af9F312296f9E.png"
    },
    {
      "chainId": 56,
      "name": "Cosmos Token",
      "symbol": "ATOM",
      "address": "0x0Eb3a705fc54725037CC9e008bDede697f62F335",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png"
    },
    {
      "chainId": 56,
      "name": "AUTOv2",
      "symbol": "AUTO",
      "address": "0xa184088a740c695E156F91f5cC086a06bb78b827",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8387.png"
    },
    {
      "chainId": 56,
      "name": "Axie Infinity Shard",
      "symbol": "AXS",
      "address": "0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png"
    },
    {
      "chainId": 56,
      "name": "BabyCake",
      "symbol": "BABYCAKE",
      "address": "0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10971.png"
    },
    {
      "chainId": 56,
      "name": "Bakery Token",
      "symbol": "BAKE",
      "address": "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png"
    },
    {
      "chainId": 56,
      "name": "AllianceBlock",
      "symbol": "bALBT",
      "address": "0x72fAa679E1008Ad8382959FF48E392042A8b06f7",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x72fAa679E1008Ad8382959FF48E392042A8b06f7.png"
    },
    {
      "chainId": 56,
      "name": "BAND Protocol Token",
      "symbol": "BAND",
      "address": "0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4679.png"
    },
    {
      "chainId": 56,
      "name": "Basic Attention Token",
      "symbol": "BAT",
      "address": "0x101d82428437127bF1608F699CD651e6Abf9766E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1697.png"
    },
    {
      "chainId": 56,
      "name": "Battle Hero",
      "symbol": "BATH",
      "address": "0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12435.png"
    },
    {
      "chainId": 56,
      "name": "bBADGER",
      "symbol": "bBADGER",
      "address": "0x1F7216fdB338247512Ec99715587bb97BBf96eae",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x1F7216fdB338247512Ec99715587bb97BBf96eae.png"
    },
    {
      "chainId": 56,
      "name": "BitBook",
      "symbol": "BBT",
      "address": "0xD48474E7444727bF500a32D5AbE01943f3A59A64",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10201.png"
    },
    {
      "chainId": 56,
      "name": "Conflux",
      "symbol": "bCFX",
      "address": "0x045c4324039dA91c52C55DF5D785385Aab073DcF",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x045c4324039dA91c52C55DF5D785385Aab073DcF.png"
    },
    {
      "chainId": 56,
      "name": "Bitcoin Cash Token",
      "symbol": "BCH",
      "address": "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png"
    },
    {
      "chainId": 56,
      "name": "Bomb Crypto",
      "symbol": "BCOIN",
      "address": "0x00e1656e45f18ec6747F5a8496Fd39B50b38396D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12252.png"
    },
    {
      "chainId": 56,
      "name": "bDIGG",
      "symbol": "bDIGG",
      "address": "0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA.png"
    },
    {
      "chainId": 56,
      "name": "bDollar",
      "symbol": "BDO",
      "address": "0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8219.png"
    },
    {
      "chainId": 56,
      "name": "Bella Protocol",
      "symbol": "BEL",
      "address": "0x8443f091997f06a61670B735ED92734F5628692F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6928.png"
    },
    {
      "chainId": 56,
      "name": "Belt",
      "symbol": "BELT",
      "address": "0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8730.png"
    },
    {
      "chainId": 56,
      "name": "Beta Finance",
      "symbol": "BETA",
      "address": "0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11307.png"
    },
    {
      "chainId": 56,
      "name": "Beacon ETH",
      "symbol": "BETH",
      "address": "0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8353.png"
    },
    {
      "chainId": 56,
      "name": "b.earnfi",
      "symbol": "BFI",
      "address": "0x81859801b01764D4f0Fa5E64729f5a6C3b91435b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8796.png"
    },
    {
      "chainId": 56,
      "name": "Beefy.finance",
      "symbol": "BIFI",
      "address": "0xCa3F508B8e4Dd382eE878A314789373D80A5190A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png"
    },
    {
      "chainId": 56,
      "name": "BLINk",
      "symbol": "BLK",
      "address": "0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7784.png"
    },
    {
      "chainId": 56,
      "name": "Binamon",
      "symbol": "BMON",
      "address": "0x08ba0619b1e7A582E0BCe5BBE9843322C954C340",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10704.png"
    },
    {
      "chainId": 56,
      "name": "Bondly",
      "symbol": "BONDLY",
      "address": "0x5D0158A5c3ddF47d4Ea4517d8DB0D76aA2e87563",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7931.png"
    },
    {
      "chainId": 56,
      "name": "BunnyPark",
      "symbol": "BP",
      "address": "0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10904.png"
    },
    {
      "chainId": 56,
      "name": "ROOBEE",
      "symbol": "bROOBEE",
      "address": "0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4804.png"
    },
    {
      "chainId": 56,
      "name": "Berry",
      "symbol": "BRY",
      "address": "0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8483.png"
    },
    {
      "chainId": 56,
      "name": "BSC Ecosystem Defi blue chips",
      "symbol": "BSCDEFI",
      "address": "0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3.png"
    },
    {
      "chainId": 56,
      "name": "BSCPad",
      "symbol": "BSCPAD",
      "address": "0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8660.png"
    },
    {
      "chainId": 56,
      "name": "BSCEX",
      "symbol": "BSCX",
      "address": "0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8190.png"
    },
    {
      "chainId": 56,
      "name": "Biswap",
      "symbol": "BSW",
      "address": "0x965F527D9159dCe6288a2219DB51fc6Eef120dD1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10746.png"
    },
    {
      "chainId": 56,
      "name": "Binance Pegged Bitcoin",
      "symbol": "BTCB",
      "address": "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4023.png"
    },
    {
      "chainId": 56,
      "name": "Standard BTC Hashrate Token",
      "symbol": "BTCST",
      "address": "0x78650B139471520656b9E7aA7A5e9276814a38e9",
      "decimals": 17,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8891.png"
    },
    {
      "chainId": 56,
      "name": "Bittrue",
      "symbol": "BTR",
      "address": "0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3.png"
    },
    {
      "chainId": 56,
      "name": "Bittorrent",
      "symbol": "BTT",
      "address": "0x352Cb5E19b12FC216548a2677bD0fce83BaE434B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16086.png"
    },
    {
      "chainId": 56,
      "name": "Bittorrent Old",
      "symbol": "BTTOLD",
      "address": "0x8595F9dA7b868b1822194fAEd312235E43007b49",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3718.png"
    },
    {
      "chainId": 56,
      "name": "Bunny Token",
      "symbol": "BUNNY",
      "address": "0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7791.png"
    },
    {
      "chainId": 56,
      "name": "Burger Swap",
      "symbol": "BURGER",
      "address": "0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7158.png"
    },
    {
      "chainId": 56,
      "name": "Binance Pegged BUSD",
      "symbol": "BUSD",
      "address": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 56,
      "name": "Coin98",
      "symbol": "C98",
      "address": "0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10903.png"
    },
    {
      "chainId": 56,
      "name": "CryptoArt.ai",
      "symbol": "CART",
      "address": "0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11018.png"
    },
    {
      "chainId": 56,
      "name": "ChainGuardians",
      "symbol": "CGG",
      "address": "0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8648.png"
    },
    {
      "chainId": 56,
      "name": "Tranchess",
      "symbol": "CHESS",
      "address": "0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10974.png"
    },
    {
      "chainId": 56,
      "name": "Chromia",
      "symbol": "CHR",
      "address": "0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png"
    },
    {
      "chainId": 56,
      "name": "Compound Finance",
      "symbol": "COMP",
      "address": "0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png"
    },
    {
      "chainId": 56,
      "name": "Contentos",
      "symbol": "COS",
      "address": "0x96Dd399F9c3AFda1F194182F71600F1B65946501",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x96Dd399F9c3AFda1F194182F71600F1B65946501.png"
    },
    {
      "chainId": 56,
      "name": "Cream",
      "symbol": "CREAM",
      "address": "0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6193.png"
    },
    {
      "chainId": 56,
      "name": "CertiK Token",
      "symbol": "CTK",
      "address": "0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4807.png"
    },
    {
      "chainId": 56,
      "name": "Concentrated Voting Power",
      "symbol": "CVP",
      "address": "0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E.png"
    },
    {
      "chainId": 56,
      "name": "Cyclone",
      "symbol": "CYC",
      "address": "0x810EE35443639348aDbbC467b33310d2AB43c168",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8590.png"
    },
    {
      "chainId": 56,
      "name": "Binance Pegged DAI",
      "symbol": "DAI",
      "address": "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 56,
      "name": "Mines of Dalarnia",
      "symbol": "DAR",
      "address": "0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11374.png"
    },
    {
      "chainId": 56,
      "name": "Deri",
      "symbol": "DERI",
      "address": "0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8424.png"
    },
    {
      "chainId": 56,
      "name": "DeXe",
      "symbol": "DEXE",
      "address": "0x039cB485212f996A9DBb85A9a75d898F94d38dA6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7326.png"
    },
    {
      "chainId": 56,
      "name": "DefiDollar DAO",
      "symbol": "DFD",
      "address": "0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff.png"
    },
    {
      "chainId": 56,
      "name": "DFuture",
      "symbol": "DFT",
      "address": "0x42712dF5009c20fee340B245b510c0395896cF6e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8758.png"
    },
    {
      "chainId": 56,
      "name": "Decentral Games",
      "symbol": "DG",
      "address": "0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7798.png"
    },
    {
      "chainId": 56,
      "name": "Dodo",
      "symbol": "DODO",
      "address": "0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7224.png"
    },
    {
      "chainId": 56,
      "name": "Dogecoin",
      "symbol": "DOGE",
      "address": "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/74.png"
    },
    {
      "chainId": 56,
      "name": "Polkadot Token",
      "symbol": "DOT",
      "address": "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png"
    },
    {
      "chainId": 56,
      "name": "Duet Governance Token",
      "symbol": "DUET",
      "address": "0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10132.png"
    },
    {
      "chainId": 56,
      "name": "Dusk",
      "symbol": "DUSK",
      "address": "0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4092.png"
    },
    {
      "chainId": 56,
      "name": "Dvision Network",
      "symbol": "DVI",
      "address": "0x758FB037A375F17c7e195CC634D77dA4F554255B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7590.png"
    },
    {
      "chainId": 56,
      "name": "Elrond",
      "symbol": "EGLD",
      "address": "0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png"
    },
    {
      "chainId": 56,
      "name": "EOS Token",
      "symbol": "EOS",
      "address": "0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1765.png"
    },
    {
      "chainId": 56,
      "name": "Ellipsis",
      "symbol": "EPS",
      "address": "0xA7f552078dcC247C2684336020c03648500C6d9F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8938.png"
    },
    {
      "chainId": 56,
      "name": "Ertha Token",
      "symbol": "ERTHA",
      "address": "0x62823659d09F9F9D2222058878f89437425eB261",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12294.png"
    },
    {
      "chainId": 56,
      "name": "CryptoMines Eternal",
      "symbol": "ETERNAL",
      "address": "0xD44FD09d74cd13838F137B590497595d6b3FEeA4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11736.png"
    },
    {
      "chainId": 56,
      "name": "Binance Pegged ETH",
      "symbol": "ETH",
      "address": "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 56,
      "name": "Easy V2",
      "symbol": "EZ",
      "address": "0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png"
    },
    {
      "chainId": 56,
      "name": "Fight Token",
      "symbol": "FIGHT",
      "address": "0x4f39c3319188A723003670c3F9B9e7EF991E52F3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16035.png"
    },
    {
      "chainId": 56,
      "name": "Filecoin",
      "symbol": "FIL",
      "address": "0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png"
    },
    {
      "chainId": 56,
      "name": "Defina Finance",
      "symbol": "FINA",
      "address": "0x426c72701833fdDBdFc06c944737C6031645c708",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12203.png"
    },
    {
      "chainId": 56,
      "name": "Refinable",
      "symbol": "FINE",
      "address": "0x4e6415a5727ea08aAE4580057187923aeC331227",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9269.png"
    },
    {
      "chainId": 56,
      "name": "Formation Finance",
      "symbol": "FORM",
      "address": "0x25A528af62e56512A19ce8c3cAB427807c28CC19",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5423.png"
    },
    {
      "chainId": 56,
      "name": "Frontier Token",
      "symbol": "FRONT",
      "address": "0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5893.png"
    },
    {
      "chainId": 56,
      "name": "Froyo",
      "symbol": "FROYO",
      "address": "0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17444.png"
    },
    {
      "chainId": 56,
      "name": "Fuel",
      "symbol": "FUEL",
      "address": "0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8659.png"
    },
    {
      "chainId": 56,
      "name": "Fuse Token",
      "symbol": "FUSE",
      "address": "0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5634.png"
    },
    {
      "chainId": 56,
      "name": "GoldMiner",
      "symbol": "GM",
      "address": "0xe2604C9561D490624AA35e156e65e590eB749519",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12967.png"
    },
    {
      "chainId": 56,
      "name": "GAMEE",
      "symbol": "GMEE",
      "address": "0x84e9a6F9D240FdD33801f7135908BfA16866939A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9103.png"
    },
    {
      "chainId": 56,
      "name": "Gamifi",
      "symbol": "GMI",
      "address": "0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17311.png"
    },
    {
      "chainId": 56,
      "name": "Green Metaverse Token",
      "symbol": "GMT",
      "address": "0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18069.png"
    },
    {
      "chainId": 56,
      "name": "Hacken",
      "symbol": "HAI",
      "address": "0xaA9E582e5751d703F85912903bacADdFed26484C",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5583.png"
    },
    {
      "chainId": 56,
      "name": "Hakka Finance",
      "symbol": "HAKKA",
      "address": "0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6622.png"
    },
    {
      "chainId": 56,
      "name": "HARD",
      "symbol": "HARD",
      "address": "0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4",
      "decimals": 6,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4.png"
    },
    {
      "chainId": 56,
      "name": "Helmet.insure",
      "symbol": "Helmet",
      "address": "0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8265.png"
    },
    {
      "chainId": 56,
      "name": "MetaHero",
      "symbol": "HERO",
      "address": "0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10778.png"
    },
    {
      "chainId": 56,
      "name": "StepHero",
      "symbol": "HERO",
      "address": "0xE8176d414560cFE1Bf82Fd73B986823B89E4F545",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11067.png"
    },
    {
      "chainId": 56,
      "name": "Hedget",
      "symbol": "HGET",
      "address": "0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731",
      "decimals": 6,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731.png"
    },
    {
      "chainId": 56,
      "name": "Highstreet Token",
      "symbol": "HIGH",
      "address": "0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11232.png"
    },
    {
      "chainId": 56,
      "name": "Hot Cross Token",
      "symbol": "HOTCROSS",
      "address": "0x4FA7163E153419E0E1064e418dd7A99314Ed27b6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9867.png"
    },
    {
      "chainId": 56,
      "name": "Hotbit",
      "symbol": "HTB",
      "address": "0x4e840AADD28DA189B9906674B4Afcb77C128d9ea",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x4e840AADD28DA189B9906674B4Afcb77C128d9ea.png"
    },
    {
      "chainId": 56,
      "name": "Horizon Protocol",
      "symbol": "HZN",
      "address": "0xC0eFf7749b125444953ef89682201Fb8c6A917CD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9237.png"
    },
    {
      "chainId": 56,
      "name": "Impossible Decentralized Incubator",
      "symbol": "IDIA",
      "address": "0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10933.png"
    },
    {
      "chainId": 56,
      "name": "Impossible Finance",
      "symbol": "IF",
      "address": "0xB0e1fc65C1a741b4662B813eB787d369b8614Af1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10932.png"
    },
    {
      "chainId": 56,
      "name": "Injective Protocol",
      "symbol": "INJ",
      "address": "0xa2B726B1145A4773F68593CF171187d8EBe4d495",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7226.png"
    },
    {
      "chainId": 56,
      "name": "Bsc-Peg INSUR Token",
      "symbol": "INSUR",
      "address": "0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8799.png"
    },
    {
      "chainId": 56,
      "name": "IoTeX",
      "symbol": "IOTX",
      "address": "0x9678E42ceBEb63F23197D726B29b1CB20d0064E5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png"
    },
    {
      "chainId": 56,
      "name": "Juggernaut Finance",
      "symbol": "JGN",
      "address": "0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6942.png"
    },
    {
      "chainId": 56,
      "name": "Juventus",
      "symbol": "JUV",
      "address": "0xC40C9A843E1c6D01b7578284a9028854f6683b1B",
      "decimals": 2,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xC40C9A843E1c6D01b7578284a9028854f6683b1B.png"
    },
    {
      "chainId": 56,
      "name": "Kalmar",
      "symbol": "KALM",
      "address": "0x4BA0057f784858a48fe351445C672FF2a3d43515",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10099.png"
    },
    {
      "chainId": 56,
      "name": "KAVA",
      "symbol": "KAVA",
      "address": "0x5F88AB06e8dfe89DF127B2430Bba4Af600866035",
      "decimals": 6,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x5F88AB06e8dfe89DF127B2430Bba4Af600866035.png"
    },
    {
      "chainId": 56,
      "name": "Kattana",
      "symbol": "KTN",
      "address": "0xDAe6c2A48BFAA66b43815c5548b10800919c993E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9110.png"
    },
    {
      "chainId": 56,
      "name": "Qian Governance Token",
      "symbol": "KUN",
      "address": "0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7721.png"
    },
    {
      "chainId": 56,
      "name": "FC Lazio Fan Token",
      "symbol": "LAZIO",
      "address": "0x77d547256A2cD95F32F67aE0313E450Ac200648d",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12687.png"
    },
    {
      "chainId": 56,
      "name": "Lien",
      "symbol": "LIEN",
      "address": "0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3",
      "decimals": 8,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3.png"
    },
    {
      "chainId": 56,
      "name": "Lightning",
      "symbol": "LIGHT",
      "address": "0x037838b556d9c9d654148a284682C55bB5f56eF4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8801.png"
    },
    {
      "chainId": 56,
      "name": "Linear Finance",
      "symbol": "LINA",
      "address": "0x762539b45A1dCcE3D36d080F74d1AED37844b878",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7102.png"
    },
    {
      "chainId": 56,
      "name": "ChainLink Token",
      "symbol": "LINK",
      "address": "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 56,
      "name": "Litentry",
      "symbol": "LIT",
      "address": "0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6833.png"
    },
    {
      "chainId": 56,
      "name": "League Of Ancients",
      "symbol": "LOA",
      "address": "0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15870.png"
    },
    {
      "chainId": 56,
      "name": "Litecoin Token",
      "symbol": "LTC",
      "address": "0x4338665CBB7B2485A8855A139b75D5e34AB0DB94",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2.png"
    },
    {
      "chainId": 56,
      "name": "LTO Network",
      "symbol": "LTO",
      "address": "0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3714.png"
    },
    {
      "chainId": 56,
      "name": "lUSD",
      "symbol": "lUSD",
      "address": "0x23e8a70534308a4AAF76fb8C32ec13d17a3BD89e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19714.png"
    },
    {
      "chainId": 56,
      "name": "Mirror AMZN Token",
      "symbol": "mAMZN",
      "address": "0x3947B992DC0147D2D89dF0392213781b04B25075",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x3947B992DC0147D2D89dF0392213781b04B25075.png"
    },
    {
      "chainId": 56,
      "name": "Unmarshal",
      "symbol": "MARSH",
      "address": "0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8963.png"
    },
    {
      "chainId": 56,
      "name": "Mask Network",
      "symbol": "MASK",
      "address": "0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8536.png"
    },
    {
      "chainId": 56,
      "name": "Math",
      "symbol": "MATH",
      "address": "0xF218184Af829Cf2b0019F8E6F0b2423498a36983",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5616.png"
    },
    {
      "chainId": 56,
      "name": "Mobox",
      "symbol": "MBOX",
      "address": "0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9175.png"
    },
    {
      "chainId": 56,
      "name": "MCDEX",
      "symbol": "MCB",
      "address": "0x5fE80d2CD054645b9419657d3d10d26391780A7B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5956.png"
    },
    {
      "chainId": 56,
      "name": "Mirror COIN",
      "symbol": "mCOIN",
      "address": "0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f.png"
    },
    {
      "chainId": 56,
      "name": "MacaronSwap",
      "symbol": "MCRN",
      "address": "0xacb2d47827C9813AE26De80965845D80935afd0B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8880.png"
    },
    {
      "chainId": 56,
      "name": "Mirror GOOGL Token",
      "symbol": "mGOOGL",
      "address": "0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f.png"
    },
    {
      "chainId": 56,
      "name": "Mirror Finance",
      "symbol": "MIR",
      "address": "0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7857.png"
    },
    {
      "chainId": 56,
      "name": "Mirror NFLX Token",
      "symbol": "mNFLX",
      "address": "0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc.png"
    },
    {
      "chainId": 56,
      "name": "Monsta Infinite",
      "symbol": "MONI",
      "address": "0x9573c88aE3e37508f87649f87c4dd5373C9F31e0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11925.png"
    },
    {
      "chainId": 56,
      "name": "Meter",
      "symbol": "MTRG",
      "address": "0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6628.png"
    },
    {
      "chainId": 56,
      "name": "Mirror TSLA Token",
      "symbol": "mTSLA",
      "address": "0xF215A127A196e3988C09d052e16BcFD365Cd7AA3",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xF215A127A196e3988C09d052e16BcFD365Cd7AA3.png"
    },
    {
      "chainId": 56,
      "name": "MX Token",
      "symbol": "MX",
      "address": "0x9F882567A62a5560d147d64871776EeA72Df41D3",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x9F882567A62a5560d147d64871776EeA72Df41D3.png"
    },
    {
      "chainId": 56,
      "name": "Nabox Token",
      "symbol": "NABOX",
      "address": "0x755f34709E369D37C6Fa52808aE84A32007d1155",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9653.png"
    },
    {
      "chainId": 56,
      "name": "NAOS Finance",
      "symbol": "NAOS",
      "address": "0x758d08864fB6cCE3062667225ca10b8F00496cc2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9504.png"
    },
    {
      "chainId": 56,
      "name": "APENFT",
      "symbol": "NFT",
      "address": "0x1fC9004eC7E5722891f5f38baE7678efCB11d34D",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9816.png"
    },
    {
      "chainId": 56,
      "name": "Nerve Finance",
      "symbol": "NRV",
      "address": "0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8755.png"
    },
    {
      "chainId": 56,
      "name": "Nuls",
      "symbol": "NULS",
      "address": "0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2092.png"
    },
    {
      "chainId": 56,
      "name": "NerveNetwork",
      "symbol": "NVT",
      "address": "0xf0E406c49C63AbF358030A299C0E00118C4C6BA5",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5906.png"
    },
    {
      "chainId": 56,
      "name": "Oddz",
      "symbol": "ODDZ",
      "address": "0xCD40F2670CF58720b694968698A5514e924F742d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8717.png"
    },
    {
      "chainId": 56,
      "name": "OG",
      "symbol": "OG",
      "address": "0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c",
      "decimals": 2,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c.png"
    },
    {
      "chainId": 56,
      "name": "Oin Finance",
      "symbol": "OIN",
      "address": "0x658E64FFcF40D240A43D52CA9342140316Ae44fA",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6870.png"
    },
    {
      "chainId": 56,
      "name": "Harmony One",
      "symbol": "ONE",
      "address": "0x03fF0ff224f904be3118461335064bB48Df47938",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11696.png"
    },
    {
      "chainId": 56,
      "name": "BigOne Token",
      "symbol": "ONE",
      "address": "0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2324.png"
    },
    {
      "chainId": 56,
      "name": "Ontology Token",
      "symbol": "ONT",
      "address": "0xFd7B3A77848f1C2D67E05E54d78d174a0C850335",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2566.png"
    },
    {
      "chainId": 56,
      "name": "pBTC",
      "symbol": "pBTC",
      "address": "0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5434.png"
    },
    {
      "chainId": 56,
      "name": "Perlin X",
      "symbol": "PERL",
      "address": "0x0F9E4D49f25de22c2202aF916B681FBB3790497B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4293.png"
    },
    {
      "chainId": 56,
      "name": "Phala Network",
      "symbol": "PHA",
      "address": "0x0112e557d400474717056C4e6D40eDD846F38351",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x0112e557d400474717056C4e6D40eDD846F38351.png"
    },
    {
      "chainId": 56,
      "name": "Polkamon",
      "symbol": "PMON",
      "address": "0x1796ae0b0fa4862485106a0de9b654eFE301D0b2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8968.png"
    },
    {
      "chainId": 56,
      "name": "PNT",
      "symbol": "PNT",
      "address": "0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5794.png"
    },
    {
      "chainId": 56,
      "name": "pTokens OPEN",
      "symbol": "pOPEN",
      "address": "0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7783.png"
    },
    {
      "chainId": 56,
      "name": "FC Porto Fan Token",
      "symbol": "PORTO",
      "address": "0x49f2145d6366099e13B10FbF80646C0F377eE7f6",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14052.png"
    },
    {
      "chainId": 56,
      "name": "Prometeus",
      "symbol": "PROM",
      "address": "0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4120.png"
    },
    {
      "chainId": 56,
      "name": "Prosper",
      "symbol": "PROS",
      "address": "0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8255.png"
    },
    {
      "chainId": 56,
      "name": "Paris Saint-Germain",
      "symbol": "PSG",
      "address": "0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1",
      "decimals": 2,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1.png"
    },
    {
      "chainId": 56,
      "name": "Qubit Token",
      "symbol": "QBT",
      "address": "0x17B7163cf1Dbd286E262ddc68b553D899B93f526",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11414.png"
    },
    {
      "chainId": 56,
      "name": "BENQI",
      "symbol": "QI",
      "address": "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9288.png"
    },
    {
      "chainId": 56,
      "name": "QIAN second generation dollar",
      "symbol": "QSD",
      "address": "0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11430.png"
    },
    {
      "chainId": 56,
      "name": "Quidd Token",
      "symbol": "QUIDD",
      "address": "0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12070.png"
    },
    {
      "chainId": 56,
      "name": "Rabbit Finance",
      "symbol": "RABBIT",
      "address": "0x95a1199EBA84ac5f19546519e287d43D2F0E1b41",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10178.png"
    },
    {
      "chainId": 56,
      "name": "Radio Caca V2",
      "symbol": "RACA",
      "address": "0x12BB890508c125661E03b09EC06E404bc9289040",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11346.png"
    },
    {
      "chainId": 56,
      "name": "Reef",
      "symbol": "REEF",
      "address": "0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6951.png"
    },
    {
      "chainId": 56,
      "name": "renBTC",
      "symbol": "renBTC",
      "address": "0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5777.png"
    },
    {
      "chainId": 56,
      "name": "REVV",
      "symbol": "REVV",
      "address": "0x833F307aC507D47309fD8CDD1F835BeF8D702a93",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6993.png"
    },
    {
      "chainId": 56,
      "name": "RFOX",
      "symbol": "RFOX",
      "address": "0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7654.png"
    },
    {
      "chainId": 56,
      "name": "Rangers Protocol",
      "symbol": "RPG",
      "address": "0xc2098a8938119A52B1F7661893c0153A6CB116d5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12221.png"
    },
    {
      "chainId": 56,
      "name": "FC Santos Fan Token",
      "symbol": "SANTOS",
      "address": "0xA64455a4553C9034236734FadDAddbb64aCE4Cc7",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15248.png"
    },
    {
      "chainId": 56,
      "name": "Singularity Dao",
      "symbol": "SDAO",
      "address": "0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9638.png"
    },
    {
      "chainId": 56,
      "name": "SafePal Token",
      "symbol": "SFP",
      "address": "0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8119.png"
    },
    {
      "chainId": 56,
      "name": "Seedify",
      "symbol": "SFUND",
      "address": "0x477bC8d23c634C154061869478bce96BE6045D12",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8972.png"
    },
    {
      "chainId": 56,
      "name": "Sheesha Finance",
      "symbol": "SHEESHA",
      "address": "0x232FB065D9d24c34708eeDbF03724f2e95ABE768",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10337.png"
    },
    {
      "chainId": 56,
      "name": "CryptoBlades Skill Token",
      "symbol": "SKILL",
      "address": "0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9654.png"
    },
    {
      "chainId": 56,
      "name": "SPARTAN PROTOCOL TOKEN",
      "symbol": "SPARTA",
      "address": "0x3910db0600eA925F63C36DdB1351aB6E2c6eb102",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6992.png"
    },
    {
      "chainId": 56,
      "name": "Splintershards",
      "symbol": "SPS",
      "address": "0x1633b7157e7638C4d6593436111Bf125Ee74703F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11035.png"
    },
    {
      "chainId": 56,
      "name": "Sushi",
      "symbol": "SUSHI",
      "address": "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
      "chainId": 56,
      "name": "Suterusu",
      "symbol": "SUTER",
      "address": "0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4841.png"
    },
    {
      "chainId": 56,
      "name": "Switcheo",
      "symbol": "SWTH",
      "address": "0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2620.png"
    },
    {
      "chainId": 56,
      "name": "Swipe",
      "symbol": "SXP",
      "address": "0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4279.png"
    },
    {
      "chainId": 56,
      "name": "TemplarDAO",
      "symbol": "TEM",
      "address": "0x19e6BfC1A6e4B042Fb20531244D47E252445df01",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15288.png"
    },
    {
      "chainId": 56,
      "name": "Thetan Gem",
      "symbol": "THG",
      "address": "0x9fD87aEfe02441B123c3c32466cD9dB4c578618f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11926.png"
    },
    {
      "chainId": 56,
      "name": "TitanSwap",
      "symbol": "TITAN",
      "address": "0xe898EDc43920F357A93083F1d4460437dE6dAeC2",
      "decimals": 18,
      "logoURI": "https://tokens.pancakeswap.finance/images/0xe898EDc43920F357A93083F1d4460437dE6dAeC2.png"
    },
    {
      "chainId": 56,
      "name": "TokoCrypto",
      "symbol": "TKO",
      "address": "0x9f589e3eabe42ebC94A44727b3f3531C0c877809",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9020.png"
    },
    {
      "chainId": 56,
      "name": "Alien Worlds",
      "symbol": "TLM",
      "address": "0x2222227E22102Fe3322098e4CBfE18cFebD57c95",
      "decimals": 4,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9119.png"
    },
    {
      "chainId": 56,
      "name": "Telos",
      "symbol": "TLOS",
      "address": "0xb6C53431608E626AC81a9776ac3e999c5556717c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4660.png"
    },
    {
      "chainId": 56,
      "name": "TokenPocket",
      "symbol": "TPT",
      "address": "0xECa41281c24451168a37211F0bc2b8645AF45092",
      "decimals": 4,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5947.png"
    },
    {
      "chainId": 56,
      "name": "Tron",
      "symbol": "TRX",
      "address": "0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png"
    },
    {
      "chainId": 56,
      "name": "Thunder Token",
      "symbol": "TT",
      "address": "0x990E7154bB999FAa9b2fa5Ed29E822703311eA85",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3930.png"
    },
    {
      "chainId": 56,
      "name": "True USD",
      "symbol": "TUSD",
      "address": "0x14016E85a25aeb13065688cAFB43044C2ef86784",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png"
    },
    {
      "chainId": 56,
      "name": "Trust Wallet",
      "symbol": "TWT",
      "address": "0x4B0F1812e5Df2A09796481Ff14017e6005508003",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5964.png"
    },
    {
      "chainId": 56,
      "name": "Tixl",
      "symbol": "TXL",
      "address": "0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7024.png"
    },
    {
      "chainId": 56,
      "name": "Unifi Token",
      "symbol": "UNFI",
      "address": "0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7672.png"
    },
    {
      "chainId": 56,
      "name": "Uniswap",
      "symbol": "UNI",
      "address": "0xBf5140A22578168FD562DCcF235E5D43A02ce9B1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      "chainId": 56,
      "name": "Binance Pegged USD Coin",
      "symbol": "USDC",
      "address": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 56,
      "name": "Binance Pegged USDT",
      "symbol": "USDT",
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 56,
      "name": "UST Token",
      "symbol": "UST",
      "address": "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png"
    },
    {
      "chainId": 56,
      "name": "VAI Stablecoin",
      "symbol": "VAI",
      "address": "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7824.png"
    },
    {
      "chainId": 56,
      "name": "WBNB Token",
      "symbol": "WBNB",
      "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png"
    },
    {
      "chainId": 56,
      "name": "BitWell Token",
      "symbol": "WELL",
      "address": "0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7883.png"
    },
    {
      "chainId": 56,
      "name": "WINk",
      "symbol": "WIN",
      "address": "0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4206.png"
    },
    {
      "chainId": 56,
      "name": "Wrapped MASS",
      "symbol": "WMASS",
      "address": "0x7e396BfC8a2f84748701167c2d622F041A1D7a17",
      "decimals": 8,
      "logoURI": "https://tokens.pancakeswap.finance/images/0x7e396BfC8a2f84748701167c2d622F041A1D7a17.png"
    },
    {
      "chainId": 56,
      "name": "Wootrade",
      "symbol": "WOO",
      "address": "0x4691937a7508860F876c9c0a2a617E7d9E945D4B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7501.png"
    },
    {
      "chainId": 56,
      "name": "Woonkly Power",
      "symbol": "WOOP",
      "address": "0x8b303d5BbfBbf46F1a4d9741E491e06986894e18",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8937.png"
    },
    {
      "chainId": 56,
      "name": "Wall Street Games",
      "symbol": "WSG",
      "address": "0xA58950F05FeA2277d2608748412bf9F802eA4901",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10040.png"
    },
    {
      "chainId": 56,
      "name": "Xcademy",
      "symbol": "XCAD",
      "address": "0x431e0cD023a32532BF3969CddFc002c00E98429d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9868.png"
    },
    {
      "chainId": 56,
      "name": "Exeedme",
      "symbol": "XED",
      "address": "0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8163.png"
    },
    {
      "chainId": 56,
      "name": "XEND",
      "symbol": "XEND",
      "address": "0x4a080377f83D669D7bB83B3184a8A5E61B500608",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8519.png"
    },
    {
      "chainId": 56,
      "name": "xMARK",
      "symbol": "xMARK",
      "address": "0x26A5dFab467d4f58fB266648CAe769503CEC9580",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8835.png"
    },
    {
      "chainId": 56,
      "name": "Mars Ecosystem",
      "symbol": "XMS",
      "address": "0x7859B01BbF675d67Da8cD128a50D155cd881B576",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10030.png"
    },
    {
      "chainId": 56,
      "name": "XRP Token",
      "symbol": "XRP",
      "address": "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/52.png"
    },
    {
      "chainId": 56,
      "name": "Tezos Token",
      "symbol": "XTZ",
      "address": "0x16939ef78684453bfDFb47825F8a5F714f12623a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png"
    },
    {
      "chainId": 56,
      "name": "Venus Token",
      "symbol": "XVS",
      "address": "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7288.png"
    },
    {
      "chainId": 56,
      "name": "X World Games",
      "symbol": "XWG",
      "address": "0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9461.png"
    },
    {
      "chainId": 56,
      "name": "yearn.finance",
      "symbol": "YFI",
      "address": "0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png"
    },
    {
      "chainId": 56,
      "name": "YFII.finance Token",
      "symbol": "YFII",
      "address": "0x7F70642d88cf1C4a3a7abb072B53B929b653edA5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5957.png"
    },
    {
      "chainId": 56,
      "name": "Zcash Token",
      "symbol": "ZEC",
      "address": "0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png"
    },
    {
      "chainId": 56,
      "name": "ZeroSwapToken",
      "symbol": "ZEE",
      "address": "0x44754455564474A89358B2C2265883DF993b12F0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7438.png"
    },
    {
      "chainId": 56,
      "name": "Zilliqa",
      "symbol": "ZIL",
      "address": "0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787",
      "decimals": 12,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png"
    },
    {
      "chainId": 56,
      "name": "C98",
      "symbol": "C98",
      "address": "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10903.png"
    },
    {
      "chainId": 56,
      "name": "Cellframe Token",
      "symbol": "CELL",
      "address": "0xf3E1449DDB6b218dA2C9463D4594CEccC8934346",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8992.png"
    },
    {
      "chainId": 56,
      "name": "Go Crypto Me",
      "symbol": "GCME",
      "address": "0x9528cCEb678B90dAf02cA5cA45622D5cBaF58A30",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18363.png"
    },
    {
      "chainId": 56,
      "name": "Deeper Network",
      "symbol": "DPR",
      "address": "0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8894.png"
    },
    {
      "chainId": 56,
      "name": "FLOKI",
      "symbol": "FLOKI",
      "address": "0xfb5B838b6cfEEdC2873aB27866079AC55363D37E",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10804.png"
    },
    {
      "chainId": 56,
      "name": "API Inu",
      "symbol": "API",
      "address": "0xA1943Bc4a417ffd2E9e11A97383fa3f9548291c3",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19833.png"
    },
    {
      "chainId": 56,
      "name": "Green Satoshi Token",
      "symbol": "GST",
      "address": "0x4a2c860cEC6471b9F5F5a336eB4F38bb21683c98",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16352.png"
    },
    {
      "chainId": 56,
      "name": "SONIC INU",
      "symbol": "SONIC",
      "address": "0x7E24D548438100f1fA9cf2CFAc2aD09516440f02",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19861.png"
    },
    {
      "chainId": 56,
      "name": "Movey Token",
      "symbol": "MOVEY",
      "address": "0x2b511AA476213E9081Dd6A59a3739f0CB9d01162",
      "decimals": 5,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19741.png"
    },
    {
      "chainId": 56,
      "name": "Firework Games",
      "symbol": "FIRE",
      "address": "0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19825.png"
    },
    {
      "chainId": 56,
      "name": "Magic Internet Money",
      "symbol": "MIM",
      "address": "0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/162.png"
    },
    {
      "chainId": 56,
      "name": "Centcex",
      "symbol": "CENX",
      "address": "0x739e81bcd49854d7bdf526302989f14a2e7994b2",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14849.png"
    },
    {
      "chainId": 56,
      "name": "Baby Doge Coin",
      "symbol": "BabyDoge",
      "address": "0xc748673057861a797275CD8A068AbB95A902e8de",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10407.png"
    },
    {
      "chainId": 56,
      "name": "MIOTAC",
      "symbol": "IOTA",
      "address": "0xd944f1D1e9d5f9Bb90b62f9D45e447D989580782",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1720.png"
    },
    {
      "chainId": 56,
      "name": "CATCOIN",
      "symbol": "CATS",
      "address": "0x2f0c6e147974BfbF7Da557b88643D74C324053A2",
      "decimals": 0,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17318.png"
    },
    {
      "chainId": 56,
      "name": "Frax Share",
      "symbol": "FXS",
      "address": "0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png"
    },
    {
      "chainId": 56,
      "name": "Hamster",
      "symbol": "HAM",
      "address": "0x679D5b2d94f454c950d683D159b87aa8eae37C9e",
      "decimals": 7,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10336.png"
    },
    {
      "chainId": 56,
      "name": "Xodex",
      "symbol": "XODEX",
      "address": "0xddD9d7D280c78b564C55BEb26562E367d4b9Bd8a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19932.png"
    },
    {
      "chainId": 56,
      "name": "Synapse",
      "symbol": "SYN",
      "address": "0xa4080f1778e69467E905B8d6F72f6e441f9e9484",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png"
    },
    {
      "chainId": 56,
      "name": "WeWay Token",
      "symbol": "WWY",
      "address": "0x9Ab70e92319f0b9127df78868Fd3655fB9f1E322",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17047.png"
    },
    {
      "chainId": 56,
      "name": "LUCA",
      "symbol": "LUCA",
      "address": "0x51E6Ac1533032E72e92094867fD5921e3ea1bfa0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15736.png"
    },
    {
      "chainId": 56,
      "name": "Orion Protocol",
      "symbol": "ORN",
      "address": "0xe4CA1F75ECA6214393fCE1C1b316C237664EaA8e",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5631.png"
    },
    {
      "chainId": 56,
      "name": "Gala",
      "symbol": "GALA",
      "address": "0x7ddee176f665cd201f93eede625770e2fd911990",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7080.png"
    },
    {
      "chainId": 56,
      "name": "CEEK VR",
      "symbol": "CEEK",
      "address": "0xe0f94ac5462997d2bc57287ac3a3ae4c31345d66",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2856.png"
    },
    {
      "chainId": 56,
      "name": "Chromia",
      "symbol": "CHR",
      "address": "0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png"
    },
    {
      "chainId": 56,
      "name": "SuperFarm",
      "symbol": "SUPER",
      "address": "0x51ba0b044d96c3abfca52b64d733603ccc4f0d4d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8290.png"
    },
    {
      "chainId": 56,
      "name": "YooShi",
      "symbol": "YOOSHI",
      "address": "0x02fF5065692783374947393723dbA9599e59F591",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9892.png"
    },
    {
      "chainId": 56,
      "name": "BabySwap",
      "symbol": "BABY",
      "address": "0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10334.png"
    },
    {
      "chainId": 56,
      "name": "GameFi",
      "symbol": "GAFI",
      "address": "0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11783.png"
    },
    {
      "chainId": 56,
      "name": "Dotmoovs",
      "symbol": "MOOV",
      "address": "0x0ebd9537A25f56713E34c45b38F421A1e7191469",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10046.png"
    },
    {
      "chainId": 56,
      "name": "DEEPSPACE",
      "symbol": "DPS",
      "address": "0xf275e1AC303a4C9D987a2c48b8E555A77FeC3F1C",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11437.png"
    },
    {
      "chainId": 56,
      "name": "Kryptomon",
      "symbol": "KMON",
      "address": "0xc732B6586A93b6B7CF5FeD3470808Bc74998224D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11291.png"
    },
    {
      "chainId": 56,
      "name": "Rubic",
      "symbol": "RBC",
      "address": "0x8E3BCC334657560253B83f08331d85267316e08a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7219.png"
    },
    {
      "chainId": 56,
      "name": "Catgirl",
      "symbol": "CATGIRL",
      "address": "0x79eBC9A2ce02277A4b5b3A768b1C0A4ed75Bd936",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10275.png"
    },
    {
      "chainId": 56,
      "name": "SafePal",
      "symbol": "SFP",
      "address": "0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8119.png"
    },
    {
      "chainId": 56,
      "name": "Project Galaxy",
      "symbol": "GAL",
      "address": "0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11877.png"
    },
    {
      "chainId": 56,
      "name": "Chromia",
      "symbol": "CHR",
      "address": "0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png"
    },
    {
      "chainId": 56,
      "name": "1inch Network",
      "symbol": "1INCH",
      "address": "0x111111111117dC0aa78b770fA6A738034120C302",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png"
    },
    {
      "chainId": 56,
      "name": "Adshares",
      "symbol": "ADS",
      "address": "0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A",
      "decimals": 11,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1883.png"
    },
    {
      "chainId": 56,
      "name": "BinaryX",
      "symbol": "BNX",
      "address": "0x8C851d1a123Ff703BD1f9dabe631b69902Df5f97",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9891.png"
    },
    {
      "chainId": 56,
      "name": "Vita Inu",
      "symbol": "VINU",
      "address": "0xfEbe8C1eD424DbF688551D4E2267e7A53698F0aa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15270.png"
    },
    {
      "chainId": 56,
      "name": "iMe Lab",
      "symbol": "LIME",
      "address": "0x7bC75e291E656E8658D66Be1cc8154A3769A35Dd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10469.png"
    },
    {
      "chainId": 56,
      "name": "Aave Token",
      "symbol": "AAVE",
      "address": "0xfb6115445bff7b52feb98650c87f44907e58f802",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"
    },
    {
      "chainId": 56,
      "name": "Fantom",
      "symbol": "FTM",
      "address": "0xad29abb318791d579433d831ed122afeaf29dcfe",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png"
    },
    {
      "chainId": 56,
      "name": "Synthetix Network Token",
      "symbol": "SNX",
      "address": "0x9Ac983826058b8a9C7Aa1C9171441191232E8404",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png"
    },
    {
      "chainId": 56,
      "name": "MATIC Token",
      "symbol": "MATIC",
      "address": "0xCC42724C6683B7E57334c4E856f4c9965ED682bD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
      "chainId": 56,
      "name": "MAKER Token",
      "symbol": "MKR",
      "address": "0x5f0Da599BB2ccCfcf6Fdfd7D81743B6020864350",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png"
    },
    {
      "chainId": 56,
      "name": "PinkSale",
      "symbol": "PINKSALE",
      "address": "0x602bA546A7B06e0FC7f58fD27EB6996eCC824689",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12566.png"
    },
    {
      "chainId": 56,
      "name": "Pitbull",
      "symbol": "PIT",
      "address": "0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9177.png"
    },
    {
      "chainId": 56,
      "name": "Volt Inu V2",
      "symbol": "VOLT",
      "address": "0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19650.png"
    },
    {
      "chainId": 56,
      "name": "Zombie Inu",
      "symbol": "ZINU",
      "address": "0x21F9B5b2626603e3F40bfc13d01AfB8c431D382F",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13382.png"
    },
    {
      "chainId": 56,
      "name": "PureFi Protocol",
      "symbol": "UFI",
      "address": "0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10973.png"
    },
    {
      "chainId": 56,
      "name": "Bolide",
      "symbol": "BLID",
      "address": "0x766AFcf83Fd5eaf884B3d529b432CA27A6d84617",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20765.png"
    },
    {
      "chainId": 56,
      "name": "Stargate Finance",
      "symbol": "STG",
      "address": "0xB0D502E938ed5f4df2E681fE6E419ff29631d62b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18934.png"
    },
    {
      "chainId": 56,
      "name": "CumRocket",
      "symbol": "CUMMIES",
      "address": "0x27Ae27110350B98d564b9A3eeD31bAeBc82d878d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9212.png"
    },
    {
      "chainId": 56,
      "name": "CateCoin",
      "symbol": "CATE",
      "address": "0xE4FAE3Faa8300810C835970b9187c268f55D998F",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9656.png"
    },
    {
      "chainId": 56,
      "name": "Zelda Inu",
      "symbol": "ZLDA",
      "address": "0x26a7546c8f5e5f706cb598CAA68134f6eCf8d657",
      "decimals": 4,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16760.png"
    },
    {
      "chainId": 56,
      "name": "Wombat Exchange",
      "symbol": "WOM",
      "address": "0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19623.png"
    },
    {
      "chainId": 56,
      "name": "Occam.Fi",
      "symbol": "OCC",
      "address": "0x2a4DFfa1Fa0F86CE7f0982F88Aecc199FB3476bc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9191.png"
    },
    {
      "chainId": 56,
      "name": "XDAO",
      "symbol": "XDAO",
      "address": "0x71eebA415A523F5C952Cc2f06361D5443545Ad28",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21760.png"
    },
    {
      "chainId": 56,
      "name": "Wrapped TON Coin",
      "symbol": "WTON",
      "address": "0x76A797A59Ba2C17726896976B7B3747BfD1d220f",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png"
    },
    {
      "chainId": 56,
      "name": "Alpine F1 Team Fan Token",
      "symbol": "ALPINE",
      "address": "0x287880Ea252b52b63Cc5f40a2d3E5A44aa665a76",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18112.png"
    },
    {
      "chainId": 56,
      "name": "Manchester City Fan Token",
      "symbol": "CITY",
      "address": "0xEE77861fd75a7535C6B4929a66754816fc9C797B",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10049.png"
    },
    {
      "chainId": 56,
      "name": "Symbiosis",
      "symbol": "SIS",
      "address": "0xF98b660AdF2ed7d9d9D9dAACC2fb0CAce4F21835",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15084.png"
    },
    {
      "chainId": 56,
      "name": "Frax",
      "symbol": "FRAX",
      "address": "0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    },
    {
      "chainId": 56,
      "name": "Radiant Capital",
      "symbol": "RDNT",
      "address": "0xf7de7e8a6bd59ed41a4b5fe50278b3b7f31384df",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/21106.png"
    },
    {
      "chainId": 56,
      "name": "CyberConnect",
      "symbol": "CYBER",
      "address": "0x14778860e937f509e651192a90589de711fb88a9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24781.png"
    },
    {
      "chainId": 56,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 56,
      "name": "First Digital USD",
      "symbol": "FDUSD",
      "address": "0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/26081.png"
    },
    {
      "chainId": 56,
      "name": "TrueUSD",
      "symbol": "TUSD",
      "address": "0x40af3827F39D0EAcBF4A168f8D4ee67c121D11c9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png"
    },
    {
      "chainId": 56,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0x2416092f143378750bb29b79eD961ab195CcEea5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 56,
      "name": "Staked Frax Ether",
      "symbol": "sfrxETH",
      "address": "0x3Cd55356433C89E50DC51aB07EE0fa0A95623D53",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23177.png"
    },
    {
      "chainId": 56,
      "name": "pSTAKE Finance",
      "symbol": "PSTAKE",
      "address": "0x4c882ec256823ee773b25b414d36f92ef58a7c0c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15996.png"
    },
    {
      "chainId": 56,
      "address": "0x6ad9e9c098a45b2b41b519119c31c3dcb02accb2",
      "symbol": "PZP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1721219821700_6144257007522838.png",
      "name": "PlayZap"
    },
    {
      "chainId": 56,
      "address": "0x193f4A4a6ea24102F49b931DEeeb931f6E32405d",
      "symbol": "TLOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719399725923_13445977406217557.png",
      "name": "Telos"
    },
    {
      "chainId": 56,
      "address": "0xfceb31a79f71ac9cbdcf853519c1b12d379edc46",
      "symbol": "Lista",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719398042832_8112509109766302.png",
      "name": "Lista DAO"
    },
    {
      "chainId": 56,
      "address": "0x8ea5219a16c2dbF1d6335A6aa0c6bd45c50347C5",
      "symbol": "OOE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1666922406718_40623932820124664.png",
      "name": "OpenOcean"
    },
    {
      "chainId": 56,
      "address": "0xB7E2713CF55cf4b469B5a8421Ae6Fc0ED18F1467",
      "symbol": "OLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712737385613_5033866723659204.png",
      "name": "OpenLeverage Token V2"
    },
    {
      "chainId": 56,
      "address": "0x267E0C7456dF5254492127eA7b2e14e556B492B8",
      "symbol": "RVF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1711084268280_43561682248292244.jpg",
      "name": "RocketX Exchange V2"
    },
    {
      "chainId": 56,
      "address": "0xf48f91df403976060cc05dbbf8a0901b09fdefd4",
      "symbol": "Minu",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1708980043911_24176891326005867.jpg",
      "name": "Minu"
    },
    {
      "chainId": 56,
      "address": "0xbb2826ab03b6321e170f0558804f2b6488c98775",
      "symbol": "BABYBONK",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1705510680823_5361988977714918.png",
      "name": "BABYBONK"
    },
    {
      "chainId": 56,
      "address": "0x551897f8203bd131b350601d3ac0679ba0fc0136",
      "symbol": "NFP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1703988649950_17766228650274218.png",
      "name": "NFPrompt "
    },
    {
      "chainId": 56,
      "address": "0x5ca718e2c0f2e873b8de38b02ad0497e8ac38ecb",
      "symbol": "C-DAO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1696702466577_27095792331957.png",
      "name": "Cyber-DAO"
    },
    {
      "chainId": 56,
      "address": "0x04756126f044634c9a0f0e985e60c88a51acc206",
      "symbol": "CSIX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1695133407985_8145298767961895.jpg",
      "name": "Carbon Browser"
    },
    {
      "chainId": 56,
      "address": "0xb3ed0a426155b79b898849803e3b36552f7ed507",
      "symbol": "PENDLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692867050905_4618511207418101.jpg",
      "name": "Pendle"
    },
    {
      "chainId": 56,
      "address": "0xfdc66a08b0d0dc44c17bbd471b88f49f50cdd20f",
      "symbol": "SDEX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692240843830_19589586202212206.jpg",
      "name": "SmarDex"
    },
    {
      "chainId": 56,
      "address": "0x8888888888f004100C0353d657BE6300587A6CcD",
      "symbol": "ACS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692153391560_26917689358922914.png",
      "name": "ACryptoS"
    },
    {
      "chainId": 56,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "IUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691566666177_1166892795463288.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 56,
      "address": "0x872a34ebb2d54af86827810eebc7b9dc6b2144aa",
      "symbol": "RVF_v1",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1689584423502_9888942278243307.jpg",
      "name": "RocketX exchange V1"
    },
    {
      "chainId": 56,
      "address": "0xd691d9a68c887bdf34da8c36f63487333acfd103",
      "symbol": "MAV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1688011577460_34962692731880773.png",
      "name": "Maverick Protocol"
    },
    {
      "chainId": 56,
      "address": "0xa3870fbBeb730BA99e4107051612af3465CA9F5e",
      "symbol": "STABLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1687172014042_9624216147947688.png",
      "name": "STABLE"
    },
    {
      "chainId": 56,
      "address": "0x11A38e06699b238D6D9A0C7A01f3AC63a07ad318",
      "symbol": "USDFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1687258481374_8229506030200269.jpg",
      "name": "USDFI"
    },
    {
      "chainId": 56,
      "address": "0x4268b8f0b87b6eae5d897996e6b845ddbd99adf3",
      "symbol": "AXLUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1685934473511_46586889499097994.jpg",
      "name": "Axelar USDC"
    },
    {
      "chainId": 56,
      "address": "0x2297aEbD383787A160DD0d9F71508148769342E3",
      "symbol": "BTC.B",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1685363045413_6238515342623994.png",
      "name": "BTC.B(OFT)"
    },
    {
      "chainId": 56,
      "address": "0x95c91eef65f50570cfc3f269961a00108cf7bf59",
      "symbol": "DONS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684407217731_8224625873384337.jpg",
      "name": "The Dons"
    },
    {
      "chainId": 56,
      "address": "0x5cd0c2c744caf04cda258efc6558a3ed3defe97b",
      "symbol": "CZR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684218765142_10120600705099769.png",
      "name": "CZRed"
    },
    {
      "chainId": 56,
      "address": "0xe68b79e51bf826534ff37aa9cee71a3842ee9c70",
      "symbol": "CZUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684212136987_2150157940496935.png",
      "name": "CZUSD"
    },
    {
      "chainId": 56,
      "address": "0x5335e87930b410b8c5bb4d43c3360aca15ec0c8c",
      "symbol": "USDT(Overnight)",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683711079008_4333458390248488.jpg",
      "name": "Overnight.fi USDT "
    },
    {
      "chainId": 56,
      "address": "0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65",
      "symbol": "USD",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683710204370_258692266103274.JPG",
      "name": "Overnight.fi USD  "
    },
    {
      "chainId": 56,
      "address": "0x64048a7eecf3a2f1ba9e144aac3d7db6e58f555e",
      "symbol": "FRXETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683515165027_04335089626782751.jpg",
      "name": "Frax Ether"
    },
    {
      "chainId": 56,
      "address": "0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5",
      "symbol": "HAY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683367049899_28087826414564554.jpg",
      "name": "Destablecoin HAY"
    },
    {
      "chainId": 56,
      "address": "0xf33893de6eb6ae9a67442e066ae9abd228f5290c",
      "symbol": "GRV",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681183267288_9630165967657189.png",
      "name": "GroveCoin"
    },
    {
      "chainId": 56,
      "address": "0x2dff88a56767223a5529ea5960da7a3f5f766406",
      "symbol": "ID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679537689436_08350939087172571.png",
      "name": "SPACE ID"
    },
    {
      "chainId": 56,
      "address": "0x56d06a78ef8e95d6043341f24759e2834be6f97b",
      "symbol": "DZOO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678965548253_5276155530673527.png",
      "name": "Degen Zoo"
    },
    {
      "chainId": 56,
      "address": "0xb64e280e9d1b5dbec4accedb2257a87b400db149",
      "symbol": "LVL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676966971957_4935939029723706.png",
      "name": "Level"
    },
    {
      "chainId": 56,
      "address": "0xf4c8e32eadec4bfe97e0f595add0f4450a863a11",
      "symbol": "THE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676891997505_9596628013061135.png",
      "name": "Thena"
    },
    {
      "chainId": 56,
      "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
      "symbol": "BOB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1674201470866_10771012952301162.png",
      "name": "BOB"
    },
    {
      "chainId": 56,
      "address": "0xcb5327ed4649548e0d73e70b633cdfd99af6da87",
      "symbol": "PRIMAL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1673923611928_45124033225415827.png",
      "name": "PRIMAL"
    },
    {
      "chainId": 56,
      "address": "0x1ce0c2827e2ef14d5c4f29a091d735a204794041",
      "symbol": "AVAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1673446254819_20661408114765423.png",
      "name": "Binance-Peg Avalanche Token"
    },
    {
      "chainId": 56,
      "address": "0x37a56cdcD83Dce2868f721De58cB3830C44C6303",
      "symbol": "ZBC",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671458892638_3246368744756343.jpg",
      "name": "Zebec Protocol"
    },
    {
      "chainId": 56,
      "address": "0x52F24a5e03aee338Da5fd9Df68D2b6FAe1178827",
      "symbol": "ankrBNB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671008309109_468750470166053.png",
      "name": "Ankr Staked BNB"
    },
    {
      "chainId": 56,
      "address": "0x086ddd008e20dd74c4fb216170349853f8ca8289",
      "symbol": "MBE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1670472672680_36114246355703816.JPG",
      "name": "MxmBoxcEus Token"
    },
    {
      "chainId": 56,
      "address": "0x98936bde1cf1bff1e7a8012cee5e2583851f2067",
      "symbol": "ANN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669185193859_6873957338478152.png",
      "name": "Annex Finance"
    },
    {
      "chainId": 56,
      "address": "0x44ec807ce2f4a6f2737a92e985f318d035883e47",
      "symbol": "HFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669117515278_26303471790539.jpg",
      "name": "Hashflow"
    },
    {
      "chainId": 56,
      "address": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
      "symbol": "MULTI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669116429465_4753793996709088.png",
      "name": "Multichain"
    },
    {
      "chainId": 56,
      "address": "0x14a9a94e555fdd54c21d7f7e328e61d7ebece54b",
      "symbol": "LOOT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1667890190868_4580585554387142.png",
      "name": "Lootex"
    },
    {
      "chainId": 56,
      "address": "0x2ab0e9e4ee70fff1fb9d67031e44f6410170d00e",
      "symbol": "BXEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1667531747758_5721975039255991.png",
      "name": "XEN Crypto"
    },
    {
      "chainId": 56,
      "address": "0xc2e9d07f66a89c44062459a47a0d2dc038e4fb16",
      "symbol": "stkBNB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1664269205535_336520128749328.png",
      "name": "pSTAKE Staked BNB"
    },
    {
      "chainId": 56,
      "address": "0x1bdd3cf7f79cfb8edbb955f20ad99211551ba275",
      "symbol": "BNBX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1664269044377_4727615335877742.jpg",
      "name": "Stader BNBx"
    },
    {
      "chainId": 56,
      "address": "0x7465b49f83bfd74e8df8574d43bfff34edbc1758",
      "symbol": "AMATICB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1664267993537_9463999814946371.png",
      "name": "Ankr Reward-Earning Staked MATIC"
    },
    {
      "chainId": 56,
      "address": "0x9c65ab58d8d978db963e63f2bfb7121627e3a739",
      "symbol": "MDX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x9c65ab58d8d978db963e63f2bfb7121627e3a739.png",
      "name": "MDX Token"
    },
    {
      "chainId": 56,
      "address": "0xfe56d5892bdffc7bf58f2e84be1b2c32d21c308b",
      "symbol": "KNC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1660734567796_9281563720980928.png",
      "name": "Kyber Network Crystal"
    },
    {
      "chainId": 56,
      "address": "0xd23811058eb6e7967d9a00dc3886e75610c4abba",
      "symbol": "KNIGHT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1660734511740_5937332759492198.jpg",
      "name": "KnightSwap"
    },
    {
      "chainId": 56,
      "address": "0xe05A08226c49b636ACf99c40Da8DC6aF83CE5bB3",
      "symbol": "ankrETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659434631109_3270742165656033.jpg",
      "name": "Ankr Eth2 Reward Bearing Certificate"
    },
    {
      "chainId": 56,
      "address": "0xd17479997F34dd9156Deef8F95A52D81D265be9c",
      "symbol": "USDD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1651924675034_5478321435787277.png",
      "name": "USDD"
    },
    {
      "chainId": 56,
      "address": "0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C",
      "symbol": "ankrMATIC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1653739507057_6345922838275662.png",
      "name": "Ankr Staked MATIC"
    },
    {
      "chainId": 56,
      "address": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
      "symbol": "deUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650029101183_05015582857711909.png",
      "name": "deBridge USD Coin"
    },
    {
      "chainId": 56,
      "address": "0x0b079B33B6e72311c6BE245F9f660CC385029fc3",
      "symbol": "APE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1647685207270_7574985937300007.jpg",
      "name": "ApeCoin"
    },
    {
      "chainId": 56,
      "address": "0x26193c7fa4354ae49ec53ea2cebc513dc39a10aa",
      "symbol": "SEA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1645065479559_05251054286913215.png",
      "name": "StarSharks SEA"
    },
    {
      "chainId": 56,
      "address": "0x0ecaf010fc192e2d5cbeb4dfb1fee20fbd733aa1",
      "symbol": "SPG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1645065436327_14786590885670825.jpg",
      "name": "Space Crypto"
    },
    {
      "chainId": 56,
      "address": "0x4803ac6b79f9582f69c4fa23c72cb76dd1e46d8d",
      "symbol": "TMT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1645065357654_28759165278764054.png",
      "name": "TopManager"
    },
    {
      "chainId": 56,
      "address": "0x475bfaa1848591ae0e6ab69600f48d828f61a80e",
      "symbol": "DOME",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1645065301968_22577087323875866.jpg",
      "name": "Everdome"
    },
    {
      "chainId": 56,
      "address": "0xf0a8ecbce8caadb7a07d1fcd0f87ae1bd688df43",
      "symbol": "GODZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1643720478284_3676874791228144.png",
      "name": "Cryptogodz"
    },
    {
      "chainId": 56,
      "address": "0x9720ca160bbd4e7f3dd4bb3f8bd4227ca0342e63",
      "symbol": "GMPD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642739611263_23170937007576353.png",
      "name": "GamesPad"
    },
    {
      "chainId": 56,
      "address": "0x924d221c4fd599e5894706ac2e8f6a35a3cc981e",
      "symbol": "FRR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642661902671_5529776821018315.png",
      "name": "Frontrow"
    },
    {
      "chainId": 56,
      "address": "0xc9882def23bc42d53895b8361d0b1edc7570bc6a",
      "symbol": "FIST",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1654508270679_7044954954885956.png",
      "name": "FistToken"
    },
    {
      "chainId": 56,
      "address": "0x276b440fdb4c54631c882cac9e4317929e751ff8",
      "symbol": "REV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642601141187_07998671988025308.jpg",
      "name": "Revolution"
    },
    {
      "chainId": 56,
      "address": "0xBBCA42c60b5290F2c48871A596492F93fF0Ddc82",
      "symbol": "DOMI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642568723650_19067383325168286.png",
      "name": "Domi "
    },
    {
      "chainId": 56,
      "address": "0x683fae4411249ca05243dfb919c20920f3f5bfe0",
      "symbol": "GHC",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642565332542_9980540162447473.png",
      "name": "Galaxy Heroes Coin"
    },
    {
      "chainId": 56,
      "address": "0x4477b28e8b797ebaebd2539bb24290fdfcc27807",
      "symbol": "RFG",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641570951267_6838257966533852.png",
      "name": "Refugees Token"
    },
    {
      "chainId": 56,
      "address": "0xd8047afecb86e44eff3add991b9f063ed4ca716b",
      "symbol": "GGG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641536671746_5537710154592934.png",
      "name": "Good Games Guild"
    },
    {
      "chainId": 56,
      "address": "0xf4ed363144981d3a65f42e7d0dc54ff9eef559a1",
      "symbol": "FARA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641303642308_3787035701587649.png",
      "name": "FaraLand"
    },
    {
      "chainId": 56,
      "address": "0x24ce3d571fbcfd9d81dc0e1a560504636a4d046d",
      "symbol": "METAPETS",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641309568963_3212573638393179.png",
      "name": "MetaPets"
    },
    {
      "chainId": 56,
      "address": "0x4b8285ab433d8f69cb48d5ad62b415ed1a221e4f",
      "symbol": "MCRT",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641123245045_9125764216427192.png",
      "name": "MagicCraft "
    },
    {
      "chainId": 56,
      "address": "0xfd9faca15558eb243ac0012cf7acfbf480574668",
      "symbol": "MVRS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641123160525_6233421079814339.png",
      "name": "Meta MVRS "
    },
    {
      "chainId": 56,
      "address": "0xd52efe1039d706722b3d696e74ad4095dc3d3860",
      "symbol": "PWR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641267470524_1496782469387261.png",
      "name": "Power"
    },
    {
      "chainId": 56,
      "address": "0x7ad7242a99f21aa543f9650a56d141c57e4f6081",
      "symbol": "JADE ",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641122938688_09118434845434131.png",
      "name": "JADE "
    },
    {
      "chainId": 56,
      "address": "0xa3499dd7dbbbd93cb0f8303f8a8ace8d02508e73",
      "symbol": "LACE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640061712088_6462317269772366.png",
      "name": "Lovelace"
    },
    {
      "chainId": 56,
      "address": "0x9c67638c4fa06fd47fb8900fc7f932f7eab589de",
      "symbol": "ARKER ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640059457275_3000650642155118.png",
      "name": "ARKER "
    },
    {
      "chainId": 56,
      "address": "0x5492ef6aeeba1a3896357359ef039a8b11621b45",
      "symbol": "CHMB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639983973447_09695190070221504.png",
      "name": "Chumbi Valley"
    },
    {
      "chainId": 56,
      "address": "0xaff9084f2374585879e8b434c399e29e80cce635",
      "symbol": "FLUX",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639192023550_488684033739881.png",
      "name": "Flux"
    },
    {
      "chainId": 56,
      "address": "0xf68c9df95a18b2a5a5fa1124d79eeeffbad0b6fa",
      "symbol": "ANY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638628225009_6472394885163804.jpg",
      "name": "Anyswap"
    },
    {
      "chainId": 56,
      "address": "0x04260673729c5f2b9894a467736f3d85f8d34fc8",
      "symbol": "CPAN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637670990321_05553070031092133.png",
      "name": "CryptoPlanes"
    },
    {
      "chainId": 56,
      "address": "0x50332bdca94673f33401776365b66cc4e81ac81d",
      "symbol": "CCAR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637660548456_5149320731324383.png",
      "name": "CryptoCars"
    },
    {
      "chainId": 56,
      "address": "0x1f39dd2bf5a27e2d4ed691dcf933077371777cb0",
      "symbol": "Nora",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636702852000_6955849088043498.png",
      "name": "SnowCrash Token"
    },
    {
      "chainId": 56,
      "address": "0xb3a6381070b1a15169dea646166ec0699fdaea79",
      "symbol": "GOLD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636446532287_31313732319066667.png",
      "name": "CyberDragon Gold "
    },
    {
      "chainId": 56,
      "address": "0xd3c9609b6cbc6ef02390f33c230590c38f9e5f9d",
      "symbol": "PROT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636450456526_8401839757065861.jpeg",
      "name": "ArmzLegends"
    },
    {
      "chainId": 56,
      "address": "0x0e7beec376099429b85639eb3abe7cf22694ed49",
      "symbol": "BUNI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636342284095_16387264595202877.png",
      "name": "Bunicorn"
    },
    {
      "chainId": 56,
      "address": "0x1d229b958d5ddfca92146585a8711aecbe56f095",
      "symbol": "ZOO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636364758723_14212669651089116.png",
      "name": "ZOO - Crypto World"
    },
    {
      "chainId": 56,
      "address": "0x7ffc1243232da3ac001994208e2002816b57c669",
      "symbol": "ZOO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636341597801_12924766999006598.png",
      "name": "CryptoZoo"
    },
    {
      "chainId": 56,
      "address": "0x5e0691ba91e21f3fdc88a0550aa4f7304ed89b5c",
      "symbol": "DGCL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636266071547_15035687284769095.png",
      "name": "DigiCol Token"
    },
    {
      "chainId": 56,
      "address": "0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83",
      "symbol": "BRISE",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635753509466_5045839612838678.png",
      "name": "Bitrise Token"
    },
    {
      "chainId": 56,
      "address": "0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c",
      "symbol": "WARS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635758239845_3011665961126244.png",
      "name": "MetaWars"
    },
    {
      "chainId": 56,
      "address": "0xfeea0bdd3d07eb6fe305938878c0cadbfa169042",
      "symbol": "8PAY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xfeea0bdd3d07eb6fe305938878c0cadbfa169042.png",
      "name": "8pay"
    },
    {
      "chainId": 56,
      "address": "0x4197c6ef3879a08cd51e5560da5064b773aa1d29",
      "symbol": "ACS V1",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x4197c6ef3879a08cd51e5560da5064b773aa1d29.png",
      "name": "ACryptoS V1"
    },
    {
      "chainId": 56,
      "address": "0xda5be69074afd12354173b4350ec9117e73e92e2",
      "symbol": "AQT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xda5be69074afd12354173b4350ec9117e73e92e2.png",
      "name": "AQT"
    },
    {
      "chainId": 56,
      "address": "0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95",
      "symbol": "BANANA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95.png",
      "name": "ApeSwapFinance Banana"
    },
    {
      "chainId": 56,
      "address": "0x393d87e44c7b1f5ba521b351532c24ece253b849",
      "symbol": "BLES",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x393d87e44c7b1f5ba521b351532c24ece253b849.png",
      "name": "BLES"
    },
    {
      "chainId": 56,
      "address": "0x96058f8c3e16576d9bd68766f3836d9a33158f89",
      "symbol": "BONDLY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x96058f8c3e16576d9bd68766f3836d9a33158f89.png",
      "name": "Bondly Token"
    },
    {
      "chainId": 56,
      "address": "0x92d7756c60dcfd4c689290e8a9f4d263b3b32241",
      "symbol": "BOR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x92d7756c60dcfd4c689290e8a9f4d263b3b32241.png",
      "name": "BoringDAO Token"
    },
    {
      "chainId": 56,
      "address": "0x790be81c3ca0e53974be2688cdb954732c9862e1",
      "symbol": "BREW",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x790be81c3ca0e53974be2688cdb954732c9862e1.png",
      "name": "CafeSwap Token"
    },
    {
      "chainId": 56,
      "address": "0xd1102332a213e21faf78b69c03572031f3552c33",
      "symbol": "BTD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xd1102332a213e21faf78b69c03572031f3552c33.png",
      "name": "Bat True Dollar"
    },
    {
      "chainId": 56,
      "address": "0xc2e1acef50ae55661855e8dcb72adb182a3cc259",
      "symbol": "BTS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xc2e1acef50ae55661855e8dcb72adb182a3cc259.png",
      "name": "Bat True Share"
    },
    {
      "chainId": 56,
      "address": "0x965b0df5bda0e7a0649324d78f03d5f7f2de086a",
      "symbol": "COOK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x965b0df5bda0e7a0649324d78f03d5f7f2de086a.png",
      "name": "COOK"
    },
    {
      "chainId": 56,
      "address": "0x422e3af98bc1de5a1838be31a56f75db4ad43730",
      "symbol": "COW",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x422e3af98bc1de5a1838be31a56f75db4ad43730.png",
      "name": "COW"
    },
    {
      "chainId": 56,
      "address": "0x373e768f79c820aa441540d254dca6d045c6d25b",
      "symbol": "DERC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x373e768f79c820aa441540d254dca6d045c6d25b.png",
      "name": "DeRace"
    },
    {
      "chainId": 56,
      "address": "0x72f28c09be1342447fa01ebc76ef508473d08c5c",
      "symbol": "DGN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x72f28c09be1342447fa01ebc76ef508473d08c5c.png",
      "name": "https://degen.trade/"
    },
    {
      "chainId": 56,
      "address": "0x233d91a0713155003fc4dce0afa871b508b3b715",
      "symbol": "DITTO",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x233d91a0713155003fc4dce0afa871b508b3b715.png",
      "name": "Ditto"
    },
    {
      "chainId": 56,
      "address": "0x002d8563759f5e1eaf8784181f3973288f6856e4",
      "symbol": "DMOD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x002d8563759f5e1eaf8784181f3973288f6856e4.png",
      "name": "DMOD"
    },
    {
      "chainId": 56,
      "address": "0xc9457161320210d22f0d0d5fc1309acb383d4609",
      "symbol": "DOV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xc9457161320210d22f0d0d5fc1309acb383d4609.png",
      "name": "DOV"
    },
    {
      "chainId": 56,
      "address": "0xfb7400707df3d76084fbeae0109f41b178f71c02",
      "symbol": "DOWS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xfb7400707df3d76084fbeae0109f41b178f71c02.png",
      "name": "Shadows"
    },
    {
      "chainId": 56,
      "address": "0xfb62ae373aca027177d1c18ee0862817f9080d08",
      "symbol": "DPET",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xfb62ae373aca027177d1c18ee0862817f9080d08.png",
      "name": "My DeFi Pet Token"
    },
    {
      "chainId": 56,
      "address": "0xf952fc3ca7325cc27d15885d37117676d25bfda6",
      "symbol": "EGG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xf952fc3ca7325cc27d15885d37117676d25bfda6.png",
      "name": "Goose Golden Egg"
    },
    {
      "chainId": 56,
      "address": "0x1ab6478b47270ff05af11a012ac17b098758e193",
      "symbol": "bcFLUX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x1ab6478b47270ff05af11a012ac17b098758e193.png",
      "name": "Flux Protocol"
    },
    {
      "chainId": 56,
      "address": "0xfd5af95c12446b60d23e16a4ea95690ce942e5dc",
      "symbol": "FREL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xfd5af95c12446b60d23e16a4ea95690ce942e5dc.png",
      "name": "FREL"
    },
    {
      "chainId": 56,
      "address": "0xc53708664b99df348dd27c3ac0759d2da9c40462",
      "symbol": "GUM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xc53708664b99df348dd27c3ac0759d2da9c40462.png",
      "name": "GourmetGalaxy"
    },
    {
      "chainId": 56,
      "address": "0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54",
      "symbol": "HAPI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54.png",
      "name": "HAPI"
    },
    {
      "chainId": 56,
      "address": "0xe1d1f66215998786110ba0102ef558b22224c016",
      "symbol": "HOO",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xe1d1f66215998786110ba0102ef558b22224c016.png",
      "name": "HooToken"
    },
    {
      "chainId": 56,
      "address": "0x78e1936f065fd4082387622878c7d11c9f05ecf4",
      "symbol": "JNTR/b",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x78e1936f065fd4082387622878c7d11c9f05ecf4.png",
      "name": "JNTR/b"
    },
    {
      "chainId": 56,
      "address": "0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d",
      "symbol": "JULb",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d.png",
      "name": "JULb"
    },
    {
      "chainId": 56,
      "address": "0x5a41f637c3f7553dba6ddc2d3ca92641096577ea",
      "symbol": "JulD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea.png",
      "name": "JulSwap"
    },
    {
      "chainId": 56,
      "address": "0x7979f6c54eba05e18ded44c4f986f49a5de551c2",
      "symbol": "KEBAB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x7979f6c54eba05e18ded44c4f986f49a5de551c2.png",
      "name": "Kebab Token"
    },
    {
      "chainId": 56,
      "address": "0x4e1b16ef22935a575a6811d4616f98c4077e4408",
      "symbol": "KEL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x4e1b16ef22935a575a6811d4616f98c4077e4408.png",
      "name": "KEL"
    },
    {
      "chainId": 56,
      "address": "0xe6df05ce8c8301223373cf5b969afcb1498c5528",
      "symbol": "KOGE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xe6df05ce8c8301223373cf5b969afcb1498c5528.png",
      "name": "BNB48 Club Token"
    },
    {
      "chainId": 56,
      "address": "0xbc194e6f748a222754c3e8b9946922c09e7d4e91",
      "symbol": "LEV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xbc194e6f748a222754c3e8b9946922c09e7d4e91.png",
      "name": "LEV"
    },
    {
      "chainId": 56,
      "address": "0x35e869B7456462b81cdB5e6e42434bD27f3F788c",
      "symbol": "MDO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x35e869b7456462b81cdb5e6e42434bd27f3f788c.png",
      "name": "Midas Dollar"
    },
    {
      "chainId": 56,
      "address": "0x68E374F856bF25468D365E539b700b648Bf94B67",
      "symbol": "MIST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x68e374f856bf25468d365e539b700b648bf94b67.png",
      "name": "Mist"
    },
    {
      "chainId": 56,
      "address": "0xe4b22193d68f18f8e8eb3a26f4d64cb6d4573022",
      "symbol": "MOZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xe4b22193d68f18f8e8eb3a26f4d64cb6d4573022.png",
      "name": "MOZ"
    },
    {
      "chainId": 56,
      "address": "0xd7730681b1dc8f6f969166b29d8a5ea8568616a3",
      "symbol": "NAFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xd7730681b1dc8f6f969166b29d8a5ea8568616a3.png",
      "name": "Nafter"
    },
    {
      "chainId": 56,
      "address": "0xde3dbbe30cfa9f437b293294d1fd64b26045c71a",
      "symbol": "NFTB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xde3dbbe30cfa9f437b293294d1fd64b26045c71a.png",
      "name": "NFTB"
    },
    {
      "chainId": 56,
      "address": "0xee9801669c6138e84bd50deb500827b776777d28",
      "symbol": "O3",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xee9801669c6138e84bd50deb500827b776777d28.png",
      "name": "O3"
    },
    {
      "chainId": 56,
      "address": "0x076ddce096c93dcf5d51fe346062bf0ba9523493",
      "symbol": "PARA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x076ddce096c93dcf5d51fe346062bf0ba9523493.png",
      "name": "Paralink Network"
    },
    {
      "chainId": 56,
      "address": "0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8",
      "symbol": "PET",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8.png",
      "name": "Pet Token"
    },
    {
      "chainId": 56,
      "address": "0x7e624fa0e1c4abfd309cc15719b7e2580887f570",
      "symbol": "POLS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x7e624fa0e1c4abfd309cc15719b7e2580887f570.png",
      "name": "PolkastarterToken"
    },
    {
      "chainId": 56,
      "address": "0x3fcca8648651e5b974dd6d3e50f61567779772a8",
      "symbol": "POTS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x3fcca8648651e5b974dd6d3e50f61567779772a8.png",
      "name": "Moonpot"
    },
    {
      "chainId": 56,
      "address": "0x31471e0791fcdbe82fbf4c44943255e923f1b794",
      "symbol": "PVU",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x31471e0791fcdbe82fbf4c44943255e923f1b794.png",
      "name": "Plant vs Undead Token"
    },
    {
      "chainId": 56,
      "address": "0xedecfb4801c04f3eb394b89397c6aafa4adda15b",
      "symbol": "PYRAM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xedecfb4801c04f3eb394b89397c6aafa4adda15b.png",
      "name": "Pyram Token"
    },
    {
      "chainId": 56,
      "address": "0xb8c540d00dd0bf76ea12e4b4b95efc90804f924e",
      "symbol": "QUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xb8c540d00dd0bf76ea12e4b4b95efc90804f924e.png",
      "name": "QUSD Stablecoin"
    },
    {
      "chainId": 56,
      "address": "0x8519ea49c997f50ceffa444d240fb655e89248aa",
      "symbol": "RAMP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x8519ea49c997f50ceffa444d240fb655e89248aa.png",
      "name": "RAMP DEFI"
    },
    {
      "chainId": 56,
      "address": "0x155040625d7ae3e9cada9a73e3e44f76d3ed1409",
      "symbol": "REVO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x155040625d7ae3e9cada9a73e3e44f76d3ed1409.png",
      "name": "REVO"
    },
    {
      "chainId": 56,
      "address": "0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2",
      "symbol": "ROCKS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2.png",
      "name": "ROCKS"
    },
    {
      "chainId": 56,
      "address": "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
      "symbol": "SHIB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x2859e4544c4bb03966803b044a93563bd2d0dd4d.png",
      "name": "SHIBA INU"
    },
    {
      "chainId": 56,
      "address": "0x338d44d19c8d45ed9771b8d2b95d988f0e42187f",
      "symbol": "SOCCER",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x338d44d19c8d45ed9771b8d2b95d988f0e42187f.png",
      "name": "SOCCER"
    },
    {
      "chainId": 56,
      "address": "0x6855f7bb6287f94ddcc8915e37e73a3c9fee5cf3",
      "symbol": "STACK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x6855f7bb6287f94ddcc8915e37e73a3c9fee5cf3.png",
      "name": "STACK"
    },
    {
      "chainId": 56,
      "address": "0x31d0a7ada4d4c131eb612db48861211f63e57610",
      "symbol": "START",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x31d0a7ada4d4c131eb612db48861211f63e57610.png",
      "name": "BSCstarter"
    },
    {
      "chainId": 56,
      "address": "0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4",
      "symbol": "STAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4.png",
      "name": "StableX Token"
    },
    {
      "chainId": 56,
      "address": "0xf7fb08c187e6cd1f2149e6c818d0b6d4d4ef1430",
      "symbol": "STN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xf7fb08c187e6cd1f2149e6c818d0b6d4d4ef1430.png",
      "name": "STN"
    },
    {
      "chainId": 56,
      "address": "0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb",
      "symbol": "SWGb",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb.png",
      "name": "SWGb"
    },
    {
      "chainId": 56,
      "address": "0x71de20e0c4616e7fcbfdd3f875d568492cbe4739",
      "symbol": "SWINGBY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x71de20e0c4616e7fcbfdd3f875d568492cbe4739.png",
      "name": "SWINGBY token"
    },
    {
      "chainId": 56,
      "address": "0xfd799ddcca8ab5c1ad8a3d64a58d4e907c9d0b71",
      "symbol": "TAP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xfd799ddcca8ab5c1ad8a3d64a58d4e907c9d0b71.png",
      "name": "TAP"
    },
    {
      "chainId": 56,
      "address": "0xdff8cb622790b7f92686c722b02cab55592f152c",
      "symbol": "TEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xdff8cb622790b7f92686c722b02cab55592f152c.png",
      "name": "Tenet"
    },
    {
      "chainId": 56,
      "address": "0x1311b352467d2b5c296881badea82850bcd8f886",
      "symbol": "TOOLS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x1311b352467d2b5c296881badea82850bcd8f886.png",
      "name": "TOOLS"
    },
    {
      "chainId": 56,
      "address": "0x7af173f350d916358af3e218bdf2178494beb748",
      "symbol": "TRADE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x7af173f350d916358af3e218bdf2178494beb748.png",
      "name": "UniTrade"
    },
    {
      "chainId": 56,
      "address": "0x270388e0ca29cfd7c7e73903d9d933a23d1bab39",
      "symbol": "TSX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x270388e0ca29cfd7c7e73903d9d933a23d1bab39.png",
      "name": "TSX"
    },
    {
      "chainId": 56,
      "address": "0x6ff2d9e5891a7a7c554b80e0d1b791483c78bce9",
      "symbol": "WAULT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x6ff2d9e5891a7a7c554b80e0d1b791483c78bce9.png",
      "name": "Wault Finance"
    },
    {
      "chainId": 56,
      "address": "0x443cab9583b83eaa7a712c9d64525e57e2a7eb3f",
      "symbol": "XTM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637209220613_9771524796854829.png",
      "name": "Torum"
    },
    {
      "chainId": 56,
      "address": "0x9d173e6c594f479b4d47001f8e6a95a7adda42bc",
      "symbol": "ZOON",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x9d173e6c594f479b4d47001f8e6a95a7adda42bc.png",
      "name": "CryptoZoon"
    },
    {
      "chainId": 56,
      "address": "0x973616ff3b9d8f88411c5b4e6f928ee541e4d01f",
      "symbol": "aETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x973616ff3b9d8f88411c5b4e6f928ee541e4d01f.png",
      "name": "Ankr Eth2 Reward Bearing Bond"
    },
    {
      "chainId": 56,
      "address": "0xbcf39f0edda668c58371e519af37ca705f2bfcbd",
      "symbol": "pCWS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0xbcf39f0edda668c58371e519af37ca705f2bfcbd.png",
      "name": "PolyCrowns"
    },
    {
      "chainId": 56,
      "address": "0x0d9319565be7f53CeFE84Ad201Be3f40feAE2740",
      "symbol": "sBDO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x0d9319565be7f53cefe84ad201be3f40feae2740.png",
      "name": "bDollar Share"
    },
    {
      "chainId": 56,
      "address": "0x541e619858737031a1244a5d0cd47e5ef480342c",
      "symbol": "wSOTE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x541e619858737031a1244a5d0cd47e5ef480342c.png",
      "name": "Wrapped SOTE"
    },
    {
      "chainId": 56,
      "address": "0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5",
      "symbol": "zSEED",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/bsc/0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5.png",
      "name": "zSeedToken"
    },
    {
      "chainId": 56,
      "address": "0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389",
      "symbol": "ACSI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634714659190_3169047610869795.png",
      "name": "ACryptoSI"
    },
    {
      "chainId": 56,
      "address": "0x48DC0190dF5ece990c649A7A07bA19D3650a9572",
      "symbol": "AUCTION",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634884765220_4038457789109604.png",
      "name": "Bounce Token"
    },
    {
      "chainId": 56,
      "address": "0x8263CD1601FE73C066bf49cc09841f35348e3be0",
      "symbol": "ALU",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635075338379_694667997171063.png",
      "name": "Altura"
    },
    {
      "chainId": 56,
      "address": "0x293C3Ee9ABaCb08BB8ceD107987F00EfD1539288",
      "symbol": "EMPIRE",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635075683985_06312261865855517.png",
      "name": "Empire Token"
    },
    {
      "chainId": 56,
      "address": "0xc864019047b864b6ab609a968ae2725dfaee808a",
      "symbol": "BIT",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635075895501_5460770851668286.png",
      "name": "BIT TOKEN"
    },
    {
      "chainId": 56,
      "address": "0x07663837218a003e66310a01596af4bf4e44623d",
      "symbol": "rUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635299162052_6705753741545755.png",
      "name": "rUSD"
    },
    {
      "chainId": 56,
      "address": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
      "symbol": "1ART",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635386241277_11410086439393874.png",
      "name": "ArtWallet "
    },
    {
      "chainId": 56,
      "address": "0xd9dE2B1973E57Dc9DBA90c35d6Cd940ae4A3CBe1",
      "symbol": "MILO",
      "decimals": 9,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/milo.png",
      "name": "Milo Inu"
    },
    {
      "chainId": 56,
      "address": "0x570A5D26f7765Ecb712C0924E4De545B89fD43dF",
      "symbol": "SOL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/sol.png",
      "name": "Solana Token"
    },
    {
      "chainId": 56,
      "address": "0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8",
      "symbol": "SD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/sd.png",
      "name": "Stader Token"
    },
    {
      "chainId": 56,
      "address": "0xD767713e787Cb351e4daFb777DD93d54f5381D29",
      "symbol": "GRI",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/gri.png",
      "name": "Great Resilient Incentive"
    },
    {
      "chainId": 56,
      "address": "0x8105ECe4ce08B6B6449539A5db23e23b973DfA8f",
      "symbol": "MAP",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/map.png",
      "name": "MAP Protocol"
    }
  ],
  "137": [
    {
      "chainId": 137,
      "name": "Aave",
      "symbol": "AAVE",
      "address": "0xD6DF932A45C0f255f85145f286eA0b292B21C90B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"
    },
    {
      "chainId": 137,
      "name": "Adamant",
      "symbol": "ADDY",
      "address": "0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21357.png"
    },
    {
      "chainId": 137,
      "name": "Adshares",
      "symbol": "ADS",
      "address": "0x598e49f01bEfeB1753737934a5b11fea9119C796",
      "decimals": 11,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1883.png"
    },
    {
      "chainId": 137,
      "name": "Angle Protocol",
      "symbol": "agEUR",
      "address": "0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15024.png"
    },
    {
      "chainId": 137,
      "name": "Akash Network",
      "symbol": "AKT",
      "address": "0xf14fbC6B30e2c4BC05A1D4fbE34bf9f14313309D",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7431.png"
    },
    {
      "chainId": 137,
      "name": "Aluna",
      "symbol": "ALN",
      "address": "0xa8fcEe762642f156b5D757b6FabC36E06b6d4A1A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5544.png"
    },
    {
      "chainId": 137,
      "name": "AnRKey X",
      "symbol": "ANRX",
      "address": "0x554f074d9cCda8F483d1812d4874cBebD682644E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8057.png"
    },
    {
      "chainId": 137,
      "name": "Anyswap",
      "symbol": "ANY",
      "address": "0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 137,
      "name": "Atlantis",
      "symbol": "ATLX",
      "address": "0x0b68782eFF3177f1F9240B64A7e2F8E0497e2454",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16853.png"
    },
    {
      "chainId": 137,
      "name": "Cosmos",
      "symbol": "ATOM",
      "address": "0xac51C4c48Dc3116487eD4BC16542e27B5694Da1b",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png"
    },
    {
      "chainId": 137,
      "name": "Avalanche Token",
      "symbol": "AVAX",
      "address": "0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png?1604021818"
    },
    {
      "chainId": 137,
      "name": "AurusSILVER",
      "symbol": "AWS",
      "address": "0x24aD14A4f0BB79a9dcf201514CB0E651C79DB76B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9547.png"
    },
    {
      "chainId": 137,
      "name": "BABYQUICK",
      "symbol": "BABYQUICK",
      "address": "0x9a05D1FF699ea187Dc8523E333eD63503f0d82db",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13927.png"
    },
    {
      "chainId": 137,
      "name": "Blockchain Monster Coin",
      "symbol": "BCMC",
      "address": "0xc10358f062663448a3489fC258139944534592ac",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12751.png"
    },
    {
      "chainId": 137,
      "name": "Bella",
      "symbol": "BEL",
      "address": "0x28C388FB1F4fa9F9eB445f0579666849EE5eeb42",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6928.png"
    },
    {
      "chainId": 137,
      "name": "beefy.finance",
      "symbol": "BIFI",
      "address": "0xFbdd194376de19a88118e84E279b977f165d01b8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7311.png"
    },
    {
      "chainId": 137,
      "name": "GoBlank Token",
      "symbol": "BLANK",
      "address": "0xf4C83080E80AE530d6f8180572cBbf1Ac9D5d435",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8695.png"
    },
    {
      "chainId": 137,
      "name": "BLOK",
      "symbol": "BLOK",
      "address": "0x229b1b6C23ff8953D663C4cBB519717e323a0a84",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11206.png"
    },
    {
      "chainId": 137,
      "name": "Binance Token",
      "symbol": "BNB",
      "address": "0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615"
    },
    {
      "chainId": 137,
      "name": "ChainGuardians Governance Token",
      "symbol": "CGG",
      "address": "0x2Ab4f9aC80F33071211729e45Cfc346C1f8446d5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8648.png"
    },
    {
      "chainId": 137,
      "name": "NFT Champions",
      "symbol": "CHAMP",
      "address": "0x8f9E8e833A69Aa467E42c46cCA640da84DD4585f",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13425.png"
    },
    {
      "chainId": 137,
      "name": "Crosschain IOTX",
      "symbol": "CIOTX",
      "address": "0x300211Def2a644b036A9bdd3e58159bb2074d388",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11851.png"
    },
    {
      "chainId": 137,
      "name": "Otter Clam",
      "symbol": "CLAM2",
      "address": "0xC250e9987A032ACAC293d838726C511E6E1C029d",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15233.png"
    },
    {
      "chainId": 137,
      "name": "Centaur Token",
      "symbol": "CNTR",
      "address": "0xdae89dA41a96956e9e70320Ac9c0dd077070D3a5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7349.png"
    },
    {
      "chainId": 137,
      "name": "Compound",
      "symbol": "COMP",
      "address": "0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png"
    },
    {
      "chainId": 137,
      "name": "DeFi11",
      "symbol": "D11",
      "address": "0xc58158c14D4757EF36Ce25e493758F2fcEEDec5D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11147.png"
    },
    {
      "chainId": 137,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 137,
      "name": "DeRace Token",
      "symbol": "DERC",
      "address": "0xB35fcBCF1fD489fCe02Ee146599e893FDCdC60e6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10744.png"
    },
    {
      "chainId": 137,
      "name": "DeSpace Protocol",
      "symbol": "DES",
      "address": "0xa062fc09cA6bdeb2f6E3b77E1d4e09C42C964742",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10183.png"
    },
    {
      "chainId": 137,
      "name": "decentral.games",
      "symbol": "DG",
      "address": "0xef938b6da8576a896f6E0321ef80996F4890f9c4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15478.png"
    },
    {
      "chainId": 137,
      "name": "DeHive.finance",
      "symbol": "DHV",
      "address": "0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8867.png"
    },
    {
      "chainId": 137,
      "name": "DinoSwap",
      "symbol": "DINO",
      "address": "0xAa9654BECca45B5BDFA5ac646c939C62b527D394",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10777.png"
    },
    {
      "chainId": 137,
      "name": "DefiPulse Index",
      "symbol": "DPI",
      "address": "0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12465/small/defi_pulse_index_set.png?1600051053"
    },
    {
      "chainId": 137,
      "name": "DSLA",
      "symbol": "DSLA",
      "address": "0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5423.png"
    },
    {
      "chainId": 137,
      "name": "Waves Ducks",
      "symbol": "EGG",
      "address": "0x51dE72b17c7bD12E9E6d69eb506a669EB6b5249E",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/17298/small/200x200_pixel.png?1627288546"
    },
    {
      "chainId": 137,
      "name": "Elementeum",
      "symbol": "ELET",
      "address": "0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3931.png"
    },
    {
      "chainId": 137,
      "name": "Starchi Liquid Luck",
      "symbol": "ELIXIR",
      "address": "0xED0bb69f9F96E8C5FffdF16c468eceC385134eA5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12480.png"
    },
    {
      "chainId": 137,
      "name": "Dogelon",
      "symbol": "ELON",
      "address": "0xE0339c80fFDE91F3e20494Df88d4206D86024cdF",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9436.png"
    },
    {
      "chainId": 137,
      "name": "Ethernity Chain",
      "symbol": "ERN",
      "address": "0x0E50BEA95Fe001A370A4F1C220C49AEdCB982DeC",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8615.png"
    },
    {
      "chainId": 137,
      "name": "SifChain",
      "symbol": "EROWAN",
      "address": "0xa7051C5a22d963b81D71C2BA64D46a877fBc1821",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8541.png"
    },
    {
      "chainId": 137,
      "name": "Ether",
      "symbol": "ETH",
      "address": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 137,
      "name": "ETHA",
      "symbol": "ETHA",
      "address": "0x59E9261255644c411AfDd00bD89162d09D862e38",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8709.png"
    },
    {
      "chainId": 137,
      "name": "EASY V2",
      "symbol": "EZ",
      "address": "0x34C1b299A74588D6Abdc1b85A53345A48428a521",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png"
    },
    {
      "chainId": 137,
      "name": "Fear NFTs",
      "symbol": "FEAR",
      "address": "0xa2CA40DBe72028D3Ac78B5250a8CB8c404e7Fb8C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9866.png"
    },
    {
      "chainId": 137,
      "name": "Fish",
      "symbol": "FISH",
      "address": "0x3a3Df212b7AA91Aa0402B9035b098891d276572B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10134.png"
    },
    {
      "chainId": 137,
      "name": "FireStarter",
      "symbol": "FLAME",
      "address": "0x22e3f02f86Bc8eA0D73718A2AE8851854e62adc5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12678.png"
    },
    {
      "chainId": 137,
      "name": "Fodl",
      "symbol": "FODL",
      "address": "0x5314bA045a459f63906Aa7C76d9F337DcB7d6995",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12767.png"
    },
    {
      "chainId": 137,
      "name": "Frax",
      "symbol": "FRAX",
      "address": "0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    },
    {
      "chainId": 137,
      "name": "Fantom Token",
      "symbol": "FTM",
      "address": "0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016"
    },
    {
      "chainId": 137,
      "name": "GAIA Everworld",
      "symbol": "GAIA",
      "address": "0x723B17718289A91AF252D616DE2C77944962d122",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12650.png"
    },
    {
      "chainId": 137,
      "name": "GAME Credits",
      "symbol": "GAME",
      "address": "0x8d1566569d5b695d44a9a234540f68D393cDC40D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/576.png"
    },
    {
      "chainId": 137,
      "name": "GameStation",
      "symbol": "GAMER",
      "address": "0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13449.png"
    },
    {
      "chainId": 137,
      "name": "GemBites",
      "symbol": "GBTS",
      "address": "0xbe9512e2754cb938dd69Bbb96c8a09Cb28a02D6D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10834.png"
    },
    {
      "chainId": 137,
      "name": "Genesis",
      "symbol": "GENESIS",
      "address": "0x51869836681BcE74a514625c856aFb697a013797",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13932.png"
    },
    {
      "chainId": 137,
      "name": "Aavegotchi GHST Token",
      "symbol": "GHST",
      "address": "0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7046.png"
    },
    {
      "chainId": 137,
      "name": "GAMEE",
      "symbol": "GMEE",
      "address": "0xcf32822ff397Ef82425153a9dcb726E5fF61DCA7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9103.png"
    },
    {
      "chainId": 137,
      "name": "Gains Network",
      "symbol": "GNS",
      "address": "0xE5417Af564e4bFDA1c483642db72007871397896",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13663.png"
    },
    {
      "chainId": 137,
      "name": "Helmet.insure on Polygon",
      "symbol": "Guard",
      "address": "0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8265.png"
    },
    {
      "chainId": 137,
      "name": "Hanu Yokia",
      "symbol": "HANU",
      "address": "0x709A4B6217584188DDb93c82f5d716D969accE1C",
      "decimals": 12,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12652.png"
    },
    {
      "chainId": 137,
      "name": "HEXX",
      "symbol": "HEX",
      "address": "0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5015.png"
    },
    {
      "chainId": 137,
      "name": "HONOR",
      "symbol": "HONOR",
      "address": "0xb82A20B4522680951F11c94c54B8800c1C237693",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10620.png"
    },
    {
      "chainId": 137,
      "name": "Decentral Games ICE",
      "symbol": "ICE",
      "address": "0xc6C855AD634dCDAd23e64DA71Ba85b8C51E5aD7c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13133.png"
    },
    {
      "chainId": 137,
      "name": "iFARM",
      "symbol": "iFARM",
      "address": "0xab0b2ddB9C7e440fAc8E140A89c0dbCBf2d7Bbff",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/harvestfi/assets/main/farm-logo.png"
    },
    {
      "chainId": 137,
      "name": "IG Gold",
      "symbol": "IGG",
      "address": "0xe6FC6C7CB6d2c31b359A49A33eF08aB87F4dE7CE",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4054.png"
    },
    {
      "chainId": 137,
      "name": "Impermax",
      "symbol": "IMX",
      "address": "0x60bB3D364B765C497C8cE50AE0Ae3f0882c5bD05",
      "decimals": 18,
      "logoURI": "https://polygon.impermax.finance/build/assets/logoURI/0x60bb3d364b765c497c8ce50ae0ae3f0882c5bd05.png"
    },
    {
      "chainId": 137,
      "name": "IRISnet",
      "symbol": "IRIS",
      "address": "0x3dc6052a693E4a2fc28Eb2Ea12fe0CfD3BD221D1",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3874.png"
    },
    {
      "chainId": 137,
      "name": "JPY Coin",
      "symbol": "JPYC",
      "address": "0x6AE7Dfc73E0dDE2aa99ac063DcF7e8A63265108c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9045.png"
    },
    {
      "chainId": 137,
      "name": "Jarvis Reward Token",
      "symbol": "JRT",
      "address": "0x596eBE76e2DB4470966ea395B0d063aC6197A8C5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5187.png"
    },
    {
      "chainId": 137,
      "name": "KeyFi Token",
      "symbol": "KEYFI",
      "address": "0xD1A5f2A049343fc4D5F8d478f734eBa51B22375E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8561.png"
    },
    {
      "chainId": 137,
      "name": "Forest Knight",
      "symbol": "KNIGHT",
      "address": "0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11324.png"
    },
    {
      "chainId": 137,
      "name": "Kommunitas",
      "symbol": "KOM",
      "address": "0xC004e2318722EA2b15499D6375905d75Ee5390B8",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10403.png"
    },
    {
      "chainId": 137,
      "name": "ChainLink Token",
      "symbol": "LINK",
      "address": "0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped LUNA Token",
      "symbol": "LUNA",
      "address": "0x74946A114ca2B26267983ef51aD8664Cabde4034",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/8284/small/luna1557227471663.png?1567147072"
    },
    {
      "chainId": 137,
      "name": "MAI",
      "symbol": "MAI",
      "address": "0xa3Fa99A148fA48D14Ed51d610c367C61876997F1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10238.png"
    },
    {
      "chainId": 137,
      "name": "Decentraland MANA",
      "symbol": "MANA",
      "address": "0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1966.png"
    },
    {
      "chainId": 137,
      "name": "Mask Network",
      "symbol": "MASK",
      "address": "0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8536.png"
    },
    {
      "chainId": 137,
      "name": "MASQ",
      "symbol": "MASQ",
      "address": "0xEe9A352F6aAc4aF1A5B9f467F6a93E0ffBe9Dd35",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8376.png"
    },
    {
      "chainId": 137,
      "name": "Monsoon Finance",
      "symbol": "MCASH",
      "address": "0xa25610a77077390A75aD9072A084c5FbC7d43A0d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11233.png"
    },
    {
      "chainId": 137,
      "name": "MCHCoin",
      "symbol": "MCHC",
      "address": "0xee7666aACAEFaa6efeeF62ea40176d3eB21953B9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9686.png"
    },
    {
      "chainId": 137,
      "name": "MacaronSwap Token",
      "symbol": "MCRN",
      "address": "0xBA25B552C8A098AFdf276324C32C71fE28e0Ad40",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8880.png"
    },
    {
      "chainId": 137,
      "name": "Morpheus Infrastructure Token",
      "symbol": "MITx",
      "address": "0x31042A4E66eDa0d12143ffc8cC1552D611dA4cbA",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2709.png"
    },
    {
      "chainId": 137,
      "name": "Million",
      "symbol": "MM",
      "address": "0x5647Fe4281F8F6F01E84BCE775AD4b828A7b8927",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10866.png"
    },
    {
      "chainId": 137,
      "name": "Museum of Crypto Art",
      "symbol": "MOCA",
      "address": "0xcE899f26928a2B21c6a2Fddd393EF37c61dbA918",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10220.png"
    },
    {
      "chainId": 137,
      "name": "Ocean Token",
      "symbol": "mOCEAN",
      "address": "0x282d8efCe846A88B159800bd4130ad77443Fa1A1",
      "decimals": 18,
      "logoURI": "https://oceanprotocol.com/static/4ad704a150d436a1f32d495413fc47cd/favicon-white.png"
    },
    {
      "chainId": 137,
      "name": "Monavale",
      "symbol": "MONA",
      "address": "0x6968105460f67c3BF751bE7C15f92F5286Fd0CE5",
      "decimals": 18,
      "logoURI": "https://i.imgur.com/FR12tmm.jpg"
    },
    {
      "chainId": 137,
      "name": "MoonEdge",
      "symbol": "MOONED",
      "address": "0x7E4c577ca35913af564ee2a24d882a4946Ec492B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10695.png"
    },
    {
      "chainId": 137,
      "name": "SHEESHA POLYGON",
      "symbol": "mSHEESHA",
      "address": "0x88C949b4eB85a90071f2C0beF861BDDEe1a7479D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16972.png"
    },
    {
      "chainId": 137,
      "name": "MaticVerse",
      "symbol": "Mverse",
      "address": "0xFEb090FcD433dE479396E82DB8B83a470dbAD3c9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11062.png"
    },
    {
      "chainId": 137,
      "name": "Mysterium",
      "symbol": "MYST",
      "address": "0x1379E8886A944d2D9d440b3d88DF536Aea08d9F3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1721.png"
    },
    {
      "chainId": 137,
      "name": "Nexo",
      "symbol": "NEXO",
      "address": "0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/200x200/2694.png"
    },
    {
      "chainId": 137,
      "name": "NASDEX Token",
      "symbol": "NSDX",
      "address": "0xE8d17b127BA8b9899a160D9a07b69bCa8E08bfc6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12312.png"
    },
    {
      "chainId": 137,
      "name": "NSHARE",
      "symbol": "NSHARE",
      "address": "0x948D0a28b600BDBd77AF4ea30E6F338167034181",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16225.png"
    },
    {
      "chainId": 137,
      "name": "OM",
      "symbol": "OM",
      "address": "0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6536.png"
    },
    {
      "chainId": 137,
      "name": "OpenOcean",
      "symbol": "OOE",
      "address": "0x9d5565dA88e596730522CbC5a918d2A89dbC16d9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9938.png"
    },
    {
      "chainId": 137,
      "name": "Orbit Bridge Polygon AUTOv2",
      "symbol": "PAUTO",
      "address": "0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/13751/small/autofarm_icon_200x200.png?1611494288"
    },
    {
      "chainId": 137,
      "name": "PolkaBridge",
      "symbol": "PBR",
      "address": "0x0D6ae2a429df13e44A07Cd2969E085e4833f64A0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8320.png"
    },
    {
      "chainId": 137,
      "name": "pTokens BTC",
      "symbol": "pBTC",
      "address": "0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5434.png"
    },
    {
      "chainId": 137,
      "name": "Polygon Ecosystem Index",
      "symbol": "PECO",
      "address": "0xA9536B9c75A9E0faE3B56a96AC8EdF76AbC91978",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14218.png"
    },
    {
      "chainId": 137,
      "name": "PERA",
      "symbol": "PERA",
      "address": "0xe95fD76CF16008c12FF3b3a937CB16Cd9Cc20284",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9785.png"
    },
    {
      "chainId": 137,
      "name": "Phoenix Token",
      "symbol": "PHX",
      "address": "0x9C6BfEdc14b5C23E3900889436Edca7805170f01",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/17675/small/phx_logo.png?1628832432"
    },
    {
      "chainId": 137,
      "name": "PLOT",
      "symbol": "PLOT",
      "address": "0xe82808eaA78339b06a691fd92E1Be79671cAd8D3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7422.png"
    },
    {
      "chainId": 137,
      "name": "PILLAR",
      "symbol": "PLR",
      "address": "0xa6b37fC85d870711C56FbcB8afe2f8dB049AE774",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/809/small/v2logo-1.png?1624906282"
    },
    {
      "chainId": 137,
      "name": "pTokens PNT",
      "symbol": "PNT",
      "address": "0xB6bcae6468760bc0CDFb9C8ef4Ee75C9dd23e1Ed",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11659/small/pNetwork.png?1592411134"
    },
    {
      "chainId": 137,
      "name": "ParaSwap",
      "symbol": "PSP",
      "address": "0x42d61D766B85431666B39B89C43011f24451bFf6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14534.png"
    },
    {
      "chainId": 137,
      "name": "PYR Token",
      "symbol": "PYR",
      "address": "0x430EF9263E76DAE63c84292C3409D61c598E9682",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9308.png"
    },
    {
      "chainId": 137,
      "name": "Qi Dao",
      "symbol": "QI",
      "address": "0x580A84C73811E1839F75d86d75d88cCa0c241fF4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10237.png"
    },
    {
      "chainId": 137,
      "name": "Quickswap",
      "symbol": "QUICK [OLD]",
      "address": "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8206.png"
    },
    {
      "chainId": 137,
      "name": "Quickswap",
      "symbol": "QUICK",
      "address": "0xb5c064f955d8e7f38fe0460c556a72987494ee17",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8206.png"
    },
    {
      "chainId": 137,
      "name": "Zerogoki Token",
      "symbol": "REI",
      "address": "0xB9f9e37c2CdbaFF928C3Da730b02F06fE09aE70E",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/17015/small/iconZerogoki.png?1626618921"
    },
    {
      "chainId": 137,
      "name": "Relay Token",
      "symbol": "RELAY",
      "address": "0x904371845Bc56dCbBcf0225ef84a669b2fD6bd0d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11317.png"
    },
    {
      "chainId": 137,
      "name": "REVV",
      "symbol": "REVV",
      "address": "0x70c006878a5A50Ed185ac4C87d837633923De296",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6993.png"
    },
    {
      "chainId": 137,
      "name": "Darwinia",
      "symbol": "Ring",
      "address": "0x9C1C23E60B72Bc88a043bf64aFdb16A02540Ae8f",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9443/small/RING.png?1615271827"
    },
    {
      "chainId": 137,
      "name": "SAND",
      "symbol": "SAND",
      "address": "0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6210.png"
    },
    {
      "chainId": 137,
      "name": "SHIBA INU",
      "symbol": "SHIB",
      "address": "0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446"
    },
    {
      "chainId": 137,
      "name": "Snook",
      "symbol": "SNK",
      "address": "0x689f8e5913C158fFB5Ac5aeb83b3C875F5d20309",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11715.png"
    },
    {
      "chainId": 137,
      "name": "SOL",
      "symbol": "SOL",
      "address": "0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4128/small/coinmarketcap-solana-200.png?1616489452"
    },
    {
      "chainId": 137,
      "name": "BSCstarter",
      "symbol": "START",
      "address": "0x6Ccf12B480A99C54b23647c995f4525D544A7E72",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8662.png"
    },
    {
      "chainId": 137,
      "name": "SuperFarm",
      "symbol": "SUPER",
      "address": "0xa1428174F516F527fafdD146b883bB4428682737",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8290.png"
    },
    {
      "chainId": 137,
      "name": "TrustSwap Token",
      "symbol": "SWAP",
      "address": "0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5829.png"
    },
    {
      "chainId": 137,
      "name": "The Crypto Prophecies",
      "symbol": "TCP",
      "address": "0x032F85b8FbF8540a92B986d953e4C3A61C76d39E",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15054/small/tcp.PNG?1619579195"
    },
    {
      "chainId": 137,
      "name": "Cryptomeda",
      "symbol": "TECH",
      "address": "0x6286A9e6f7e745A6D884561D88F94542d6715698",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11579.png"
    },
    {
      "chainId": 137,
      "name": "Telcoin",
      "symbol": "TEL",
      "address": "0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32",
      "decimals": 2,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2394.png"
    },
    {
      "chainId": 137,
      "name": "TETU Reward Token",
      "symbol": "TETU",
      "address": "0x255707B70BF90aa112006E1b07B9AeA6De021424",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12452.png"
    },
    {
      "chainId": 137,
      "name": "THX Network",
      "symbol": "THX",
      "address": "0x2934b36ca9A4B31E633C5BE670C8C8b28b6aA015",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24103.png"
    },
    {
      "chainId": 137,
      "name": "TOMB",
      "symbol": "TOMB",
      "address": "0x0e98C977B943f06075b2D795794238fBfB9b9a34",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/16133/small/tomb_icon_noBG.png?1623055476"
    },
    {
      "chainId": 137,
      "name": "UniArts Network Token",
      "symbol": "UART",
      "address": "0xf244E91A46A9cdd48da295cA5d0B27894f8032B1",
      "decimals": 12,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12750.png"
    },
    {
      "chainId": 137,
      "name": "Unibright",
      "symbol": "UBT",
      "address": "0x7FBc10850caE055B27039aF31bD258430e714c62",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2758.png"
    },
    {
      "chainId": 137,
      "name": "UnirisToken",
      "symbol": "UCO",
      "address": "0x3C720206bFaCB2d16fA3ac0ed87D2048Dbc401Fc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6887.png"
    },
    {
      "chainId": 137,
      "name": "PureFi Token",
      "symbol": "UFI",
      "address": "0x3c205C8B3e02421Da82064646788c82f7bd753B9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10973.png"
    },
    {
      "chainId": 137,
      "name": "UniLend Finance Token",
      "symbol": "UFT",
      "address": "0x5B4CF2C120A9702225814E18543ee658c5f8631e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7412.png"
    },
    {
      "chainId": 137,
      "name": "Unreal Governance Token",
      "symbol": "UGT",
      "address": "0xBa4c54Ea2d66b904C82847A7d2357d22B857E812",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/200x200/11292.png"
    },
    {
      "chainId": 137,
      "name": "Uniswap",
      "symbol": "UNI",
      "address": "0xb33EaAd8d922B1083446DC23f610c2567fB5180f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      "chainId": 137,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 137,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped UST Token",
      "symbol": "UST",
      "address": "0x692597b009d13C4049a947CAB2239b7d6517875F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png"
    },
    {
      "chainId": 137,
      "name": "Vision Token",
      "symbol": "VISION",
      "address": "0x034b2090b579228482520c589dbD397c53Fc51cC",
      "decimals": 18,
      "logoURI": "https://s3-us-west-2.amazonaws.com/acf-uploads/apyvisionlogo200circle.png"
    },
    {
      "chainId": 137,
      "name": "VOXEL Token",
      "symbol": "VOXEL",
      "address": "0xd0258a3fD00f38aa8090dfee343f10A9D4d30D3F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15678.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped CRO",
      "symbol": "WCRO",
      "address": "0xf2D8124b8F9267DaD61351c7aD252362880C6638",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/7310/small/cypto.png?1547043960"
    },
    {
      "chainId": 137,
      "name": "FABWELT",
      "symbol": "WELT",
      "address": "0x23E8B6A3f6891254988B84Da3738D2bfe5E703b9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14681.png"
    },
    {
      "chainId": 137,
      "name": "Wise Token",
      "symbol": "WISE",
      "address": "0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a",
      "decimals": 18,
      "logoURI": "https://etherscan.io/token/images/wisetoken_32.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped Matic",
      "symbol": "WMATIC",
      "address": "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8925.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped ONE",
      "symbol": "WONE",
      "address": "0x80c0CBDB8d0B190238795d376f0bD57fd40525F2",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png?1565065793"
    },
    {
      "chainId": 137,
      "name": "Wootrade Network",
      "symbol": "WOO",
      "address": "0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7501.png"
    },
    {
      "chainId": 137,
      "name": "WOWswap",
      "symbol": "WOW",
      "address": "0x855D4248672a1fCE482165e8DBE1207b94b1968a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8605.png"
    },
    {
      "chainId": 137,
      "name": "WazirX",
      "symbol": "WRX",
      "address": "0x72d6066F486bd0052eefB9114B66ae40e0A6031a",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5161.png"
    },
    {
      "chainId": 137,
      "name": "Wall Street Games",
      "symbol": "WSG",
      "address": "0x3C1BB39bb696B443a1D80BB2b3a3d950Ba9DEE87",
      "decimals": 18,
      "logoURI": "https://i.imgur.com/BDibHLm.png"
    },
    {
      "chainId": 137,
      "name": "XCAD Token",
      "symbol": "XCAD",
      "address": "0xA55870278d6389ec5B524553D03C04F5677c061E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9868.png"
    },
    {
      "chainId": 137,
      "name": "X-Cash",
      "symbol": "XCash",
      "address": "0x03678f2c2c762DC63c2Bb738c3a837D366eDa560",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3334.png"
    },
    {
      "chainId": 137,
      "name": "Decentral Games Governance",
      "symbol": "xDG",
      "address": "0xc6480Da81151B2277761024599E8Db2Ad4C388C8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15510.png"
    },
    {
      "chainId": 137,
      "name": "Exeedme",
      "symbol": "XED",
      "address": "0x2fe8733dcb25BFbbA79292294347415417510067",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8163.png"
    },
    {
      "chainId": 137,
      "name": "YfDAI.finance",
      "symbol": "Yf-DAI",
      "address": "0x7E7fF932FAb08A0af569f93Ce65e7b8b23698Ad8",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/12385/small/1619048513068.png?1622193581"
    },
    {
      "chainId": 137,
      "name": "yearn.finance",
      "symbol": "YFI",
      "address": "0xDA537104D6A5edd53c6fBba9A898708E465260b6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png"
    },
    {
      "chainId": 137,
      "name": "ZigCoin",
      "symbol": "ZIG",
      "address": "0x7BeBd226154E865954A87650FAefA8F485d36081",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9260.png"
    },
    {
      "chainId": 137,
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 137,
      "name": "Synapse",
      "symbol": "SYN",
      "address": "0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png"
    },
    {
      "chainId": 137,
      "name": "SushiSwap",
      "symbol": "SUSHI",
      "address": "0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
      "chainId": 137,
      "name": "Render Token",
      "symbol": "RNDR",
      "address": "0x61299774020dA444Af134c82fa83E3810b309991",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5690.png"
    },
    {
      "chainId": 137,
      "name": "Polychain Monsters",
      "symbol": "PMON",
      "address": "0x1796ae0b0fa4862485106a0de9b654eFE301D0b2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8968.png"
    },
    {
      "chainId": 137,
      "name": "Rubic",
      "symbol": "RBC",
      "address": "0xc3cFFDAf8F3fdF07da6D5e3A89B8723D5E385ff8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7219.png"
    },
    {
      "chainId": 137,
      "name": "Binance USD",
      "symbol": "BUSD",
      "address": "0xA8D394fE7380b8cE6145d5f85E6aC22d4E91ACDe",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 137,
      "name": "PlayDapp",
      "symbol": "PLA",
      "address": "0x8765f05ADce126d70bcdF1b0a48Db573316662eB",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7461.png"
    },
    {
      "chainId": 137,
      "name": "Affyn",
      "symbol": "FYN",
      "address": "0x3B56a704C01D650147ADE2b8cEE594066b3F9421",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17073.png"
    },
    {
      "chainId": 137,
      "name": "Kitsumon",
      "symbol": "$KMC",
      "address": "0x44d09156c7b4ACf0C64459Fbcced7613F5519918",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16326.png"
    },
    {
      "chainId": 137,
      "name": "Karmaverse",
      "symbol": "Knot",
      "address": "0xb763F1177E9B2Fb66FBe0d50372E3e2575c043e5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18749.png"
    },
    {
      "chainId": 137,
      "name": "Lido DAO",
      "symbol": "LDO",
      "address": "0xC3C7d422809852031b44ab29EEC9F1EfF2A58756",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8000.png"
    },
    {
      "chainId": 137,
      "name": "iMe Lab",
      "symbol": "LIME",
      "address": "0x7f67639Ffc8C93dD558d452b8920b28815638c44",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10469.png"
    },
    {
      "chainId": 137,
      "name": "Vigorus",
      "symbol": "VIS",
      "address": "0xcC1B9517460D8aE86fe576f614d091fCa65a28Fc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13103.png"
    },
    {
      "chainId": 137,
      "name": "Stargate Finance",
      "symbol": "STG",
      "address": "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18934.png"
    },
    {
      "chainId": 137,
      "name": "XDAO",
      "symbol": "XDAO",
      "address": "0x71eeba415a523f5c952cc2f06361d5443545ad28",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21760.png"
    },
    {
      "chainId": 137,
      "name": "Rocket Pool",
      "symbol": "RPL",
      "address": "0x7205705771547cf79201111b4bd8aaf29467b9ec",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/2943.png"
    },
    {
      "chainId": 137,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 137,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 137,
      "name": "Verse",
      "symbol": "VERSE",
      "address": "0xc708d6f2153933daa50b2d0758955be0a93a8fec",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22929.png"
    },
    {
      "chainId": 137,
      "name": "USD+",
      "symbol": "USD+",
      "address": "0x236eeC6359fb44CCe8f97E99387aa7F8cd5cdE1f",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20317.png"
    },
    {
      "chainId": 137,
      "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
      "symbol": "USDM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714669489081_7948201150808347.png",
      "name": "Mountain Protocol USDM"
    },
    {
      "chainId": 137,
      "address": "0x27DfD2D7b85e0010542da35C6EBcD59E45fc949D",
      "symbol": "THE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1706579005151_7507709256328334.png",
      "name": "Thena"
    },
    {
      "chainId": 137,
      "address": "0x57999936fc9a9ec0751a8d146cce11901be8bed0",
      "symbol": "VRSW",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704720347580_6095396835298592.jpeg",
      "name": "VirtuSwap"
    },
    {
      "chainId": 137,
      "address": "0x0bd49815ea8e2682220bcb41524c0dd10ba71d41",
      "symbol": "PYM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1699907397881_750935026475748.jpg",
      "name": "Playermon"
    },
    {
      "chainId": 137,
      "address": "0x6899face15c14348e1759371049ab64a3a06bfa6",
      "symbol": "SDEX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692240879311_5110646896120243.jpg",
      "name": "SmarDex"
    },
    {
      "chainId": 137,
      "address": "0xbfa35599c7aebb0dace9b5aa3ca5f2a79624d8eb",
      "symbol": "RETRO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690944074418_9885278544632408.jpg",
      "name": "Retro Finance"
    },
    {
      "chainId": 137,
      "address": "0xd1f9c58e33933a993a3891f8acfe05a68e1afc05",
      "symbol": "SFL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1686210570901_9949281779376056.jpg",
      "name": "Sunflower Land"
    },
    {
      "chainId": 137,
      "address": "0x750e4c4984a9e0f12978ea6742bc1c5d248f40ed",
      "symbol": "AXLUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1685934712922_725874789521737.jpg",
      "name": "Axelar USDC"
    },
    {
      "chainId": 137,
      "address": "0xe111178a87a3bff0c8d18decba5798827539ae99",
      "symbol": "EURS",
      "decimals": 2,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1680166925283_17808256522311838.png",
      "name": "STASIS EURO"
    },
    {
      "chainId": 137,
      "address": "0xbfc70507384047aa74c29cdc8c5cb88d0f7213ac",
      "symbol": "ALI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1675854431126_3028622198441615.jpg",
      "name": "Artificial Liquid Intelligence"
    },
    {
      "chainId": 137,
      "address": "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
      "symbol": "BOB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1674201442537_5411928690716046.png",
      "name": "BOB"
    },
    {
      "chainId": 137,
      "address": "0x2760e46d9bb43dafcbecaad1f64b93207f9f0ed7",
      "symbol": "MVX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1672742526350_9871429198839679.png",
      "name": "Metavault"
    },
    {
      "chainId": 137,
      "address": "0x43df9c0a1156c96cea98737b511ac89d0e2a1f46",
      "symbol": "GOVI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1672110577570_11003097278337126.jpg",
      "name": "CVI"
    },
    {
      "chainId": 137,
      "address": "0x22a31bd4cb694433b6de19e0acc2899e553e9481",
      "symbol": "MMF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1672025325148_4211250520429277.jpg",
      "name": "MMFinance"
    },
    {
      "chainId": 137,
      "address": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
      "symbol": "JPYC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671697880645_36944048274298935.png",
      "name": "JPY Coin"
    },
    {
      "chainId": 137,
      "address": "0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39",
      "symbol": "BUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671245075491_7443227084474797.png",
      "name": "Binance-Peg BUSD Token"
    },
    {
      "chainId": 137,
      "address": "0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4",
      "symbol": "stMATIC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1660903337224_4560316585179449.png",
      "name": "Lido Staked Matic"
    },
    {
      "chainId": 137,
      "address": "0x67eb41a14c0fe5cd701fc9d5a3d6597a72f641a6",
      "symbol": "GDDY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1670474157204_2823034827563551.png",
      "name": "Giddy"
    },
    {
      "chainId": 137,
      "address": "0x55555555a687343c6ce28c8e1f6641dc71659fad",
      "symbol": "XY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669284836284_01822542890524259.png",
      "name": "XY Finance"
    },
    {
      "chainId": 137,
      "address": "0x9fb9a33956351cf4fa040f65a13b835a3c8764e3",
      "symbol": "MULTI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1669116498450_2410692863955417.png",
      "name": "Multichain"
    },
    {
      "chainId": 137,
      "address": "0xed755dba6ec1eb520076cec051a582a6d81a8253",
      "symbol": "CHAMP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1668594279439_1547284331169272.jpg",
      "name": "Ultimate Champions "
    },
    {
      "chainId": 137,
      "address": "0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689",
      "symbol": "WNT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1660906742285_38303018002840994.png",
      "name": "Wicrypt"
    },
    {
      "chainId": 137,
      "address": "0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97",
      "symbol": "DFYN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659091813351_7113734711591275.png",
      "name": "Dfyn Network"
    },
    {
      "chainId": 137,
      "address": "0x101A023270368c0D50BFfb62780F4aFd4ea79C35",
      "symbol": "ANKR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1664246990204_3407976441131719.png",
      "name": "Ankr"
    },
    {
      "chainId": 137,
      "address": "0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395",
      "symbol": "DFX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659091862792_3888622196451825.png",
      "name": "DFX Finance"
    },
    {
      "chainId": 137,
      "address": "0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f",
      "symbol": "MUST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659091609818_4204100061089149.png",
      "name": "Must"
    },
    {
      "chainId": 137,
      "address": "0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c",
      "symbol": "KNC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659091376583_2874595764042496.png",
      "name": "Kyber Network Crystal"
    },
    {
      "chainId": 137,
      "address": "0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3",
      "symbol": "BAL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659090722075_30192828471605826.png",
      "name": "Balancer"
    },
    {
      "chainId": 137,
      "address": "0x172370d5cd63279efa6d502dab29171933a610af",
      "symbol": "CRV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1659090503009_4854886895761452.png",
      "name": "Curve DAO"
    },
    {
      "chainId": 137,
      "address": "0x39ab6574c289c3ae4d88500eec792ab5b947a5eb",
      "symbol": "DYST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1656673019589_6675963284640591.png",
      "name": "Dystopia"
    },
    {
      "chainId": 137,
      "address": "0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a",
      "symbol": "MESH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1656329272711_694526707705986.png",
      "name": "Meshswap Protocol"
    },
    {
      "chainId": 137,
      "address": "0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58",
      "symbol": "ankrMATIC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1653739525363_5982120772421002.png",
      "name": "Ankr Staked MATIC"
    },
    {
      "chainId": 137,
      "address": "0x62f594339830b90ae4c084ae7d223ffafd9658a7",
      "symbol": "SPHERE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650033490877_3375525678568654.png",
      "name": "Sphere Finance"
    },
    {
      "chainId": 137,
      "address": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
      "symbol": "deUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650029090036_6170070150622293.png",
      "name": "deBridge USD Coin"
    },
    {
      "chainId": 137,
      "address": "0x1a3acf6d19267e2d3e7f898f42803e90c9219062",
      "symbol": "FXS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1649073312321_4011823693934131.png",
      "name": "Frax Share"
    },
    {
      "chainId": 137,
      "address": "0x823cd4264c1b951c9209ad0deaea9988fe8429bf",
      "symbol": "MAAAVE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648802662108_23062503682757907.png",
      "name": "Matic Aave Interest Bearing AAVE"
    },
    {
      "chainId": 137,
      "address": "0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360",
      "symbol": "AMAAVE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648802493273_5394709792738763.png",
      "name": "Aave Polygon AAVE"
    },
    {
      "chainId": 137,
      "address": "0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59",
      "symbol": "ICE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1643720325972_8789441259812336.png",
      "name": "Popsicle Finance"
    },
    {
      "chainId": 137,
      "address": "0x9719d867A500Ef117cC201206B8ab51e794d3F82",
      "symbol": "maUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x9719d867a500ef117cc201206b8ab51e794d3f82.png",
      "name": "Matic Aave interest bearing USDC"
    },
    {
      "chainId": 137,
      "address": "0x033d942A6b495C4071083f4CDe1f17e986FE856c",
      "symbol": "AGA",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x033d942a6b495c4071083f4cde1f17e986fe856c.png",
      "name": "AGA Token (PoS)"
    },
    {
      "chainId": 137,
      "address": "0xF84BD51eab957c2e7B7D646A3427C5A50848281D",
      "symbol": "AGAr",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0xf84bd51eab957c2e7b7d646a3427c5a50848281d.png",
      "name": "AGA Rewards (PoS)"
    },
    {
      "chainId": 137,
      "address": "0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e",
      "symbol": "CC10",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x9c49ba0212bb5db371e66b59d1565b7c06e4894e.png",
      "name": "Cryptocurrency Top Tokens Index (PoS)"
    },
    {
      "chainId": 137,
      "address": "0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6",
      "symbol": "CEL",
      "decimals": 4,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0xd85d1e945766fea5eda9103f918bd915fbca63e6.png",
      "name": "Celsius (PoS)"
    },
    {
      "chainId": 137,
      "address": "0x42435F467D33e5C4146a4E8893976ef12BBCE762",
      "symbol": "DEFI5",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x42435f467d33e5c4146a4e8893976ef12bbce762.png",
      "name": "DEFI Top 5 Tokens Index (PoS)"
    },
    {
      "chainId": 137,
      "address": "0x8a2870fb69A90000D6439b7aDfB01d4bA383A415",
      "symbol": "DEGEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x8a2870fb69a90000d6439b7adfb01d4ba383a415.png",
      "name": "DEGEN Index (PoS)"
    },
    {
      "chainId": 137,
      "address": "0xF4B0903774532AEe5ee567C02aaB681a81539e92",
      "symbol": "GAJ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0xf4b0903774532aee5ee567c02aab681a81539e92.png",
      "name": "PolyGaj Token"
    },
    {
      "chainId": 137,
      "address": "0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b",
      "symbol": "Krill",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x05089c9ebffa4f0aca269e32056b1b36b37ed71b.png",
      "name": "Krill"
    },
    {
      "chainId": 137,
      "address": "0x8A953CfE442c5E8855cc6c61b1293FA648BAE472",
      "symbol": "PolyDoge",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x8a953cfe442c5e8855cc6c61b1293fa648bae472.png",
      "name": "PolyDoge"
    },
    {
      "chainId": 137,
      "address": "0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61",
      "symbol": "PolyMoon",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0xefb3009ddac87e8144803d78e235e7fb4cd36e61.png",
      "name": "PolyMoon"
    },
    {
      "chainId": 137,
      "address": "0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79",
      "symbol": "SX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79.png",
      "name": "SportX (PoS)"
    },
    {
      "chainId": 137,
      "address": "0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16",
      "symbol": "WOLF",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x8f18dc399594b451eda8c5da02d0563c0b2d0f16.png",
      "name": "moonwolf.io"
    },
    {
      "chainId": 137,
      "address": "0x5f1657896b38c4761dbc5484473c7a7c845910b6",
      "symbol": "pSWAMP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x5f1657896b38c4761dbc5484473c7a7c845910b6.png",
      "name": "pSwampy"
    },
    {
      "chainId": 137,
      "address": "0x4c16f69302ccb511c5fac682c7626b9ef0dc126a",
      "symbol": "polyBUNNY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/polygon/0x4c16f69302ccb511c5fac682c7626b9ef0dc126a.png",
      "name": "Polygon BUNNY"
    },
    {
      "chainId": 137,
      "address": "0x3eB177A6693eC81d1E170136f8AD02fffBE172a7",
      "symbol": "AUMI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635228244417_8097348181280004.png",
      "name": "AutoMatic Network"
    },
    {
      "chainId": 137,
      "address": "0xD3c325848D7c6E29b574Cb0789998b2ff901f17E",
      "symbol": "1ART",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635386525569_726327472199642.png",
      "name": "ArtWallet"
    }
  ],
  "169": [
    {
      "chainId": 169,
      "name": "QuickSwap",
      "symbol": "QUICK",
      "address": "0xE22E3D44Ea9Fb0A87Ea3F7a8f41D869C677f0020",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19966.png"
    },
    {
      "chainId": 169,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xf417F5A458eC102B90352F697D6e2Ac3A3d2851f",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 169,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xb73603C5d87fA094B7314C74ACE2e64D165016fb",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 169,
      "name": "StakeStone Ether",
      "symbol": "STONE",
      "address": "0xEc901DA9c68E90798BbBb74c11406A32A70652C3",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/33103/standard/200_200.png"
    },
    {
      "chainId": 169,
      "name": "Wrapped Mountain Protocol USD",
      "symbol": "wUSDM",
      "address": "0xbdAd407F77f44F7Da6684B416b1951ECa461FB07",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/33785/standard/wUSDM_PNG_240px.png"
    },
    {
      "chainId": 169,
      "name": "Manta Network",
      "symbol": "MANTA",
      "address": "0x95CeF13441Be50d20cA4558CC0a27B601aC544E5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13631.png"
    },
    {
      "chainId": 169,
      "name": "Wrapped liquid staked Ether 2.0",
      "symbol": "wstETH",
      "address": "0x2FE3AD97a60EB7c79A976FC18Bb5fFD07Dd94BA5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 169,
      "name": "Rocket Pool ETH",
      "symbol": "rETH",
      "address": "0x6E9655611b42C10b9aF25B6ca08bE349Df45c370",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15060.png"
    },
    {
      "chainId": 169,
      "name": "Blast ETH",
      "symbol": "BETH",
      "address": "0xaff124456115674d04B1caAD15b627Da95dBd85C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 169,
      "name": "Wrapped Mountain Protocol USD",
      "symbol": "wUSDM",
      "address": "0xbdAd407F77f44F7Da6684B416b1951ECa461FB07",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13631.png"
    },
    {
      "chainId": 169,
      "address": "0x0dc808adce2099a9f62aa87d9670745aba741746",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704871506364_5434002482450826.png",
      "name": "Bridged Wrapped Ether (Manta Pacific)"
    },
    {
      "chainId": 169,
      "address": "0x91647632245caBf3d66121F86C387aE0ad295F9A",
      "symbol": "iZi",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/izumi.svg",
      "name": "iZUMi Token"
    },
    {
      "chainId": 169,
      "address": "0x078f712f038a95beea94f036cadb49188a90604b",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 169,
      "address": "0x1c466b9371f8aBA0D7c458bE10a62192Fcb8Aa71",
      "symbol": "DAI",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/dai.png",
      "name": "Dai Stablecoin"
    },
    {
      "chainId": 169,
      "address": "0x305E88d809c9DC03179554BFbf85Ac05Ce8F18d6",
      "symbol": "WBTC",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/WBTC.png",
      "name": "Wrapped BTC"
    },
    {
      "chainId": 169,
      "address": "0x6fae4d9935e2fcb11fc79a64e917fb2bf14dafaa",
      "symbol": "TIA",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/tia.png",
      "name": "TIA"
    },
    {
      "chainId": 169,
      "address": "0xCd5d6dE3fdBce1895F0Dac13A065673599ED6806",
      "symbol": "ASM",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/asm.png",
      "name": "Asmatch Token"
    },
    {
      "chainId": 169,
      "address": "0x20A512dbdC0D006f46E6cA11329034Eb3d18c997",
      "symbol": "LAB.m",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/labm.png",
      "name": "LayerBank Token"
    },
    {
      "chainId": 169,
      "address": "0xB385E52903C802B3bDca7C4D0C78460A8988E1ce",
      "symbol": "BEL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bel.png",
      "name": "Bella Token"
    },
    {
      "chainId": 169,
      "address": "0x19585009Ce333efCC4a383c28B27A0Eb2e8497Ea",
      "symbol": "WEBMI",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/webmi.png",
      "name": "Webmi Token"
    },
    {
      "chainId": 169,
      "address": "0xcba2aeec821b0b119857a9ab39e09b034249681a",
      "symbol": "stMANTA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/stmanta.png",
      "name": "Staked MANTA"
    },
    {
      "chainId": 169,
      "address": "0x0d613b80f9afb3cef99fe26702227d74b0178740",
      "symbol": "MNU",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mnu.png",
      "name": "Minu the Manta"
    },
    {
      "chainId": 169,
      "address": "0xaa902c15676c076ec0e7cb7cc57201d2162e2eb6",
      "symbol": "MANTE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mante.png",
      "name": "MANTE"
    },
    {
      "chainId": 169,
      "address": "0xCA24Fdce9d4D9BD69c829689bAEa02E34d025f43",
      "symbol": "KUMA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/kuma.png",
      "name": "KUMA"
    },
    {
      "chainId": 169,
      "address": "0x71763103f697E550200cF4a5C25850114F560CA7",
      "symbol": "MZARD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mzard.png",
      "name": "Mantizard Token"
    },
    {
      "chainId": 169,
      "address": "0x95228f93CAadb0385A398dC7d3F2d49f7FAcf5EA",
      "symbol": "LUBU",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/lubu.png",
      "name": "LubuInc Token"
    },
    {
      "chainId": 169,
      "address": "0xc9144b827604048d66a3455f8f5632cece651fc5",
      "symbol": "KENNY",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/kenny.png",
      "name": "Clark Kenny Token"
    },
    {
      "chainId": 169,
      "address": "0x3ef9c52c921600e43e42bd6aed33c6f8bf3123fb",
      "symbol": "MONK",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/monk.png",
      "name": "MONK BOT Token"
    },
    {
      "chainId": 169,
      "address": "0x7cf9937f62c9785910de90c9296000f96d9b682e",
      "symbol": "Manti",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/manti.png",
      "name": "Manti Token"
    },
    {
      "chainId": 169,
      "address": "0x3867ddb33b95252cd661879a335f1a826c2172b0",
      "symbol": "CrabbyCat",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/crabbycat.png",
      "name": "Crabby Cat Token"
    }
  ],
  "252": [
    {
      "chainId": 252,
      "name": "Frax",
      "symbol": "FRAX",
      "address": "0xfc00000000000000000000000000000000000001",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    }
  ],
  "288": [
    {
      "chainId": 288,
      "name": "AlphaCyber",
      "symbol": "aCYBER",
      "address": "0x641e3C61DBFc5C9eD8f620eb474271cd4439f7d5",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x641e3C61DBFc5C9eD8f620eb474271cd4439f7d5/logo.png"
    },
    {
      "chainId": 288,
      "name": "Banana",
      "symbol": "BANA",
      "address": "0xc67b9B1B0557aeAfA10AA1fFa1d7c87087a6149E",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xc67b9B1B0557aeAfA10AA1fFa1d7c87087a6149E/logo.png"
    },
    {
      "chainId": 288,
      "name": "BobaDoge",
      "symbol": "BDoge",
      "address": "0x121636C43E96D97AB00B6c6994cDDEBEf27dE1C7",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x121636C43E96D97AB00B6c6994cDDEBEf27dE1C7/logo.png"
    },
    {
      "chainId": 288,
      "name": "Brewery",
      "symbol": "BRE",
      "address": "0x91dED49c35642dAb13C3bAdABa1AE7b6DD59c90f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19256.png"
    },
    {
      "chainId": 288,
      "name": "BobaTeaInu",
      "symbol": "BTEA",
      "address": "0x9c3a582A678BE0B0E954d7a5C07b0C7Eb8EF1e03",
      "decimals": 9,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x9c3a582A678BE0B0E954d7a5C07b0C7Eb8EF1e03/logo.png"
    },
    {
      "chainId": 288,
      "name": "Boba WAGMI v0 Option",
      "symbol": "WAGMIV0",
      "address": "0x8493C4d9Cd1a79be0523791E3331c78Abb3f9672",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x8493C4d9Cd1a79be0523791E3331c78Abb3f9672/logo.png"
    },
    {
      "chainId": 288,
      "name": "Boba WAGMI v1 Option",
      "symbol": "WAGMIV1",
      "address": "0xCe055Ea4f29fFB8bf35E852522B96aB67Cbe8197",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xCe055Ea4f29fFB8bf35E852522B96aB67Cbe8197/logo.png"
    },
    {
      "chainId": 288,
      "name": "Zencha",
      "symbol": "ZENC",
      "address": "0x7445Efc8A6F1279434133408BEdeb6Eb4d1537Ab",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x7445Efc8A6F1279434133408BEdeb6Eb4d1537Ab/logo.png"
    },
    {
      "chainId": 288,
      "name": "Synapse",
      "symbol": "SYN",
      "address": "0xb554A55358fF0382Fb21F0a478C3546d1106Be8c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png"
    },
    {
      "chainId": 288,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 288,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0xdc0486f8bf31DF57a952bcd3c1d3e166e3d9eC8b",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 288,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 288,
      "name": "Boba Token",
      "symbol": "BOBA",
      "address": "0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14556.png"
    },
    {
      "chainId": 288,
      "name": "OolongSwap Token",
      "symbol": "OLO",
      "address": "0x5008F837883EA9a07271a1b5eB0658404F5a9610",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15072.png"
    }
  ],
  "324": [
    {
      "chainId": 324,
      "name": "Mute",
      "symbol": "MUTE",
      "address": "0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8795.png"
    },
    {
      "chainId": 324,
      "name": "Whisper",
      "symbol": "WISP",
      "address": "0xc8Ec5B0627C794de0e4ea5d97AD9A556B361d243",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24192.png"
    },
    {
      "chainId": 324,
      "name": "zkDoge",
      "symbol": "ZKDOGE",
      "address": "0xbFB4b5616044Eded03e5b1AD75141f0D9Cb1499b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24161.png"
    },
    {
      "chainId": 324,
      "name": "ZKCULT",
      "symbol": "zCULT",
      "address": "0x8aAAF6E6167825829Ab29F260f246AFE742FB243",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24299.png"
    },
    {
      "chainId": 324,
      "name": "ZK INU",
      "symbol": "$ZKINU",
      "address": "0x45559297BdEDf453e172833AC7086f7D03f6690B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24274.png"
    },
    {
      "chainId": 324,
      "name": "ZKInu",
      "symbol": "ZINU",
      "address": "0xa51bc2433a33c448dd40f7074bcab751a1922706",
      "decimals": 18,
      "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zinu.png"
    },
    {
      "chainId": 324,
      "name": "ZKRISE",
      "symbol": "ZKRISE",
      "address": "0x7C9becB53A7702244Ca260Fd01B4748756CE7BB3",
      "decimals": 18,
      "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zkrise.png"
    },
    {
      "chainId": 324,
      "name": "Zynergy",
      "symbol": "ZYN",
      "address": "0x0231B3DE40B6B3BDd28dcEf037F1b7a3fCf5A95a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24358.png"
    },
    {
      "chainId": 324,
      "name": "Symbiosis",
      "symbol": "SIS",
      "address": "0xdd9f72afED3631a6C85b5369D84875e6c42f1827",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15084.png"
    },
    {
      "chainId": 324,
      "name": "Zksync Shib",
      "symbol": "ZKSHIB",
      "address": "0x5e38cb3e6c0faafaa5c32c482864fcef5a0660ad",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24270.png"
    },
    {
      "chainId": 324,
      "name": "Ether",
      "symbol": "ETH",
      "address": "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 324,
      "name": "Furucombo",
      "symbol": "COMBO",
      "address": "0xc2b13bb90e33f1e191b8aa8f44ce11534d5698e3",
      "decimals": 18,
      "logoURI": "https://tokens.1inch.io/324/0xc2b13bb90e33f1e191b8aa8f44ce11534d5698e3.png"
    },
    {
      "chainId": 324,
      "name": "Perpetual",
      "symbol": "PERP",
      "address": "0x42c1c56be243c250ab24d2ecdcc77f9ccaa59601",
      "decimals": 18,
      "logoURI": "https://tokens.1inch.io/0x42c1c56be243c250ab24d2ecdcc77f9ccaa59601.png"
    },
    {
      "chainId": 324,
      "name": "ZkSync Gem Token",
      "symbol": "ZGEM",
      "address": "0x2b64237277c605d07f17b96f9627712340c32981",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24150.png"
    },
    {
      "chainId": 324,
      "name": "Velocore",
      "symbol": "VC",
      "address": "0x85d84c774cf8e9ff85342684b0e795df72a24908",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24254.png"
    },
    {
      "chainId": 324,
      "name": "USD+",
      "symbol": "USD+",
      "address": "0x8e86e46278518efc1c5ced245cba2c7e3ef11557",
      "decimals": 6,
      "logoURI": "https://tokens.1inch.io/0x8e86e46278518efc1c5ced245cba2c7e3ef11557.png"
    },
    {
      "chainId": 324,
      "name": "Hush",
      "symbol": "HUSH",
      "address": "0x6d27b6252b980ba4350a1e349a3b684237d5e986",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24648.png"
    },
    {
      "chainId": 324,
      "name": "Metavault Trade",
      "symbol": "MVX",
      "address": "0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305",
      "decimals": 18,
      "logoURI": "https://tokens.1inch.io/0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305.png"
    },
    {
      "chainId": 324,
      "name": "LUSD Stablecoin",
      "symbol": "LUSD",
      "address": "0x503234f203fc7eb888eec8513210612a43cf6115",
      "decimals": 18,
      "logoURI": "https://tokens.1inch.io/0x503234f203fc7eb888eec8513210612a43cf6115.png"
    },
    {
      "chainId": 324,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 324,
      "name": "Wrapped Bitcoin",
      "symbol": "WBTC",
      "address": "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 324,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x16a9494e257703797d747540f01683952547ee5b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 324,
      "name": "zkUSD Dollar",
      "symbol": "zkUSD",
      "address": "0xfc7e56298657b002b3e656400e746b7212912757",
      "decimals": 6,
      "logoURI": "/icons/tokens/zkUSD.png"
    },
    {
      "chainId": 324,
      "name": "crvUSD",
      "symbol": "crvUSD",
      "address": "0x43cd37cc4b9ec54833c8ac362dd55e58bfd62b86",
      "decimals": 18,
      "logoURI": "https://icons.llamao.fi/icons/protocols/crvusd?w=48&h=48"
    },
    {
      "chainId": 324,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 324,
      "name": "Wrapped liquid staked Ether 2.0",
      "symbol": "wstETH",
      "address": "0x703b52F2b28fEbcB60E1372858AF5b18849FE867",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 324,
      "name": "Rocket Pool ETH",
      "symbol": "rETH",
      "address": "0x32Fd44bB869620C0EF993754c8a00Be67C464806",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15060.png"
    },
    {
      "chainId": 324,
      "name": "ZKsync",
      "symbol": "ZK",
      "address": "0x5A7d6b2F92C77FAD6CCaBd7EE0624E64907Eaf3E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24091.png"
    },
    {
      "chainId": 324,
      "address": "0x2141d7fe06A1d69c016fC638bA75b6Ef92Fa1435",
      "symbol": "ZKID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700036072858_07864293052047677.png",
      "name": "zkSync id"
    },
    {
      "chainId": 324,
      "address": "0xe027d939f7de6f521675907cf086f59e4d75b876",
      "symbol": "SLR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1697784152197_011984332394620267.png",
      "name": "Solarcoin"
    },
    {
      "chainId": 324,
      "address": "0x31c2c031fdc9d33e974f327ab0d9883eae06ca4a",
      "symbol": "ZF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1697449841221_19154925245814347.jpeg",
      "name": "zkSwap Finance"
    },
    {
      "chainId": 324,
      "address": "0x5756a28e2aae01f600fc2c01358395f5c1f8ad3a",
      "symbol": "VS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692784393231_3298822461701647.png",
      "name": "veSync"
    },
    {
      "chainId": 324,
      "address": "0x240f765af2273b0cab6caff2880d6d8f8b285fa4",
      "symbol": "SWORD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1689128976397_5958909793911085.jpg",
      "name": "eZKalibur"
    },
    {
      "chainId": 324,
      "address": "0x787c09494ec8bcb24dcaf8659e7d5d69979ee508",
      "symbol": "MAV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1688011613464_275452766995389.png",
      "name": "Maverick Protocol"
    },
    {
      "chainId": 324,
      "address": "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681885607540_05879612878434748.png",
      "name": "Wrapped Ether"
    },
    {
      "chainId": 324,
      "address": "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
      "symbol": "USDC.e",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678448675507_5119370827467724.png",
      "name": "Bridged USDC"
    },
    {
      "chainId": 324,
      "address": "0x2039bb4116B4EFc145Ec4f0e2eA75012D6C0f181",
      "symbol": "BUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683543006863_42876782550726644.png",
      "name": "Celer-Binance USD"
    },
    {
      "chainId": 324,
      "address": "0x47260090ce5e83454d5f05a0abbb2c953835f777",
      "symbol": "SPACE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681722999096_909385069062874.png",
      "name": "SpaceFi"
    },
    {
      "chainId": 324,
      "address": "0xf6d9a093a1c69a152d87e269a7d909e9d76b1815",
      "symbol": "LAUNCH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683612318921_8617597377109774.png",
      "name": "Superlauncher"
    },
    {
      "chainId": 324,
      "address": "0xbe9f8c0d6f0fd7e46cdacca340747ea2f247991d",
      "symbol": "IBEX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683612259605_14453394228003758.jpg",
      "name": "Impermax"
    },
    {
      "chainId": 324,
      "address": "0xd0ea21ba66b67be636de1ec4bd9696eb8c61e9aa",
      "symbol": "OT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681885302291_03492546839266697.png",
      "name": "Onchain Trade"
    },
    {
      "chainId": 324,
      "address": "0x99bbe51be7cce6c8b84883148fd3d12ace5787f2",
      "symbol": "VC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681723862493_6429303272368583.png",
      "name": "Velocore"
    },
    {
      "chainId": 324,
      "address": "0x1382628e018010035999A1FF330447a0751aa84f",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 324,
      "address": "0x4b9eb6c0b6ea15176bbf62841c6b2a8a398cb656",
      "symbol": "DAI",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/dai.png",
      "name": "Dai Stablecoin"
    },
    {
      "chainId": 324,
      "address": "0xb6841b40BAAFEdF4e37935270357FF89dF42e68E",
      "symbol": "USDLR",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdlr.png",
      "name": "USDLR by Stable"
    },
    {
      "chainId": 324,
      "address": "0x61B41e0244133B9c9c47A57e51a5EF70be2c5DD4",
      "symbol": "BOLT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bolt.png",
      "name": "Bolt Token"
    },
    {
      "chainId": 324,
      "address": "0x9Db6BF8B0215A02BEA9beF28A92D829FD008D480",
      "symbol": "Cheems",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/cheems.png",
      "name": "Cheems (new)"
    },
    {
      "chainId": 324,
      "address": "0xd599dA85F8Fc4877e61f547dFAcffe1238A7149E",
      "symbol": "Cheems",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/cheems.png",
      "name": "Cheems"
    },
    {
      "chainId": 324,
      "address": "0x496d88D1EFc3E145b7c12d53B78Ce5E7eda7a42c",
      "symbol": "slUSDT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Shared-liquidity USDT"
    },
    {
      "chainId": 324,
      "address": "0x32440D79446E25E90Ff4DEE5807B61D759c8d264",
      "symbol": "stETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/steth.png",
      "name": "Shared-liquidity stETH"
    },
    {
      "chainId": 324,
      "address": "0xbf6F47970276bcbB4E0Ec9dcFd27b16F68b78E74",
      "symbol": "BTC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/btc.png",
      "name": "Shared-liquidity BTC"
    },
    {
      "chainId": 324,
      "address": "0x7eCf006f7c45149B457e9116392279CC8A630F14",
      "symbol": "ZKSP",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zksp.png",
      "name": "ZKSP token"
    },
    {
      "chainId": 324,
      "address": "0x9929bCAC4417A21d7e6FC86F6Dae1Cc7f27A2e41",
      "symbol": "DEXTF",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/dextf.png",
      "name": "DEXTF token"
    },
    {
      "chainId": 324,
      "address": "0x5f7CBcb391d33988DAD74D6Fd683AadDA1123E4D",
      "symbol": "RF",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/rf.png",
      "name": "ReactorFusion"
    },
    {
      "chainId": 324,
      "address": "0x47EF4A5641992A72CFd57b9406c9D9cefEE8e0C4",
      "symbol": "ZAT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zat.png",
      "name": "zkApes token"
    },
    {
      "chainId": 324,
      "address": "0x6aF43486Cb84bE0e3EDdCef93d3C43Ef0C5F63b1",
      "symbol": "xSPACE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/space.png",
      "name": "X SpaceFi token"
    },
    {
      "chainId": 324,
      "address": "0xecd9f240ed3895c77db676004328dd1d246f33c9",
      "symbol": "ERA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zkera.png",
      "name": "ERA token"
    },
    {
      "chainId": 324,
      "address": "0x591CAdFaF2F5dBBD09580C8b74A4770A9255bF28",
      "symbol": "BLADE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/blade.png",
      "name": "Bladedao"
    },
    {
      "chainId": 324,
      "address": "0x5B1635b7E74e004166Ace90f4f70f983824FFe1a",
      "symbol": "ZNS",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zns.png",
      "name": "ZNS token"
    },
    {
      "chainId": 324,
      "address": "0xE9d81b108cF6062607BCD888C745fb67e9d5eFcd",
      "symbol": "esZKDX",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/eszkdx.png",
      "name": "esZKDX token"
    },
    {
      "chainId": 324,
      "address": "0x458A2E32eAbc7626187E6b75f29D7030a5202bD4",
      "symbol": "LSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/lsd.png",
      "name": "LSD token"
    },
    {
      "chainId": 324,
      "address": "0x7E2A6456Bb3eCEcbdb38bC76Ad8dF9e448B15835",
      "symbol": "GGG",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/ggg.png",
      "name": "Good Games Guild"
    },
    {
      "chainId": 324,
      "address": "0xAbdb137D013b8B328FA43Fc04a6fA340D1CeA733",
      "symbol": "ZKS",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zks.png",
      "name": "zkSwap Token"
    },
    {
      "chainId": 324,
      "address": "0x5199EfB3D001309cb6a86fad6764F2BFeE282f1c",
      "symbol": "ZKHYT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/hyt.png",
      "name": "zkSync Hydroxyl Token"
    },
    {
      "chainId": 324,
      "address": "0xB7183e52a6f2D97a675B0e9CBB08Eb01a700D6d3",
      "symbol": "ZKMIT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mit.png",
      "name": "zkSync Meta Interstellar Token"
    },
    {
      "chainId": 324,
      "address": "0xe161a60da0943dad69affe0249b7f623c518337b",
      "symbol": "SPIRAL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/spiral.png",
      "name": "SpiralFi Token"
    },
    {
      "chainId": 324,
      "address": "0xB83CFB285fc8D936E8647FA9b1cC641dBAae92D9",
      "symbol": "BEL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bel.png",
      "name": "Bella Token"
    },
    {
      "chainId": 324,
      "address": "0xed4040fD47629e7c8FBB7DA76bb50B3e7695F0f2",
      "symbol": "HOLD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/hold.png",
      "name": "Holdstation"
    }
  ],
  "1088": [
    {
      "chainId": 1088,
      "name": "Hera Finance",
      "symbol": "HERA",
      "address": "0x6f05709bc91bad933346f9e159f0d3fdbc2c9dce",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19225.png"
    },
    {
      "chainId": 1088,
      "name": "Hummus",
      "symbol": "HUM",
      "address": "0x4aac94985cd83be30164dfe7e9af7c054d7d2121",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19625.png"
    },
    {
      "chainId": 1088,
      "name": "Maia",
      "symbol": "MAIA",
      "address": "0x72c232d56542ba082592dee7c77b1c6cfa758bcd",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17181.png"
    },
    {
      "chainId": 1088,
      "name": "Hermes Protocol",
      "symbol": "HERMES",
      "address": "0xb27bbeaaca2c00d6258c3118bab6b5b6975161c8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21272.png"
    },
    {
      "chainId": 1088,
      "name": "Tethys Finance",
      "symbol": "TETHYS",
      "address": "0x69fdb77064ec5c84fa2f21072973eb28441f43f3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16640.png"
    },
    {
      "chainId": 1088,
      "name": "Tether",
      "symbol": "m.USDT",
      "address": "0xbb06dca3ae6887fabf931640f67cab3e3a16f4dc",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 1088,
      "name": "WETH",
      "symbol": "WETH",
      "address": "0x420000000000000000000000000000000000000a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 1088,
      "name": "USD Coin",
      "symbol": "m.USDC",
      "address": "0xea32a96608495e54156ae48931a7c20f0dcc1a21",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 1088,
      "name": "Netswap",
      "symbol": "NETT",
      "address": "0x90fe084f877c65e1b577c7b2ea64b8d8dd1ab278",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16615.png"
    },
    {
      "chainId": 1088,
      "name": "Metis",
      "symbol": "METIS",
      "address": "0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 1088,
      "address": "0xaf20f5f19698f1d19351028cd7103b63d30de7d7",
      "symbol": "WAGMI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1706843317340_22317235323760287.png",
      "name": "Wagmi"
    }
  ],
  "1101": [
    {
      "chainId": 1101,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xC5015b9d9161Dca7e18e32f6f25C4aD850731Fd4",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
    },
    {
      "chainId": 1101,
      "name": "Matic",
      "symbol": "MATIC",
      "address": "0xa2036f0538221a77A3937F1379699f44945018d0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
      "chainId": 1101,
      "name": "QuickSwap",
      "symbol": "QUICK",
      "address": "0x68286607A1d43602d880D349187c3c48c0fD05E6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19966.png"
    },
    {
      "chainId": 1101,
      "name": "Staked Lido MATIC",
      "symbol": "stMATIC",
      "address": "0x83b874c1e09D316059d929da402dcB1A98e92082",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/18688.png"
    },
    {
      "chainId": 1101,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 1101,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x1E4a5963aBFD975d8c9021ce480b42188849D41d",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 1101,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1",
      "decimals": 8,
      "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png"
    },
    {
      "chainId": 1101,
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 1101,
      "address": "0x37eAA0eF3549a5Bb7D431be78a3D99BD360d19e5",
      "symbol": "USDC.E",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719207922533_09287485310751853.png",
      "name": "Bridged USDC"
    },
    {
      "chainId": 1101,
      "address": "0xdf474b7109b73b7d57926d43598d5934131136b2",
      "symbol": "ANKR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1695905754823_16321387641425722.png",
      "name": "ANKR"
    },
    {
      "chainId": 1101,
      "address": "0xf329bc8e21da7f278ce806e04c2266974edb1386",
      "symbol": "KOKOS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1689339435682_6584604820068647.png",
      "name": "Kokonut Swap"
    },
    {
      "chainId": 1101,
      "address": "0xCf7eceE185f19e2E970a301eE37F93536ed66179",
      "symbol": "frxETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1686291876197_753738369388202.jpg",
      "name": "Frax Ether"
    },
    {
      "chainId": 1101,
      "address": "0xff8544fed5379d9ffa8d47a74ce6b91e632ac44d",
      "symbol": "FRAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1686291773299_6812661047710524.png",
      "name": "Frax"
    }
  ],
  "1116": [
    {
      "chainId": 1116,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 1116,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 1116,
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0xeAB3aC417c4d6dF6b143346a46fEe1B847B50296",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 1116,
      "name": "PLANET",
      "symbol": "PLANET",
      "address": "0x04596cf36027D177d460c2D0c4235eEc0a97EcBB",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/26585.png"
    },
    {
      "chainId": 1116,
      "name": "Bitcoin",
      "symbol": "BTC.b",
      "address": "0x2297aEbD383787A160DD0d9F71508148769342E3",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20721.png"
    },
    {
      "chainId": 1116,
      "name": "Aquarius",
      "symbol": "ARS",
      "address": "0x204e2D49b7cDA6d93301bcF667A2Da28Fb0e5780",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/27847.png"
    },
    {
      "chainId": 1116,
      "address": "0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f",
      "symbol": "CORE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/core.png",
      "name": "CORE Token"
    },
    {
      "chainId": 1116,
      "address": "0x8034ab88c3512246bf7894f57c834dddbd1de01f",
      "symbol": "COREBTC",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/corebtc.svg",
      "name": "Core Wrapped BTC Token"
    },
    {
      "chainId": 1116,
      "address": "0x5b1fb849f1f76217246b8aaac053b5c7b15b7dc3",
      "symbol": "SolvBTC.b",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/solvbtcb.svg",
      "name": "Free Bridged SolvBTC.b"
    },
    {
      "chainId": 1116,
      "address": "0xe04d21d999faedf1e72ade6629e20a11a1ed14fa",
      "symbol": "SolvBTC.m",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/solvbtcm.png",
      "name": "Free Bridged SolvBTC.m"
    },
    {
      "chainId": 1116,
      "address": "0xb3a8f0f0da9ffc65318aa39e55079796093029ad",
      "symbol": "stCORE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/stcore.png",
      "name": "Liquid staked CORE"
    }
  ],
  "1329": [
    {
      "chainId": 1329,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xB75D0B03c06A926e488e2659DF1A861F860bD3d1",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 1329,
      "name": "SEIYAN",
      "symbol": "SEIYAN",
      "address": "0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598",
      "decimals": 6,
      "logoURI": "https://dzyb4dm7r8k8w.cloudfront.net/prod/logos/0x5f0E07dFeE5832Faa00c63F2D33A0D79150E8598/logo.png"
    },
    {
      "chainId": 1329,
      "name": "iSEI",
      "symbol": "iSEI",
      "address": "0x5Cf6826140C1C56Ff49C808A1A75407Cd1DF9423",
      "decimals": 6,
      "logoURI": "https://dzyb4dm7r8k8w.cloudfront.net/prod/logos/0x5Cf6826140C1C56Ff49C808A1A75407Cd1DF9423/logo.png"
    },
    {
      "chainId": 1329,
      "address": "0xe30fedd158a2e3b13e9badaeabafc5516e95e8c7",
      "symbol": "WSEI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718099331562_5858731232221568.png",
      "name": "Wrapped SEI "
    },
    {
      "chainId": 1329,
      "address": "0xdd7d5e4ea2125d43c16eed8f1ffefffa2f4b4af6",
      "symbol": "JLY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718190211995_8422750624736308.png",
      "name": "Jellyverse"
    },
    {
      "chainId": 1329,
      "address": "0xc18b6a15fb0ceaf5eb18696eefcb5bc7b9107149",
      "symbol": "POPO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718190156552_9424625042853143.png",
      "name": "JellyversePopo The Cat"
    },
    {
      "chainId": 1329,
      "address": "0x3894085ef7ff0f0aedf52e2a2704928d1ec074f1",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718099461153_9001280693388223.png",
      "name": "USDC"
    }
  ],
  "1625": [
    {
      "chainId": 1625,
      "address": "0xFbDa5F676cB37624f28265A144A48B0d6e87d3b6",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin"
    },
    {
      "chainId": 1625,
      "address": "0x816E810f9F787d669FB71932DeabF6c83781Cd48",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD"
    },
    {
      "chainId": 1625,
      "address": "0x729ed87bbE7B7e4B7F09BCb9c668580818d98BB9",
      "symbol": "WBTC",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/WBTC.png",
      "name": "Wrapped BTC"
    },
    {
      "chainId": 1625,
      "address": "0xf6f832466Cd6C21967E0D954109403f36Bc8ceaA",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/WETH.png",
      "name": "Wrapped Ether"
    },
    {
      "chainId": 1625,
      "address": "0xBC0f4Ca84D2ac97bc4702564b033A6402159e8DE",
      "symbol": "wstETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/wsteth.png",
      "name": "Wrapped liquid staked Ether 2.0"
    },
    {
      "chainId": 1625,
      "address": "0xeD244AaFCE5962cF43CD780b948c11591663D79d",
      "symbol": "weETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/weeth.png",
      "name": "Wrapped eETH"
    },
    {
      "chainId": 1625,
      "address": "0xBB859E225ac8Fb6BE1C7e38D87b767e95Fef0EbD",
      "symbol": "G",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/gravity.png",
      "name": "Gravity Token"
    }
  ],
  "2001": [
    {
      "chainId": 2001,
      "name": "MilkySwap",
      "symbol": "MILKY",
      "address": "0x063A5E4cD5e15ac66ea47134Eb60e6b30A51B2bf",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19510.png"
    },
    {
      "chainId": 2001,
      "name": "Ravelin Finance",
      "symbol": "RAV",
      "address": "0x9B7c74Aa737FE278795fAB2Ad62dEFDbBAedFBCA",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20125.png"
    },
    {
      "chainId": 2001,
      "name": "Blueshift",
      "symbol": "BLUES",
      "address": "0x8c008BBA2Dd56b99f4A6aB276bE3a478cB075F0C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19595.png"
    },
    {
      "chainId": 2001,
      "name": "DaiStablecoin",
      "symbol": "multiDAI",
      "address": "0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9956/large/4943.png"
    },
    {
      "chainId": 2001,
      "name": "Ethereum",
      "symbol": "multiETH",
      "address": "0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 2001,
      "name": "OccamFi",
      "symbol": "multiOCC",
      "address": "0x461d52769884ca6235b685ef2040f47d30c94eb5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6989.png"
    },
    {
      "chainId": 2001,
      "name": "USDCoin",
      "symbol": "multiUSDC",
      "address": "0xb44a9b6905af7c801311e8f4e76932ee959c663c",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10240.png"
    },
    {
      "chainId": 2001,
      "name": "TetherUSD",
      "symbol": "multiUSDT",
      "address": "0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 2001,
      "name": "WrappedBTC",
      "symbol": "multiWBTC",
      "address": "0x6ab6d61428fde76768d7b45d8bfeec19c6ef91a8",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 2001,
      "name": "Binance Smart Chain",
      "symbol": "multiBNB",
      "address": "0xFbdd194376de19a88118e84E279b977f165d01b8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
    },
    {
      "chainId": 2001,
      "name": "Binance USD",
      "symbol": "multiBUSD",
      "address": "0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 2001,
      "name": "Tether USD",
      "symbol": "ceUSDT",
      "address": "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 2001,
      "name": "USD Coin",
      "symbol": "ceUSDC",
      "address": "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 2001,
      "name": "Dai Stablecoin",
      "symbol": "ceDAI",
      "address": "0x6De33698e9e9b787e09d3Bd7771ef63557E148bb",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/9956/large/4943.png"
    },
    {
      "chainId": 2001,
      "name": "Wrapped Ether",
      "symbol": "ceWETH",
      "address": "0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 2001,
      "name": "Wrapped BTC",
      "symbol": "ceWBTC",
      "address": "0x8d50a024B2F5593605d3cE8183Ca8969226Fcbf8",
      "decimals": 8,
      "logoURI": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png"
    },
    {
      "chainId": 2001,
      "name": "Binance Smart Chain",
      "symbol": "ceBNB",
      "address": "0x7f27352D5F83Db87a5A3E00f4B07Cc2138D8ee52",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
    },
    {
      "chainId": 2001,
      "name": "Binance USD",
      "symbol": "ceBUSD",
      "address": "0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 2001,
      "name": "Polygon",
      "symbol": "ceMATIC",
      "address": "0x8006320739fC281da67Ee62eB9b4Ef8ADD5C903a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png"
    },
    {
      "chainId": 2001,
      "name": "Avalanche",
      "symbol": "ceAVAX",
      "address": "0x86c28C9a6f2DC3C156AA2ad450F0F9d3A5Dec12e",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png"
    },
    {
      "chainId": 2001,
      "name": "Fantom",
      "symbol": "ceFTM",
      "address": "0xc3FEc6F18dDb7583DA572374Ca8d11c6F0590dAe",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png"
    },
    {
      "chainId": 2001,
      "name": "USD Coin",
      "symbol": "sUSDC",
      "address": "0x42110A5133F91B49E32B671Db86E2C44Edc13832",
      "decimals": 6,
      "logoURI": "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/USD_Coin_icon.png"
    },
    {
      "chainId": 2001,
      "name": "Wrapped ADA",
      "symbol": "wADA",
      "address": "0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19369.png"
    },
    {
      "chainId": 2001,
      "name": "World Mobile Token",
      "symbol": "WMT",
      "address": "0x4D5C63c07463EFa5bb26EE0A6D8C39dd0e3DaBD9",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13769.png"
    },
    {
      "chainId": 2001,
      "name": "MuesliSwap",
      "symbol": "MILK",
      "address": "0x386e685B5cBAa7bC06e3Dd2eEcDC56105076e4Fa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17275.png"
    },
    {
      "chainId": 2001,
      "name": "Ravcube Share",
      "symbol": "RSHARE",
      "address": "0xD81E377E9cd5093CE752366758207Fc61317fC70",
      "decimals": 18,
      "logoURI": "https://occamx-icons-bucket.s3.eu-central-1.amazonaws.com/icons/RShareLogoTBG.png"
    },
    {
      "chainId": 2001,
      "name": "Hosky Token",
      "symbol": "HOSKY",
      "address": "0x13fe17701861b769d58f44c2cEBd918886d3D205",
      "decimals": 0,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16755.png"
    },
    {
      "chainId": 2001,
      "name": "Iagon",
      "symbol": "IAG",
      "address": "0x658b95858457549cFC0af513b1b902aB0F6E6946",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11078.png"
    },
    {
      "chainId": 2001,
      "name": "Mimatic",
      "symbol": "MAI",
      "address": "0xb9c8f0d3254007ee4b98970b94544e473cd610ec",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10238.png"
    },
    {
      "chainId": 2001,
      "name": "GeroWallet",
      "symbol": "GERO",
      "address": "0xeA55e366Eb0f4cFAAf8d83dC1fC7286F11b5b1cb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9904.png"
    },
    {
      "chainId": 2001,
      "name": "Charli3",
      "symbol": "C3",
      "address": "0xdE482b84360c248C0d7260826a8B06a5A837235d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9991.png"
    },
    {
      "chainId": 2001,
      "name": "CARD.STARTER",
      "symbol": "CARDS",
      "address": "0xa350679eaFDb5836da9A796fF721a1fd8415c75F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9047.png"
    },
    {
      "chainId": 2001,
      "name": "Symbiosis",
      "symbol": "SIS",
      "address": "0xedd4D7DAa6bf8746997CEbbF974a60B838757601",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15084.png"
    }
  ],
  "2222": [
    {
      "chainId": 2222,
      "name": "Vara",
      "symbol": "VARA",
      "address": "0xE1da44C0dA55B075aE8E2e4b6986AdC76Ac77d73",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23836.png"
    },
    {
      "chainId": 2222,
      "name": "Wrapped KAVA",
      "symbol": "wKAVA",
      "address": "0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/24165.png"
    },
    {
      "chainId": 2222,
      "name": "Ethereum",
      "symbol": "ETH",
      "address": "0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 2222,
      "name": "Wrapped Bitcoin",
      "symbol": "WBTC",
      "address": "0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 2222,
      "name": "Beefy.Finance",
      "symbol": "BIFI",
      "address": "0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10240.png"
    },
    {
      "chainId": 2222,
      "name": "LiquidDriver",
      "symbol": "LQDR",
      "address": "0x6c2c113c8cA73dB67224EF4D8C8dFCeC61e52a9C",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/equilibre-finance/token-images/main/assets/LQDR.png"
    },
    {
      "chainId": 2222,
      "name": "ACryptoS",
      "symbol": "ACS",
      "address": "0x739ca6D71365a08f584c8FC4e1029045Fa8ABC4B",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/equilibre-finance/token-images/main/assets/ACS.png"
    },
    {
      "chainId": 2222,
      "name": "Mare Finance",
      "symbol": "MARE",
      "address": "0xd86C8d4279CCaFbec840c782BcC50D201f277419",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23835.png"
    },
    {
      "chainId": 2222,
      "name": "Tarot",
      "symbol": "TAROT",
      "address": "0x165DBb08de0476271714952C3C1F068693bd60D7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9983.png"
    },
    {
      "chainId": 2222,
      "name": "BOMB",
      "symbol": "fBOMB",
      "address": "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9085.png"
    },
    {
      "chainId": 2222,
      "name": "Lockless Staked-Wrapped KAVA",
      "symbol": "swKAVA",
      "address": "0x9d9682577CA889c882412056669bd936894663fd",
      "decimals": 18,
      "logoURI": "https://raw.githubusercontent.com/equilibre-finance/token-images/main/assets/SWKAVA.png"
    },
    {
      "chainId": 2222,
      "name": "Binance Coin",
      "symbol": "BNB",
      "address": "0xABd380327Fe66724FFDa91A87c772FB8D00bE488",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12546.png"
    },
    {
      "chainId": 2222,
      "address": "0xf3C091ed43de9c270593445163a41A876A0bb3dd",
      "symbol": "ORBS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1723101807712_4334322211563344.jpg",
      "name": "Orbs"
    },
    {
      "chainId": 2222,
      "address": "0x52369B1539EA8F4e1eadEEF18D85462Dcf9a3658",
      "symbol": "KAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718079026625_5804681856506144.png",
      "name": "Kinetix Finance"
    },
    {
      "chainId": 2222,
      "address": "0xb5c4423a65B953905949548276654C96fcaE6992",
      "symbol": "WBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1716175862872_2333452368344655.png",
      "name": "Wrapped BTC"
    },
    {
      "chainId": 2222,
      "address": "0x211Cc4DD073734dA055fbF44a2b4667d5E5fE5d2",
      "symbol": "sUSDe",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712837615627_861380205318913.png",
      "name": "StakedUSDeOFT"
    },
    {
      "chainId": 2222,
      "address": "0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34",
      "symbol": "USDe",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712837516408_9629074232136663.png",
      "name": "USDe"
    },
    {
      "chainId": 2222,
      "address": "0x2dfd4de5ae386cd3f4fc8e2cb39240852e47f5e8",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1707622172532_43498632096242207.png",
      "name": "Wrapped Ether"
    },
    {
      "chainId": 2222,
      "address": "0x15932E26f5BD4923d46a2b205191C4b5d5f43FE3",
      "symbol": "ATOM",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1707622377421_07604884445986815.png",
      "name": "Cosmos Hub"
    },
    {
      "chainId": 2222,
      "address": "0x332c72dd7e77070740f01d2d35851c461585d5d0",
      "symbol": "axlLqdr",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1694415277400_7789258043717533.jpg",
      "name": "Axelar Wrapped Lqdr"
    },
    {
      "chainId": 2222,
      "address": "0x919C1c267BC06a7039e03fcc2eF738525769109c",
      "symbol": "USDt",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1694414936688_25604370296689205.jpg",
      "name": "Tether"
    },
    {
      "chainId": 2222,
      "address": "0x8888888888f004100C0353d657BE6300587A6CcD",
      "symbol": "ACS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692084475414_5436178933304128.jpg",
      "name": "ACryptoS "
    },
    {
      "chainId": 2222,
      "address": "0x1a35EE4640b0A3B87705B0A4B45D227Ba60Ca2ad",
      "symbol": "axlWBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1686279070923_8665889013283781.png",
      "name": "Axelar WBTC"
    },
    {
      "chainId": 2222,
      "address": "0xb829b68f57CC546dA7E5806A929e53bE32a4625D",
      "symbol": "axlWETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1686278521506_015411584423122848.png",
      "name": "Axelar WETH"
    },
    {
      "chainId": 2222,
      "address": "0xDb0E1e86B01c4ad25241b1843E407Efc4D615248",
      "symbol": "USX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679905974110_5991416705535366.png",
      "name": "dForce USD"
    },
    {
      "chainId": 2222,
      "address": "0xb84df10966a5d7e1ab46d9276f55d57bd336afc7",
      "symbol": "MAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679463284591_47237891313686275.png",
      "name": "MAI"
    },
    {
      "chainId": 2222,
      "address": "0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D",
      "symbol": "SUSHI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679388799638_661277707959065.png",
      "name": "Sushi"
    },
    {
      "chainId": 2222,
      "address": "0x5C7e299CF531eb66f2A1dF637d37AbB78e6200C7",
      "symbol": "AXLDAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678435686147_5544470528973675.png",
      "name": "Axelar DAI"
    },
    {
      "chainId": 2222,
      "address": "0x7f5373AE26c3E8FfC4c77b7255DF7eC1A9aF52a6",
      "symbol": "AXLUSDT",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678435638720_788725438689502.png",
      "name": "Axelar USDT"
    },
    {
      "chainId": 2222,
      "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      "symbol": "AXLUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678435412910_10590000213940565.png",
      "name": "Axelar USDC "
    },
    {
      "chainId": 2222,
      "address": "0x59889b7021243dB5B1e065385F918316cD90D46c",
      "symbol": "M-BTC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mbtc.svg",
      "name": "Merlin BTC"
    }
  ],
  "4200": [
    {
      "chainId": 4200,
      "name": "iZUMi Bond USD",
      "symbol": "iUSD",
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19799.png"
    },
    {
      "chainId": 4200,
      "name": "HUHU CAT",
      "symbol": "HUHU",
      "address": "0x7a677e59dC2C8a42d6aF3a62748c5595034A008b",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/huhu.png"
    },
    {
      "chainId": 4200,
      "name": "Bridged BRC20 VOYA",
      "symbol": "VOYA",
      "address": "0x480e158395cc5b41e5584347c495584ca2caf78d",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/voya.png"
    },
    {
      "chainId": 4200,
      "name": "Jeff Token",
      "symbol": "JEFF",
      "address": "0xd79f579d7e34782465863a6f837b2692bda7b989",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/jeff.png"
    },
    {
      "chainId": 4200,
      "name": "Elixir Token",
      "symbol": "ELXR",
      "address": "0xa49fc6e5bbb77622b96bf242fee4028b8b61ffa0",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/elxr.png"
    },
    {
      "chainId": 4200,
      "name": "DRAGON Token",
      "symbol": "DRAGON",
      "address": "0x1dd67628db8661186de74a3adc6e3f44def5709e",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/dragonmerlin.png"
    },
    {
      "chainId": 4200,
      "name": "King Arthur Token",
      "symbol": "ART",
      "address": "0xdbbc61004663f0116dd5ae5751436c722b7168e6",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/art.png"
    },
    {
      "chainId": 4200,
      "name": "MRC404 Token",
      "symbol": "MRC404",
      "address": "0x6df1b05ac85007bcd4f25aca7ab505415b3d51dc",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mrc404.png"
    },
    {
      "chainId": 4200,
      "name": "RAT404 Token",
      "symbol": "RAT404",
      "address": "0xbf108e20fd47995b2b1a316db76c1c145e5bdeac",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/rat404.png"
    },
    {
      "chainId": 4200,
      "name": "BIUU Token",
      "symbol": "BIUU",
      "address": "0x3cFD33fD6f670ebBf63434D4b09DcA6D403aEFea",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/biuu.png"
    },
    {
      "chainId": 4200,
      "name": "Wizard Token",
      "symbol": "Wizard",
      "address": "0x2503d56065a1369d7270dc2038c16a113699454d",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/wizard.png"
    },
    {
      "chainId": 4200,
      "name": "Merlin Pad Token",
      "symbol": "MerlinPad",
      "address": "0x5782d6FdDFE62cb1650FE42582Ef8E37EAEA03E2",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/merlinpad.png"
    },
    {
      "chainId": 4200,
      "name": "Merlin Meme Token (Meme)",
      "symbol": "MERLIN(Meme)",
      "address": "0x402b2e84CB09b08e18Bd47636594Afb789401ddc",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/merlinmeme.png"
    },
    {
      "chainId": 4200,
      "name": "The Grand VOYA Sailor",
      "symbol": "Sailor",
      "address": "0xe380a2d2477799fff336a2937ea00a29a84a22c9",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/sailor.png"
    },
    {
      "chainId": 4200,
      "name": "SPELL Token",
      "symbol": "SPELL",
      "address": "0xcd88e36a9ce94e4c36f08b0b8086a80639e11e7a",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/spell.png"
    },
    {
      "chainId": 4200,
      "name": "Merlin Ball Token",
      "symbol": "BALL",
      "address": "0xF18781109992b12580A646ed0392a6Fc50318209",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/ball.png"
    },
    {
      "chainId": 4200,
      "name": "Merlin Box Token",
      "symbol": "MerlinBox",
      "address": "0x63c2e663a6cfb0f5568c84a1c8134acbe1b88bec",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/merlinbox.png"
    },
    {
      "chainId": 4200,
      "name": "Merlin Land Token",
      "symbol": "MerlinLand",
      "address": "0xf0db39a5e37eb2df2d2b968f3fc8e5d7a9969dea",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/merlinland.png"
    },
    {
      "chainId": 4200,
      "name": "Owl Token",
      "symbol": "Owl",
      "address": "0x62e99191071fc1c5947cf1e21aa95708dcc51adb",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/owl.png"
    }
  ],
  "5000": [
    {
      "chainId": 5000,
      "name": "Mantle Token",
      "symbol": "MNT",
      "address": "0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/27614.png"
    },
    {
      "chainId": 5000,
      "name": "Wrapped MNT",
      "symbol": "WMNT",
      "address": "0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/27614.png"
    },
    {
      "chainId": 5000,
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 5000,
      "name": "Tether",
      "symbol": "USDT",
      "address": "0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 5000,
      "name": "LUSD Stablecoin",
      "symbol": "LUSD",
      "address": "0xf93a85d53e4aF0D62bdf3A83CCFc1EcF3EAf9F32",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/9566.png"
    },
    {
      "chainId": 5000,
      "name": "Pendle Token",
      "symbol": "PENDLE",
      "address": "0xd27B18915e7acc8FD6Ac75DB6766a80f8D2f5729",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/pendle.png"
    },
    {
      "chainId": 5000,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 5000,
      "name": "Wrapped liquid staked Ether 2.0",
      "symbol": "wstETH",
      "address": "0x636D4073738C071326Aa70c9e5DB7C334bEb87bE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 5000,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 5000,
      "name": "Mantle USD",
      "symbol": "mUSD",
      "address": "0xab575258d37EaA5C8956EfABe71F4eE8F6397cF3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/28692.png"
    },
    {
      "chainId": 5000,
      "name": "Axelar Wrapped USDC",
      "symbol": "AxlUSDC",
      "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png"
    },
    {
      "chainId": 5000,
      "name": "Mantle Staked Ether",
      "symbol": "mETH",
      "address": "0xcDA86A272531e8640cD7F1a92c01839911B90bb0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29035.png"
    },
    {
      "chainId": 5000,
      "address": "0xc96de26018a54d51c097160568752c4e3bd6c364",
      "symbol": "FBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1724243939228_08972512822376055.png",
      "name": "Ignition FBTC"
    },
    {
      "chainId": 5000,
      "address": "0xc1e0c8c30f251a07a894609616580ad2ceb547f2",
      "symbol": "CLEO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1716908188004_009910741472122231.png",
      "name": "Cleopatra"
    },
    {
      "chainId": 5000,
      "address": "0xd2b4c9b0d70e3da1fbdd98f469bd02e77e12fc79",
      "symbol": "aUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714393398752_47466011509818173.png",
      "name": "Aurelius USD"
    },
    {
      "chainId": 5000,
      "address": "0x26a6b0dcdcfb981362afa56d581e4a7dba3be140",
      "symbol": "PUFF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712598894990_4742427896141901.png",
      "name": "Puff"
    },
    {
      "chainId": 5000,
      "address": "0x4515a45337f461a11ff0fe8abf3c606ae5dc00c9",
      "symbol": "MOE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704872370365_35490538129625926.png",
      "name": "MOE"
    },
    {
      "chainId": 5000,
      "address": "0xa84bdecd44e6cee1c588a3c97fcc4482831fde05",
      "symbol": "cETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1701892704801_20597586568421122.png",
      "name": "REAX Ethereum"
    },
    {
      "chainId": 5000,
      "address": "0x5be26527e817998a7206475496fde1e68957c5a6",
      "symbol": "USDY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1699420375805_23951117431367952.png",
      "name": "Ondo US Dollar Yield"
    },
    {
      "chainId": 5000,
      "address": "0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1694159850673_01787531906169937.png",
      "name": "USD Coin"
    },
    {
      "chainId": 5000,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 5000,
      "address": "0x217B4382a1De262C0FBa97C1B8378904B4a25e4D",
      "symbol": "$MDragon",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/dragon.png",
      "name": "Mantle Dragon Token"
    },
    {
      "chainId": 5000,
      "address": "0x51cfe5b1E764dC253F4c8C1f19a081fF4C3517eD",
      "symbol": "MINU",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/minu.png",
      "name": "Mantle Inu Token"
    },
    {
      "chainId": 5000,
      "address": "0x25356aeca4210eF7553140edb9b8026089E49396",
      "symbol": "LEND",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/lend.png",
      "name": "Lendle Protocol Token"
    },
    {
      "chainId": 5000,
      "address": "0x6A3b0eb5b57C9a4F5772fc900dAE427E65f8C1a5",
      "symbol": "CTT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/ctt.png",
      "name": "Cashtree Token"
    },
    {
      "chainId": 5000,
      "address": "0x3390108E913824B8eaD638444cc52B9aBdF63798",
      "symbol": "BEL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bel.png",
      "name": "Bella"
    },
    {
      "chainId": 5000,
      "address": "0x23ee2343B892b1BB63503a4FAbc840E0e2C6810f",
      "symbol": "AXL",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/axl.png",
      "name": "Axelar Token"
    },
    {
      "chainId": 5000,
      "address": "0x8fe7176f0bf63358ad9490fd24ac0bdb4dac33a8",
      "symbol": "USDLR",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdlr.png",
      "name": "USDLR by Stable"
    },
    {
      "chainId": 5000,
      "address": "0x7cbf716aa9a6114281188867572ac87586e4abf2",
      "symbol": "stUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/stusd.png",
      "name": "Solv Strategy USD"
    }
  ],
  "5165": [
    {
      "chainId": 5165,
      "name": "Wrapped ETH",
      "symbol": "WETH",
      "address": "0xE5b3562A0fa9eC3e718C96FfE349e1280D2Be591",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 5165,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xDeF886C55a79830C47108eeb9c37e78a49684e41",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    }
  ],
  "7000": [
    {
      "chainId": 7000,
      "name": "USDC.ETH",
      "symbol": "USDC.ETH",
      "address": "0x0cbe0dF132a6c6B4a2974Fa1b7Fb953CF0Cc798a",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 7000,
      "name": "USDT.ETH",
      "symbol": "USDT.ETH",
      "address": "0x7c8dDa80bbBE1254a7aACf3219EBe1481c6E01d7",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 7000,
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "symbol": "iZi",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/izumi.svg",
      "name": "iZUMi Token"
    },
    {
      "chainId": 7000,
      "address": "0x5F0b1a82749cb4E2278EC87F8BF6B618dC71a8bf",
      "symbol": "ZETA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zeta.png",
      "name": "Zeta Token"
    },
    {
      "chainId": 7000,
      "address": "0xd97B1de3619ed2c6BEb3860147E30cA8A7dC9891",
      "symbol": "ETH.ETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/eth.png",
      "name": "ZRC20 ETH-eth_mainnet"
    },
    {
      "chainId": 7000,
      "address": "0x48f80608B672DC30DC7e3dbBd0343c5F02C738Eb",
      "symbol": "BNB.BSC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bnb.png",
      "name": "ZRC20 BNB-bsc_mainnet"
    },
    {
      "chainId": 7000,
      "address": "0x13A0c5930C028511Dc02665E7285134B6d11A5f4",
      "symbol": "BTC.BTC",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/btc.png",
      "name": "ZRC20 BTC-btc_mainnet"
    },
    {
      "chainId": 7000,
      "address": "0x91d4F0D54090Df2D81e834c3c8CE71C6c865e79F",
      "symbol": "USDT.BSC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "ZRC20 USDT-bsc_mainnet"
    },
    {
      "chainId": 7000,
      "address": "0x05BA149A7bd6dC1F937fA9046A9e05C05f3b18b0",
      "symbol": "USDC.BSC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "ZRC20 USDC-bsc_mainnet"
    },
    {
      "chainId": 7000,
      "address": "0x45334a5B0a01cE6C260f2B570EC941C680EA62c0",
      "symbol": "stZETA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/stzeta.svg",
      "name": "Staked ZETA"
    },
    {
      "chainId": 7000,
      "address": "0xcba2aeec821b0b119857a9ab39e09b034249681a",
      "symbol": "stZETA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/stzetaaf.png",
      "name": "Accumulated Finance stZETA"
    }
  ],
  "8453": [
    {
      "chainId": 8453,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 8453,
      "name": "Coinbase Wrapped Staked ETH",
      "symbol": "cbETH",
      "address": "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21535.png"
    },
    {
      "chainId": 8453,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 8453,
      "name": "Wrapped liquid staked Ether",
      "symbol": "wstETH",
      "address": "0xc1CBa3fCea344f92D9239c08C0568f6F2F0ee452",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 8453,
      "name": "Axelar Wrapped USDC",
      "symbol": "AxlUSDC",
      "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png"
    },
    {
      "chainId": 8453,
      "name": "USD+",
      "symbol": "USD+",
      "address": "0xB79DD08EA68A908A97220C76d19A6aA9cBDE4376",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/20317.png"
    },
    {
      "chainId": 8453,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 8453,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0x2416092f143378750bb29b79eD961ab195CcEea5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 8453,
      "address": "0x04d5ddf5f3a8939889f11e97f8c4bb48317f1938",
      "symbol": "USDz",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1724077970886_08008082156507856.png",
      "name": "Anzen USD"
    },
    {
      "chainId": 8453,
      "address": "0xb1a03eda10342529bbf8eb700a06c60441fef25d",
      "symbol": "MIGGLES",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1722570507085_10183024132465901.png",
      "name": "Mister Miggles"
    },
    {
      "chainId": 8453,
      "address": "0x6985884c4392d348587b19cb9eaaf157f13271cd",
      "symbol": "ZRO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719303778544_8407894431829408.jpg",
      "name": "LayerZero"
    },
    {
      "chainId": 8453,
      "address": "0x940181a94a35a4569e4529a3cdfb74e38fd98631",
      "symbol": "AERO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718786697608_600753119549583.png",
      "name": "Aerodrome Finance"
    },
    {
      "chainId": 8453,
      "address": "0x3c3860d89b81c91974Fc1f8A41aeEef604c17058",
      "symbol": "KAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1718079110844_430560228187441.png",
      "name": "Kinetix Finance"
    },
    {
      "chainId": 8453,
      "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
      "symbol": "USDM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714669415426_143530120606147.png",
      "name": "Mountain Protocol USDM"
    },
    {
      "chainId": 8453,
      "address": "0x532f27101965dd16442e59d40670faf5ebb142e4",
      "symbol": "BRETT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1713875953209_7729286940399311.png",
      "name": "Brett"
    },
    {
      "chainId": 8453,
      "address": "0x548f93779fbc992010c07467cbaf329dd5f059b7",
      "symbol": "BMX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1713247219279_03529087211957371.png",
      "name": "BMX"
    },
    {
      "chainId": 8453,
      "address": "0xbf1aea8670d2528e08334083616dd9c5f3b087ae",
      "symbol": "MAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712838802448_9017077128841848.png",
      "name": "MIMATIC"
    },
    {
      "chainId": 8453,
      "address": "0x4ed4e862860bed51a9570b96d89af5e1b0efefed",
      "symbol": "DEGEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712056751398_985093085768419.png",
      "name": "Degen"
    },
    {
      "chainId": 8453,
      "address": "0x7233062d88133b5402d39d62bfa23a1b6c8d0898",
      "symbol": "FORT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1701716594835_3367922347563703.png",
      "name": "Citadel FORT"
    },
    {
      "chainId": 8453,
      "address": "0xa3d1a8deb97b111454b294e2324efad13a9d8396",
      "symbol": "OVN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1699554882001_5145582630824388.png",
      "name": "Overnight Finance"
    },
    {
      "chainId": 8453,
      "address": "0xc2bc7a73613b9bd5f373fe10b55c59a69f4d617b",
      "symbol": "DACKIE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1695233859449_1676183686600199.jpeg",
      "name": "DackieSwap"
    },
    {
      "chainId": 8453,
      "address": "0x1dd2d631c92b1acdfcdd51a0f7145a50130050c4",
      "symbol": "ALB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1693363980475_12272411021892027.png",
      "name": "AlienBase"
    },
    {
      "chainId": 8453,
      "address": "0x22dc834c3ff3e45f484bf24b9b07b851b981900f",
      "symbol": "SMUDCAT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691737806000_37961504959053194.png",
      "name": "Smudge Cat"
    },
    {
      "chainId": 8453,
      "address": "0xe1f9ac62a2f34881f6fe0f84322de9d7024c2b8e",
      "symbol": "MOCHI",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691737720952_029337857956963642.png",
      "name": "Mochi"
    },
    {
      "chainId": 8453,
      "address": "0x27d2decb4bfc9c76f0309b8e88dec3a601fe25a8",
      "symbol": "BALD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691737588131_7234484030185611.png",
      "name": "Bald"
    },
    {
      "chainId": 8453,
      "address": "0x8544FE9D190fD7EC52860abBf45088E81Ee24a8c",
      "symbol": "TOSHI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691737329499_4863110362656602.png",
      "name": "Toshi"
    },
    {
      "chainId": 8453,
      "address": "0x78a087d713be963bf307b18f2ff8122ef9a63ae9",
      "symbol": "BSWAP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691737085038_6475484812091672.png",
      "name": "BaseSwap"
    },
    {
      "chainId": 8453,
      "address": "0x99ECb5811843894DC62f968f78bC906A7c5A5ED2",
      "symbol": "SBASE",
      "decimals": 18,
      "logoURI": "",
      "name": "SafeBase"
    },
    {
      "chainId": 8453,
      "address": "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA",
      "symbol": "USDbC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691736661698_8173935197180304.png",
      "name": "USD Base Coin"
    },
    {
      "chainId": 8453,
      "address": "0x4200000000000000000000000000000000000006",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704872227342_9754767024237914.png",
      "name": "WETH"
    },
    {
      "chainId": 8453,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 8453,
      "address": "0x4A3A6Dd60A34bB2Aba60D73B4C88315E9CeB6A3D",
      "symbol": "MIM",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mim.png",
      "name": "Magic Internet Money"
    }
  ],
  "34443": [
    {
      "chainId": 34443,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xd988097fb8612cc24eeC14542bC03424c656005f",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 34443,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xf0F161fDA2712DB8b566946122a5af183995e2eD",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 34443,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0xcDd475325D6F564d27247D1DddBb0DAc6fA0a5CF",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 34443,
      "name": "Dai",
      "symbol": "DAI",
      "address": "0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 34443,
      "name": "StakeStone Ether",
      "symbol": "STONE",
      "address": "0x80137510979822322193FC997d400D5A6C747bf7",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/33103/standard/200_200.png?1702602672"
    },
    {
      "chainId": 34443,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0x2416092f143378750bb29b79eD961ab195CcEea5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 34443,
      "name": "Wrapped eETH",
      "symbol": "weETH",
      "address": "0x028227c4dd1e5419d11Bb6fa6e661920c519D4F5",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/33033/standard/weETH.png?1701438396"
    },
    {
      "chainId": 34443,
      "address": "0x18470019bf0e94611f15852f7e93cf5d65bc34ca",
      "symbol": "ION",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1715268149727_964807331795436.png",
      "name": "Ionic Protocol"
    },
    {
      "chainId": 34443,
      "address": "0xdfc7c877a950e49d2610114102175a06c2e3167a",
      "symbol": "MODE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1715137756734_5447812101500713.jpg",
      "name": "Mode"
    },
    {
      "chainId": 34443,
      "address": "0xfda619b6d20975be80a10332cd39b9a4b0faa8bb",
      "symbol": "SMD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1711435271551_29440834628162516.png",
      "name": "SwapMode"
    },
    {
      "chainId": 34443,
      "address": "0x4200000000000000000000000000000000000006",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712555989310_6228164862053469.png",
      "name": "WETH"
    },
    {
      "chainId": 34443,
      "address": "0x59889b7021243dB5B1e065385F918316cD90D46c",
      "symbol": "M-BTC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mbtc.svg",
      "name": "Merlin BTC"
    },
    {
      "chainId": 34443,
      "address": "0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A",
      "symbol": "weETH.mode",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/weeth.png",
      "name": "Wrapped eETH"
    }
  ],
  "42161": [
    {
      "chainId": 42161,
      "name": "0xBitcoin Token",
      "symbol": "0xBTC",
      "address": "0x7cb16cb78ea464aD35c8a50ABF95dff3c9e09d5d",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2837.png"
    },
    {
      "chainId": 42161,
      "name": "Agave",
      "symbol": "AGVE",
      "address": "0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "Alchemy",
      "symbol": "ALCH",
      "address": "0x0e15258734300290a651FdBAe8dEb039a8E7a2FA",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14719/thumb/sbEW5W8.png?1617939648"
    },
    {
      "chainId": 42161,
      "name": "Aluna",
      "symbol": "ALN",
      "address": "0x9b3fa2A7C3EB36d048A5d38d81E7fAFC6bc47B25",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5544.png"
    },
    {
      "chainId": 42161,
      "name": "BAC",
      "symbol": "BAC",
      "address": "0x6F67043201C903bbCBC129750CB3b328Dd56a0a5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7813.png"
    },
    {
      "chainId": 42161,
      "name": "Badger",
      "symbol": "BADGER",
      "address": "0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7859.png"
    },
    {
      "chainId": 42161,
      "name": "Balancer",
      "symbol": "BAL",
      "address": "0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5728.png"
    },
    {
      "chainId": 42161,
      "name": "BarkCoin",
      "symbol": "BARK",
      "address": "0xBbFbde08Bf1BE235a3Fa97d6A27fFfA19Ac4a8a8",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "GoBlank Token",
      "symbol": "BLANK",
      "address": "0xA5eC9d64b64b8B9E94FEaA7538c084b38117E7Ba",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8695.png"
    },
    {
      "chainId": 42161,
      "name": "BarnBridge Governance Token",
      "symbol": "BOND",
      "address": "0x0D81E50bC677fa67341c44D7eaA9228DEE64A4e1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7440.png"
    },
    {
      "chainId": 42161,
      "name": "Boost",
      "symbol": "BOOST",
      "address": "0xd44e8F8768D4ed25119921a53802D8758A5b20dD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11216.png"
    },
    {
      "chainId": 42161,
      "name": "BTU Protocol",
      "symbol": "BTU",
      "address": "0xBA9a5Dd807c9F072850bE15a52dF3408BA25Fd18",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3737.png"
    },
    {
      "chainId": 42161,
      "name": "Cap",
      "symbol": "CAP",
      "address": "0x031d35296154279DC1984dCD93E392b1f946737b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5809.png"
    },
    {
      "chainId": 42161,
      "name": "CelerToken",
      "symbol": "CELR",
      "address": "0x3a8B787f78D775AECFEEa15706D4221B40F345AB",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3814.png"
    },
    {
      "chainId": 42161,
      "name": "Cryption Network Token",
      "symbol": "CNT",
      "address": "0x989D099d29F62b839C8CbD41c82c6554a5515752",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9747.png"
    },
    {
      "chainId": 42161,
      "name": "Compound",
      "symbol": "COMP",
      "address": "0x354A6dA3fcde098F8389cad84b0182725c6C91dE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5692.png"
    },
    {
      "chainId": 42161,
      "name": "COTI Token",
      "symbol": "COTI",
      "address": "0x6FE14d3CC2f7bDdffBa5CdB3BBE7467dd81ea101",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3992.png"
    },
    {
      "chainId": 42161,
      "name": "Cream",
      "symbol": "CREAM",
      "address": "0xf4D48Ce3ee1Ac3651998971541bAdbb9A14D7234",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6193.png"
    },
    {
      "chainId": 42161,
      "name": "Curve DAO Token",
      "symbol": "CRV",
      "address": "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6538.png"
    },
    {
      "chainId": 42161,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 42161,
      "name": "DEFI Top 5 Tokens Index",
      "symbol": "DEFI5",
      "address": "0xdeBa25AF35e4097146d7629055E0EC3C71706324",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8430.png"
    },
    {
      "chainId": 42161,
      "name": "DEGEN Index",
      "symbol": "DEGEN",
      "address": "0xAE6e3540E97b0b9EA8797B157B510e133afb6282",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/14143/thumb/alpha_logo.png?1614651244"
    },
    {
      "chainId": 42161,
      "name": "dForce",
      "symbol": "DF",
      "address": "0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4758.png"
    },
    {
      "chainId": 42161,
      "name": "DFYN Token",
      "symbol": "DFYN",
      "address": "0x1D54Aa7E322e02A0453c0F2fA21505cE7F2E9E93",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9511.png"
    },
    {
      "chainId": 42161,
      "name": "dHedge DAO Token",
      "symbol": "DHT",
      "address": "0x8038F3C971414FD1FC220bA727F2D4A0fC98cb65",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7094.png"
    },
    {
      "chainId": 42161,
      "name": "DODO bird",
      "symbol": "DODO",
      "address": "0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7224.png"
    },
    {
      "chainId": 42161,
      "name": "The Doge NFT",
      "symbol": "DOG",
      "address": "0x4425742F1EC8D98779690b5A3A6276Db85Ddc01A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11557.png"
    },
    {
      "chainId": 42161,
      "name": "Dopex Governance Token",
      "symbol": "DPX",
      "address": "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11188.png"
    },
    {
      "chainId": 42161,
      "name": "Digital Standard Unit",
      "symbol": "DSU",
      "address": "0xE212f5E733257ed5628a2FeBcEdBc9222e535F51",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/17482/thumb/photo_2021-08-03_09-24-16.png?1627953917"
    },
    {
      "chainId": 42161,
      "name": "DeversiFi Token",
      "symbol": "DVF",
      "address": "0xA7Aa2921618e3D63dA433829d448b58C9445A4c3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/10759.png"
    },
    {
      "chainId": 42161,
      "name": "DXdao",
      "symbol": "DXD",
      "address": "0xC3Ae0333F0F34aa734D5493276223d95B8F9Cb37",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5589.png"
    },
    {
      "chainId": 42161,
      "name": "Empty Set Share",
      "symbol": "ESS",
      "address": "0xCE32aA8d60807182c0003Ef9cc1976Fa10E5d312",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/17481/thumb/photo_2021-08-03_03-26-29.png?1627953584"
    },
    {
      "chainId": 42161,
      "name": "dForce EUR",
      "symbol": "EUX",
      "address": "0x969131D8ddC06C2Be11a13e6E7fACF22CF57d95e",
      "decimals": 18,
      "logoURI": "https://etherscan.io/token/images/dforceeur_32.png"
    },
    {
      "chainId": 42161,
      "name": "Flux",
      "symbol": "FLUX",
      "address": "0xF80D589b3Dbe130c270a69F1a69D050f268786Df",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5876.png"
    },
    {
      "chainId": 42161,
      "name": "Flux Protocol",
      "symbol": "FLUX",
      "address": "0x2338a5d62E9A766289934e8d2e83a443e8065b83",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9837.png"
    },
    {
      "chainId": 42161,
      "name": "Futureswap Token",
      "symbol": "FST",
      "address": "0x488cc08935458403a0458e45E20c0159c8AB2c92",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8961.png"
    },
    {
      "chainId": 42161,
      "name": "Fuse Token",
      "symbol": "FUSE",
      "address": "0xBDeF0E9ef12E689F366fe494A7A7D0dad25D9286",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5634.png"
    },
    {
      "chainId": 42161,
      "name": "GMX",
      "symbol": "GMX",
      "address": "0x590020B1005b8b25f1a2C82c5f743c540dcfa24d",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "Gnosis Token",
      "symbol": "GNO",
      "address": "0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png"
    },
    {
      "chainId": 42161,
      "name": "GOVI",
      "symbol": "GOVI",
      "address": "0x07E49d5dE43DDA6162Fa28D24d5935C151875283",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8408.png"
    },
    {
      "chainId": 42161,
      "name": "Graph Token",
      "symbol": "GRT",
      "address": "0x9623063377AD1B27544C965cCd7342f7EA7e88C7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png"
    },
    {
      "chainId": 42161,
      "name": "Impermax",
      "symbol": "IMX",
      "address": "0x9c67eE39e3C4954396b9142010653F17257dd39C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9532.png"
    },
    {
      "chainId": 42161,
      "name": "QIAN governance token",
      "symbol": "KUN",
      "address": "0x04cb2d263a7489f02d813eaaB9Ba1bb8466347F2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7721.png"
    },
    {
      "chainId": 42161,
      "name": "Land",
      "symbol": "LAND",
      "address": "0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba",
      "decimals": 18,
      "logoURI": "https://etherscan.io/token/images/farmland_32.png"
    },
    {
      "chainId": 42161,
      "name": "ChainLink Token",
      "symbol": "LINK",
      "address": "0xf97f4df75117a78c1A5a0DBb814Af92458539FB4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 42161,
      "name": "LoopringCoin V2",
      "symbol": "LRC",
      "address": "0x46d0cE7de6247b0A95f67b43B589b4041BaE7fbE",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1934.png"
    },
    {
      "chainId": 42161,
      "name": "MAGIC",
      "symbol": "MAGIC",
      "address": "0x539bdE0d7Dbd336b79148AA742883198BBF60342",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14783.png"
    },
    {
      "chainId": 42161,
      "name": "My Alpha Leaderboard",
      "symbol": "MAL",
      "address": "0xAA086809EFA469631DD90D8C6cB267bAb107E958",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "MATH Token",
      "symbol": "MATH",
      "address": "0x99F40b01BA9C469193B360f72740E416B17Ac332",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5616.png"
    },
    {
      "chainId": 42161,
      "name": "Antimatter.Finance Governance Token",
      "symbol": "MATTER",
      "address": "0xaaA62D9584Cbe8e4D68A43ec91BfF4fF1fAdB202",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8603.png"
    },
    {
      "chainId": 42161,
      "name": "MCDEX Token",
      "symbol": "MCB",
      "address": "0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5956.png"
    },
    {
      "chainId": 42161,
      "name": "Maker",
      "symbol": "MKR",
      "address": "0x2e9a6Df78E42a30712c10a9Dc4b1C8656f8F2879",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png"
    },
    {
      "chainId": 42161,
      "name": "Meta",
      "symbol": "MTA",
      "address": "0x5298Ee77A8f9E226898403eBAC33e68a62F770A0",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/11846/thumb/mStable.png?1594950533"
    },
    {
      "chainId": 42161,
      "name": "Indexed",
      "symbol": "NDX",
      "address": "0xB965029343D55189c25a7f3e0c9394DC0F5D41b1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8260.png"
    },
    {
      "chainId": 42161,
      "name": "New era",
      "symbol": "NEC",
      "address": "0xd67D9F7E018B4e7613b0251BBe3Ba3988Baf7C16",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "Feisty Doge NFT",
      "symbol": "NFD",
      "address": "0xc9c2B86CD4cdbAB70cd65D22EB044574c3539F6c",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11368.png"
    },
    {
      "chainId": 42161,
      "name": "Octo.fi",
      "symbol": "OCTO",
      "address": "0x52f5d9B3a2bB89D3aEC5829A3415c21115aCD633",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7202.png"
    },
    {
      "chainId": 42161,
      "name": "Olympus",
      "symbol": "OHM",
      "address": "0x6E6a3D8F1AfFAc703B1aEF1F43B8D2321bE40043",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16209.png"
    },
    {
      "chainId": 42161,
      "name": "OVR",
      "symbol": "OVR",
      "address": "0x55704A0e9E2eb59E176C5b69655DbD3DCDCFc0F0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8144.png"
    },
    {
      "chainId": 42161,
      "name": "Perpetual",
      "symbol": "PERP",
      "address": "0x753D224bCf9AAFaCD81558c32341416df61D3DAC",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6950.png"
    },
    {
      "chainId": 42161,
      "name": "PickleToken",
      "symbol": "PICKLE",
      "address": "0x965772e0E9c84b6f359c8597C891108DcF1c5B1A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7022.png"
    },
    {
      "chainId": 42161,
      "name": "Plenny",
      "symbol": "PL2",
      "address": "0x3642c0680329ae3e103E2B5AB29DDfed4d43CBE5",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "Premia",
      "symbol": "PREMIA",
      "address": "0x51fC0f6660482Ea73330E414eFd7808811a57Fa2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8476.png"
    },
    {
      "chainId": 42161,
      "name": "Rai Reflex Index",
      "symbol": "RAI",
      "address": "0xaeF5bbcbFa438519a5ea80B4c7181B4E78d419f2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8525.png"
    },
    {
      "chainId": 42161,
      "name": "Dopex Rebate Token",
      "symbol": "RDPX",
      "address": "0x32Eb7902D4134bf98A28b963D26de779AF92A212",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12057.png"
    },
    {
      "chainId": 42161,
      "name": "Rari Governance Token",
      "symbol": "RGT",
      "address": "0xef888bcA6AB6B1d26dbeC977C455388ecd794794",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7486.png"
    },
    {
      "chainId": 42161,
      "name": "Route",
      "symbol": "ROUTE",
      "address": "0x5298060A95205BE6Dd4aBc21910A4bB23D6DCD8b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8292.png"
    },
    {
      "chainId": 42161,
      "name": "SakeToken",
      "symbol": "SAKE",
      "address": "0x552E4e96A0Ce6D36d161b63984848c8dAC471ea2",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6997.png"
    },
    {
      "chainId": 42161,
      "name": "Stake DAO Token",
      "symbol": "SDT",
      "address": "0x7bA4a00d54A07461D9DB2aEF539e91409943AdC9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8299.png"
    },
    {
      "chainId": 42161,
      "name": "Sperax",
      "symbol": "SPA",
      "address": "0x5575552988A3A80504bBaeB1311674fCFd40aD4B",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6715.png"
    },
    {
      "chainId": 42161,
      "name": "Spell Token",
      "symbol": "SPELL",
      "address": "0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11289.png"
    },
    {
      "chainId": 42161,
      "name": "Strips Token",
      "symbol": "STRP",
      "address": "0x326c33FD1113c1F29B35B4407F3d6312a8518431",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11848.png"
    },
    {
      "chainId": 42161,
      "name": "SUM",
      "symbol": "SUM",
      "address": "0x20f9628a485ebCc566622314f6e07E7Ee61fF332",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11208.png"
    },
    {
      "chainId": 42161,
      "name": "SushiToken",
      "symbol": "SUSHI",
      "address": "0xd4d42F0b6DEF4CE0383636770eF773390d85c61A",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
      "chainId": 42161,
      "name": "Swapr",
      "symbol": "SWPR",
      "address": "0xdE903E2712288A1dA82942DDdF2c20529565aC30",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12368.png"
    },
    {
      "chainId": 42161,
      "name": "Taekwondo Access Credit",
      "symbol": "TAC",
      "address": "0xFa51B42d4C9EA35F1758828226AaEdBeC50DD54E",
      "decimals": 18,
      "logoURI": "https://etherscan.io/token/images/tkdcoop_32.png"
    },
    {
      "chainId": 42161,
      "name": "Tracer",
      "symbol": "TCR",
      "address": "0xA72159FC390f0E3C6D415e658264c7c4051E9b87",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12341.png"
    },
    {
      "chainId": 42161,
      "name": "TrueUSD",
      "symbol": "TUSD",
      "address": "0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png"
    },
    {
      "chainId": 42161,
      "name": "UniBright",
      "symbol": "UBT",
      "address": "0x2aD62674A64E698C24831Faf824973C360430140",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2758.png"
    },
    {
      "chainId": 42161,
      "name": "Unlock Discount Token",
      "symbol": "UDT",
      "address": "0xd5d3aA404D7562d09a848F96a8a8d5D65977bF90",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9364.png"
    },
    {
      "chainId": 42161,
      "name": "Uniswap",
      "symbol": "UNI",
      "address": "0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      "chainId": 42161,
      "name": "Unity Network",
      "symbol": "UNT",
      "address": "0x250F471385894fc81183a99d6fDe8CE9C5B142d6",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/14602.png"
    },
    {
      "chainId": 42161,
      "name": "Bridged USDC",
      "symbol": "USDC.e",
      "address": "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 42161,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 42161,
      "name": "dForce USD",
      "symbol": "USX",
      "address": "0xcd14C3A2ba27819B352aae73414A26e2b366dC50",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/13080.png"
    },
    {
      "chainId": 42161,
      "name": "Validator",
      "symbol": "VALX",
      "address": "0x8d1c89DcF613e3e709AfE9Abecae591D0e2B64Ca",
      "decimals": 18,
      "logoURI": "https://etherscan.io/token/images/validator_32.png"
    },
    {
      "chainId": 42161,
      "name": "VISOR",
      "symbol": "VISR",
      "address": "0x995C235521820f2637303Ca1970c7c044583df44",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9170.png"
    },
    {
      "chainId": 42161,
      "name": "Vox.Finance",
      "symbol": "VOX",
      "address": "0x2eD14d1788dfB780fD216706096AeD018514ECcd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7465.png"
    },
    {
      "chainId": 42161,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 42161,
      "name": "Wrapped CHI",
      "symbol": "WCHI",
      "address": "0xA64eCCe74F8CdB7a940766B71f1b108BAC69851a",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5541.png"
    },
    {
      "chainId": 42161,
      "name": "Wootrade Network",
      "symbol": "WOO",
      "address": "0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7501.png"
    },
    {
      "chainId": 42161,
      "name": "xToken",
      "symbol": "XTK",
      "address": "0xF0A5717Ec0883eE56438932b0fe4A20822735fBa",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8599.png"
    },
    {
      "chainId": 42161,
      "name": "yearn.finance",
      "symbol": "YFI",
      "address": "0x82e3A8F066a6989666b031d916c43672085b1582",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png"
    },
    {
      "chainId": 42161,
      "name": "Zippie",
      "symbol": "ZIPT",
      "address": "0x0F61B24272AF65EACF6adFe507028957698e032F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2724.png"
    },
    {
      "chainId": 42161,
      "name": "Interest-Bearing Bitcoin",
      "symbol": "ibBTC",
      "address": "0x9Ab3FD50FcAe73A1AEDa959468FD0D662c881b42",
      "decimals": 18,
      "logoURI": "https://assets.coingecko.com/coins/images/15500/thumb/ibbtc.png?1621077589"
    },
    {
      "chainId": 42161,
      "name": "KAKI USDC",
      "symbol": "kUSDC",
      "address": "0x4f947b40BEEB9D8130437781a560E5c7D089730f",
      "decimals": 18
    },
    {
      "chainId": 42161,
      "name": "Synth sUSD",
      "symbol": "sUSD",
      "address": "0xA970AF1a584579B618be4d69aD6F73459D112F95",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2927.png"
    },
    {
      "chainId": 42161,
      "name": "Symbiosis",
      "symbol": "SIS",
      "address": "0x9E758B8a98a42d612b3D38B66a22074DC03D7370",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15084.png"
    },
    {
      "chainId": 42161,
      "name": "Arbitrum",
      "symbol": "ARB",
      "address": "0x912CE59144191C1204E64559FE8253a0e49E6548",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png"
    },
    {
      "chainId": 42161,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 42161,
      "name": "Arbswap",
      "symbol": "ARBS",
      "address": "0xf50874f8246776CA4B89eEF471E718F70f38458F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/20661.png"
    },
    {
      "chainId": 42161,
      "name": "Rocket Pool",
      "symbol": "RPL",
      "address": "0xb766039cc6db368759c1e56b79affe831d0cc507",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/2943.png"
    },
    {
      "chainId": 42161,
      "name": "GMX",
      "symbol": "GMX",
      "address": "0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/11857.png"
    },
    {
      "chainId": 42161,
      "name": "Radiant Capital",
      "symbol": "RDNT",
      "address": "0x3082cc23568ea640225c2467653db90e9250aaa0",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/21106.png"
    },
    {
      "chainId": 42161,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 42161,
      "name": "Wrapped liquid staked Ether",
      "symbol": "wstETH",
      "address": "0x5979D7b546E38E414F7E9822514be443A4800529",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 42161,
      "name": "Axelar Wrapped USDC",
      "symbol": "AxlUSDC",
      "address": "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png"
    },
    {
      "chainId": 42161,
      "name": "Coinbase Wrapped Staked ETH",
      "symbol": "cbETH",
      "address": "0x1DEBd73E752bEaF79865Fd6446b0c970EaE7732f",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21535.png"
    },
    {
      "chainId": 42161,
      "name": "Wrapped SOL",
      "symbol": "SOL",
      "address": "0x2bcC6D6CdBbDC0a4071e48bb3B969b06B3330c07",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png"
    },
    {
      "chainId": 42161,
      "name": "Wrapped BNB",
      "symbol": "WBNB",
      "address": "0xa9004A5421372E1D83fB1f85b0fc986c912f91f3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png"
    },
    {
      "chainId": 42161,
      "name": "AAVE Token",
      "symbol": "AAVE",
      "address": "0xba5DdD1f9d7F570dc94a51479a000E3BCE967196",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"
    },
    {
      "chainId": 42161,
      "name": "Rocket Pool ETH",
      "symbol": "rETH",
      "address": "0xec70dcb4a1efa46b8f2d97c310c9c4790ba5ffa8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15060.png"
    },
    {
      "chainId": 42161,
      "name": "Lido DAO Token",
      "symbol": "LDO",
      "address": "0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8000.png"
    },
    {
      "chainId": 42161,
      "name": "Ankr Staked ETH",
      "symbol": "ankrETH",
      "address": "0xe05A08226c49b636ACf99c40Da8DC6aF83CE5bB3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8100.png"
    },
    {
      "chainId": 42161,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0x2416092f143378750bb29b79eD961ab195CcEea5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 42161,
      "name": "Staked Frax Ether",
      "symbol": "sfrxETH",
      "address": "0x95aB45875cFFdba1E5f451B950bC2E42c0053f39",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/23177.png"
    },
    {
      "chainId": 42161,
      "address": "0x7dff72693f6a4149b17e7c6314655f6a9f7c8b33",
      "symbol": "GHO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1721715100988_9831555419613607.png",
      "name": "GHO"
    },
    {
      "chainId": 42161,
      "address": "0x6985884C4392D348587B19cb9eAAf157F13271cd",
      "symbol": "ZRO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719303422943_8401090053605518.jpg",
      "name": "LayerZero"
    },
    {
      "chainId": 42161,
      "address": "0x59d9356e565ab3a36dd77763fc0d87feaf85508c",
      "symbol": "USDM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714669364548_7904770250866477.png",
      "name": "Mountain Protocol USDM"
    },
    {
      "chainId": 42161,
      "address": "0x35751007a407ca6feffe80b3cb397736d2cf4dbe",
      "symbol": "WEETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1713256155857_923155655672022.png",
      "name": "Wrapped eETH"
    },
    {
      "chainId": 42161,
      "address": "0xDCbf4CB83d27C408B30dD7F39bfcabD7176B1Ba3",
      "symbol": "OOE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1709214843485_6319207297124871.jpg",
      "name": "OpenOcean"
    },
    {
      "chainId": 42161,
      "address": "0xa3d1a8deb97b111454b294e2324efad13a9d8396",
      "symbol": "OVN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1707833349941_6487035242237684.png",
      "name": "Overnight Finance"
    },
    {
      "chainId": 42161,
      "address": "0x83d6c8c06ac276465e4c92e7ac8c23740f435140",
      "symbol": "HMX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1707746501864_33654313836813166.png",
      "name": "HMX"
    },
    {
      "chainId": 42161,
      "address": "0xcbe94d75ec713b7ead84f55620dc3174beeb1cfe",
      "symbol": "FORE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1707497877974_2938668620767584.png",
      "name": "FORE Protocol"
    },
    {
      "chainId": 42161,
      "address": "0x1622bf67e6e5747b81866fe0b85178a93c7f86e3",
      "symbol": "UMAMI",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1707407370487_4549116754222542.jpg",
      "name": "Umami"
    },
    {
      "chainId": 42161,
      "address": "0x4cb9a7ae498cedcbb5eae9f25736ae7d428c9d66",
      "symbol": "XAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1705389666084_9475367797522429.png",
      "name": "XAI"
    },
    {
      "chainId": 42161,
      "address": "0x00cbcf7b3d37844e44b888bc747bdd75fcf4e555",
      "symbol": "XPET",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1703835480816_6016786274367283.png",
      "name": "xPet.tech"
    },
    {
      "chainId": 42161,
      "address": "0xaeaeed23478c3a4b798e4ed40d8b7f41366ae861",
      "symbol": "ANKR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700546804831_7234342705497487.png",
      "name": "Ankr Network"
    },
    {
      "chainId": 42161,
      "address": "0xdb298285fe4c5410b05390ca80e8fbe9de1f259b",
      "symbol": "FOREX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1695704953670_6337929205797028.png",
      "name": "handle.fi"
    },
    {
      "chainId": 42161,
      "address": "0x872bad41cfc8ba731f811fea8b2d0b9fd6369585",
      "symbol": "gFLY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1695704826819_16224657313376767.png",
      "name": "BattleFly"
    },
    {
      "chainId": 42161,
      "address": "0x0c880f6761f1af8d9aa9c466984b80dab9a8c9e8",
      "symbol": "PENDLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692867110147_06362543995564218.jpg",
      "name": "Pendle"
    },
    {
      "chainId": 42161,
      "address": "0x580e933d90091b9ce380740e3a4a39c67eb85b4c",
      "symbol": "GSWIFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692778259912_8291465977088692.png",
      "name": "GameSwift"
    },
    {
      "chainId": 42161,
      "address": "0x11f98c7e42a367dab4f200d2fdc460fb445ce9a8",
      "symbol": "SPARTA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692777367160_4189323479482858.png",
      "name": "SpartaDEX"
    },
    {
      "chainId": 42161,
      "address": "0xabd587f2607542723b17f14d00d99b987c29b074",
      "symbol": "SDEX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692241136608_4233938879624928.jpg",
      "name": "SmarDex"
    },
    {
      "chainId": 42161,
      "address": "0x8888888888f004100C0353d657BE6300587A6CcD",
      "symbol": "ACS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692153417541_6594388155027546.png",
      "name": "ACryptoS"
    },
    {
      "chainId": 42161,
      "address": "0x7b5eb3940021ec0e8e463d5dbb4b7b09a89ddf96",
      "symbol": "WOM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691568092945_3620382274802332.jpg",
      "name": "Wombat Exchange"
    },
    {
      "chainId": 42161,
      "address": "0x250Caff618cf205997309940c14C52B5DcEb351E",
      "symbol": "CROWD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1691376214807_6689467701581033.png",
      "name": "CrowdSwap"
    },
    {
      "chainId": 42161,
      "address": "0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f",
      "symbol": "MMY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1688032829938_8281273469081127.jpg",
      "name": "Mummy Finance"
    },
    {
      "chainId": 42161,
      "address": "0x82af49447d8a07e3bd95bd0d56f35241523fbab1",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1660286569947_04237702874332694.png",
      "name": "Wrapped Ether"
    },
    {
      "chainId": 42161,
      "address": "0xeb8E93A0c7504Bffd8A8fFa56CD754c63aAeBFe8",
      "symbol": "DAI ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683711696692_9044472700788917.png",
      "name": "Overnight.fi DAI "
    },
    {
      "chainId": 42161,
      "address": "0x3b60ff35d3f7f62d636b067dd0dc0dfdad670e4e",
      "symbol": "LADYS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684407083031_41084804010732645.JPG",
      "name": "Milady Meme Coin"
    },
    {
      "chainId": 42161,
      "address": "0xaaa6c1e32c55a7bfa8066a6fae9b42650f262418",
      "symbol": "RAM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684123161035_3763019889320074.jpg",
      "name": "Ramses Exchange"
    },
    {
      "chainId": 42161,
      "address": "0x15b2fb8f08e4ac1ce019eadae02ee92aedf06851",
      "symbol": "CHR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1684122955392_3068546999300781.jpg",
      "name": "Chronos Finance"
    },
    {
      "chainId": 42161,
      "address": "0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65",
      "symbol": "USD+",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683711544327_8434015804843185.JPG",
      "name": "Overnight.fi USD+"
    },
    {
      "chainId": 42161,
      "address": "0x178412e79c25968a32e89b11f63b33f733770c2a",
      "symbol": "FRXETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683515124171_5533333101140256.jpg",
      "name": "Frax Ether"
    },
    {
      "chainId": 42161,
      "address": "0x3A18dcC9745eDcD1Ef33ecB93b0b6eBA5671e7Ca",
      "symbol": "KUJI",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1683171643713_14968656293046356.jpg",
      "name": "Kujira"
    },
    {
      "chainId": 42161,
      "address": "0x09e18590e8f76b6cf471b3cd75fe1a1a9d2b2c2b",
      "symbol": "AIDOGE",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681883066287_14452730509949108.jpg",
      "name": "ArbDoge AI"
    },
    {
      "chainId": 42161,
      "address": "0x56b251d4b493ee3956e3f899d36b7290902d2326",
      "symbol": "MMF",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681817554564_40962165897324887.jpg",
      "name": "MMFinance"
    },
    {
      "chainId": 42161,
      "address": "0xd5954c3084a1ccd70b4da011e67760b8e78aee84",
      "symbol": "ARX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681807320302_45912293125172954.png",
      "name": "Arbidex"
    },
    {
      "chainId": 42161,
      "address": "0x15a808ed3846d25e88ae868de79f1bcb1ac382b5",
      "symbol": "MVD",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1681119942693_2069786926978805.png",
      "name": "Metavault DAO"
    },
    {
      "chainId": 42161,
      "address": "0xd6c0156E85deCdF2983c7A2c4A3015eD268d6f8A",
      "symbol": "ROSX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1680604488324_033559270498115135.png",
      "name": "Roseon"
    },
    {
      "chainId": 42161,
      "address": "0x680447595e8b7b3aa1b43beb9f6098c79ac2ab3f",
      "symbol": "USDD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1680503201660_004837092111310781.png",
      "name": "USDD"
    },
    {
      "chainId": 42161,
      "address": "0x5db7b150c5f38c5f5db11dcbdb885028fcc51d68",
      "symbol": "STR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679645600271_41668230611215584.png",
      "name": "Sterling Finance"
    },
    {
      "chainId": 42161,
      "address": "0x463913d3a3d3d291667d53b8325c598eb88d3b0e",
      "symbol": "SLIZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1679645560403_12466757111418247.png",
      "name": "SolidLizard"
    },
    {
      "chainId": 42161,
      "address": "0xfac38532829fdd744373fdcd4708ab90fa0c4078",
      "symbol": "TLPT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678162086253_7155445248512882.JPG",
      "name": "tLPT"
    },
    {
      "chainId": 42161,
      "address": "0x289ba1701c2f088cf0faf8b3705246331cb8a839",
      "symbol": "LPT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1678162014631_5146931859981692.png",
      "name": "Livepeer"
    },
    {
      "chainId": 42161,
      "address": "0x876ec6be52486eeec06bc06434f3e629d695c6ba",
      "symbol": "FLUID",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1677680590520_08664980609957662.png",
      "name": "FluidFi"
    },
    {
      "chainId": 42161,
      "address": "0x982239D38Af50B0168dA33346d85Fb12929c4c07",
      "symbol": "TROVE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676989046499_8378564119198308.png",
      "name": "Arbitrove Protocol"
    },
    {
      "chainId": 42161,
      "address": "0x088cd8f5ef3652623c22d48b1605dcfe860cd704",
      "symbol": "VELA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676965623812_1266406515246865.png",
      "name": "Vela Token"
    },
    {
      "chainId": 42161,
      "address": "0x079504b86d38119f859c4194765029f692b7b7aa",
      "symbol": "LYRA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676630487983_7509778339200519.png",
      "name": "Lyra Finance"
    },
    {
      "chainId": 42161,
      "address": "0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07",
      "symbol": "JOE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1675936390934_021727992489167125.png",
      "name": "JOE"
    },
    {
      "chainId": 42161,
      "address": "0x1A5B0aaF478bf1FDA7b934c76E7692D722982a6D",
      "symbol": "BFR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1668519619894_7648242062364901.jpg",
      "name": "Buffer Token"
    },
    {
      "chainId": 42161,
      "address": "0x64343594ab9b56e99087bfa6f2335db24c2d1f17",
      "symbol": "VST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1654019188369_5033276623551377.png",
      "name": "Vesta Stable"
    },
    {
      "chainId": 42161,
      "address": "0x10393c20975cF177a3513071bC110f7962CD67da",
      "symbol": "JONES",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1649850583018_3312717213644991.png",
      "name": "Jones DAO"
    },
    {
      "chainId": 42161,
      "address": "0x6694340fc020c5E6B96567843da2df01b2CE1eb6",
      "symbol": "STG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648128062874_058739401970302474.png",
      "name": "Stargate Finance"
    },
    {
      "chainId": 42161,
      "address": "0x99c409e5f62e4bd2ac142f17cafb6810b8f0baae",
      "symbol": "BIFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676358925889_18291958091608462.png",
      "name": "Beefy.Finance"
    },
    {
      "chainId": 42161,
      "address": "0x3b475f6f2f41853706afc9fa6a6b8c5df1a2724c",
      "symbol": "ZYB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676358736128_047576584356795015.jpg",
      "name": "Zyberswap"
    },
    {
      "chainId": 42161,
      "address": "0x18c11fd286c5ec11c3b683caa813b77f5163a122",
      "symbol": "GNS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676358335880_4749195157300208.jpg",
      "name": "Gains Network"
    },
    {
      "chainId": 42161,
      "address": "0x3d9907f9a368ad0a51be60f7da3b97cf940982d8",
      "symbol": "GRAIL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1673024117385_10498688941969303.jpg",
      "name": "Camelot Token"
    },
    {
      "chainId": 42161,
      "address": "0x431402e8b9de9aa016c743880e04e517074d8cec",
      "symbol": "HEGIC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1672810147867_6771047139162278.jpg",
      "name": "Hegic"
    },
    {
      "chainId": 42161,
      "address": "0xb348b87b23d5977e2948e6f36ca07e1ec94d7328",
      "symbol": "FISH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671701403158_9553720505788565.png",
      "name": "SwapFish"
    },
    {
      "chainId": 42161,
      "address": "0xDBf31dF14B66535aF65AaC99C32e9eA844e14501",
      "symbol": "RENBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1661151044584_6641692875412855.png",
      "name": "renBTC"
    },
    {
      "chainId": 42161,
      "address": "0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a",
      "symbol": "MIM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637927428166_6678016456693587.png",
      "name": "Magic Internet Money"
    },
    {
      "chainId": 42161,
      "address": "0x17fc002b466eec40dae837fc4be5c67993ddbd6f",
      "symbol": "FRAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648904910983_5811737493655886.png",
      "name": "Frax "
    },
    {
      "chainId": 42161,
      "address": "0xcAB86F6Fb6d1C2cBeeB97854A0C023446A075Fe3",
      "symbol": "deETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650029215738_15732322915186336.png",
      "name": "deBridge Ether "
    },
    {
      "chainId": 42161,
      "address": "0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa",
      "symbol": "deUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650029132289_12499390331785354.png",
      "name": "deBridge USD Coin"
    },
    {
      "chainId": 42161,
      "address": "0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7",
      "symbol": "FXS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648904710979_811680259074864.png",
      "name": "Frax Share"
    },
    {
      "chainId": 42161,
      "address": "0xa684cd057951541187f288294a1e1C2646aA2d24",
      "symbol": "VSTA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648808309036_20667985804740718.jpg",
      "name": "Vesta Finance "
    },
    {
      "chainId": 42161,
      "address": "0xdb96f8efd6865644993505318cc08ff9c42fb9ac",
      "symbol": "Z2O",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637928264168_1276740912855694.png",
      "name": "ZeroTwOhm"
    },
    {
      "chainId": 42161,
      "address": "0x8fbd420956fdd301f4493500fd0bcaaa80f2389c",
      "symbol": "AMY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637893005424_2740901520185888.png",
      "name": "Amy Finance Token"
    },
    {
      "chainId": 42161,
      "address": "0x3509f19581afedeff07c53592bc0ca84e4855475",
      "symbol": "XUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637892796159_3482286577377456.png",
      "name": "xDollar Stablecoin"
    },
    {
      "chainId": 42161,
      "address": "0xed3fb761414da74b74f33e5c5a1f78104b188dfc",
      "symbol": "NYAN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638170899569_12235125291982096.png",
      "name": "ArbiNYAN"
    },
    {
      "chainId": 42161,
      "address": "0x10010078a54396f62c96df8532dc2b4847d47ed3",
      "symbol": "HND",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637892347363_6124669428880605.png",
      "name": "Hundred Finance"
    },
    {
      "chainId": 42161,
      "address": "0xfa42da1bd08341537a44a4ca9d236d1c00a98b40",
      "symbol": "CARBON",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637832686338_7422730180325647.png",
      "name": "Carboncoin"
    },
    {
      "chainId": 42161,
      "address": "0x07aaa29e63ffeb2ebf59b33ee61437e1a91a3bb2",
      "symbol": "QSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637832211908_8528681646600584.png",
      "name": " QIAN second generation dollar"
    },
    {
      "chainId": 42161,
      "address": "0x641441c631e2f909700d2f41fd87f0aa6a6b4edb",
      "symbol": "USX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637831920597_5897580640643303.png",
      "name": "dForce USD"
    },
    {
      "chainId": 42161,
      "address": "0x9ef758ac000a354479e538b8b2f01b917b8e89e7",
      "symbol": "XDO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637831645816_6024340081244819.png",
      "name": "xDollar"
    },
    {
      "chainId": 42161,
      "address": "0x23a941036ae778ac51ab04cea08ed6e2fe103614",
      "symbol": "GRT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637749726435_5769394092149096.png",
      "name": "Graph Token"
    },
    {
      "chainId": 42161,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    }
  ],
  "42170": [
    {
      "chainId": 42170,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 42170,
      "name": "Arbitrum",
      "symbol": "ARB",
      "address": "0xf823C3cD3CeBE0a1fA952ba88Dc9EEf8e0Bf46AD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png"
    }
  ],
  "43114": [
    {
      "chainId": 43114,
      "name": "Pangolin",
      "symbol": "PNG",
      "address": "0x60781C2586D68229fde47564546784ab3fACA982",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8422.png"
    },
    {
      "chainId": 43114,
      "name": "Wrapped AVAX",
      "symbol": "WAVAX",
      "address": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9462.png"
    },
    {
      "chainId": 43114,
      "name": "Ether",
      "symbol": "ETH",
      "address": "0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    },
    {
      "chainId": 43114,
      "name": "1INCH Network",
      "symbol": "1INCH",
      "address": "0xd501281565bf7789224523144Fe5D98e8B28f267",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png"
    },
    {
      "chainId": 43114,
      "name": "Aave Token",
      "symbol": "AAVE",
      "address": "0x8cE2Dee54bB9921a2AE0A63dBb2DF8eD88B91dD9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png"
    },
    {
      "chainId": 43114,
      "name": "Binance USD",
      "symbol": "BUSD.e",
      "address": "0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 43114,
      "name": "ChainLink Token",
      "symbol": "LINK",
      "address": "0xB3fe5374F67D7a22886A0eE082b2E2f9d2651651",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 43114,
      "name": "Graph Token",
      "symbol": "GRT.e",
      "address": "0x8a0cac13c7da965a312f08ea4229c37869e85cb9",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png"
    },
    {
      "chainId": 43114,
      "name": "SushiToken",
      "symbol": "SUSHI",
      "address": "0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png"
    },
    {
      "chainId": 43114,
      "name": "Synthetix Network Token",
      "symbol": "SNX",
      "address": "0xbec243c995409e6520d7c41e404da5deba4b209b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png"
    },
    {
      "chainId": 43114,
      "name": "UMA Voting Token v1",
      "symbol": "UMA.e",
      "address": "0x3bd2b1c7ed8d396dbb98ded3aebb41350a5b2339",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5617.png"
    },
    {
      "chainId": 43114,
      "name": "Uniswap",
      "symbol": "UNI.e",
      "address": "0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      "chainId": 43114,
      "name": "Wrapped BTC",
      "symbol": "WBTC.e",
      "address": "0x50b7545627a5162f82a992c33b87adc75187b218",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 43114,
      "name": "yearn.finance",
      "symbol": "YFI",
      "address": "0x99519AcB025a0e0d44c3875A4BbF03af65933627",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/5864.png"
    },
    {
      "chainId": 43114,
      "name": "Joe Token",
      "symbol": "JOE",
      "address": "0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11396.png"
    },
    {
      "chainId": 43114,
      "name": "Magic Internet Money",
      "symbol": "MIM",
      "address": "0x130966628846BFd36ff31a822705796e8cb8C18D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/162.png"
    },
    {
      "chainId": 43114,
      "name": "Frax Share",
      "symbol": "FXS",
      "address": "0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6953.png"
    },
    {
      "chainId": 43114,
      "name": "Wrapped Ether",
      "symbol": "WETH",
      "address": "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2396.png"
    },
    {
      "chainId": 43114,
      "name": "Synapse",
      "symbol": "SYN",
      "address": "0x1f1E7c893855525b303f99bDF5c3c05Be09ca251",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12147.png"
    },
    {
      "chainId": 43114,
      "name": "Wrapped MEMO",
      "symbol": "wMEMO",
      "address": "0x0da67235dD5787D67955420C84ca1cEcd4E5Bb3b",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17076.png"
    },
    {
      "chainId": 43114,
      "name": "LostToken",
      "symbol": "LOST",
      "address": "0x449674B82F05d498E126Dd6615a1057A9c088f2C",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19024.png"
    },
    {
      "chainId": 43114,
      "name": "Biometric Financial",
      "symbol": "BioFi",
      "address": "0x9366d30FeBA284E62900f6295BC28c9906f33172",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19907.png"
    },
    {
      "chainId": 43114,
      "name": "Step App",
      "symbol": "FITFI",
      "address": "0x714f020C54cc9D104B6F4f6998C63ce2a31D1888",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/19778.png"
    },
    {
      "chainId": 43114,
      "name": "Wonderland",
      "symbol": "TIME",
      "address": "0xb54f16fB19478766A268F172C9480f8da1a7c9C3",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11585.png"
    },
    {
      "chainId": 43114,
      "name": "Yield Yak",
      "symbol": "YAK",
      "address": "0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11415.png"
    },
    {
      "chainId": 43114,
      "name": "ROCO FINANCE",
      "symbol": "ROCO",
      "address": "0xb2a85C5ECea99187A977aC34303b80AcbDdFa208",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/12653.png"
    },
    {
      "chainId": 43114,
      "name": "Avalaunch",
      "symbol": "XAVA",
      "address": "0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9797.png"
    },
    {
      "chainId": 43114,
      "name": "Heroes of NFT",
      "symbol": "HON",
      "address": "0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/17969.png"
    },
    {
      "chainId": 43114,
      "name": "Platypus Finance",
      "symbol": "PTP",
      "address": "0x22d4002028f537599bE9f666d1c4Fa138522f9c8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16231.png"
    },
    {
      "chainId": 43114,
      "name": "Dai Stablecoin",
      "symbol": "DAI.e",
      "address": "0xd586E7F844cEa2F87f50152665BCbc2C279D8d70",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 43114,
      "name": "Tether USD",
      "symbol": "USDT.e",
      "address": "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 43114,
      "name": "Chainlink Token",
      "symbol": "LINK.e",
      "address": "0x5947BB275c521040051D82396192181b413227A3",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png"
    },
    {
      "chainId": 43114,
      "name": "MAKER Token",
      "symbol": "MKR",
      "address": "0x88128fd4b259552A9A1D457f435a6527AAb72d42",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png"
    },
    {
      "chainId": 43114,
      "name": "Ampleforth",
      "symbol": "AMPL",
      "address": "0x027dbcA046ca156De9622cD1e2D907d375e53aa7",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4056.png"
    },
    {
      "chainId": 43114,
      "name": "BENQI",
      "symbol": "QI",
      "address": "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/9288.png"
    },
    {
      "chainId": 43114,
      "name": "Stargate Finance",
      "symbol": "STG",
      "address": "0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/18934.png"
    },
    {
      "chainId": 43114,
      "name": "Akita Inu",
      "symbol": "AKITA",
      "address": "0xcaF5191fc480F43e4DF80106c7695ECA56E48B18",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/8378.png"
    },
    {
      "chainId": 43114,
      "name": "Circle USD",
      "symbol": "USDC",
      "address": "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 43114,
      "name": "Frax",
      "symbol": "FRAX",
      "address": "0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/6952.png"
    },
    {
      "chainId": 43114,
      "name": "GMX",
      "symbol": "GMX",
      "address": "0x62edc0692bd897d2295872a9ffcac5425011c661",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/11857.png"
    },
    {
      "chainId": 43114,
      "name": "TrueUSD",
      "symbol": "TUSD",
      "address": "0x1c20e891bab6b1727d14da358fae2984ed9b59eb",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png"
    },
    {
      "chainId": 43114,
      "address": "0xaaab9d12a30504559b0c5a9a5977fee4a6081c6b",
      "symbol": "PHAR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1715941183101_12762460889492955.png",
      "name": "Pharaoh"
    },
    {
      "chainId": 43114,
      "address": "0x69260b9483f9871ca57f81a90d91e2f96c2cd11d",
      "symbol": "GGP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1706235403084_12632883818993368.png",
      "name": "GoGoPool"
    },
    {
      "chainId": 43114,
      "address": "0xa25eaf2906fa1a3a13edac9b9657108af7b703e3",
      "symbol": "ggAVAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1706081260306_5826336964078325.png",
      "name": "GoGoPool Liquid Staking Token"
    },
    {
      "chainId": 43114,
      "address": "0x093783055f9047c2bff99c4e414501f8a147bc69",
      "symbol": "ALOT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1703170512708_30826305241079854.png",
      "name": "Dexalot"
    },
    {
      "chainId": 43114,
      "address": "0x420fca0121dc28039145009570975747295f2329",
      "symbol": "COQ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1702979338437_691251252057336.png",
      "name": "Coq Inu"
    },
    {
      "chainId": 43114,
      "address": "0x12d8ce035c5de3ce39b1fdd4c1d5a745eaba3b8c",
      "symbol": "ankrETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1700546070997_09423946012733353.png",
      "name": "Ankr Staked ETH "
    },
    {
      "chainId": 43114,
      "address": "0xfb98b335551a418cd0737375a2ea0ded62ea213b",
      "symbol": "PENDLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692867080675_29652009112931177.jpg",
      "name": "Pendle"
    },
    {
      "chainId": 43114,
      "address": "0x8888888888f004100C0353d657BE6300587A6CcD",
      "symbol": "ACS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692153462767_5199942823294075.png",
      "name": "ACryptoS"
    },
    {
      "chainId": 43114,
      "address": "0xC891EB4cbdEFf6e073e859e987815Ed1505c2ACD",
      "symbol": "EUROC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1685192961782_9817228801491376.png",
      "name": "Euro Coin"
    },
    {
      "chainId": 43114,
      "address": "0x18706c65b12595edb43643214eacdb4f618dd166",
      "symbol": "BAY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1676360553404_20690969300261175.png",
      "name": "BayMax Finance"
    },
    {
      "chainId": 43114,
      "address": "0xFc6Da929c031162841370af240dEc19099861d3B",
      "symbol": "DOMI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671703152887_8539481614586732.png",
      "name": "Domi"
    },
    {
      "chainId": 43114,
      "address": "0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB",
      "symbol": "JYPC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671697670591_1553113735309477.png",
      "name": "JPY Coin"
    },
    {
      "chainId": 43114,
      "address": "0x9c9e5fd8bbc25984b178fdce6117defa39d2db39",
      "symbol": "BUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1671245282822_780468336302703.png",
      "name": "Binance-Peg BUSD Token"
    },
    {
      "chainId": 43114,
      "address": "0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642600369413_5107823985163122.jpg",
      "name": "TetherToken"
    },
    {
      "chainId": 43114,
      "address": "0x152b9d0FdC40C096757F570A51E494bd4b943E50",
      "symbol": "BTC.b",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1656670178703_23116297089352522.svg",
      "name": "Bitcoin"
    },
    {
      "chainId": 43114,
      "address": "0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE",
      "symbol": "sAVAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1645066444760_8595672573461763.png",
      "name": "Staked AVAX"
    },
    {
      "chainId": 43114,
      "address": "0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4",
      "symbol": "VTX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1653301995083_5171753833585997.png",
      "name": "Vector Finance"
    },
    {
      "chainId": 43114,
      "address": "0xc0c5aa69dbe4d6dddfbc89c0957686ec60f24389",
      "symbol": "XEN",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1667531785629_4504213763629119.png",
      "name": "XEN Crypto"
    },
    {
      "chainId": 43114,
      "address": "0xDF474B7109b73b7D57926d43598D5934131136b2",
      "symbol": "ANKR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1663822909158_03175858068336357.svg",
      "name": "Ankr"
    },
    {
      "chainId": 43114,
      "address": "0xc3344870d52688874b06d844e0c36cc39fc727f6",
      "symbol": "ankrAVAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1658311661542_9477867315587034.JPG",
      "name": "Ankr Reward Bearing AVAX"
    },
    {
      "chainId": 43114,
      "address": "0xa32608e873f9ddef944b24798db69d80bbb4d1ed",
      "symbol": "CRA",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639306373105_6034549969845249.png",
      "name": "Crabada"
    },
    {
      "chainId": 43114,
      "address": "0xf693248f96fe03422fea95ac0afbbbc4a8fdd172",
      "symbol": "TUS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639306286889_2387037009318147.png",
      "name": "Treasure Under Sea "
    },
    {
      "chainId": 43114,
      "address": "0x111111111111ed1d73f860f57b2798b683f2d325",
      "symbol": "YUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1656578721416_8111554053618262.png",
      "name": "YUSD Stablecoin"
    },
    {
      "chainId": 43114,
      "address": "0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5",
      "symbol": "FIRE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1649755361718_40860946525206465.jpg",
      "name": "The Phoenix"
    },
    {
      "chainId": 43114,
      "address": "0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3",
      "symbol": "deUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1650029114650_08327457524438842.png",
      "name": "deBridge USD Coin"
    },
    {
      "chainId": 43114,
      "address": "0x214db107654ff987ad859f34125307783fc8e387",
      "symbol": "FXS",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648904691464_6847375181795985.png",
      "name": "Frax Share"
    },
    {
      "chainId": 43114,
      "address": "0xf329e36C7bF6E5E86ce2150875a84Ce77f477375",
      "symbol": "AAAVE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648801885630_9658702907022438.png",
      "name": "Aave AAVE"
    },
    {
      "chainId": 43114,
      "address": "0x6ab707Aca953eDAeFBc4fD23bA73294241490620",
      "symbol": "AUSDT",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648801688472_23325592396064065.png",
      "name": "Aave USDT"
    },
    {
      "chainId": 43114,
      "address": "0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8",
      "symbol": "AWETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648801472951_9881779257920693.png",
      "name": "Aave WETH"
    },
    {
      "chainId": 43114,
      "address": "0x078f358208685046a11C85e8ad32895DED33A249",
      "symbol": "AWBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648801354201_4787929897969496.png",
      "name": "Aave WBTC"
    },
    {
      "chainId": 43114,
      "address": "0x625e7708f30ca75bfd92586e17077590c60eb4cd",
      "symbol": "AUSDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648800598729_3527648953250244.png",
      "name": "Aave USDC"
    },
    {
      "chainId": 43114,
      "address": "0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530",
      "symbol": "ALINK",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648800470041_9427809593504186.png",
      "name": "Aave LINK"
    },
    {
      "chainId": 43114,
      "address": "0x82e64f49ed5ec1bc6e43dad4fc8af9bb3a2312ee",
      "symbol": "ADAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648800277587_1321964226633754.png",
      "name": "Aave DAI"
    },
    {
      "chainId": 43114,
      "address": "0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11",
      "symbol": "axlUST",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1648112491796_24445859804930659.png",
      "name": "Axelar Wrapped UST"
    },
    {
      "chainId": 43114,
      "address": "0xaBC9547B534519fF73921b1FBA6E672b5f58D083",
      "symbol": "WOO.e",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1647941920208_5275606528824555.jpg",
      "name": "WOO Network"
    },
    {
      "chainId": 43114,
      "address": "0x0802d66f029c46E042b74d543fC43B6705ccb4ba",
      "symbol": "APE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1647685241637_4302493200865387.jpg",
      "name": "ApeCoin"
    },
    {
      "chainId": 43114,
      "address": "0x9f285507ea5b4f33822ca7abb5ec8953ce37a645",
      "symbol": "DEG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1647665047651_18980156443833396.png",
      "name": "Degis Token"
    },
    {
      "chainId": 43114,
      "address": "0x783C08b5F26E3daf8C4681F3bf49844e425b6393",
      "symbol": "AUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1646208600388_8016544631355003.png",
      "name": "Avaware USD"
    },
    {
      "chainId": 43114,
      "address": "0xD81D45E7635400dDD9c028839e9a9eF479006B28",
      "symbol": "EMBR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1646218584742_47622400801306086.jpg",
      "name": "EmbrToken"
    },
    {
      "chainId": 43114,
      "address": "0xa5e2cfe48fe8c4abd682ca2b10fcaafe34b8774c",
      "symbol": "PSHARE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1645065656628_4353546372297692.png",
      "name": "Piggy Share"
    },
    {
      "chainId": 43114,
      "address": "0xc7f4debc8072e23fe9259a5c0398326d8efb7f5c",
      "symbol": "HEC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1644404157998_40441311252506384.JPG",
      "name": "HeroesChained"
    },
    {
      "chainId": 43114,
      "address": "0x924157b5dbb387a823719916b25256410a4ad470",
      "symbol": "SLOT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1644403513197_6776366153258726.png",
      "name": "Snowtomb LOT "
    },
    {
      "chainId": 43114,
      "address": "0x959b88966fc5b261df8359961357d34f4ee27b4a",
      "symbol": "UNIV",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1643719344123_41268550283451755.png",
      "name": "Universe"
    },
    {
      "chainId": 43114,
      "address": "0x8f47416cae600bccf9530e9f3aeaa06bdd1caa79",
      "symbol": "THOR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1643287574184_7974688197778437.png",
      "name": "Thor"
    },
    {
      "chainId": 43114,
      "address": "0xb599c3590F42f8F995ECfa0f85D2980B76862fc1",
      "symbol": "UST",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1643285575217_5413973955114924.png",
      "name": "UST (Wormhole)"
    },
    {
      "chainId": 43114,
      "address": "0x120ad3e5a7c796349e591f1570d9f7980f4ea9cb",
      "symbol": "LUNA",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1643285433238_40967396633121966.png",
      "name": "Axelar Wrapped LUNA"
    },
    {
      "chainId": 43114,
      "address": "0x8ae8be25c23833e0a01aa200403e826f611f9cd2",
      "symbol": "CRAFT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642749795998_9188862228298427.png",
      "name": "TaleCraft"
    },
    {
      "chainId": 43114,
      "address": "0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30",
      "symbol": "DCAU",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642471130793_5218729421275803.png",
      "name": "Dragon Crypto Aurum"
    },
    {
      "chainId": 43114,
      "address": "0x4f60a160d8c2dddaafe16fcc57566db84d674bd6",
      "symbol": "JEWEL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1642171408600_5046980904932108.png",
      "name": "DeFi Kingdoms"
    },
    {
      "chainId": 43114,
      "address": "0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0",
      "symbol": "BOOFI ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641124082185_29693805163186826.png",
      "name": "Boo Finance Token"
    },
    {
      "chainId": 43114,
      "address": "0x321e7092a180bb43555132ec53aaa65a5bf84251",
      "symbol": "gOHM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1641123933739_4123388231654983.png",
      "name": "Governance OHM"
    },
    {
      "chainId": 43114,
      "address": "0x320aDa89DbFA3A154613D2731c9BC3a4030DbA19",
      "symbol": "FROST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640318459223_9295976235790222.png",
      "name": "FROST"
    },
    {
      "chainId": 43114,
      "address": "0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6",
      "symbol": "IME",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640318143358_38174107752311426.png",
      "name": "Imperium Empires Token"
    },
    {
      "chainId": 43114,
      "address": "0xff579d6259dedcc80488c9b89d2820bcb5609160",
      "symbol": "LVT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640173289337_8609771459843021.png",
      "name": "Louverture"
    },
    {
      "chainId": 43114,
      "address": "0x5684a087c739a2e845f4aaaabf4fbd261edc2be8",
      "symbol": "LF",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1640172932821_9120856902758341.png",
      "name": "Life"
    },
    {
      "chainId": 43114,
      "address": "0x1ecd47ff4d9598f89721a2866bfeb99505a413ed",
      "symbol": "AVME",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1639571210434_14193944164659333.png",
      "name": "AVME"
    },
    {
      "chainId": 43114,
      "address": "0xF2F7CE610a091B94d41D69f4fF1129434a82E2f0",
      "symbol": "GG",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638963262869_334559632639281.png",
      "name": "GalaxyGoggle"
    },
    {
      "chainId": 43114,
      "address": "0x7C08413cbf02202a1c13643dB173f2694e0F73f0",
      "symbol": "MAXI",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638963161999_9906499738727959.png",
      "name": "Maximizer "
    },
    {
      "chainId": 43114,
      "address": "0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D",
      "symbol": "MELT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1638013353119_5668285530135033.png",
      "name": "Defrost Finance Token "
    },
    {
      "chainId": 43114,
      "address": "0x8cd309e14575203535ef120b5b0ab4dded0c2073",
      "symbol": "wsOHM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637660405200_18417188279485397.png",
      "name": "Wrapped sOHM"
    },
    {
      "chainId": 43114,
      "address": "0x397bbd6a0e41bdf4c3f971731e180db8ad06ebc1",
      "symbol": "AVXT ",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1637200355637_3591249937463865.png",
      "name": "Avaxtars Token"
    },
    {
      "chainId": 43114,
      "address": "0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17",
      "symbol": "DYP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636702678438_013388706409719786.png",
      "name": "DeFiYieldProtocol"
    },
    {
      "chainId": 43114,
      "address": "0x7d1232B90D3F809A54eeaeeBC639C62dF8a8942f",
      "symbol": "SB",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636445434248_176771240066546.png",
      "name": "Snowbank"
    },
    {
      "chainId": 43114,
      "address": "0x0f34919404a290e71fc6A510cB4a6aCb8D764b24",
      "symbol": "BLIZZ",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1636445213134_6193080826515858.png",
      "name": "Blizz.Finance Protocol Token"
    },
    {
      "chainId": 43114,
      "address": "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      "symbol": "USDC.e",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664.png",
      "name": "USD Coin"
    },
    {
      "chainId": 43114,
      "address": "0x6d923f688c7ff287dc3a5943caeefc994f97b290",
      "symbol": "SMRTR",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635759017012_19737946137709228.png",
      "name": "SmarterCoin"
    },
    {
      "chainId": 43114,
      "address": "0x542fA0B261503333B90fE60c78F2BeeD16b7b7fD",
      "symbol": "TRACTOR",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1635754380046_7659663962089835.png",
      "name": "TRACTOR JOE"
    },
    {
      "chainId": 43114,
      "address": "0x63a72806098bd3d9520cc43356dd78afe5d386d9",
      "symbol": "AAVE.e",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x63a72806098bd3d9520cc43356dd78afe5d386d9.png",
      "name": "Aave Token"
    },
    {
      "chainId": 43114,
      "address": "0x78ea17559b3d2cf85a7f9c2c704eda119db5e6de",
      "symbol": "AVE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x78ea17559b3d2cf85a7f9c2c704eda119db5e6de.png",
      "name": "Avaware"
    },
    {
      "chainId": 43114,
      "address": "0xa1144a6A1304bd9cbb16c800F7a867508726566E",
      "symbol": "BAG",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0xa1144a6a1304bd9cbb16c800f7a867508726566e.png",
      "name": "Baguette"
    },
    {
      "chainId": 43114,
      "address": "0x81440c939f2c1e34fc7048e518a637205a632a74",
      "symbol": "CYCLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x81440c939f2c1e34fc7048e518a637205a632a74.png",
      "name": "Cycle Token"
    },
    {
      "chainId": 43114,
      "address": "0x90842eb834cfd2a1db0b1512b254a18e4d396215",
      "symbol": "GB",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x90842eb834cfd2a1db0b1512b254a18e4d396215.png",
      "name": "Good Bridging"
    },
    {
      "chainId": 43114,
      "address": "0xb27c8941a7Df8958A1778c0259f76D1F8B711C35",
      "symbol": "KLO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0xb27c8941a7df8958a1778c0259f76d1f8b711c35.png",
      "name": "Kalao"
    },
    {
      "chainId": 43114,
      "address": "0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084",
      "symbol": "LYD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084.png",
      "name": "LydiaFinance Token"
    },
    {
      "chainId": 43114,
      "address": "0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c",
      "symbol": "PEFI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0xe896cdeaac9615145c0ca09c8cd5c25bced6384c.png",
      "name": "Penguin Finance"
    },
    {
      "chainId": 43114,
      "address": "0xC38f41A296A4493Ff429F1238e030924A1542e50",
      "symbol": "SNOB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0xc38f41a296a4493ff429f1238e030924a1542e50.png",
      "name": "Snowball"
    },
    {
      "chainId": 43114,
      "address": "0xce1bffbd5374dac86a2893119683f4911a2f7814",
      "symbol": "SPELL",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0xce1bffbd5374dac86a2893119683f4911a2f7814.png",
      "name": "Spell Token"
    },
    {
      "chainId": 43114,
      "address": "0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985",
      "symbol": "SPORE",
      "decimals": 9,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x6e7f5c0b9f4432716bdd0a77a3601291b9d9e985.png",
      "name": "Spore"
    },
    {
      "chainId": 43114,
      "address": "0x6afd5a1ea4b793cc1526d6dc7e99a608b356ef7b",
      "symbol": "STORM",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x6afd5a1ea4b793cc1526d6dc7e99a608b356ef7b.png",
      "name": "Storm Token"
    },
    {
      "chainId": 43114,
      "address": "0x37b608519f91f70f2eeb0e5ed9af4061722e4f76",
      "symbol": "SUSHI.e",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x37b608519f91f70f2eeb0e5ed9af4061722e4f76.png",
      "name": "SushiToken"
    },
    {
      "chainId": 43114,
      "address": "0x094bd7b2d99711a1486fb94d4395801c6d0fddcc",
      "symbol": "TEDDY",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x094bd7b2d99711a1486fb94d4395801c6d0fddcc.png",
      "name": "Teddy Cash"
    },
    {
      "chainId": 43114,
      "address": "0x4fbf0429599460d327bd5f55625e30e4fc066095",
      "symbol": "TSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x4fbf0429599460d327bd5f55625e30e4fc066095.png",
      "name": "Teddy Dollar"
    },
    {
      "chainId": 43114,
      "address": "0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a",
      "symbol": "VSO",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a.png",
      "name": "Verso"
    },
    {
      "chainId": 43114,
      "address": "0x9eaac1b23d935365bd7b542fe22ceee2922f52dc",
      "symbol": "YFI.e",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/avax/0x9eaac1b23d935365bd7b542fe22ceee2922f52dc.png",
      "name": "yearn.finance"
    },
    {
      "chainId": 43114,
      "address": "0xcc2f1d827b18321254223df4e84de399d9ff116c",
      "symbol": "SMRT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1634806070088_48027228841072933.jpeg",
      "name": "Smartcoin"
    }
  ],
  "56288": [
    {
      "chainId": 56288,
      "name": "Binance Coin",
      "symbol": "BNB",
      "address": "0x4200000000000000000000000000000000000023",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png"
    },
    {
      "chainId": 56288,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x1E633Dcd0d3D349126983D58988051F7c62c543D",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 56288,
      "name": "Binance USD",
      "symbol": "BUSD",
      "address": "0x4a2c2838c3907D024916c3f4Fe07832745Ae4bec",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png"
    },
    {
      "chainId": 56288,
      "name": "Ethereum",
      "symbol": "ETH",
      "address": "0x38Ca7a190f57D38F4DC44413508e1078cD9E0045",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
    }
  ],
  "59144": [
    {
      "chainId": 59144,
      "name": "Symbiosis",
      "symbol": "SIS",
      "address": "0x6EF95B6f3b0F39508e3E04054Be96D5eE39eDE0d",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15084.png"
    },
    {
      "chainId": 59144,
      "name": "Izumi Finance",
      "symbol": "IZI",
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/16305.png"
    },
    {
      "chainId": 59144,
      "name": "Axelar Wrapped USDC",
      "symbol": "AxlUSDC",
      "address": "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png"
    },
    {
      "chainId": 59144,
      "name": "Wrapped liquid staked Ether 2.0",
      "symbol": "wstETH",
      "address": "0xB5beDd42000b71FddE22D3eE8a79Bd49A568fC8F",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 59144,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0x2416092f143378750bb29b79eD961ab195CcEea5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 59144,
      "name": "Dai Stablecoin",
      "symbol": "DAI",
      "address": "0x4AF15ec2A0BD43Db75dd04E62FAA3B8EF36b00d5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 59144,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xA219439258ca9da29E9Cc4cE5596924745e12B93",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 59144,
      "address": "0x1Bf74C010E6320bab11e2e5A532b5AC15e0b8aA6",
      "symbol": "weETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1716174130164_4898439812717552.png",
      "name": "Wrapped eETH"
    },
    {
      "chainId": 59144,
      "address": "0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1686640679739_2713352589901794.png",
      "name": "WETH"
    },
    {
      "chainId": 59144,
      "address": "0x176211869ca2b568f2a7d4ee941e073a821ee1ff",
      "symbol": "USDC",
      "decimals": 6,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1692780041837_266541839292614.jpg",
      "name": "USD Coin"
    },
    {
      "chainId": 59144,
      "address": "0x3aAB2285ddcDdaD8edf438C1bAB47e1a9D05a9b4",
      "symbol": "WBTC",
      "decimals": 8,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704419911857_6277920754520832.png",
      "name": "Wrapped Bitcoin"
    },
    {
      "chainId": 59144,
      "address": "0x1be3735dd0c0eb229fb11094b6c277192349ebbf",
      "symbol": "LUBE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1714410146731_1648396882628771.png",
      "name": "LUBE"
    },
    {
      "chainId": 59144,
      "address": "0xf3b001d64c656e30a62fbaaca003b1336b4ce12a",
      "symbol": "MAI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712838846208_6331034495914698.png",
      "name": "MIMATIC"
    },
    {
      "chainId": 59144,
      "address": "0x43E8809ea748EFf3204ee01F08872F063e44065f",
      "symbol": "MENDI",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1711937671546_7970585391518368.png",
      "name": "Mendi Finance"
    },
    {
      "chainId": 59144,
      "address": "0xaaaac83751090c6ea42379626435f805ddf54dc8",
      "symbol": "NILE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1710341386070_48341495115785693.png",
      "name": "Nile"
    },
    {
      "chainId": 59144,
      "address": "0x1a51b19CE03dbE0Cb44C1528E34a7EDD7771E9Af",
      "symbol": "LYNX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1708754146054_9668020962253807.png",
      "name": "Lynex"
    },
    {
      "chainId": 59144,
      "address": "0x93f4d0ab6a8b4271f4a28db399b5e30612d21116",
      "symbol": "STONE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704420555639_9475281891428406.png",
      "name": "StakeStone ETH"
    },
    {
      "chainId": 59144,
      "address": "0x8C56017B172226fE024dEa197748FC1eaccC82B1",
      "symbol": "XFIT",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1704419827258_09833745826195028.jpg",
      "name": "XFai"
    },
    {
      "chainId": 59144,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "IUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690343501595_12850304813730773.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 59144,
      "address": "0x5471ea8f739dd37E9B81Be9c5c77754D8AA953E4",
      "symbol": "AVAX",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690343284796_9812246512871032.png",
      "name": "Avalanche"
    },
    {
      "chainId": 59144,
      "address": "0x265B25e22bcd7f10a5bD6E6410F10537Cc7567e8",
      "symbol": "MATIC",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1690343211690_12508380286986087.png",
      "name": "Polygon"
    },
    {
      "chainId": 59144,
      "address": "0xf5C6825015280CdfD0b56903F9F8B5A2233476F5",
      "symbol": "BNB",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1689765638340_8814697163483636.png",
      "name": "BNB"
    },
    {
      "chainId": 59144,
      "address": "0x7d43AABC515C356145049227CeE54B608342c0ad",
      "symbol": "BUSD",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1685671015103_3678742051846413.png",
      "name": "Binance USD"
    },
    {
      "chainId": 59144,
      "address": "0xe4D584ae9b753e549cAE66200A6475d2f00705f7",
      "symbol": "M-BTC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/mbtc.svg",
      "name": "Merlin BTC"
    },
    {
      "chainId": 59144,
      "address": "0x5FFcE65A40f6d3de5332766ffF6A28BF491C868c",
      "symbol": "SolvBTC.m",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/solvbtc.png",
      "name": "Free Bridged SolvBTC"
    },
    {
      "chainId": 59144,
      "address": "0x5FBDF89403270a1846F5ae7D113A989F850d1566",
      "symbol": "FOXY",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/foxy.png",
      "name": "Foxy Token"
    },
    {
      "chainId": 59144,
      "address": "0xB97F21D1f2508fF5c73E7B5AF02847640B1ff75d",
      "symbol": "LAB",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/lab.png",
      "name": "LineaBank Token"
    },
    {
      "chainId": 59144,
      "address": "0x78354f8dccb269a615a7e0a24f9b0718fdc3c7a7",
      "symbol": "ZERO",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zero.png",
      "name": "ZeroLend Token"
    },
    {
      "chainId": 59144,
      "address": "0x3cDEF3c187B271D8c2D71091d3a6506bC929f8B9",
      "symbol": "ZKUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zkusdl.png",
      "name": "ZKUSD Stablecoin"
    },
    {
      "chainId": 59144,
      "address": "0xb20116eE399f15647BB1eEf9A74f6ef3b58bc951",
      "symbol": "LYU",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/lyu.png",
      "name": "LYVE Debt Token"
    },
    {
      "chainId": 59144,
      "address": "0xfc04413ca9360256beb7c66ad73348faa8f92c68",
      "symbol": "LINE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/line.png",
      "name": "The Line Token"
    }
  ],
  "81457": [
    {
      "chainId": 81457,
      "name": "BlastCat",
      "symbol": "BCAT",
      "address": "0x9bD75c164dAf830733AC2EA71A0258f95aac7C57",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29590.png"
    },
    {
      "chainId": 81457,
      "name": "Blast Inu",
      "symbol": "BINU",
      "address": "0x34050224F9eA1859790b7CBBBe2264f1204771A6",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29596.png"
    },
    {
      "chainId": 81457,
      "name": "Finger Blast",
      "symbol": "FINGER",
      "address": "0x7217124C626f0b7077bE91dF939195C9a8184ecC",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29597.png"
    },
    {
      "chainId": 81457,
      "name": "USDB",
      "symbol": "USDB",
      "address": "0x4300000000000000000000000000000000000003",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29599.png"
    },
    {
      "chainId": 81457,
      "name": "Axelar Wrapped USDC",
      "symbol": "axlUSDC",
      "address": "0xEB466342C4d449BC9f53A865D5Cb90586f405215",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png"
    },
    {
      "chainId": 81457,
      "name": "Renzo Restaked ETH",
      "symbol": "ezETH",
      "address": "0x2416092f143378750bb29b79eD961ab195CcEea5",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/29520.png"
    },
    {
      "chainId": 81457,
      "address": "0x15d24de366f69b835be19f7cf9447e770315dd80",
      "symbol": "KAP",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719503976735_8939047348184361.jpeg",
      "name": "KAP Games"
    },
    {
      "chainId": 81457,
      "address": "0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad",
      "symbol": "BLAST",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1719459120906_6812715278603554.png",
      "name": "BLAST"
    },
    {
      "chainId": 81457,
      "address": "0x4300000000000000000000000000000000000004",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1711951881121_7429812530569315.png",
      "name": "WETH"
    },
    {
      "chainId": 81457,
      "address": "0x73c369F61c90f03eb0Dd172e95c90208A28dC5bc",
      "symbol": "OLE",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1712733652430_671120309098274.png",
      "name": "powering both DistrictOne and OpenLeverage"
    },
    {
      "chainId": 81457,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 81457,
      "address": "0xf782e172a14ee1c85cd980c15375ba0e87957028",
      "symbol": "CACA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/caca.png",
      "name": "Ting Tiger Cat"
    },
    {
      "chainId": 81457,
      "address": "0x0bde92b80c759326b89b62c5082febed90e8627f",
      "symbol": "BOOM",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/boom.png",
      "name": "Boom!"
    },
    {
      "chainId": 81457,
      "address": "0x2b193502f4b8f5f8fe22692449a30f52bf6364f2",
      "symbol": "BANG",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bang.png",
      "name": "Bang!"
    },
    {
      "chainId": 81457,
      "address": "0x038f1C6ED5FccF690A920a27b39366eeeF27eFCe",
      "symbol": "RICH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/rich.png",
      "name": "Frog Rich"
    }
  ],
  "167000": [
    {
      "chainId": 167000,
      "name": "TAIKO",
      "symbol": "TAIKO",
      "address": "0xa9d23408b9ba935c230493c40c73824df71a0975",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/31525.png"
    },
    {
      "chainId": 167000,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x07d83526730c7438048D55A4fc0b850e2aaB6f0b",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 167000,
      "address": "0xa2fba3fde6c9e7386716b577e1258577cb9b5bf7",
      "symbol": "iZi",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/izumi.svg",
      "name": "iZUMi Token"
    },
    {
      "chainId": 167000,
      "address": "0x2DEF195713CF4a606B49D07E520e22C17899a736",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD"
    },
    {
      "chainId": 167000,
      "address": "0xA51894664A773981C6C112C43ce576f315d5b1B6",
      "symbol": "ETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/eth.png",
      "name": "Ether"
    },
    {
      "chainId": 167000,
      "address": "0x9c2dc7377717603eB92b2655c5f2E7997a4945BD",
      "symbol": "USDT",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD (Stargate)"
    },
    {
      "chainId": 167000,
      "address": "0x19e26B0638bf63aa9fa4d14c6baF8D52eBE86C5C",
      "symbol": "USDC.e",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdcstar.png",
      "name": "Bridged USDC (Stargate)"
    },
    {
      "chainId": 167000,
      "address": "0x2D9E1dA77F086c50f93db46E554974B71536a9Ee",
      "symbol": "MAGA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/maga.png",
      "name": "MAGA Token"
    }
  ],
  "534352": [
    {
      "chainId": 534352,
      "name": "Symbiosis",
      "symbol": "SIS",
      "address": "0x1467b62A6AE5CdcB10A6a8173cfe187DD2C5a136",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15084.png"
    },
    {
      "chainId": 534352,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0x3C1BCa5a656e69edCD0D4E36BEbb3FcDAcA60Cf1",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png"
    },
    {
      "chainId": 534352,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 534352,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 534352,
      "name": "Uniswap token",
      "symbol": "UNI",
      "address": "0x434cdA25E8a2CA5D9c1C449a8Cb6bCbF719233E8",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png"
    },
    {
      "chainId": 534352,
      "name": "Pancakeswap token",
      "symbol": "CAKE",
      "address": "0xF613F403C2887aa473b46252da3AA5D6a6084b15",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png"
    },
    {
      "chainId": 534352,
      "name": "DAI stable coin",
      "symbol": "DAI",
      "address": "0xcA77eB3fEFe3725Dc33bccB54eDEFc3D9f764f97",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 534352,
      "name": "Rocket Pool ETH",
      "symbol": "rETH",
      "address": "0x53878B874283351D26d206FA512aEcE1Bef6C0dD",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/15060.png"
    },
    {
      "chainId": 534352,
      "name": "Wrapped stETH",
      "symbol": "wstETH",
      "address": "0xf610A9dfB7C89644979b4A0f27063E9e7d7Cda32",
      "decimals": 18,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/128x128/12409.png"
    },
    {
      "chainId": 534352,
      "name": "Axelar Wrapped USDC",
      "symbol": "AxlUSDC",
      "address": "0xeb466342c4d449bc9f53a865d5cb90586f405215",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/21420.png"
    },
    {
      "chainId": 534352,
      "address": "0x5300000000000000000000000000000000000004",
      "symbol": "WETH",
      "decimals": 18,
      "logoURI": "https://s3.openocean.finance/token_logos/logos/1698028713856_06541191978532601.png",
      "name": "WETH"
    },
    {
      "chainId": 534352,
      "address": "0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747",
      "symbol": "iZi",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/izumi.svg",
      "name": "iZUMi Token"
    },
    {
      "chainId": 534352,
      "address": "0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D",
      "symbol": "iUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/iusd.png",
      "name": "iZUMi Bond USD"
    },
    {
      "chainId": 534352,
      "address": "0x2Bbbdf97295F73175b12CC087cF446765931e1C3",
      "symbol": "wstETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/wsteth.png",
      "name": "Wrapped liquid staked Ether 2.0"
    },
    {
      "chainId": 534352,
      "address": "0x80137510979822322193FC997d400D5A6C747bf7",
      "symbol": "STONE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/stone.png",
      "name": "Stake Stone Ether"
    },
    {
      "chainId": 534352,
      "address": "0x01f0a31698c4d065659b9bdc21b3610292a1c506",
      "symbol": "weETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/weeth.png",
      "name": "Wrapped eETH"
    },
    {
      "chainId": 534352,
      "address": "0xc4d46e8402f476f269c379677c99f18e22ea030e",
      "symbol": "PufETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/pufeth.png",
      "name": "Puffer Vault"
    },
    {
      "chainId": 534352,
      "address": "0xa25b25548b4c98b0c7d3d27dca5d5ca743d68b7f",
      "symbol": "wrsETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/wrseth.png",
      "name": "rsETH Wrapper"
    },
    {
      "chainId": 534352,
      "address": "0xb65aD8d81d1E4Cb2975352338805AF6e39BA8Be8",
      "symbol": "SCROLLY",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/scrolly.png",
      "name": "Scrolly The Map"
    },
    {
      "chainId": 534352,
      "address": "0x4e198da3efc21ffad5c2d1501a439354157b3269",
      "symbol": "SWIF",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/swif.png",
      "name": "Scroll Wif Hat"
    },
    {
      "chainId": 534352,
      "address": "0xdd6a49995ad38fe7409b5d5cb5539261bd1bc901",
      "symbol": "CAT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/cat.png",
      "name": "Danjuan Cat"
    },
    {
      "chainId": 534352,
      "address": "0x61a9cc561b6c1f9c31bcdeb447afecf25f33bbf9",
      "symbol": "PANDA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/panda.png",
      "name": "Panda Token"
    },
    {
      "chainId": 534352,
      "address": "0xeDEAbc3A1e7D21fE835FFA6f83a710c70BB1a051",
      "symbol": "LUSD",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/lusd.png",
      "name": "Liquity USD"
    },
    {
      "chainId": 534352,
      "address": "0x79379C0E09a41d7978f883a56246290eE9a8c4d3",
      "symbol": "AAVE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/aave.png",
      "name": "Aave Token"
    },
    {
      "chainId": 534352,
      "address": "0x6a28e90582c583fcd3347931c544819C31e9D0e0",
      "symbol": "BAL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/bal.png",
      "name": "Balancer Token"
    },
    {
      "chainId": 534352,
      "address": "0xB755039eDc7910C1F1BD985D48322E55A31AC0bF",
      "symbol": "CRV",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/crv.png",
      "name": "Curve DAO"
    },
    {
      "chainId": 534352,
      "address": "0x608ef9A3BffE206B86c3108218003b3cfBf99c84",
      "symbol": "KNC",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/knc.png",
      "name": "Kyber Network Crystal"
    },
    {
      "chainId": 534352,
      "address": "0x690f1d2da47d9a759a93dd2b0ace3c1627f216ba",
      "symbol": "VEN",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/ven.png",
      "name": "Venium Token"
    },
    {
      "chainId": 534352,
      "address": "0xab48f0427cd97a5ce3e78bf05faaac3a7f77a117",
      "symbol": "WASSIE",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/wassie.png",
      "name": "Wassie Token"
    },
    {
      "chainId": 534352,
      "address": "0xD9692f1748aFEe00FACE2da35242417dd05a8615",
      "symbol": "GHO",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/gho.png",
      "name": "Gho Token"
    },
    {
      "chainId": 534352,
      "address": "0x1f4f171676f8cb3b1c3fd38867b3b160679f934a",
      "symbol": "KALA",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/kala.png",
      "name": "Kalax Token"
    },
    {
      "chainId": 534352,
      "address": "0x2A00647F45047f05BDed961Eb8ECABc42780e604",
      "symbol": "LAB.s",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/labs.png",
      "name": "LayerBank Token"
    },
    {
      "chainId": 534352,
      "address": "0x95a52EC1d60e74CD3Eb002fE54A2c74b185A4C16",
      "symbol": "SKY",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/sky.png",
      "name": "Skydrome Token"
    },
    {
      "chainId": 534352,
      "address": "0x3275fa3a5e244a17b16a9867ebf5e0f9e5d9fcd0",
      "symbol": "EGGROLL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/eggroll.png",
      "name": "蛋卷"
    }
  ],
  "810180": [
    {
      "chainId": 810180,
      "name": "Dai",
      "symbol": "DAI",
      "address": "0xF573fA04A73d5AC442F3DEa8741317fEaA3cDeab",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png"
    },
    {
      "chainId": 810180,
      "name": "Tether USD",
      "symbol": "USDT",
      "address": "0x2F8A25ac62179B31D62D7F80884AE57464699059",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
    },
    {
      "chainId": 810180,
      "name": "USD Coin",
      "symbol": "USDC",
      "address": "0x1a1A3b2ff016332e866787B311fcB63928464509",
      "decimals": 6,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
    },
    {
      "chainId": 810180,
      "name": "Wrapped BTC",
      "symbol": "WBTC",
      "address": "0xDa4AaEd3A53962c83B35697Cd138cc6df43aF71f",
      "decimals": 8,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png"
    },
    {
      "chainId": 810180,
      "address": "0x8280a4e7D5B3B658ec4580d3Bc30f5e50454F169",
      "symbol": "ETH",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/eth.png",
      "name": "Ether"
    },
    {
      "chainId": 810180,
      "address": "0xC967dabf591B1f4B86CFc74996EAD065867aF19E",
      "symbol": "ZKL",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zkl.png",
      "name": "zkLink Token"
    },
    {
      "chainId": 810180,
      "address": "0xfFE944D301BB97b1271f78c7d0E8C930b75DC51B",
      "symbol": "USDC.Linea",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin (Linea)"
    },
    {
      "chainId": 810180,
      "address": "0xAF5852CA4Fc29264226Ed0c396dE30C945589D6D",
      "symbol": "USDT.Linea",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD (Linea)"
    },
    {
      "chainId": 810180,
      "address": "0x220B1C622c8c169a9174f42CEA89a9E2f83B63F6",
      "symbol": "USDC.Eth",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin (Ethereum)"
    },
    {
      "chainId": 810180,
      "address": "0x0ace5E8e1Be0d3Df778f639d79fa8231b376b9F1",
      "symbol": "USDT.Eth",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD (Ethereum)"
    },
    {
      "chainId": 810180,
      "address": "0x60CF0D62329699A23E988d500A7E40Faae4a3E4D",
      "symbol": "USDC.ZkSync",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin (ZkSync Era)"
    },
    {
      "chainId": 810180,
      "address": "0x8Fed4307f02eCcbd9EC88C84081Ba5eDCAcD0964",
      "symbol": "USDT.ZkSync",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD (ZkSync Era)"
    },
    {
      "chainId": 810180,
      "address": "0x7581469cb53E786F39ff26E8aF6Fd750213dAcEd",
      "symbol": "USDC.Arbi",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin (Arbitrum)"
    },
    {
      "chainId": 810180,
      "address": "0x012726F9f458a63f86055b24E67BA0aa26505028",
      "symbol": "USDT.Arbi",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdt.png",
      "name": "Tether USD (Arbitrum)"
    },
    {
      "chainId": 810180,
      "address": "0xA8A59Bb7fe9fE2364ae39a3B48E219fAB096c852",
      "symbol": "USDC.Manta",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin (Manta)"
    },
    {
      "chainId": 810180,
      "address": "0x4E340B4Ea46ca1D1CE6e2dF7b21e649e2921521f",
      "symbol": "USDC.Mantle",
      "decimals": 6,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/usdc.png",
      "name": "USD Coin (Mantle)"
    },
    {
      "chainId": 810180,
      "address": "0xEbc45Ef3B6D7E31573DAa9BE81825624725939f9",
      "symbol": "WBTC.Eth",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/WBTC.png",
      "name": "Wrapped BTC (Ethereum)"
    },
    {
      "chainId": 810180,
      "address": "0x3DabBd8A31a411E85f628278d6601fCeD82f6844",
      "symbol": "WBTC.Arbi",
      "decimals": 8,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/WBTC.png",
      "name": "Wrapped BTC (Arbitrum)"
    },
    {
      "chainId": 810180,
      "address": "0xbEAf16cFD8eFe0FC97C2a07E349B9411F5dC272C",
      "symbol": "SolvBTC.m",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/solvbtc.png",
      "name": "Free Bridged SolvBTC"
    },
    {
      "chainId": 810180,
      "address": "0xFb8dBdc644eb54dAe0D7A9757f1e6444a07F8067",
      "symbol": "BTCT",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/btct.png",
      "name": "BTCT Token"
    },
    {
      "chainId": 810180,
      "address": "0x6d316f7bfc72d3924ef37c782f066044a4347da8",
      "symbol": "ZK",
      "decimals": 18,
      "logoURI": "https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/tokens/zk.png",
      "name": "ZK"
    }
  ],
  "11155111": [
    {
      "chainId": 11155111,
      "name": "Wrapped TON",
      "symbol": "WTON",
      "address": "0x331f40cc27ac106e1d5242ce633dc6436626a6f8",
      "decimals": 9,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/11419.png"
    }
  ],
  // "728126428": [
  //   {
  //     "chainId": 728126428,
  //     "name": "USD Coin",
  //     "symbol": "USDC",
  //     "address": "0x3487B63D30B5B2C87FB7FFA8BCFADE38EAAC1ABE",
  //     "decimals": 6,
  //     "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "name": "Sundog",
  //     "symbol": "SUNDOG",
  //     "address": "0xEA4E3DFACDF6D5B25F74CE4B689D79105043583C",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TXL6rJbvmjD46zeN1JssfgxvSo99qC8MRT.png"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "name": "FOFAR",
  //     "symbol": "FOFAR",
  //     "address": "0xC895F64CCF06E60B777ACAD85AD5ADC22ACA4B48",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TUFonyWZ4Tza5MzgDj6g2u5rfdGoRVYG7g.jpeg"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "name": "SUNCAT",
  //     "symbol": "SUNCAT",
  //     "address": "0x0A955A2E79EF21F3C16A2102214281BF12F21A39",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TAwAg9wtQzTMFsijnSFotJrpxhMm3AqW1d.jpeg"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "name": "SUNWUKONG",
  //     "symbol": "SUNWUKONG",
  //     "address": "0x8F793A97122706692FD97E58FBD7703125D722B5",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TP3prcvQknVthrVnn281cKST56eWiLgJJM.png"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "name": "Invest Zone",
  //     "symbol": "IVfun",
  //     "address": "0xB7BA8910F0F1AFAAB8D6156E45DC1EF06788F817",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TSig7sWzEL2K83mkJMQtbyPpiVSbR6pZnb.jpeg"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "address": "0xD1B1C28F4C49C85F11DFCE645E1F8217CDF1796A",
  //     "symbol": "DRGN",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TV5yB8f4AdoAfVVUdkytyZnX5e7SeGAZr2.jpeg",
  //     "name": "Dragon Sun"
  //   },
  //   {
  //     "chainId": 728126428,
  //     "address": "0x2C7D76E4709FC6ACBA784D7A3F326CC2CEE8D03D",
  //     "symbol": "MUNCAT",
  //     "decimals": 18,
  //     "logoURI": "https://static.tronscan.org/production/upload/logo/new/sun/TE2T2vLnEQT1XW647EAQAHWqd6NZL1hweR.jpeg",
  //     "name": "MUNCAT"
  //   }
  // ]
};

export default tokens;