import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Eip1193Provider, BrowserProvider, ethers } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useSwitchNetwork
} from "@web3modal/ethers/react";
import { toast } from "react-toastify";
import axios from "axios";
import {
  SEI_LP_CONTRACT_ADDRESS
} from "../abis/FairLaunchFactoryAddress";
import fairLaunchFactorySeiABI from "../abis/FairLaunchFactorySeiABI";

interface WithdrawalHistory {
  timestamp: string;
  withdrawnAmount: string;
  signer: `0x${string}` | undefined;
  balanceAfter: number;
  accumulatedFeesAfter: number;
  transactionHash: any;
  network: string;
}

export default function AdminDashboard() {
  const [contractBalance, setContractBalance] = useState("0");
  const [treasuryAddress, setTreasuryAddress] = useState("");
  const [accumulatedFees, setAccumulatedFees] = useState("0");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [newTreasuryAddress, setNewTreasuryAddress] = useState("");
  const [withdrawalHistory, setWithdrawalHistory] = useState<
    WithdrawalHistory[]
  >([]);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);

  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [fairLaunchContract, setFairLaunchContract] = React.useState(
    null as any
  );

  const [feePercent, setFeePercent] = useState<number>(0);
  const [targetMarketCap, setTargetMarketCap] = useState<number>(0);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [initialAmount, setInitialAmount] = useState<number>(0);
  const { switchNetwork } = useSwitchNetwork()

  useEffect(() => {
    if (chainId && chainId !== 1329) {
      toast.warn("Please switch to Sei network!")
    }
  }, [chainId])

  useEffect(() => {
    pageSetup(); 

    fetchWithdrawalHistory();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, walletProvider]);

  const pageSetup = async () => {
    if (walletProvider) {
      const ethersProvider = new BrowserProvider(
        walletProvider as Eip1193Provider
      );
      const tempSigner = await ethersProvider.getSigner();
      const contract = new ethers.Contract(SEI_LP_CONTRACT_ADDRESS, fairLaunchFactorySeiABI.abi, tempSigner);
      setFairLaunchContract(contract);
      await updateContractData(contract);
    }
  };

  const fetchContractParameters = async (contract: ethers.Contract) => {
    try {
      const fee = await contract.FEE_PERCENT();
      const marketCap = await contract.TARGET_MARKET_CAP();
      const supply = await contract.TOTAL_SUPPLY(); 
      const initial = await contract.INITIAL_AMOUNT();

      setFeePercent(Number(fee));
      setTargetMarketCap(Number(marketCap));
      setTotalSupply(Number(ethers.formatEther(supply)));
      setInitialAmount(Number(initial));
    } catch (error) {
      console.error("Failed to fetch contract parameters:", error);
      toast.error("Failed to fetch contract parameters. Please try again.");
    }
  };

  const updateContractData = async (contract: ethers.Contract) => {
    try {
      const balance = await contract.getContractBalance();
      const treasury = await contract.treasury_address();
      const fees = await contract.accumulatedFees();

      setContractBalance(ethers.formatEther(balance));
      setTreasuryAddress(treasury);
      setAccumulatedFees(ethers.formatEther(fees));

      await fetchContractParameters(contract);
    } catch (error) {
      console.error("Failed to fetch contract data:", error);
      toast.error("Failed to fetch contract data. Please try again.");
    }
  };

  const saveWithdrawalHistory = async (
    contract: ethers.Contract,
    withdrawnAmount: string,
    tnxHash: any
  ) => {
    const balance = await contract.getContractBalance();
    const fees = await contract.getAccumulatedFees();

    const newHistory = {
      timestamp: new Date().toLocaleTimeString(),
      withdrawnAmount,
      balanceAfter: parseFloat(ethers.formatEther(balance)),
      accumulatedFeesAfter: parseFloat(ethers.formatEther(fees)),
      signer: address,
      transactionHash: tnxHash,
      network: "Sei",
    };
    const headers = { 
      'Access-Control-Allow-Origin': '*',
      'x-api-key': process.env.REACT_APP_AUTH_KEY,
      'x-user-identifier': process.env.REACT_APP_USER_ID
    };
    await axios.post(
      `${process.env.REACT_APP_API_URL}/withdrawal-history`,
      newHistory,
      { headers: headers }
    );

    setWithdrawalHistory((prevData) => [...prevData, newHistory]);
  };

  const fetchWithdrawalHistory = async () => {
    try {
      const headers = { 
        'Access-Control-Allow-Origin': '*',
        'x-api-key': process.env.REACT_APP_AUTH_KEY,
        'x-user-identifier': process.env.REACT_APP_USER_ID
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/withdrawal-history`,
        { headers: headers }
      );
      setWithdrawalHistory(response.data);
    } catch (error) {
      console.error("Failed to fetch withdrawal history:", error);
      toast.error("Failed to fetch withdrawal history. Please try again.");
    }
  };

  const handleWithdraw = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first.");
      return;
    }

    if (chainId && chainId !== 1329) {
      await switchNetwork(1329);
    }

    try {
      const tx = await fairLaunchContract.withdrawFees(
        ethers.parseEther(withdrawAmount)
      );
      const txResp = await tx.wait();

      toast.success(
        `Successfully withdrawn ${withdrawAmount} tokens to treasury.`
      );
      setWithdrawAmount("");
      await updateContractData(fairLaunchContract);
      await saveWithdrawalHistory(
        fairLaunchContract,
        withdrawAmount,
        txResp.hash
      );
      setOpenWithdrawDialog(false);
    } catch (error) {
      toast.error("Failed to withdraw. Please check the amount and try again.");
    }
  };

  const handleSetTreasuryAddress = async () => {
    if (!isConnected) {
      toast.error("Please connect your wallet first.");
      return;
    }

    if (chainId && chainId !== 1329) {
      await switchNetwork(1329);
    }

    if (!ethers.isAddress(newTreasuryAddress)) {
      toast.error(
        "Invalid address format. Please enter a valid Ethereum address."
      );
      return;
    }

    try {
      const tx = await fairLaunchContract.setTreasuryAddress(
        ethers.getAddress(newTreasuryAddress)
      );
      await tx.wait();

      toast.success("Treasury address updated successfully.");
      setNewTreasuryAddress("");
      await updateContractData(fairLaunchContract);
    } catch (error) {
      console.error("Failed to set new treasury address:", error);
      toast.error("Failed to set new treasury address. Please try again.");
    }
  };

  const handleUpdateParameter = async (
    paramName: string,
    value: number,
    setterFunction: string
  ) => {
    if (!isConnected) {
      toast.error("Please connect your wallet first.");
      return;
    }

    if (chainId && chainId !== 1329) {
      await switchNetwork(1329);
    }

    try {
      const tx = await fairLaunchContract[setterFunction](value);
      await tx.wait();

      toast.success(`${paramName} updated successfully.`);
      await updateContractData(fairLaunchContract);
    } catch (error) {
      console.error(`Failed to update ${paramName}:`, error);
      toast.error(`Failed to update ${paramName}. Please try again.`);
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Contract Balance</Typography>
              <Typography variant="h4">
                {Number(parseFloat(contractBalance).toFixed(4))} SEI
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Accumulated Fees</Typography>
              <Typography variant="h4">
                {Number(parseFloat(accumulatedFees).toFixed(4))} SEI
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenWithdrawDialog(true)}
          >
            Withdraw Fees
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Treasury Address</Typography>
              <Typography>{treasuryAddress}</Typography>
              <Box sx={{ mt: 2 }}>
                <TextField
                  label="New Treasury Address"
                  variant="outlined"
                  fullWidth
                  value={newTreasuryAddress}
                  onChange={(e) => setNewTreasuryAddress(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" onClick={handleSetTreasuryAddress}>
                  Update Treasury Address
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Contract Parameters
              </Typography>
              <Grid container spacing={3}>
                {[
                  {
                    label: "Fee Percent",
                    current: feePercent,
                    setter: setFeePercent,
                    action: "setFeePercent",
                  },
                  {
                    label: "Target Market Cap",
                    current: targetMarketCap,
                    setter: setTargetMarketCap,
                    action: "setTargetMarketCap",
                  },
                  {
                    label: "Total Supply",
                    current: totalSupply,
                    setter: setTotalSupply,
                    action: "setTotalSupply",
                  },
                  {
                    label: "Initial Amount",
                    current: initialAmount,
                    setter: setInitialAmount,
                    action: "setInitialAmount",
                  },
                ].map((param, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Typography variant="subtitle1">{param.label}</Typography>
                    <Typography variant="body1" gutterBottom>
                      Current: {param.current}
                    </Typography>
                    <TextField
                      label={`New ${param.label}`}
                      type="number"
                      fullWidth
                      margin="normal"
                      onChange={(e) => param.setter(Number(e.target.value))}
                    />
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() =>
                        handleUpdateParameter(
                          param.label,
                          param.current,
                          param.action
                        )
                      }
                      sx={{ mt: 1 }}
                    >
                      Update {param.label}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Typography variant="h6" sx={{ p: 2 }}>
              Balance and Fees History
            </Typography>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Timestamp</TableCell>
                    <TableCell>Withdrawn Amount</TableCell>
                    <TableCell>Signer</TableCell>
                    <TableCell>Balance After</TableCell>
                    <TableCell>Accumulated Fees After</TableCell>
                    <TableCell>Transaction Hash</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {withdrawalHistory.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.timestamp}</TableCell>
                      <TableCell>
                        {Number(parseFloat(row.withdrawnAmount).toFixed(4))}
                      </TableCell>
                      <TableCell>{row.signer}</TableCell>
                      <TableCell>{row.balanceAfter.toFixed(4)}</TableCell>
                      <TableCell>
                        {row.accumulatedFeesAfter.toFixed(4)}
                      </TableCell>
                      <TableCell>
                        <a
                          href={`https://seitrace.com/tx/${row.transactionHash}?chain=pacific-1`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.transactionHash.slice(0, 10)}...
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openWithdrawDialog}
        onClose={() => setOpenWithdrawDialog(false)}
      >
        <DialogTitle>Withdraw Fees</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the amount of fees you want to withdraw to the treasury.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Amount to Withdraw"
            type="number"
            fullWidth
            variant="outlined"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenWithdrawDialog(false)}>Cancel</Button>
          <Button onClick={handleWithdraw}>Withdraw</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}